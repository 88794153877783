import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LateSubscriber } from 'src/app/allocation-api';

@Component({
  selector: 'app-late-subscribers-modal',
  templateUrl: './late-subscribers-modal.component.html',
  styleUrls: ['./late-subscribers-modal.component.scss'],
  providers: [DatePipe]
})
export class LateSubscribersModalComponent {
  lateSubscribers: Array<LateSubscriber> | undefined;

  constructor(private config: DynamicDialogConfig, private datePipe: DatePipe) {
    this.lateSubscribers = this.config.data.lateSubscribers;
  }

  subscriptionName(editionId?: number): string {
    switch (editionId?.toString()[0]) {
      case '5':
        return 'Glambag';
      case '6':
        return 'Glampass';
      default:
        return 'Glambox';
    }
  }

  editionDate(editionId?: number): string | null {
    if (!editionId) {
      return null;
    }
    const data =
      ((editionId / 100) % 10000).toFixed(0) +
      '-' +
      (editionId % 100).toString().padStart(2, '0') +
      '-01 12:00:00';
    return this.datePipe.transform(
      new Date(data),
      'MMMM/yyyy',
      undefined,
      'pt-BR'
    );
  }
}
