<div class="title">Produtos padrão do Draft: {{ draft?.name }}</div>
<div><b>Edição</b>: {{ edition?.theme }}</div>
<div>
  Defina as variantes que serão enviadas em <b>todas as composições</b> por
  padrão.
</div>
<br />
<div>
  <b>Assinantes no draft: </b> {{ draftSubscribers?.length | number : '1.0-0' }}
</div>
<div class="selection" *ngIf="products && user && selectedProducts">
  <p-pickList
    [source]="products"
    [target]="selectedProducts"
    [sourceHeader]="'Variantes em estoque (' + products.length + ')'"
    [targetHeader]="'Selecionadas (' + selectedProducts.length + ')'"
    [dragdrop]="true"
    [responsive]="true"
    breakpoint="1200px"
    styleClass="products-list"
    [showSourceControls]="false"
    [showTargetControls]="false"
    [showSourceFilter]="true"
    [showTargetFilter]="true"
    filterBy="productVariantName,internalEAN,brandName,subcategory,category,productVariantId"
    sourceFilterPlaceholder="Buscar por Nome ou CEAN"
    (onMoveToTarget)="checkStocks() && saveDraftDefaultProducts()"
    (onMoveToSource)="reorder() && saveDraftDefaultProducts()"
    (onMoveAllToTarget)="checkStocks() && saveDraftDefaultProducts()"
    (onMoveAllToSource)="reorder() && saveDraftDefaultProducts()"
    styleClass="product-list"
    scrollHeight="75vh"
  >
    <ng-template let-product #item>
      <app-product-card
        [user]="user"
        [draft]="draft"
        [dragging]="isSelected(product)"
        [product]="product"
        [showFilters]="false"
        [changeSubscription]="product.selected"
        class="product-cards"
        (subscriptionChange)="product.subscriptionId = $event"
      ></app-product-card>
    </ng-template>
    <ng-template #emptyfiltermessagesource>
      Sem variantes com o filtro informado
    </ng-template>
  </p-pickList>
  <div class="buttons">
    <p-button
      styleClass="p-button-primary"
      [disabled]="!selectedProducts.length"
      label="Avançar"
      (onClick)="submit()"
    ></p-button>
    <small class="error" *ngIf="!selectedProducts.length">
      *Obrigatório pelo menos um
    </small>
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
