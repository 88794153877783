import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServicesModule } from 'src/app/services/services.module';
import { AdminRoutingModule } from './users-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordUpdateModule } from 'src/app/components/password-update/password-update.module';
import { UserFormModule } from 'src/app/components/user-form/user-form.module';
import { PasswordUpdateModalComponent } from './password-update-modal/password-update-modal.component';
import { UserFormModalComponent } from './user-form-modal/user-form-modal.component';
import { UsersComponent } from './users.component';

@NgModule({
  declarations: [
    UsersComponent,
    UserFormModalComponent,
    PasswordUpdateModalComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ServicesModule,
    TableModule,
    ButtonModule,
    ConfirmPopupModule,
    DropdownModule,
    InputTextModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    TooltipModule,
    UserFormModule,
    PasswordUpdateModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class UsersModule {}
