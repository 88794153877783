import {
  Component,
  OnInit,
  Signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeDTO,
  BeautyProfileAttribute,
  Draft,
  DraftCompositionFilter,
  DraftCompositionFilterControllerService,
  DraftCompositionProductReserved,
  DraftCompositionRequest,
  DraftCompositionSummary,
  DraftControllerService,
  DraftProductFilter,
  DraftSubscriberBadgeDTO,
  DraftSubscriberStateCities,
  FilterDTO,
  FilterTypeDTO,
  Group,
  Option,
  PersonControllerService,
  ProductCategory,
  ProductControllerService,
  ProductVariantAvailable,
  ProductVariantBrand
} from 'src/app/allocation-api';
import { ProductSelectModalComponent } from 'src/app/components/product-select-modal/product-select-modal.component';
import {
  ActionButtonClickEvent,
  TableActionButton,
  TableColumn
} from 'src/app/components/table/table.component';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';
import { CompositionSubscribersModalComponent } from '../../draft-summary/composition-subscribers-modal/composition-subscribers-modal.component';

@Component({
  selector: 'app-draft-composition-filters-modal',
  templateUrl: './draft-composition-filters-modal.component.html',
  styleUrl: './draft-composition-filters-modal.component.scss',
  providers: [ConfirmationService, DialogService],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class DraftCompositionFiltersModalComponent implements OnInit {
  @ViewChild(MultiSelect)
  multiselect: MultiSelect | undefined;

  config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private draftService: DraftControllerService = inject(DraftControllerService);
  private personService: PersonControllerService = inject(
    PersonControllerService
  );
  private productService: ProductControllerService = inject(
    ProductControllerService
  );
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private dialog: DialogService = inject(DialogService);
  private filterService: DraftCompositionFilterControllerService = inject(
    DraftCompositionFilterControllerService
  );
  private ref: DynamicDialogRef = inject(DynamicDialogRef);

  isEdition = computed(
    () =>
      this.config.data.draft !== undefined &&
      ((this.product() && !this.reserved()) ||
        this.config.data.draftComposition === undefined)
  );
  productFilters: WritableSignal<Array<DraftProductFilter> | undefined> =
    signal(undefined);
  productOrignalFilters: Array<DraftProductFilter> | undefined;
  draftCompositionFilters: WritableSignal<
    Array<DraftProductFilter> | undefined
  > = signal(undefined);
  productValidFilters = computed(() =>
    this.productFilters()
      ?.filter(
        (f) =>
          f.filterId !== 13 ||
          f.value !== this.product().productVariantId?.toString()
      )
      .map((f) => ({
        ...f,
        value: f.value
          .split(',')
          .filter((v) => v !== this.product().productVariantId.toString())
          .toString()
      }))
  );
  subscriberCount: number;
  filters: WritableSignal<Array<FilterDTO> | undefined> = signal(undefined);
  states: Array<DraftSubscriberStateCities> | undefined;
  badges: Array<DraftSubscriberBadgeDTO> | undefined;
  allBadges: Array<BadgeDTO> | undefined;
  categories: Array<ProductCategory> | undefined;
  categoriesList: Array<ProductCategory> | undefined;
  beautyProfile: WritableSignal<Array<Group> | undefined> = signal(undefined);
  filterTypes: Array<FilterTypeDTO> | undefined;
  compositionForm = new FormGroup({
    draftCompositionName: new FormControl<string | undefined>(
      undefined,
      Validators.required
    ),
    draftId: new FormControl<number | undefined>(
      undefined,
      Validators.required
    ),
    partition: new FormControl<boolean>(true)
  });

  allFilterTypes = computed(() =>
    this.filters()?.reduce(
      (types: { [filter: string]: Array<FilterTypeDTO> }, filter) => {
        if (filter.name && !types[filter.name])
          types[filter.name] = filter.filterTypes || [];
        else if (filter.name && types[filter.name])
          types[filter.name] = types[filter.name].concat(
            filter.filterTypes || []
          );
        return types;
      },
      {}
    )
  );
  reserved = computed(
    () => this.config.data?.reserved as DraftCompositionProductReserved
  );
  product = signal(
    (this.reserved() || this.config.data?.product) as (
      | ProductVariantAvailable
      | DraftCompositionProductReserved
    ) & { subscribers?: number; totalFilters?: number }
  );
  draftComposition = signal(
    this.config.data?.draftComposition as DraftCompositionSummary
  );
  draft = signal(this.config.data?.draft as Draft);
  beautyProfileQuestions = computed(() => {
    return this.beautyProfile()?.reduce(
      (questions: Array<BeautyProfileAttribute>, group) => {
        questions = questions
          .concat(group.attributes || [])
          .concat(
            group.attributeGroups?.reduce(
              (atts: Array<BeautyProfileAttribute>, g) =>
                (atts = atts.concat(g.attributes || [])),
              []
            ) || []
          );
        return questions;
      },
      []
    );
  });
  beautyProfileOptions = computed(() => {
    return this.beautyProfileQuestions()?.reduce(
      (options: Array<Option>, question) =>
        options.concat(question.options || []),
      []
    );
  });
  filterGroup = signal(
    new FormGroup({
      filter: new FormControl<string | null | undefined>(
        null,
        Validators.required
      ),
      filterType: new FormControl<number | null | undefined>(
        { value: null, disabled: true },
        Validators.required
      ),
      value: new FormControl<string | number | null | undefined | Array<any>>(
        { value: null, disabled: true },
        Validators.required
      ),
      valueRange: new FormControl<number | null | undefined>(
        { value: null, disabled: true },
        Validators.required
      ),
      valueDescription: new FormControl<string>(null)
    })
  );
  filtersToDisplay = computed(() => [...new Set(this.filters() || [])]);
  filtersForDropdown = computed(() => {
    if (!this.filters()?.length) return [];
    return this.filters();
  });
  filterValueOptions: WritableSignal<
    Array<{
      label?: string;
      value?: any;
      items?: Array<{
        label: string;
        value: any;
        parent?: string;
        group?: string;
      }>;
    }>
  > = signal([]);
  cols = computed(() =>
    [
      new TableColumn('Filtro', 'filter', false, 'text'),
      new TableColumn('Condição', 'condition', false, 'text'),
      new TableColumn('Atributo', 'question', false, 'text'),
      new TableColumn('Valor', 'value', false, 'number'),
      new TableColumn('Período', 'period', false, 'text')
    ].concat(
      (this.product() && !this.reserved()) ||
        (!this.product() && !this.config.data.draftComposition)
        ? [new TableColumn('Ação', '', false, 'button')]
        : []
    )
  );
  tableActions = [
    new TableActionButton(
      '',
      '',
      PrimeIcons.TRASH,
      () => true,
      'p-button-danger',
      'Remover filtro',
      'bottom',
      true,
      true,
      'error',
      'small'
    )
  ];

  rows: Signal<Array<DraftCompositionProductFilterDTO>> = computed(() => {
    if (!this.filters()?.length) return [];
    if (!this.draftComposition()) return [];
    if (!this.product() && this.draftComposition())
      return (
        this.draftCompositionFilters()?.reduce(
          (list: Array<DraftCompositionProductFilterDTO>, f) => {
            list = list.concat(
              f.value.split(',').map((v) => ({
                filter: this.findFilter(f.filterId).name,
                condition: this.allFilterTypes()[
                  this.findFilter(f.filterId).name
                ].find((ft) => ft.filterId === f.filterId).name,
                value: this.findValue(f.filterId, v),
                valueRange: f.valueRange,
                question: [1, 2, 17].includes(f.filterId)
                  ? this.beautyProfileQuestions()?.find((q) =>
                      q.options.some((o) => o.id === Number(v))
                    ).name
                  : '-',
                period: this.valueRangeLabel(f.valueRange),
                filterId: f.filterId,
                originalValue: v
              }))
            );
            return list;
          },
          []
        ) || []
      );
    return (
      this.productFilters()?.some((f) => f.filterId === 0)
        ? []
        : [
            {
              filter: 'Variante',
              condition: 'Não recebeu',
              question: '-',
              period: '-',
              value: this.findValue(
                13,
                this.product().productVariantId.toString()
              ),
              filterId: 13,
              originalValue: this.product().productVariantId?.toString()
            } as DraftCompositionProductFilterDTO
          ]
    ).concat(
      this.productValidFilters()
        ?.filter((p) => p.filterId > 0)
        .reduce((filters: Array<DraftCompositionProductFilterDTO>, p) => {
          filters = filters.concat(
            p.value.split(',').map((v) => ({
              filter: this.findFilter(p.filterId).name,
              condition: this.allFilterTypes()[
                this.findFilter(p.filterId).name
              ].find((ft) => ft.filterId === p.filterId).name,
              value: this.findValue(p.filterId, v),
              valueRange: p.valueRange,
              question: [1, 2, 17].includes(p?.filterId)
                ? this.beautyProfileQuestions()?.find((q) =>
                    q.options.some((o) => o.id === Number(v))
                  )?.name
                : '-',
              period: this.valueRangeLabel(p.valueRange),
              filterId: p.filterId,
              originalValue: v
            }))
          );
          return filters;
        }, [])
    );
  });
  productVariants: WritableSignal<Array<ProductVariantBrand> | undefined> =
    signal(undefined);
  ready = false;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.product() && this.draftComposition())
      await Promise.all([
        this.getProductFilters(),
        this.findStates(),
        this.findSubscriberBadges(),
        this.getCategories(),
        this.getFiltersGrouped(),
        this.getBeautyProfile(),
        this.findBadgesList()
      ]);
    else if (this.draft()) {
      const promises = [
        this.getBeautyProfile(),
        this.getCategories(),
        this.getFiltersGrouped()
      ];
      if (this.draftComposition()) {
        this.compositionForm.patchValue({
          draftCompositionName: this.draftComposition().draftCompositionName,
          draftId: this.draftComposition().draftId
        });
        this.compositionForm.disable();
        promises.push(this.getPartitionFilters());
        this.subscriberCount =
          this.draftComposition().compositionSubscribers || 0;
      } else {
        this.compositionForm.controls['draftId'].setValue(this.draft().draftId);
      }
      await Promise.all(promises);
    } else
      ToastUtil.addToast({
        detail: 'Informe o id do draft para criar a partição',
        severity: 'error',
        summary: 'Erro'
      });
    this.productOrignalFilters = this.productFilters() || [];
    this.ready = true;
    LoaderService.showLoader(false);
  }

  async getPartitionFilters(): Promise<void> {
    try {
      this.draftCompositionFilters.set(
        await lastValueFrom(
          this.filterService
            .findFilters(this.draftComposition()?.draftCompositionId as number)
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getProductFilters(): Promise<void> {
    try {
      this.productFilters.set(
        await lastValueFrom(
          this.productService
            .getProductFilters(
              this.product().productVariantId as number,
              this.draftComposition().draftId as number
            )
            .pipe(map((data) => data.result))
        )
      );
      await this.findProductsInFilters(
        this.productFilters()
          .filter((p) => [12, 13].includes(p.filterId))
          .reduce(
            (values, p) =>
              values.concat(
                p.value
                  .split(',')
                  .filter(
                    (v) => v !== this.product().productVariantId.toString()
                  )
                  .map((v) => Number(v))
              ),
            []
          )
          .concat([this.product().productVariantId])
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findStates(): Promise<void> {
    try {
      this.states = await lastValueFrom(
        this.draftService
          .findStates(this.draftComposition().draftCompositionId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findSubscriberBadges(): Promise<void> {
    try {
      this.badges = await lastValueFrom(
        this.draftService
          .findSubscriberBadges(
            this.draftComposition().draftCompositionId as number
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findBadgesList(): Promise<void> {
    try {
      this.allBadges = await lastValueFrom(
        this.personService.getBadgesList().pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getCategories(): Promise<void> {
    try {
      this.categories = await lastValueFrom(
        this.productService.getCategories().pipe(map((data) => data.result))
      );
      const noParent = this.categories?.filter((c) => !c.parentId);
      noParent.forEach((p) => {
        p.children = this.categories?.filter(
          (c) => c.parentId && c.parentId === p.categoryId
        );
        p.children?.sort(
          (c1, c2) => (c1.order as number) - (c2.order as number)
        );
      });
      this.categoriesList = noParent;
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getBeautyProfile(): Promise<void> {
    try {
      this.beautyProfile.set(
        await lastValueFrom(
          this.personService.getBeautyProfile().pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getFiltersGrouped(): Promise<void> {
    try {
      this.filters.set(
        await lastValueFrom(
          this.productService
            .getFiltersGrouped()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findProductsInFilters(ids: Array<number>): Promise<void> {
    try {
      this.productVariants.set(
        await lastValueFrom(
          this.productService.findAll(ids).pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  filterSelected(): void {
    this.filterGroup.update((form) => {
      form.get('valueRange')?.disable();
      form.get('filterType')?.enable();
      return form;
    });
    this.setfilterTypes();
    if (this.filterTypes?.length === 1) {
      this.filterGroup.update((form) => {
        form.get('filterType')?.setValue(this.filterTypes[0].filterId);
        return form;
      });
      this.filterTypeSelected();
    }
    this.setfilterValueOptions();
  }

  filterTypeSelected(): void {
    this.filterGroup.update((form) => {
      form.get('value')?.enable();
      return form;
    });
    if (this.filterGroup().value.filter === 'Categoria') {
      this.filterGroup.update((form) => {
        form.get('valueRange')?.enable();
        return form;
      });
    }
  }

  changeCategory($event: CheckboxChangeEvent) {
    this.filterGroup.update((form) => {
      form.get('value')?.setValue([...$event.checked]);
      return form;
    });
  }

  setfilterTypes(): void {
    if (!this.filterGroup().get('filter')?.valid) {
      this.filterTypes = [];
    } else {
      const filter = this.filters()?.find(
        (f) => f.name === this.filterGroup().value.filter
      );
      this.filterTypes = [...filter?.filterTypes];
    }
  }

  setfilterValueOptions(): void {
    if (this.filterGroup().get('filter')?.valid) {
      switch (this.filterGroup().value.filter) {
        case 'Estado':
          this.filterValueOptions.set(
            this.states
              ? this.states.map((s) => ({
                  label: s.shippingState,
                  value: s.shippingState
                }))
              : []
          ); // Retornar estados
          break;
        case 'Cidade':
          this.filterValueOptions.set(
            this.states
              ? this.states
                  .filter(
                    (s) =>
                      this.statesFiltered.length === 0 ||
                      this.statesFiltered.includes(s.shippingState as string)
                  )
                  .map((s) => ({
                    label: s.shippingState,
                    items: s.shippingCities?.map((c) => ({
                      label: c,
                      value: c,
                      parent: s.shippingState
                    }))
                  }))
              : []
          );
          break;
        case 'Perfil de beleza':
          this.filterValueOptions.set(
            this.beautyProfileQuestions()?.length
              ? this.beautyProfileQuestions()?.map((a) => ({
                  label: a.name as string,
                  value: this.groupFirstAttribute({ value: a.id })
                    ?.name as string,
                  items: a.options?.map((o) => ({
                    label: o.name as string,
                    value: o.id,
                    parent: a.name,
                    group: this.groupFirstAttribute({ value: a.id })?.name
                  }))
                }))
              : []
          );
          break;
        case 'Jóia':
          this.filterValueOptions.set(
            this.badges
              ? this.badges.map((b) => ({ label: b.title, value: b.badgeId }))
              : []
          );
          break;
        case 'Categoria':
          this.filterValueOptions.set(
            this.categoriesList
              ? this.categoriesList.map((c) => ({
                  label: c.externalId + ' ' + c.displayName,
                  value: c.categoryId,
                  items: c.children?.length
                    ? c.children.map((cc) => ({
                        label:
                          c.externalId +
                          '.' +
                          cc.externalId +
                          ' ' +
                          (cc.displayName as string),
                        value: cc.categoryId,
                        parent: c.displayName
                      }))
                    : []
                }))
              : []
          );
          break;
        default:
          this.filterValueOptions.set([]);
          break;
      }
    }
  }

  groupFirstAttribute(att: { value: any }): Group | undefined {
    const group = this.beautyProfile()?.find((g) =>
      g.attributes?.some((a) => a.id === att.value)
    );
    if (group?.attributes && group?.attributes[0].id === att.value) {
      return group;
    }
    return undefined;
  }

  listenScroller(): void {
    setTimeout(() => {
      const cities = document.getElementById('cities');
      if (cities) {
        cities.onscroll = ($event) => {
          if (($event.target as HTMLElement).scrollTop === 0) {
            this.multiselect?.hide();
            setTimeout(() => {
              this.multiselect?.show();
              // this.filterValueOptions = [];
            }, 150);
          }
          console.log('scroll::', ($event.target as HTMLElement).scrollTop);
        };
      }
    }, 1000);
  }

  findFilter(filterId: number): FilterDTO {
    return this.filters()?.find((f) =>
      f.filterTypes.some((ft) => ft.filterId === filterId)
    );
  }

  findValue(filterId: number, value: string): any {
    switch (filterId) {
      // Perfil de beleza
      case 1:
      case 2:
      case 17:
        return this.beautyProfileOptions()?.find((o) => o.id === Number(value))
          ?.name;
      // Variante
      case 0:
      case 12:
      case 13:
        return this.productVariants()?.find(
          (pv) => pv.productVariantId === Number(value)
        )?.productVariantName;
      // Joia
      case 14:
        return this.allBadges?.find((b) => b.badgeId === Number(value))?.title;
      // Categoria
      case 15:
      case 16:
        return this.categories?.find((c) => c.categoryId === Number(value))
          ?.displayName;
      default:
        return value;
    }
  }

  valueRangeLabel(valueRange): string {
    if (valueRange === null || valueRange === undefined) return '-';
    if (valueRange > 1) return `Últimos ${valueRange} meses`;
    if (valueRange === 1) return `Último mês`;
    if (valueRange <= 0) return `Desde o início`;
  }

  async submit(
    filters?: Array<DraftProductFilter | DraftCompositionFilter>,
    close = false
  ): Promise<void> {
    try {
      if (!filters && !this.filterGroup().valid) return;
      LoaderService.showLoader();
      if (this.product() && this.draftComposition())
        filters = filters || [...this.productValidFilters()];
      else if (this.draftComposition())
        filters = filters || [...(this.draftCompositionFilters() || [])];
      if (this.filterGroup().valid)
        filters.push({
          draftId: this.draftComposition().draftId,
          draftProductId: this.product()?.productVariantId,
          filterId: this.filterGroup().value.filterType,
          value:
            typeof this.filterGroup().value.value === 'string'
              ? (this.filterGroup().value.value as string)
              : this.filterGroup().value.value.toString(),
          valueRange: this.filterGroup().value.valueRange,
          draftCompositionId: this.draftComposition().draftCompositionId
        });
      const noDuplicantFilter = filters.find((r) => r.filterId === 0);
      if (
        this.product() &&
        (!this.filterGroup().valid ||
          this.filterGroup().value.filterType !== 13 ||
          this.filterGroup().value.value !==
            this.product().productVariantId.toString())
      )
        filters.push({
          draftId: this.draftComposition().draftId,
          draftProductId: this.product().productVariantId,
          filterId: noDuplicantFilter ? noDuplicantFilter.filterId : 13,
          value: this.product().productVariantId.toString()
        });
      else if (this.product() && noDuplicantFilter)
        filters = filters.filter((f) => f.filterId > 0);
      filters = filters.reduce(
        (list: Array<DraftProductFilter | DraftCompositionFilter>, f) => {
          const exists = list.find((ff) => ff.filterId === f.filterId);
          if (exists) {
            exists.value = [
              ...new Set(exists.value.split(',').concat([f.value]))
            ].toString();
          } else {
            list.push(f);
          }
          return list;
        },
        []
      );
      if (this.product() && this.draftComposition())
        this.productFilters.set(
          await lastValueFrom(
            this.productService
              .saveProductFilters(
                filters.map((f) => ({
                  ...f,
                  draftId: this.draftComposition().draftId
                }))
              )
              .pipe(map((data) => data.result))
          )
        );
      else
        this.draftCompositionFilters.set(
          await lastValueFrom(
            this.filterService
              .save(
                this.draftComposition()?.draftCompositionId as number,
                filters
              )
              .pipe(map((data) => data.result))
          )
        );
      this.filterGroup.update((form) => {
        form.reset();
        return form;
      });
      if (this.product()) {
        delete this.product().subscribers;
        const product = await lastValueFrom(
          this.productService
            .getProductInfoById(
              this.draftComposition()?.draftCompositionId as number,
              this.product()?.productVariantId as number
            )
            .pipe(map((data) => data.result))
        );
        this.product.update((p) => {
          p.totalFilters = product?.totalFilters;
          p.subscribers = product?.subscribers;
          return p;
        });
      } else if (!close) {
        await this.calculateAvailable();
      }
      LoaderService.showLoader(false);
    } catch (error) {
      LoaderService.showLoader(false);
      ToastUtil.showErrorToast(error);
    }
  }

  removeFilter($event: ActionButtonClickEvent): void {
    let message =
      "Deseja remover o filtro '" +
      $event.item.filter +
      ' - ' +
      $event.item.condition;

    if ($event.item.question && $event.item.question !== '-')
      message += ` - ${$event.item.question}`;
    message += ` - ${$event.item.value}`;
    if ($event.item.period && $event.item.period !== '-')
      message += ` - ${$event.item.period}`;
    message += "'?";
    let allowDuplicant =
      $event.item.filterId === 13 &&
      $event.item.originalValue === this.product().productVariantId.toString();
    if (allowDuplicant) {
      message =
        'Deseja permitir que assinantes recebam este produto novamente?';
    }
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectLabel: 'Não',
      rejectIcon: PrimeIcons.BACKWARD,
      target: $event.$event.target,
      message,
      header: 'Remover filtros',
      accept: async () => {
        LoaderService.showLoader();
        const filters = (
          this.productValidFilters() || this.draftCompositionFilters()
        ).reduce((list: Array<DraftProductFilter>, r) => {
          if (
            r.filterId === $event.item.filterId &&
            r.value.split(',').length > 1
          ) {
            r.value = r.value
              .split(',')
              .filter((v) => v !== $event.item.originalValue)
              .toString();
            list.push(r);
          } else if (r.filterId !== $event.item.filterId) {
            list.push(r);
          }
          return list;
        }, []);
        if (this.product() && allowDuplicant)
          filters.push({
            draftId: this.draftComposition().draftId,
            draftProductId: this.product().productVariantId,
            filterId: 0,
            value: this.product().productVariantId.toString()
          });
        await this.submit(filters);
        if (
          !this.product() &&
          this.config.data.draftComposition &&
          !filters.length
        ) {
          this.ref.close(this.draftComposition());
        }
      }
    });
  }

  addProduct(i: number) {
    this.dialog
      .open(ProductSelectModalComponent, {
        header: 'Selecionar produto',
        dismissableMask: undefined,
        modal: true,
        width: '60%'
      })
      .onClose.subscribe(async (data: ProductVariantBrand) => {
        if (data) {
          this.filterGroup().controls['value'].setValue(
            data.productVariantId.toString()
          );
          this.filterGroup().controls['valueDescription'].setValue(
            data.productVariantName
          );
          this.productVariants.update((products) => products.concat([data]));
        }
      });
  }

  async updateComposition(): Promise<void> {
    LoaderService.showLoader();
    if (
      this.compositionForm.valid &&
      this.compositionForm.touched &&
      this.compositionForm.dirty
    ) {
      try {
        if (this.draftComposition()?.draftCompositionId) {
          this.draftComposition.set(
            await lastValueFrom(
              this.draftService
                .updateDraftComposition({
                  ...this.draftComposition(),
                  draftCompositionName:
                    this.compositionForm.value.draftCompositionName
                })
                .pipe(map((data) => data.result))
            )
          );
          LoaderService.showLoader(false);
        } else {
          this.draftComposition.set(
            await lastValueFrom(
              this.draftService
                .createDraftComposition({
                  ...this.compositionForm.value
                } as DraftCompositionRequest)
                .pipe(map((data) => data.result))
            )
          );
          await Promise.all([
            this.findStates(),
            this.findSubscriberBadges(),
            this.getPartitionFilters()
          ]);
          LoaderService.showLoader(false);
          await this.calculateAvailable();
        }
        this.compositionForm.markAsUntouched();
        this.compositionForm.markAsPristine();
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
    }
    LoaderService.showLoader(false);
  }

  async cancel(): Promise<void> {
    if (
      this.draftComposition()?.draftCompositionId &&
      !this.product() &&
      this.isEdition()
    ) {
      LoaderService.showLoader();
      try {
        await lastValueFrom(
          this.draftService.deleteDraftComposition(
            this.draftComposition()?.draftCompositionId as number
          )
        );
        LoaderService.showLoader(false);
        this.ref.close();
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
    } else if (
      this.product() &&
      !this.reserved() &&
      this.productOrignalFilters !== this.productFilters()
    ) {
      LoaderService.showLoader();
      await this.submit(this.productOrignalFilters, true);
      LoaderService.showLoader(false);
      this.ref.close(this.product());
    } else {
      this.ref.close();
    }
  }

  async finish(): Promise<void> {
    if (this.draftComposition() && !this.product()) {
      await this.createPartition();
      this.ref.close(this.draftComposition());
    } else {
      this.ref.close(this.product());
    }
  }

  addToComposition(): void {
    this.ref.close({ ...this.product(), add: true });
  }

  async createPartition(): Promise<void> {
    try {
      LoaderService.showLoader();
      const detail = await lastValueFrom(
        this.draftService
          .createPartition(
            this.draftComposition()?.draftCompositionId as number
          )
          .pipe(map((data) => data.result))
      );
      ToastUtil.addToast({
        detail,
        severity: 'success',
        summary: 'Sucesso'
      });
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
    LoaderService.showLoader(false);
  }

  async calculateAvailable(): Promise<void> {
    try {
      this.subscriberCount =
        (await lastValueFrom(
          this.filterService
            .findAvailable(
              this.draftComposition()?.draftCompositionId as number
            )
            .pipe(map((data) => data.result))
        )) || 0;
    } catch (error) {
      this.subscriberCount = 0;
      ToastUtil.showErrorToast(error);
    }
  }

  async showPartitionSubscribers(): Promise<void> {
    this.dialog.open(CompositionSubscribersModalComponent, {
      data: {
        draftCompositionId: this.draftComposition().draftCompositionId
      },
      modal: true,
      header:
        'Assinantes da partição ' +
        this.draftComposition()?.draftCompositionName,
      width: '80vw'
    });
  }

  async clearFilters(): Promise<void> {
    if (
      !this.product() &&
      this.config.data.draftComposition?.draftCompositionStatus === 0
    ) {
      LoaderService.showLoader();
      try {
        const detail = await lastValueFrom(
          this.filterService
            .clearPartitionFilters(this.draftComposition().draftCompositionId)
            .pipe(map((data) => data.result))
        );
        ToastUtil.addToast({
          detail,
          severity: 'success',
          summary: 'Sucesso'
        });
        this.ref.close(this.draftComposition());
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get statesFiltered(): Array<string> {
    return this.productFilters()?.some((f) =>
      [8, 9].includes(f.filterId as number)
    )
      ? (this.productFilters().find((f) =>
          [8, 9].includes(f.filterId as number)
        )?.value as any)
      : [];
  }
}

export interface DraftCompositionProductFilterDTO
  extends DraftCompositionFilter,
    DraftCompositionFilter {
  filter: string;
  condition: string;
  question?: string;
  period?: string;
  originalValue?: string;
}
