import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ProductHistorySyncronizationModalComponent } from './product-history-syncronization-modal/product-history-syncronization-modal.component';
import { SyncronizationRoutingModule } from './syncronization-routing.module';
import { SyncronizationComponent } from './syncronization.component';

@NgModule({
  declarations: [
    SyncronizationComponent,
    ProductHistorySyncronizationModalComponent
  ],
  imports: [
    CommonModule,
    SyncronizationRoutingModule,
    TableModule,
    ButtonModule,
    TooltipModule,
    ConfirmPopupModule,
    DialogModule,
    CalendarModule,
    TabViewModule,
    ReactiveFormsModule,
    MultiSelectModule,
    DropdownModule,
    InputTextareaModule,
    FormsModule
  ]
})
export class SyncronizationModule {}
