<div class="composition-subscribers">
  <app-table
    *ngIf="draftCompositionId() && draftService"
    [cols]="cols"
    [showHeader]="false"
    [service]="draftService"
    [pageFunctionName]="pageFunctionName()"
    [fixedFilters]="fixedFilters()"
    sortField="firstName"
    [sortOrder]="-1"
    modelName="assinantes"
    [defaultRows]="10"
  ></app-table>
</div>
