import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessagePayload } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PickList } from 'primeng/picklist';
import { lastValueFrom, map } from 'rxjs';
import {
  BrandSummary,
  Draft,
  DraftCompositionSummary,
  DraftControllerService,
  DraftDefaultProductRequest,
  DraftDefaultProductView,
  DraftSubscriberView,
  Edition,
  EditionControllerService,
  ProductControllerService,
  ProductVariantAvailable,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { BlockPageComponent } from 'src/app/components/block-page/block-page.component';
import { ChangeListenerComponent } from 'src/app/components/change-listener/change-listener.component';
import { FirebaseTopic } from 'src/app/models/enums/FirebaseTopic';
import { CookieService } from 'src/app/services/cookie.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-draft-default-products',
  templateUrl: './draft-default-products.component.html',
  styleUrls: ['./draft-default-products.component.scss'],
  providers: [ConfirmationService, DatePipe, DialogService]
})
export class DraftDefaultProductsComponent
  extends ChangeListenerComponent
  implements OnInit
{
  @ViewChild(PickList) pickList: PickList | undefined;

  @Input({ required: true }) id: number | undefined;
  draft: Draft | undefined;
  draftSubscribers: Array<DraftSubscriberView> | undefined;
  products: Array<ProductVariantAvailableDraft> | undefined;
  selectedProducts: Array<ProductVariantAvailableDraft> | undefined = [];
  emptyBrandId?: number;
  brands: Array<BrandSummary> | undefined;
  user: UserRoleViewResponse | undefined;
  defaultProducts: Array<DraftDefaultProductView> | undefined;
  compositions: Array<DraftCompositionSummary> | undefined;
  edition: Edition | undefined;
  blockModal: DynamicDialogRef | undefined;
  unsync: number | undefined;

  constructor(
    private draftService: DraftControllerService,
    private productService: ProductControllerService,
    private userService: UserControllerService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private datePipe: DatePipe,
    private editionService: EditionControllerService,
    private dialog: DialogService
  ) {
    super([FirebaseTopic.STOCK, FirebaseTopic.SYNCRONIZATION]);
  }

  async ngOnInit(): Promise<void> {
    if (this.id) {
      LoaderService.showLoader();
      await this.init();
      this.emptyBrandId = this.brands?.find(
        (b) => b.brandName?.toLocaleLowerCase() === 'sem marca'
      )?.brandId;
      setTimeout(() => {
        this.reorder(true, !this.selectedProducts?.length);
        LoaderService.showLoader(false);
      }, 1000);
    } else {
      ToastUtil.addToast({
        detail: 'Informe o id do draft',
        summary: 'Erro',
        severity: 'error'
      });
    }
  }

  async init(): Promise<void> {
    await this.checkUnsyncronizedSubscribers(this.id as number);
    if (!this.unsync) {
      await Promise.all([
        this.findDraft(),
        this.findDraftSubscribers(),
        this.findBrands(),
        this.findUser(),
        this.findCompositions(),
        this.findProductsList()
      ]);
      await Promise.all([this.findDraftDefaultProducts(), this.findEdition()]);
      if (this.compositions?.some((c) => c.compositionSubscribers)) {
        this.confirmationService.confirm({
          acceptLabel: 'Remover composições',
          acceptIcon: PrimeIcons.TRASH,
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: 'Continuar segmentação',
          rejectIcon: PrimeIcons.PLAY,
          header: 'Composições montadas',
          message:
            'Já existem composições montadas no Draft. Para altera os produtos será necessário excluir as composições!',
          reject: () => {
            this.goToSegmentation();
          },
          accept: async () => {
            LoaderService.showLoader();
            await this.deleteCompositions();
            LoaderService.showLoader(false);
          }
        });
      }
    }
  }

  async findDraft(): Promise<void> {
    try {
      this.draft = await lastValueFrom(
        this.draftService
          .get(this.id as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findDraftSubscribers(): Promise<void> {
    try {
      this.draftSubscribers = await lastValueFrom(
        this.draftService
          .findDraftSubscribers(this.id as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findDraftDefaultProducts(): Promise<void> {
    try {
      this.defaultProducts = await lastValueFrom(
        this.draftService
          .findDraftDefaultProducts(this.id as number)
          .pipe(map((data) => data.result))
      );
      this.selectedProducts = [
        ...(this.defaultProducts?.map(
          (p) =>
            ({
              ...p,
              selected: true,
              subscribers: this.draftSubscribers?.length
            } as ProductVariantAvailableDraft)
        ) || [])
      ];
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findProductsList(): Promise<void> {
    try {
      this.products = await lastValueFrom(
        this.productService
          .getAvailableListForDraft()
          .pipe(map((data) => data.result))
      );
      this.products?.forEach((p) => {
        if (!p.brandId && this.emptyBrandId) {
          p.brandId = this.emptyBrandId;
        }
        p.subscribers = this.draftSubscribers?.length;
      });
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findBrands(): Promise<void> {
    try {
      this.brands = await lastValueFrom(
        this.productService.getBrands().pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
      this.brands = [];
    }
  }

  async findUser(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findCompositions(): Promise<void> {
    try {
      this.compositions = await lastValueFrom(
        this.draftService
          .getDraftCompositions(this.id as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findEdition(): Promise<void> {
    try {
      this.edition = await lastValueFrom(
        this.editionService
          .getById(this.draft?.editionId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  isSelected(product: ProductVariantAvailable): boolean {
    return (
      this.pickList?.selectedItemsSource?.some(
        (p: ProductVariantAvailable) =>
          p.productVariantId === product.productVariantId
      ) ||
      this.pickList?.selectedItemsTarget?.some(
        (p: ProductVariantAvailable) =>
          p.productVariantId === product.productVariantId
      ) ||
      false
    );
  }

  submit(): void {
    if (this.selectedProducts) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        rejectButtonStyleClass: 'p-button-danger',
        header: 'Iniciar segmentação',
        message:
          'Deseja iniciar a segmentação com ' +
          (this.selectedProducts.length > 1
            ? ' os ' + this.selectedProducts.length + ' produtos?'
            : ' o item ' + this.selectedProducts[0].productVariantName + '?'),
        accept: async () => {
          LoaderService.showLoader();
          try {
            await Promise.all([
              this.deleteCompositions(),
              this.saveDraftDefaultProducts()
            ]);
            this.goToSegmentation();
          } catch (error) {
            ToastUtil.showErrorToast(error);
          }
          LoaderService.showLoader(false);
        }
      });
    }
  }

  goToSegmentation(): void {
    this.router.navigate(['draft/form/id/' + this.id + '/segmentation']);
  }

  checkStocks(showError = true): boolean {
    if (
      this.selectedProducts?.some(
        (p) => (p.stock as number) < (p.subscribers as number)
      )
    ) {
      LoaderService.showLoader();
      const products = this.selectedProducts?.filter(
        (p) => (p.stock as number) >= (p.subscribers as number)
      );
      delete this.selectedProducts;
      this.selectedProducts = products;
      this.reorder();
      if (showError)
        ToastUtil.showErrorToast({
          message: 'Os produtos sem estoque suficiente não podem ser incluídos'
        });
      LoaderService.showLoader(false);
    }
    this.selectedProducts?.forEach((p) => (p.selected = true));
    return true;
  }

  reorder(reset = false, resetFilter = true): boolean {
    if (this.pickList) {
      this.pickList.source = [
        ...(this.products
          ?.filter((p) =>
            this.selectedProducts?.every(
              (sp) => sp.productVariantId !== p.productVariantId
            )
          )
          .map((p) => {
            p.selected = false;
            return p;
          }) || [])
      ];
      this.pickList.source.sort(
        (p1: ProductVariantAvailable, p2: ProductVariantAvailable) =>
          (p2.stock as number) - (p1.stock as number)
      );
      if (reset) this.resetSourceFilter(resetFilter);
    }
    return true;
  }

  resetSourceFilter(defaultSearch = true): void {
    var evt = new CustomEvent('keyup');
    if (defaultSearch)
      (this.pickList?.sourceFilterViewChild?.nativeElement).value =
        this.editionName;
    (
      this.pickList?.sourceFilterViewChild?.nativeElement as HTMLElement
    ).dispatchEvent(evt);
  }

  async deleteCompositions(): Promise<void> {
    if (this.compositions) {
      for (let index = 0; index < this.compositions.length; index++) {
        await lastValueFrom(
          this.draftService
            .deleteDraftComposition(
              this.compositions[index].draftCompositionId as number
            )
            .pipe(map((data) => data.result))
        );
      }
      this.compositions = [];
    }
  }

  async handlePayload(data: { [key: string]: any }): Promise<void> {
    if (this.draft) {
      switch (data['topic']) {
        case FirebaseTopic.STOCK:
          if (
            data['draftId'] &&
            Number(data['draftId']) !== Number(this.draft.draftId)
          )
            await this.updateProductsStock(data);
          break;
        case FirebaseTopic.SYNCRONIZATION:
          if (Number(data['statusId']) === 0) {
            await this.unblockPage();
          } else if (!this.blockModal) {
            this.blockPage(data);
          } else {
            BroadcastUtil.get('sync').emit(Number(data['statusId']));
            CookieService.setCookie('sync', JSON.stringify(data), 0, 0.5);
          }
          break;
        default:
          break;
      }
    } else if (data['topic'] === FirebaseTopic.SYNCRONIZATION) {
      if (Number(data['statusId']) === 0) {
        await this.unblockPage();
      } else if (!this.blockModal) {
        this.blockPage(data);
      } else {
        BroadcastUtil.get('sync').emit(Number(data['statusId']));
        CookieService.setCookie('sync', JSON.stringify(data), 0, 0.5);
      }
    }
  }

  blockPage(data: { [key: string]: any }): void {
    CookieService.setCookie('sync', JSON.stringify(data), 0, 1);
    if (!this.blockModal)
      this.blockModal = this.dialog.open(BlockPageComponent, {
        closable: false,
        closeOnEscape: false,
        showHeader: false,
        width: '500px',
        styleClass: 'block-page',
        height: '300px',
        data
      });
  }

  async checkUnsyncronizedSubscribers(draftId: number): Promise<void> {
    let cookie: any = CookieService.getCookie('sync');
    if (cookie) {
      cookie = JSON.parse(cookie);
      if (Number(cookie.statusId) > 0) {
        this.blockPage(cookie);
        this.unsync = 1;
      }
    }
  }

  async unblockPage(): Promise<void> {
    CookieService.eraseCookie('sync');
    if (this.blockModal) {
      this.blockModal.close();
      delete this.blockModal;
      delete this.unsync;
    }
    if (!this.draft) {
      await this.init();
    }
  }

  async updateProductsStock(products: any): Promise<void> {
    const notFound: Array<number> = [];
    Object.keys(products).forEach((key) => {
      if (products && key !== 'topic' && key !== 'draftId' && products[key]) {
        if (!this.updateProductStock(Number(key), Number(products[key]))) {
          notFound.push(Number(key));
        }
      }
    });
    if (notFound.length) {
      await this.findProductsList();
    }
  }

  updateProductStock(productVariantId: number, stock: number): boolean {
    let found = false;
    if (this.products && this.selectedProducts) {
      const idx = this.products.findIndex(
        (p) => p.productVariantId === productVariantId
      );
      const idxSel = this.selectedProducts.findIndex(
        (p) => p.productVariantId === productVariantId
      );
      if (idx >= 0) {
        found = true;
        this.products[idx].stock = stock;
      }
      if (idxSel >= 0) {
        found = true;
        // if ((this.draftCompositionProducts[idxComp].reserved as number) === (this.draftCompositionProducts[idxComp].stock as number) && (this.draftCompositionProducts[idxComp].stock as number) < stock) {
        //   const reserved = this.draftCompositionProducts[idxComp].reserved || 0;
        // } else
        if ((this.selectedProducts[idxSel].subscribers as number) > stock) {
          this.productSoldOut(this.selectedProducts[idxSel], stock);
        }
        this.selectedProducts[idxSel].stock = stock;
      }
    }
    return found;
  }

  productSoldOut(product: ProductVariantAvailable, stock: number): void {
    let message = `O produto ${product.productVariantName} foi reservado em outro draft e possui apenas ${stock} unidades.<br>Deseja alterar as assinantes da composição ou remover o produto?`;
    let header = 'Produto reservado';
    if (!stock) {
      header = 'Produto esgotado';
      message = `O produto ${product.productVariantName} foi reservado e está sem estoque disponível. Não será possível utiliza-lo.`;
    }
    this.confirmationService.confirm({
      message,
      acceptVisible: stock > 0,
      acceptLabel: 'Alterar assinantes',
      rejectLabel: 'Remover produto',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      acceptIcon: ' ',
      rejectIcon: ' ',
      dismissableMask: false,
      header,
      reject: async () => {
        this.checkStocks(false);
        this.resetSourceFilter(false);
        await this.saveDraftDefaultProducts();
      },
      accept: async () => {
        this.router.navigate(['draft/form/id/' + this.id]);
      }
    });
  }

  override onMessageReceived(payload: MessagePayload): void {
    if (payload.data) this.handlePayload(payload.data);
  }

  override async onPageFocus(): Promise<void> {
    const cookies = ['products', 'sync'];
    let data: any;
    for (let cookieName of cookies) {
      let cookie = CookieService.getCookie(cookieName);
      if (cookie) {
        data = JSON.parse(cookie);
        if (Object.keys(data).length) {
          data['topic'] = data['topic'].replace('-dev', '');
          await this.handlePayload(data);
        }
        if (cookieName !== 'sync') CookieService.eraseCookie(cookieName);
      }
    }
  }

  async saveDraftDefaultProducts(): Promise<void> {
    try {
      await lastValueFrom(
        this.draftService.saveDraftDefaultProducts(
          this.id as number,
          this.selectedProducts?.map(
            (p) =>
              ({
                productVariantId: p.productVariantId,
                subscriptionId: p.subscriptionId
              } as DraftDefaultProductRequest)
          )
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  get subscriptionName(): string {
    switch (Number(((this.draft?.editionId || 0) / 1000000).toFixed(0))) {
      case 5:
        return 'Glambag';
      case 7:
        return '';
      default:
        return 'Glambox';
    }
  }

  get editionName(): string {
    return this.draft
      ? this.subscriptionName +
          ' Edição ' +
          this.datePipe.transform(
            new Date(
              (this.draft.editionId as number).toString().substring(1, 5) +
                '-' +
                (this.draft.editionId as number).toString().substring(5, 7) +
                '-02'
            ),
            'MMMM yyyy'
          )
      : '';
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }
}

export interface ProductVariantAvailableDraft extends ProductVariantAvailable {
  subscribers?: number;
  selected?: boolean;
  subscriptionId?: number;
}
