/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DraftView } from './draftView';
import { Pageable } from './pageable';
import { Sort } from './sort';

export interface PageDraftView {
  totalPages?: number;
  totalElements?: number;
  pageable?: Pageable;
  numberOfElements?: number;
  sort?: Sort;
  last?: boolean;
  first?: boolean;
  size?: number;
  content?: Array<DraftView>;
  number?: number;
  empty?: boolean;
}
