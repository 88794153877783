<ng-container *ngIf="ready">
  <div #modal class="product-filter-modal">
    <div
      class="product-filter-modal__product"
      *ngIf="product() && beautyProfile && draftComposition()"
      [class.product-card--disabled]="product().subscribers === 0"
    >
      <div class="product-filter-modal__product__info">
        <div>
          <img
            *ngIf="product().defaultImageURL"
            [src]="product().defaultImageURL"
            [alt]="product().productVariantName"
          />
          <img
            src="assets/no_img_avaliable.jpg"
            *ngIf="!product().defaultImageURL"
          />
        </div>
        <div class="product-filter-modal__product__info__details">
          <div [title]="product().productVariantName" *ngIf="!config.header">
            <strong>
              {{ product().productVariantName | slice : 0 : 35 }}</strong
            >
            <ng-container
              *ngIf="(product().productVariantName?.length || 0) > 35"
              >...</ng-container
            >
          </div>
          <div [title]="product().brandName">
            <b>Marca</b> {{ product().brandName | slice : 0 : 35 }}
            <ng-container *ngIf="(product().brandName?.length || 0) > 35"
              >...</ng-container
            >
          </div>
          <div
            [ngStyle]="{
              color:
                !product().subscribers ||
                (product().subscribers &&
                  product().stock &&
                  (product().subscribers || 0) < (product().stock || 0))
                  ? '#4b5563'
                  : 'red'
            }"
          >
            <b>Estoque</b>
            {{
              product().stock! <= 0 ? 0 : (product().stock | number : '1.0-0')
            }}
          </div>
          <div><b>BrandEquity</b>: {{ product().brandEquity || 0 }}</div>
          <div><b>CEAN</b>: {{ product().internalEAN }}</div>
          <div><b>Peso</b>: {{ product().weight | number : '1.0-2' }}g</div>
          <div>
            <b>Valor custo</b>: {{ product().costPrice | currency : 'BRL' }}
          </div>
          <div>
            <b>Valor mercado</b>: {{ product().salePrice | currency : 'BRL' }}
          </div>
        </div>
      </div>
      <div class="product-filter-modal__product__filters" *ngIf="!reserved()">
        <div
          [ngStyle]="{
            color:
              !product().subscribers ||
              (product().subscribers &&
                product().stock &&
                (product().subscribers || 0) <= (product().stock || 0))
                ? '#4b5563'
                : 'red'
          }"
          *ngIf="product().subscribers"
        >
          <strong>
            {{
              product().subscribers! > product().stock!
                ? product().stock
                : product().subscribers
            }}</strong
          >
          <span *ngIf="(product().subscribers || 0) > 1">
            <strong> Assinaturas</strong></span
          >
          <span *ngIf="product().subscribers === 1"
            ><strong> Assinatura</strong></span
          >
        </div>
        <div *ngIf="product().subscribers === 0">0 assinaturas</div>
        <div *ngIf="product().subscribers === undefined">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
      </div>
      <div class="product-filter-modal__product__divider"></div>
    </div>
    <form
      id="composition-form"
      class="horizontal"
      [formGroup]="compositionForm"
      (submit)="updateComposition()"
      *ngIf="!product() && !config.data?.draftComposition"
    >
      <p-floatlabel class="flex3">
        <input
          type="text"
          pInputText
          (blur)="draftComposition() && updateComposition()"
          name="draftCompositionName"
          id="draftCompositionName"
          formControlName="draftCompositionName"
        />
        <label for="draftCompositionName">Nome da partição</label>
      </p-floatlabel>
      <p-button
        label="Avançar"
        styleClass="p-button-primary"
        type="submit"
        *ngIf="!draftComposition()"
      ></p-button>
      <p-button
        label="Cancelar"
        styleClass="p-button-danger"
        *ngIf="!draftComposition()"
        type="button"
        (onClick)="ref.close()"
      ></p-button>
    </form>
    <div
      class="partition-modal__subscribers"
      *ngIf="draftComposition() && !product()"
    >
      <span *ngIf="subscriberCount === undefined">
        <i class="pi pi-spin pi-spinner"></i>
      </span>
      <a
        type="button"
        [class.admin-link]="subscriberCount > 0 && config.data.draftComposition"
        (click)="subscriberCount > 0 && showPartitionSubscribers()"
        *ngIf="subscriberCount !== undefined"
        >{{ subscriberCount | number : '1.0-0' }} assinante(s)</a
      >
    </div>
  </div>
  <form
    class="horizontal"
    *ngIf="
      filtersToDisplay() &&
      filterGroup() &&
      !reserved() &&
      draftComposition() &&
      (product() || isEdition())
    "
    [formGroup]="filterGroup()"
    (submit)="submit()"
  >
    <p-floatlabel class="form-control">
      <p-select
        [options]="filtersForDropdown()"
        optionLabel="name"
        optionValue="name"
        formControlName="filter"
        [autoDisplayFirst]="false"
        (onChange)="filterSelected()"
        appendTo="body"
        id="filter"
      ></p-select>
      <label for="filter">Filtro</label>
    </p-floatlabel>
    <p-floatlabel class="form-control">
      <p-select
        [options]="filterTypes || []"
        optionLabel="name"
        optionValue="filterId"
        formControlName="filterType"
        [autoDisplayFirst]="false"
        appendTo="body"
        (onChange)="filterTypeSelected()"
        id="filterType"
      ></p-select>
      <label for="filterType">Condição</label>
    </p-floatlabel>
    <p-floatlabel class="form-control">
      <p-inputNumber
        formControlName="value"
        id="filterValue"
        *ngIf="
          !filterGroup().value.filter || filterGroup().value.filter === 'Idade'
        "
      ></p-inputNumber>
      <input
        type="text"
        pInputText
        readonly
        (click)="addProduct(i)"
        formControlName="valueDescription"
        name="value"
        *ngIf="filterGroup().value.filter === 'Variante'"
        id="value"
      />
      <p-multiSelect
        [options]="filterValueOptions() || []"
        formControlName="value"
        id="filterValue"
        [group]="true"
        *ngIf="
          filterGroup().value.filter &&
          filterGroup().value.filter === 'Perfil de beleza'
        "
        [filter]="true"
        filterBy="label,parent,group"
        appendTo="body"
        [maxSelectedLabels]="0"
        selectedItemsLabel="{0} itens"
        defaultLabel="Selecione"
        [showToggleAll]="false"
        [virtualScrollItemSize]="43"
        [virtualScroll]="filterGroup().value.filter === 'Cidade'"
      >
        <ng-template let-item #group>
          <div class="attribute-group" *ngIf="item.value">
            {{ item.value }}
          </div>
          <div [class.attribute]="item.value">
            {{ item.label }}
          </div>
        </ng-template>
      </p-multiSelect>
      <p-multiSelect
        [options]="filterValueOptions() || []"
        formControlName="value"
        id="filterValue"
        [group]="
          filterGroup().value.filter === 'Cidade' ||
          filterGroup().value.filter === 'Categoria'
        "
        *ngIf="
          filterGroup().value.filter &&
          (filterGroup().value.filter === 'Estado' ||
            filterGroup().value.filter === 'Cidade' ||
            filterGroup().value.filter === 'Categoria' ||
            filterGroup().value.filter === 'Jóia')
        "
        [filter]="
          filterGroup().value.filter === 'Cidade' ||
          filterGroup().value.filter === 'Estado' ||
          filterGroup().value.filter === 'Categoria'
        "
        filterBy="label,parent,group"
        appendTo="body"
        [maxSelectedLabels]="0"
        selectedItemsLabel="{0} itens"
        defaultLabel="Selecione"
        [showToggleAll]="false"
        [virtualScrollItemSize]="43"
        [virtualScroll]="filterGroup().value.filter === 'Cidade'"
      >
        <ng-template
          let-item
          #group
          *ngIf="filterGroup().value.filter === 'Categoria'"
        >
          <p-checkbox
            [id]="item.value"
            [value]="item.value"
            formControlName="value"
            (onChange)="changeCategory($event)"
          ></p-checkbox>
          <span>&nbsp;&nbsp;{{ item.label }}</span>
        </ng-template>
      </p-multiSelect>
      <label for="filterValue">Valor</label>
    </p-floatlabel>
    <p-floatlabel class="form-control category">
      <p-inputNumber
        formControlName="valueRange"
        id="filterValueRange"
        placeholder="0 = Tudo"
        pTooltip="Intervalo em meses a ser verificado a(s) categoria(s)"
        tooltipPosition="bottom"
      ></p-inputNumber>
      <label for="filterValueRange">Intervalo (meses)</label>
    </p-floatlabel>
    <button
      pButton
      type="submit"
      icon="pi pi-plus"
      class="p-button-rounded p-button-success"
      [disabled]="filterGroup().invalid"
    ></button>
  </form>
  <br />
  <app-table
    *ngIf="draftComposition() && rows()"
    [cols]="cols()"
    [elements]="rows()"
    [lazy]="false"
    [addButton]="false"
    [exportPdf]="false"
    [exportExcel]="false"
    [tableTitle]="product() ? 'Filtros do produto' : 'Filtros da partição'"
    emptyMessage="Sem filtros"
    modelName="filtros"
    [actionButtons]="tableActions"
    (actionButtonClick)="removeFilter($event)"
    [paginator]="false"
  ></app-table>
  <div class="buttons" *ngIf="draftComposition()">
    <p-button
      [label]="
        product() && productOrignalFilters !== productFilters()
          ? 'Voltar e redefinir'
          : 'Voltar'
      "
      [severity]="
        reserved() ||
        (product() && productOrignalFilters === productFilters()) ||
        (!product() && !isEdition())
          ? 'primary'
          : 'danger'
      "
      type="button"
      (onClick)="cancel()"
    ></p-button>
    <p-button
      label="Incluir"
      severity="success"
      type="button"
      (onClick)="addToComposition()"
      [disabled]="
        !product().subscribers || !product().stock || product().stock <= 0
      "
      *ngIf="!reserved() && product()"
    ></p-button>
    <p-button
      *ngIf="
        isEdition() ||
        (product() && !reserved() && productOrignalFilters !== productFilters())
      "
      label="Salvar"
      severity="primary"
      type="button"
      (onClick)="finish()"
    ></p-button>
    <p-button
      *ngIf="
        !product() &&
        !isEdition() &&
        config.data.draftComposition?.draftCompositionStatus === 0
      "
      label="Limpar filtros"
      icon="pi pi-filter-slash"
      (onClick)="clearFilters()"
      severity="danger"
    ></p-button>
  </div>
</ng-container>
<p-confirmPopup></p-confirmPopup>
