import { Component, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Listbox, ListboxFilterEvent } from 'primeng/listbox';
import { lastValueFrom, map } from 'rxjs';
import {
  ProductControllerService,
  ProductVariantBrand
} from 'src/app/allocation-api';
import { AppDialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-product-select-modal',
  templateUrl: './product-select-modal.component.html',
  styleUrl: './product-select-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ProductSelectModalComponent {
  @ViewChild(Listbox) listBox: Listbox | undefined;

  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  private productService: ProductControllerService = inject(
    ProductControllerService
  );

  product = new FormControl<ProductVariantBrand>(null, [Validators.required]);
  products: Array<ProductVariantBrand> = [];
  searchValid = false;
  search = '';
  loading = false;
  searching = false;

  async submit(): Promise<void> {
    this.ref.close(this.product.value);
  }

  async findProducts(search?: string): Promise<void> {
    if (!search?.trim().length && !this.product?.value) return;
    try {
      this.products =
        (await lastValueFrom(
          this.productService
            .searchVariantsByNameOrExternalIdOrBrand(search)
            .pipe(
              map((data) =>
                data.result.map((p) => ({
                  ...p,
                  productVariantName: `${p.productVariantName}${
                    p.internalEAN ? ' (SKU:' + p.internalEAN + ')' : ''
                  } - ${p.brandName}`
                }))
              )
            )
        )) || [];
    } catch (error: any) {
      this.products = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  filter($event: ListboxFilterEvent): void {
    this.searchValid = $event.filter?.length > 2;
    this.search = $event.filter;
    if (this.searchValid) {
      this.loading = true;
      setTimeout(async () => {
        if (this.search === $event.filter && !this.searching) {
          this.searching = true;
          await this.findProducts(this.search);
          this.loading = false;
          this.searching = false;
        }
      }, 500);
    } else {
      this.products = [];
    }
  }
}
