<form (submit)="ref.close(review.value)" [formGroup]="review">
  <span class="p-float-label">
    <input
      type="text"
      name="message"
      id="message"
      formControlName="message"
      placeholder="Informe o problema na composição"
      pInputText
    />
    <label for="message">Motivo</label>
  </span>
  <span class="buttons">
    <p-button
      type="button"
      (onClick)="ref.close()"
      label="Voltar"
      styleClass="p-button-primary"
    />
    <p-button type="submit" label="Enviar" styleClass="p-button-danger" />
  </span>
</form>
