<div>
  <div class="title">Filtros de assinatura</div>
  <form
    class="horizontal"
    (submit)="submitForm()"
    [formGroup]="segmentationForm"
    *ngIf="segmentationForm"
  >
    <p-floatlabel class="form-control" *ngIf="signerType">
      <p-multiSelect
        formControlName="personTypes"
        placeholder="Tipo de assinante"
        optionLabel="label"
        optionValue="value"
        [options]="signerType"
        id="signerTypes"
        (onChange)="updateTotalPersonTypes()"
        (onPanelHide)="valueChanges()"
        fluid
      >
      </p-multiSelect>
      <label for="partnerTypes">Tipo de assinante</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="partnerTypes && showPartnerType">
      <p-multiSelect
        formControlName="partnerTypes"
        placeholder="Tipo de Partner"
        optionLabel="influencerStatus"
        optionValue="influencerStatusId"
        [options]="partnerTypes"
        id="partnerTypes"
        (onChange)="updateTotalPersonTypes()"
        (onPanelHide)="valueChanges()"
        fluid
      >
      </p-multiSelect>
      <label for="partnerTypes">Tipo de Partner</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="signatureTime">
      <p-select
        formControlName="isNew"
        placeholder="Tempo"
        optionLabel="label"
        optionValue="value"
        [options]="signatureTime"
        [autoDisplayFirst]="false"
        id="signatureTime"
        (onChange)="valueChanges()"
      >
      </p-select>
      <label for="signatureTime">Tempo</label>
    </p-floatlabel>
    <p-floatlabel
      class="form-control"
      *ngIf="segmentationForm.value.isNew === 0"
    >
      <p-select
        formControlName="isNewCycle"
        placeholder="Ciclo"
        optionLabel="label"
        optionValue="value"
        [options]="cycleOptions"
        [autoDisplayFirst]="false"
        id="isNewCycle"
        (onChange)="valueChanges()"
      >
      </p-select>
      <label for="isNewCycle">Ciclo</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="time">
      <p-select
        formControlName="recurrenceMonth"
        placeholder="Mês de recorrência"
        optionLabel="label"
        optionValue="value"
        [options]="time"
        [autoDisplayFirst]="false"
        (onChange)="valueChanges()"
        id="time"
      >
      </p-select>
      <label for="time">Mês de recorrência</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="recurrence">
      <p-select
        formControlName="recurrence"
        placeholder="Recorrência"
        optionLabel="label"
        optionValue="value"
        [options]="recurrence"
        [autoDisplayFirst]="false"
        id="recurrence"
        (onChange)="valueChanges()"
      >
      </p-select>
      <label for="recurrence">Recorrência</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="birthDay">
      <p-select
        formControlName="isBirthday"
        placeholder="Aniversariante"
        optionLabel="label"
        optionValue="value"
        [options]="birthDay"
        [autoDisplayFirst]="false"
        (onChange)="valueChanges()"
        id="birthDay"
      >
      </p-select>
      <label for="birthDay">Aniversariante</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="newBadge">
      <p-select
        formControlName="badgeIsNew"
        placeholder="Nova joia"
        optionLabel="label"
        optionValue="value"
        [options]="newBadge"
        (onChange)="valueChanges()"
        id="newBadge"
      >
      </p-select>
      <label for="newBadge">Nova joia</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="badges">
      <p-multiSelect
        formControlName="badgeIds"
        placeholder="Joias"
        optionLabel="title"
        optionValue="badgeId"
        [options]="badges"
        (onPanelHide)="valueChanges()"
        [maxSelectedLabels]="0"
        [selectedItemsLabel]="badgeIdsLabel"
        [filter]="false"
        inputId="badgeIds"
        (onChange)="updateTotalBadges()"
        fluid
      >
        <ng-template *ngIf="badges" let-badge #item>
          {{ badge.title }}
          <ng-container *ngIf="badge.badgeId > 0">
            - {{ badge.consecutiveEditionsToAchieve }} edições
            <span *ngIf="indexOfBadge(badge) === badges.length - 1">
              &nbsp;ou mais</span
            >
          </ng-container>
        </ng-template>
      </p-multiSelect>
      <label for="badgeIds">Joias</label>
    </p-floatlabel>
    <p-floatlabel class="form-control" *ngIf="hasGift">
      <p-select
        formControlName="hasGift"
        placeholder="Já recebeu brinde"
        optionLabel="label"
        optionValue="value"
        [options]="hasGift"
        [autoDisplayFirst]="false"
        (onChange)="valueChanges()"
        id="badges"
      >
      </p-select>
      <label for="badges">Tem brinde na caixa?</label>
    </p-floatlabel>
    <p-floatlabel class="form-control">
      <p-multiSelect
        [options]="days"
        formControlName="paymentDays"
        placeholder="Dia do pagamento"
        inputId="paymentDays"
        [filter]="false"
        [selectedItemsLabel]="paymentDaysLabel"
        [maxSelectedLabels]="0"
        (onChange)="updateTotalDays()"
        (onPanelHide)="valueChanges()"
        fluid
      >
      </p-multiSelect>
      <label for="paymentDays">Dia do pagamento</label>
    </p-floatlabel>
    <p-floatlabel class="form-control">
      <p-inputNumber
        formControlName="limit"
        id="limit"
        placeholder="Máximo de composições a serem geradas"
        pInputNumber
        (onBlur)="validateNumber()"
      ></p-inputNumber>
      <label for="limit">Limite de assinantes</label>
    </p-floatlabel>
    <p-floatlabel class="form-chip">
      <textarea
        pTextarea
        formControlName="boxIds"
        (input)="addBoxIds($event)"
        (keydown)="addBoxIds($event)"
        (keypress)="addBoxIds($event)"
        (keyup)="addBoxIds($event)"
        (blur)="addBoxIds()"
        cols="160"
        rows="5"
        id="boxIds"
      ></textarea>
      <label for="boxIds">{{ subscriptionIdName }}</label>
      <small>Informe os valores separados por Espaço, Enter, "," ou ";"</small>
    </p-floatlabel>
    <div class="boxIds">
      <div
        class="boxIds__label"
        *ngIf="segmentationForm?.value?.referenceBoxIds?.length"
      >
        Box Ids adicionados ({{
          segmentationForm.value.referenceBoxIds.length | number : '1.0-0'
        }}):
        <p-button
          icon="pi pi-times-circle"
          (onClick)="clearBoxIds()"
          [text]="true"
          pTooltip="Remover todos"
        ></p-button>
      </div>
      <div class="boxIds__selected">
        <span *ngFor="let boxId of segmentationForm?.value?.referenceBoxIds">
          <p-chip
            [label]="boxId"
            [removable]="true"
            (onRemove)="removeBoxId(boxId)"
          ></p-chip>
        </span>
      </div>
    </div>
    <div class="form-footer" *ngIf="subscribers || !isResponsible">
      <div class="result" *ngIf="subscribers">
        <span>
          Assinantes para alocar: <b>{{ subscribers.length }}</b>
        </span>
        <ng-container *ngIf="lateSubscribers?.length">
          <span>
            Assinantes com edições atrasadas: <b>{{ totalLateSubscribers }}</b>
          </span>
          <a class="admin-link" type="button" (click)="showLateSubscribers()"
            >Ver assinantes</a
          >
        </ng-container>
      </div>
      <span
        *ngIf="
          subscribers &&
          isResponsible &&
          subscribers.length &&
          subscribers.length >= 0
        "
        class="form-button"
      >
        <p-button label="Avançar" type="submit"></p-button>
      </span>
      <span *ngIf="!isResponsible" class="form-button">
        <p-button
          type="button"
          [routerLink]="
            compositionOpen
              ? 'segmentation'
              : '/draft/summary/id/' + draft?.draftId
          "
          label="Ver composições"
        ></p-button>
      </span>
    </div>
  </form>
</div>
<p-dialog
  [(visible)]="syncModal"
  [closable]="false"
  [modal]="true"
  *ngIf="hasSync"
>
  <div class="sync-modal" *ngIf="unsync">
    <p *ngIf="unsync > 1">
      Existem {{ unsync }} assinantes não sincronizados para esta edição.
    </p>
    <p *ngIf="unsync === 1">
      Existe {{ unsync }} assinante não sincronizado para esta edição.
    </p>
    <p>Deseja sincronizar agora?</p>
    <br />
    <div class="sync-modal__buttons">
      <p-button
        label="Sim"
        styleClass="p-button-primary"
        (onClick)="syncronize()"
        type="button"
      >
      </p-button>
      <p-button
        label="Não"
        styleClass="p-button-danger"
        (onClick)="clearSync()"
        type="button"
      >
      </p-button>
    </div>
  </div>
</p-dialog>

<p-confirmDialog
  header="Atenção"
  icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
