/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantBrand {
  productVariantId?: number;
  productVariantName?: string;
  brandId?: string;
  brandName?: string;
  productId?: number;
  productName?: string;
  defaultImageURL?: string;
  costPrice?: number;
  salePrice?: number;
  weight?: number;
  internalEAN?: string;
}
