import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  AdminControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { getAllRoles, getRole } from 'src/app/models/enums/Role';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { ToastUtil } from 'src/app/utils/toast.util';
import { PasswordUpdateModalComponent } from './password-update-modal/password-update-modal.component';
import { UserFormModalComponent } from './user-form-modal/user-form-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [AdminControllerService, DialogService]
})
export class UsersComponent implements OnInit {
  allowedUsers?: Array<UserRoleViewResponse & { role: string }> = [];
  permissions = [
    { label: 'Usuário', value: 'ROLE_ALLOC_USER' },
    { label: 'Admin', value: 'ROLE_ALLOC_ADMIN' }
  ];
  allPermissions: Array<{ label: string; value: string }>;

  constructor(
    private adminService: AdminControllerService,
    private confirmationService: ConfirmationService,
    private dialog: DialogService
  ) {
    this.allPermissions = getAllRoles().map((r) => ({
      label: r.label,
      value: r.enumValue
    }));
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findUsers();
    LoaderService.showLoader(false);
  }

  async findUsers(): Promise<void> {
    try {
      this.allowedUsers = (
        (await lastValueFrom(
          this.adminService.getAllowedUsers().pipe(map((data) => data.result))
        )) || []
      ).map((u) => ({
        ...u,
        role: FormatUtil.getRolePrincipal(u.roles as string)
      }));
    } catch (error: any) {
      alert(error.error.message);
      this.allowedUsers = [];
    }
  }

  addNewUser(): void {
    LoaderService.showLoader(false);
    this.dialog
      .open(UserFormModalComponent, {
        showHeader: true,
        header: 'Novo usuário',
        dismissableMask: true,
        width: '500px',
        closable: false
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          await this.findUsers();
          LoaderService.showLoader(false);
        }
      });
  }

  editUser(user: UserRoleViewResponse & { role: string }): void {
    this.dialog
      .open(UserFormModalComponent, {
        showHeader: true,
        closable: false,
        header: 'Editar usuário',
        dismissableMask: false,
        width: '500px',
        data: {
          user
        }
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          await this.findUsers();
          LoaderService.showLoader(false);
        }
      });
  }

  async updatePermission(
    event: { value: string },
    user: UserRoleViewResponse & { role: string }
  ): Promise<void> {
    LoaderService.showLoader();
    const roles = [event.value];
    if (event.value === 'ROLE_ALLOC_ADMIN') {
      roles.push('ROLE_ALLOC_USER');
    }
    try {
      const view = (await lastValueFrom(
        this.adminService
          .saveUserRoles({
            userId: user.userId as number,
            roles: roles.map((r) => getRole(r)?.value as number)
          })
          .pipe(map((data) => data.result))
      )) as UserRoleViewResponse;
      user = {
        ...view,
        role: FormatUtil.getRolePrincipal(view.roles as string)
      };
      ToastUtil.addToast({
        detail: `Permissão alterada para ${getRole(event.value)?.label}`,
        severity: 'success',
        summary: 'Sucesso'
      });
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
    LoaderService.showLoader(false);
  }

  excludeUser(event: Event, user: UserRoleViewResponse) {
    this.confirmationService.confirm({
      target: event.target as HTMLButtonElement,
      message: `Deseja remover o acesso do usuário ${user.username}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        LoaderService.showLoader();
        try {
          const response = await lastValueFrom(
            this.adminService
              .deleteUser(user.userId as number)
              .pipe(map((data) => data.result))
          );
          ToastUtil.addToast({
            severity: 'info',
            summary: 'Confirmed',
            detail: response as string
          });
          await this.findUsers();
        } catch (error: any) {
          ToastUtil.showErrorToast(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  getRoleLabel(role: string): string {
    return getRole(role)?.label || 'NONE';
  }

  changePassword(user: UserRoleViewResponse): void {
    this.dialog
      .open(PasswordUpdateModalComponent, {
        showHeader: true,
        header: 'Alterar senha',
        dismissableMask: false,
        width: '500px',
        data: {
          user
        }
      })
      .onClose.subscribe(async (success: any) => {
        if (success) {
          LoaderService.showLoader();
          await this.findUsers();
          LoaderService.showLoader(false);
        }
      });
  }

  get userId(): number {
    return TokenStorageService.getUserId();
  }
}
