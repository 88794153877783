import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy
} from '@angular/core';
import {
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe
} from '@angular/fire/messaging';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  NotificationsControllerService,
  UserControllerService,
  UserRoleViewResponse
} from './allocation-api';
import { FirebaseTopic } from './models/enums/FirebaseTopic';
import { TokenStorageService } from './services/auth/token-storage.service';
import { CookieService } from './services/cookie.service';
import { AppDialogService } from './services/dialog.service';
import { LoaderService } from './services/loader.service';
import { BroadcastUtil } from './utils/broadcast.util';
import { ToastUtil } from './utils/toast.util';

export interface MenuItem {
  title: string;
  path: string;
  permissions?: Array<string>;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  loader = false;
  menus: Array<MenuItem> = [
    {
      title: 'Drafts',
      path: 'draft',
      permissions: ['ROLE_ALLOC_USER']
    },
    {
      title: 'Sincronização',
      path: 'syncronization',
      permissions: ['ROLE_ALLOC_USER']
    },
    {
      title: 'Login',
      path: '/login'
    },
    {
      title: 'Usuários',
      path: '/users',
      permissions: ['ROLE_ALLOC_ADMIN']
    },
    {
      title: 'Configurações',
      path: '/settings',
      permissions: ['ROLE_ALLOC_USER']
    }
  ];
  user: UserRoleViewResponse;
  unsubscribeMessages: Unsubscribe | undefined;
  lastMessages: { [key: string]: string } = {};

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    this.onPageFocus();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private userService: UserControllerService,
    private notificationsService: NotificationsControllerService,
    private messaging: Messaging
  ) {
    LoaderService.progressSubscriber.subscribe((show: boolean) => {
      this.loader = show;
      this.cdRef.reattach();
      this.cdRef.detectChanges();
    });
    if (Notification.permission !== 'granted') {
      this.requestPermission();
    } else {
      this.refreshToken();
    }
    BroadcastUtil.get('toast').subscribe(
      (message: { severity: string; detail: string; summary: string }) => {
        this.messageService.add(message);
      }
    );
    BroadcastUtil.get('login').subscribe(() => this.findUserLogged());
    this.findUserLogged();
  }

  requestPermission(): void {
    Notification.requestPermission()
      .then((permission) => {
        if (permission !== 'granted') {
          AppDialogService.showErrorDialog({
            messsage: 'Você precisa permitir as notificações.'
          });
        }
        this.refreshToken();
      })
      .catch(() =>
        AppDialogService.showErrorDialog({
          messsage: 'Você precisa permitir as notificações.'
        })
      );
  }

  async refreshToken() {
    try {
      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        '/assets/firebase-messaging-sw.js',
        {
          type: 'module'
        }
      );
      const currentToken = await getToken(this.messaging, {
        vapidKey: environment.firebase.vapidKey,
        serviceWorkerRegistration
      });
      if (currentToken) {
        console.log(currentToken);
        CookieService.setCookie('FCMToken', currentToken, 7);
      } else {
        this.requestPermission();
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  get userLogged(): boolean {
    return TokenStorageService.userLogged;
  }

  get menuOptions(): Array<MenuItem> {
    return this.userLogged
      ? this.menus.filter((f) =>
          f.permissions?.every((p) =>
            TokenStorageService.getUserRoles()?.includes(p)
          )
        )
      : this.menus.filter((o) => !o.permissions || !o.permissions.length);
  }

  logout() {
    try {
      TokenStorageService.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  onMessageReceived(payload: MessagePayload): void {
    if (
      this.user &&
      payload.data &&
      payload.data['message']?.trim() &&
      payload.data['username'] &&
      payload.data['username'] === this.user.username
    ) {
      ToastUtil.addToast({
        severity: 'info',
        summary: 'Aviso',
        detail: payload.data['message']
      });
    }
  }

  onPageFocus(): void {
    let messages: string | [] = CookieService.getCookie('toast');
    if (messages) messages = JSON.parse(messages);
    else messages = [];
    (messages as any[]).forEach((data) => {
      if (data['username'] === this.user?.username)
        ToastUtil.addToast({
          severity: 'info',
          summary: 'Aviso',
          detail: data.message
        });
    });
    CookieService.eraseCookie('toast');
  }

  async findUserLogged() {
    if (this.userLogged)
      try {
        this.user = await lastValueFrom(
          this.userService.getInfo().pipe(map((data) => data.result))
        );
        await this.subscribeToTopic();
        this.unsubscribeMessages = onMessage(this.messaging, (payload) => {
          if (
            (payload.data['topic'].replace('-dev', '') as FirebaseTopic) !==
            FirebaseTopic.TOAST
          )
            return;
          if (
            payload.data &&
            payload.data['topic'] &&
            this.lastMessages[payload.data['topic']] &&
            this.lastMessages[payload.data['topic']] === payload.messageId
          ) {
            console.log('duplicated message:', payload.messageId);
          } else {
            if (payload.data && payload.data['topic']) {
              this.lastMessages[payload.data['topic']] = payload.messageId;
              payload.data['topic'] = payload.data['topic'].replace('-dev', '');
            }
            this.onMessageReceived(payload);
          }
        });
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
  }

  private async subscribeToTopic(): Promise<void> {
    try {
      const response = await lastValueFrom(
        this.notificationsService.subscribeToTopic(
          this.topic,
          CookieService.getCookie('FCMToken')
        )
      );
      console.log(response.result);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeMessages) {
      this.unsubscribeMessages();
    }
    this.unsubscribeTopics();
  }

  private async unsubscribeTopics() {
    try {
      const response = await this.notificationsService.unsubscribeFromTopic(
        this.topic,
        CookieService.getCookie('FCMToken')
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  get topic() {
    return FirebaseTopic.TOAST.concat(environment.production ? '' : '-dev');
  }
}
