<div class="compositions">
  <div class="compositions__title">
    <span
      ><strong>Composições</strong>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-rounded p-button-text"
        pTooltip="Nova composição"
        tooltipPosition="bottom"
        *ngIf="
          !problems &&
          draftCompositionProducts?.length &&
          subscribersToAlocate > 0 &&
          isResponsible
        "
        (click)="addNew.emit()"
      ></button>
    </span>
  </div>
  <div class="compositions__list">
    <div
      class="compositions__list__composition"
      *ngFor="let composition of draftCompositions; let i = index"
      [class.compositions__list__composition--selected]="isSelected(i)"
      [id]="composition.draftCompositionId"
    >
      <div
        class="compositions__list__composition__info"
        [class.compositions__list__composition__info--space-between]="
          isSelected(i) || !isResponsible
        "
      >
        <span
          *ngIf="!isPartition(composition)"
          [class.problem]="composition.message"
        >
          Composição: {{ composition.draftCompositionName }}
        </span>
        <span *ngIf="isPartition(composition)">
          Partição: {{ composition.draftCompositionName }}
        </span>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          *ngIf="
            composition.draftCompositionId &&
            composition.draftCompositionId !==
              draftComposition?.draftCompositionId &&
            isResponsible
          "
          (click)="changeName.emit(composition)"
          pTooltip="Alterar nome"
          tooltipPosition="bottom"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-eye"
          class="p-button-rounded p-button-text"
          *ngIf="
            composition.draftCompositionId &&
            composition.draftCompositionId !==
              draftComposition?.draftCompositionId &&
            !isPartition(composition)
          "
          (click)="showComposition.emit(composition)"
          pTooltip="Ver composição"
          tooltipPosition="bottom"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-filter-fill"
          class="p-button-rounded p-button-text"
          *ngIf="composition.draftCompositionId && isPartition(composition)"
          pTooltip="Ver filtros"
          tooltipPosition="bottom"
          (click)="viewPartition.emit(composition)"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-clone"
          class="p-button-rounded p-button-text"
          *ngIf="composition.draftCompositionId && isPartition(composition)"
          pTooltip="Converter em composição"
          tooltipPosition="bottom"
          (click)="convertToComposition.emit(composition)"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-danger"
          *ngIf="
            composition.draftCompositionId &&
            composition.draftCompositionId !==
              draftComposition?.draftCompositionId &&
            isResponsible &&
            composition.draftCompositionStatus === 1
          "
          pTooltip="Excluir"
          tooltipPosition="bottom"
          (click)="deleteComposition.emit(composition)"
        ></button>
      </div>
      <div class="compositions__list__composition__info">
        <span>
          Assinantes:
          <a
            type="button"
            [class.admin-link]="
              composition.compositionSubscribers &&
              composition.draftCompositionId !==
                draftComposition?.draftCompositionId
            "
            (click)="
              composition.compositionSubscribers &&
                composition.draftCompositionId !==
                  draftComposition?.draftCompositionId &&
                showCompositionSubscribers.emit(composition)
            "
            pTooltip="Ver assinantes"
            tooltipPosition="bottom"
          >
            {{ composition.compositionSubscribers | number : '1.0-0' }}
          </a>
        </span>
      </div>
      <ng-container *ngIf="!isPartition(composition)">
        <div class="compositions__list__composition__info">
          <span> Produtos: {{ composition.compositionProducts }} </span>
        </div>
        <div
          class="compositions__list__composition__info compositions__list__composition__info--vertical"
        >
          <span>
            BrandEquity:
            <span *ngIf="!brandEquities(composition)">0</span></span
          >
          <span
            class="equity"
            *ngFor="let equity of brandEquities(composition)"
          >
            {{ equity.quantity }} SKU
            {{ equityLabel(equity.brandEquity) }}
          </span>
        </div>
        <div class="compositions__list__composition__info">
          <span>
            Preço custo: {{ composition.costPrice | currency : 'BRL' }}
          </span>
        </div>
        <div class="compositions__list__composition__info">
          <span>
            Preço de mercado: {{ composition.salePrice | currency : 'BRL' }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="compositions__list__bottom">
    <div class="compositions__divider"></div>
    <div class="compositions__list__bottom__footer" *ngIf="isResponsible">
      <div class="compositions__list__bottom__footer__summary">
        <span class="title"> Alocadas </span>
        <span class="value">{{
          totalSignersInCompositions | number : '1.0-0'
        }}</span>
        <br />
        <ng-container *ngIf="totalSignersInPartitions">
          <span class="title"> Em partições </span>
          <span class="value">
            {{ totalSignersInPartitions | number : '1.0-0' }}
          </span>
          <br />
        </ng-container>
        <span class="title"> Para alocar </span>
        <span class="value">
          {{ subscribersToAlocate | number : '1.0-0' }}
        </span>
        <br />
        <span class="title"> Total </span>
        <span class="value">
          {{ totalSubscribers | number : '1.0-0' }}
        </span>
      </div>
      <div>
        <button
          [disabled]="
            problems ||
            (totalSignersInCompositions > 0 &&
              draftCompositions?.length === 1 &&
              currentCompositionInvalid)
          "
          pButton
          pRipple
          type="button"
          class="compositions__button--submit"
          label="Concluir"
          (click)="submitPage.emit()"
        ></button>
      </div>
    </div>
    <div
      class="compositions__list__bottom__footer-summary"
      *ngIf="!isResponsible"
    >
      <span>Assinantes alocadas</span>
      <span> {{ totalSignersInCompositions }}/{{ totalSubscribers }} </span>
    </div>
  </div>
</div>
