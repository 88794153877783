<p>Sincronização em andamento, por favor aguarde!</p>
<br />
<!-- <p-timeline [value]="statusList" layout="horizontal" align="top">
  <ng-template pTemplate="marker" let-event>
    <span
      class="custom-marker"
      [class.custom-marker--finished]="event < statusId"
      [class.custom-marker--current]="event === statusId"
    >
    </span>
  </ng-template>
</p-timeline>
<br /> -->
<p *ngIf="statusId > 0">
  <span *ngIf="statusId === 1">Sincronizando assinantes</span>
  <span *ngIf="statusId === 2">Sincronizando produtos recebidos</span>
  <span *ngIf="statusId === 3">Sincronizando perfil de beleza</span>
  <span *ngIf="statusId === 4">Sincronizando produtos disponíveis</span>
</p>
<br />
<p>
  <i class="pi pi-spin pi-spinner"></i>
</p>
<small class="error" *ngIf="((statusId === 2||  statusId === 4) && diff() > 60) || ((statusId === 1||  statusId === 3) && diff() > 120)">
  <a type="button" class="admin-link" (click)="checkSync()">* Sincronização travada? Clique aqui.</a>
</small>
