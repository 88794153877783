/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DraftCompositionProductReserved } from './draftCompositionProductReserved';

export interface CompositionSummaryDTO {
  draftCompositionId?: number;
  draftCompositionName?: string;
  subscribersLimit?: number;
  compositionId?: number;
  products?: Array<DraftCompositionProductReserved>;
  weight?: number;
  salePrice?: number;
  costPrice?: number;
  subscribers?: number;
  approved?: boolean;
  message?: string;
}
