<components-loader *ngIf="loader"></components-loader>
<div class="container">
  <p-menu [model]="menus">
    <ng-template #start>
      <div class="logo">
        <img src="assets/Logo_B4A_White.png" />
      </div>
    </ng-template>
  </p-menu>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<p-toast></p-toast>
