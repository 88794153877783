export enum BrandEquity {
  'Não informado' = 0,
  'Detractor' = 1,
  'Unknown' = 2,
  'Low' = 3,
  'Mid' = 4,
  'Strong' = 5
}

export function getAllBrandEquities(): Array<{ label: string; value: number }> {
  const objetos: Array<{ label: string; value: number }> = [];
  for (const [value, label] of Object.entries(BrandEquity)) {
    if (
      !objetos.some(
        (o) => o.value === Number(value) || o.value === Number(label)
      )
    )
      objetos.push({ label: label as string, value: Number(value) });
  }
  return objetos;
}

export function getEquityLabel(id: number): string | undefined {
  return getAllBrandEquities().find((e) => e.value === id)?.label;
}
