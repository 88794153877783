import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { Edition, EditionControllerService } from 'src/app/allocation-api';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-product-history-syncronization-modal',
  templateUrl: './product-history-syncronization-modal.component.html',
  styleUrls: ['./product-history-syncronization-modal.component.scss']
})
export class ProductHistorySyncronizationModalComponent implements OnInit {
  periodForm = new FormGroup({
    dateStart: new FormControl<Date>(this.yesterday, Validators.required),
    dateEnd: new FormControl<Date>(this.today, Validators.required)
  });
  editionForm = new FormGroup({
    subscriptionId: new FormControl<number>(1, Validators.required),
    editionIds: new FormControl<number[]>([], Validators.required)
  });
  editions: Array<Edition> | undefined;
  subscriptions = [
    {
      subscriptionId: 1,
      subscriptionName: 'Glambox'
    },
    {
      subscriptionId: 5,
      subscriptionName: 'Glambag'
    },
    {
      subscriptionId: 7,
      subscriptionName: 'Glamcombo'
    }
  ];

  constructor(
    public ref: DynamicDialogRef,
    private editionService: EditionControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.findEditions();
  }

  async findEditions(): Promise<void> {
    try {
      this.editions = await lastValueFrom(
        this.editionService
          .getLastSubscriptions(this.editionForm.value.subscriptionId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  releaseDate(edition: Edition): Date {
    if (edition.publicRelease) {
      return new Date(edition.publicRelease);
    }
    const month = edition.editionId?.toString().substring(5);
    const year = edition.editionId?.toString().substring(1, 4);
    return new Date(`${year}-${month?.padStart(2, '0')}-02`);
  }

  isWeekend(date: Date): boolean {
    return date.getDay() % 6 === 0;
  }

  get today(): Date {
    return new Date();
  }

  get yesterday(): Date {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    while (this.isWeekend(yesterday))
      yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }
}
