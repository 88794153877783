import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { MenuItem, MessageService, PrimeIcons } from 'primeng/api';
import { lastValueFrom, map, Subscription } from 'rxjs';
import { UserControllerService, UserRoleViewResponse } from './allocation-api';
import { StringMessage } from './models';
import { Topic } from './models/enums';
import { Message } from './models/message';
import { TokenStorageService } from './services/auth/token-storage.service';
import { LoaderService } from './services/loader.service';
import { WebsocketService } from './services/websocket.service';
import { BroadcastUtil } from './utils/broadcast.util';
import { ToastUtil } from './utils/toast.util';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy {
  loader = false;
  menus: Array<MenuItem> = [
    {
      label: 'Drafts',
      routerLink: 'draft',
      icon: PrimeIcons.BOOK
    },
    {
      label: 'Sincronização',
      routerLink: 'syncronization',
      icon: PrimeIcons.SYNC
    },
    {
      label: 'Logout',
      command: () => this.logout(),
      icon: PrimeIcons.SIGN_OUT
    }
  ];
  user: UserRoleViewResponse;
  lastMessages: { [key: string]: string } = {};
  subscriptions: Subscription[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private userService: UserControllerService,
    private websocketService: WebsocketService
  ) {
    LoaderService.progressSubscriber.subscribe((show: boolean) => {
      this.loader = show;
      this.cdRef.reattach();
      this.cdRef.detectChanges();
    });
    this.subscriptions.push(
      BroadcastUtil.get('toast').subscribe(
        (message: { severity: string; detail: string; summary: string }) => {
          this.messageService.add(message);
        }
      )
    );
    this.subscriptions.push(
      BroadcastUtil.get('login').subscribe(() => this.findUserLogged())
    );
    this.findUserLogged();
  }

  ngOnDestroy() {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  get userLogged(): boolean {
    return TokenStorageService.userLogged;
  }

  get menuOptions(): Array<MenuItem> {
    return this.userLogged ? this.menus : [];
  }

  logout() {
    try {
      TokenStorageService.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  onMessageReceived(message: Message<StringMessage>): void {
    if (
      this.user &&
      message.body &&
      message.body.message?.trim() &&
      message.body.username &&
      message.body.username === this.user.username
    ) {
      ToastUtil.addToast({
        severity: 'info',
        summary: 'Aviso',
        detail: message.body.message
      });
    }
  }

  async findUserLogged() {
    if (this.userLogged)
      try {
        this.user = await lastValueFrom(
          this.userService.getInfo().pipe(map((data) => data.result))
        );
        this.websocketService.connect(this.onMessage.bind(this));
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
  }

  onMessage(message: Message<any>): void {
    console.log(message);
    if (message.topic === Topic.MESSAGE) {
      this.lastMessages[message['topic']] = message.id;
      this.onMessageReceived(message);
    } else {
      BroadcastUtil.get(message.topic.toString()).emit(message);
    }
  }
}
