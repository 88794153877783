import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map, Subscription } from 'rxjs';
import {
  ProductHistorySyncronizationRequest,
  Syncronization,
  SyncronizationControllerService,
  SyncronizationLog
} from 'src/app/allocation-api';
import { Message, SyncronizationUpdate } from 'src/app/models';
import { Topic } from 'src/app/models/enums';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { ToastUtil } from 'src/app/utils/toast.util';
import { ProductHistorySyncronizationModalComponent } from './product-history-syncronization-modal/product-history-syncronization-modal.component';

@Component({
  selector: 'app-syncronization',
  templateUrl: './syncronization.component.html',
  styleUrls: ['./syncronization.component.scss'],
  providers: [ConfirmationService, DialogService, DatePipe],
  standalone: false
})
export class SyncronizationComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmPopup) popup: ConfirmPopup;

  subscribersToAllocate: number | undefined;
  subscribersToAllocateSynced: number | undefined;
  attributeOptions: number | undefined;
  attributeOptionsSynced: number | undefined;
  productsAvailable: number | undefined;
  productsAvailableSynced: number | undefined;
  productsHistory: number | undefined;
  productsHistorySynced: number | undefined;
  infos = signal([]);
  blockModal: DynamicDialogRef | undefined;
  sync: Syncronization | undefined;
  logs: Array<SyncronizationLog> | undefined;
  log: string | undefined;
  showLogs = false;
  timeDiffLabel: string | undefined;
  subscriptions: Subscription[] = [];
  constructor(
    private syncronizationService: SyncronizationControllerService,
    private confirmationService: ConfirmationService,
    private dialog: DialogService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      BroadcastUtil.get(
        Topic.ALLOCATION_SYNCRONIZATION_CHANGE.toString()
      ).subscribe((message: Message<SyncronizationUpdate>) => {
        this.handlePayload();
      })
    );
    await this.checkSyncInProgress();
    if (!this.sync || this.sync.status === 0) await this.findInfos();
  }

  ngOnDestroy() {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  async findInfos(): Promise<void> {
    this.infos.set([
      {
        id: 0,
        name: 'Caixas para alocar',
        synced: undefined,
        unsynced: undefined
      },
      {
        id: 1,
        name: 'Perfis de beleza',
        synced: undefined,
        unsynced: undefined
      },
      {
        id: 2,
        name: 'Produtos disponíveis',
        synced: undefined,
        unsynced: undefined
      },
      {
        id: 3,
        name: 'Produtos recebidos',
        synced: undefined,
        unsynced: undefined
      }
    ]);
    await Promise.all([
      this.findSubscribersToAllocate(),
      this.findSubscribersToAllocateSynced(),
      this.findAttributeOptions(),
      this.findAttributeOptionsSynced(),
      this.findProductsAvailable(),
      this.findProductsAvailableSynced(),
      this.findPersonProductVariants(),
      this.findPersonProductVariantsSynced()
    ]);
  }

  async findSubscribersToAllocate(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 0);
      if (info)
        info.unsynced = await lastValueFrom(
          this.syncronizationService
            .findSubscribersToAllocate(false)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findSubscribersToAllocateSynced(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 0);
      if (info)
        info.synced = await lastValueFrom(
          this.syncronizationService
            .findSubscribersToAllocate(true)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findAttributeOptions(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 1);
      if (info)
        info.unsynced = await lastValueFrom(
          this.syncronizationService
            .findAttributeOptions(false)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findAttributeOptionsSynced(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 1);
      if (info)
        info.synced = await lastValueFrom(
          this.syncronizationService
            .findAttributeOptions(true)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findProductsAvailable(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 2);
      if (info)
        info.unsynced = await lastValueFrom(
          this.syncronizationService
            .findProductsAvailable(false)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findProductsAvailableSynced(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 2);
      if (info)
        info.synced = await lastValueFrom(
          this.syncronizationService
            .findProductsAvailable(true)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findPersonProductVariants(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 3);
      if (info)
        info.unsynced = await lastValueFrom(
          this.syncronizationService
            .findPersonProductVariants(false)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findPersonProductVariantsSynced(): Promise<void> {
    try {
      const info = this.infos().find((i) => i.id === 3);
      if (info)
        info.synced = await lastValueFrom(
          this.syncronizationService
            .findPersonProductVariants(true)
            .pipe(map((data) => data.result))
        );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  callAction($event: Event, info: SyncronizationInfo): void {
    if (this.popup?.visible) {
      this.popup.visible = false;
      setTimeout(() => this.syncInfo($event, info), 150);
    } else {
      this.syncInfo($event, info);
    }
  }

  syncInfo($event: Event, info: SyncronizationInfo) {
    if (info.id === 3) {
      this.syncronize(info);
      return;
    }
    this.confirmationService.confirm({
      acceptButtonProps: {
        label: 'Sincronizar',
        severity: 'success'
      },
      rejectButtonProps: {
        label: 'Voltar',
        severity: 'danger'
      },
      header: 'Sincronizar ' + info.name,
      message:
        'As alocações serão interropidas até a conclusão.Deseja sincronizar agora?',
      target: $event?.target,
      accept: async () => {
        await this.syncronize(info);
      }
    });
  }

  async syncronize(info: SyncronizationInfo): Promise<void> {
    LoaderService.showLoader();
    switch (info.id) {
      case 1:
        await lastValueFrom(
          this.syncronizationService.syncronizeBeautyProfile()
        );
        break;
      case 2:
        await lastValueFrom(
          this.syncronizationService.syncronizeProductsAvailable()
        );
        break;
      case 3:
        this.openProductHistoryModal();
        break;
      default:
        await lastValueFrom(this.syncronizationService.syncronizeSubscribers());
        break;
    }
    await this.checkSyncInProgress();
    LoaderService.showLoader(false);
  }

  openProductHistoryModal(): void {
    this.dialog
      .open(ProductHistorySyncronizationModalComponent, {
        header: 'Sincronizar histórico de produtos',
        modal: true,
        width: '650px'
      })
      .onClose.subscribe(
        async (data: {
          dateStart?: Date;
          dateEnd?: Date;
          subscriptionIds?: number[];
          editionIds?: number[];
        }) => {
          if (data) {
            LoaderService.showLoader();
            const body: ProductHistorySyncronizationRequest = {
              dateStart:
                this.datePipe.transform(data.dateStart, 'yyyy-MM-dd') ||
                undefined,
              dateEnd:
                this.datePipe.transform(data.dateEnd, 'yyyy-MM-dd') ||
                undefined,
              editionIds: data.subscriptionIds?.reduce(
                (ids, s) =>
                  (ids = ids.concat(
                    data.editionIds.map((e) => Number(`${s}${e}`))
                  )),
                []
              )
            };
            await lastValueFrom(
              this.syncronizationService.syncronizeProductsAndHistory(body)
            );
            LoaderService.showLoader(false);
          }
        }
      );
  }

  async handlePayload(): Promise<void> {
    await this.checkSyncInProgress();
    if (!this.sync || this.sync.status === 0) {
      await this.findInfos();
    }
  }

  async onPageFocus(): Promise<void> {
    if (
      this.sync?.status ||
      (this.productsAvailable &&
        this.productsAvailableSynced &&
        this.productsHistory &&
        this.productsHistorySynced &&
        this.attributeOptions &&
        this.attributeOptionsSynced &&
        this.subscribersToAllocate &&
        this.subscribersToAllocateSynced)
    )
      await this.checkSyncInProgress();
  }

  async checkSyncInProgress(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.sync = await lastValueFrom(
        this.syncronizationService
          .findLastSyncronization()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.sync = undefined;
    }
    if (this.sync) {
      try {
        this.logs = await lastValueFrom(
          this.syncronizationService
            .findLastSyncronizationLogs()
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        this.logs = [];
      }
      this.showLogs = false;
      this.log =
        this.logs?.reduce(
          (s: string, log) =>
            (s += `${this.datePipe.transform(
              new Date(log.dateCreated as any),
              'dd/MM/yyyy HH:mm:ss'
            )} - ${log.detailMessage}\n`),
          ''
        ) || '';
    }
    if (this.timeDiff < 60) {
      this.timeDiffLabel = this.timeDiff.toFixed(0) + ' segundos';
    } else {
      this.timeDiffLabel =
        (this.timeDiff / 60).toFixed(0) +
        ' minutos' +
        (Number((this.timeDiff % 60).toFixed(0)) > 0
          ? ' e ' + (this.timeDiff % 60).toFixed(0) + ' segundos'
          : '');
    }
    LoaderService.showLoader(false);
  }

  async refreshInfo(info: SyncronizationInfo, synced = false): Promise<void> {
    if (synced) delete info.synced;
    else delete info.unsynced;
    switch (info.id) {
      case 1:
        if (synced) await this.findAttributeOptionsSynced();
        else await this.findAttributeOptions();
        break;
      case 2:
        if (synced) await this.findProductsAvailableSynced();
        else await this.findProductsAvailable();
        break;
      case 3:
        if (synced) await this.findPersonProductVariantsSynced();
        else await this.findPersonProductVariants();
        break;
      default:
        if (synced) await this.findSubscribersToAllocateSynced();
        else await this.findSubscribersToAllocate();
        break;
    }
  }

  unsyncedDiff(info: SyncronizationInfo): number | undefined {
    if (info.synced !== undefined && info.unsynced !== undefined)
      return info.unsynced - info.synced;
    return undefined;
  }

  get timeDiff(): number {
    let today = new Date();
    if (this.sync?.dateFinished)
      today = new Date(this.sync?.dateFinished as any);
    return (
      ((today.getTime() || 0) -
        (new Date(this.sync?.dateCreated as any).getTime() || 0)) /
      1000
    );
  }
}

export interface SyncronizationInfo {
  name: string;
  synced: number | undefined;
  unsynced: number | undefined;
  id: number;
}
