<div class="summary" *ngIf="draft && compositions && draftFilters">
  <h3>
    Draft {{ draft.draftId }} - {{ draft.name }}
    <ng-container *ngIf="!draft.draftStatus">(Em andamento)</ng-container>
  </h3>
  <br />
  <div class="draft-info">
    <div class="info">
      <span><strong>Assinatura</strong>: {{ subscriptionName }}</span>
      <span><strong>Edição</strong>: {{ editionDate | titlecase }}</span>
    </div>
    <div class="info">
      <span><strong>Composições</strong>: {{ compositions.length }}</span>
      <span><strong>Assinantes</strong>: {{ totalSubscribers }}</span>
    </div>
  </div>
  <br />
  <h3>Filtros</h3>
  <br />
  <div class="draft-filters">
    <div class="info">
      <span><strong>Assinante</strong>: {{ personType }}</span>
      <span *ngIf="draftFilters.partnerType"
        ><strong>Partner</strong>: {{ parnerType }}</span
      >
      <span><strong>Tempo</strong>: {{ timeFilter }}</span>
    </div>
    <div class="info">
      <span
        ><strong>Mês de recorrência</strong>:
        {{ recurrenceMonth | titlecase }}</span
      >
      <span><strong>Recorrência</strong>: {{ recurrence }}</span>
    </div>
    <div class="info">
      <span><strong>Aniversariante</strong>: {{ birthday }}</span>
      <span><strong>Nova joia</strong>: {{ badgeIsNew }}</span>
    </div>
    <div class="info">
      <span><strong>Joia</strong>: {{ badge }}</span>
      <span><strong>Tem brinde?</strong>: {{ gift }}</span>
    </div>
    <div class="info">
      <span><strong>Dia do pagamento</strong>: {{ paymentDay }}</span>
      <span><strong>Limite de assinantes</strong>: {{ subscribersLimit }}</span>
    </div>
  </div>
</div>
<div class="compositions" *ngIf="compositions">
  <p-table [value]="compositions" dataKey="draftCompositionId">
    <ng-template #header>
      <tr>
        <th style="width: 5rem"></th>
        <th pSortableColumn="draftCompositionName">
          Composisição <p-sortIcon field="draftCompositionName"></p-sortIcon>
        </th>
        <th pSortableColumn="products.length">
          Produtos <p-sortIcon field="products.length"></p-sortIcon>
        </th>
        <th pSortableColumn="subscribers">
          Assinantes <p-sortIcon field="subscribers"></p-sortIcon>
        </th>
        <th pSortableColumn="costPrice">
          Preço de custo <p-sortIcon field="costPrice"></p-sortIcon>
        </th>
        <th pSortableColumn="salePrice">
          Preço de mercado <p-sortIcon field="salePrice"></p-sortIcon>
        </th>
        <th pSortableColumn="weight">
          Peso <p-sortIcon field="weight"></p-sortIcon>
        </th>
        <th
          *ngIf="
            !this.draft?.draftStatus &&
            !this.draft.approved &&
            (owner || responsible)
          "
        >
          Ação
        </th>
      </tr>
    </ng-template>
    <ng-template #body let-composition let-expanded="expanded">
      <tr
        [class.problem]="
          !draft.draftStatus && composition.message && (responsible || owner)
        "
        [class.approved]="!draft.draftStatus && composition.approved"
        [class.pending-review]="
          !draft.draftStatus && !composition.message && !composition.approved
        "
      >
        <td>
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="composition"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="toggleComposition(composition)"
          ></button>
        </td>
        <td>{{ composition.draftCompositionName }}</td>
        <td class="text-center">{{ composition.products.length || 0 }}</td>
        <td class="text-center">
          <a
            class="admin-link"
            type="button"
            (click)="showCompositionSubscribers(composition)"
          >
            {{ composition.subscribers }}
          </a>
        </td>
        <td class="text-right">
          {{ composition.costPrice | currency : 'BRL' }}
        </td>
        <td class="text-right">
          {{ composition.salePrice | currency : 'BRL' }}
        </td>
        <td class="text-right">{{ composition.weight }}g</td>
        <td *ngIf="pendingReview">
          <a
            type="button"
            pButton
            class="p-button-rounded p-button-text p-button-sm p-button-success"
            icon="pi pi-check"
            pTooltip="Aprovar"
            tooltipPosition="bottom"
            (click)="approveComposition(composition)"
            *ngIf="!composition.approved"
          >
          </a>
          <a
            type="button"
            pButton
            class="p-button-rounded p-button-text p-button-sm p-button-danger"
            icon="pi pi-times"
            pTooltip="Reprovar"
            tooltipPosition="bottom"
            (click)="rejectComposition(composition)"
            *ngIf="!composition.message"
          >
          </a>
        </td>
        <td
          *ngIf="
            !this.draft?.draftStatus && !this.draft.approved && responsible
          "
        >
          <a
            type="button"
            (click)="editComposition(composition)"
            pButton
            class="p-button-rounded p-button-text p-button-sm p-button-primary"
            icon="pi pi-pencil"
            pTooltip="Editar composição"
            tooltipPosition="bottom"
          >
          </a>
          <a
            type="button"
            (click)="deleteComposition(composition)"
            pButton
            class="p-button-rounded p-button-text p-button-sm p-button-danger"
            icon="pi pi-trash"
            pTooltip="Excluir composição"
            tooltipPosition="bottom"
          >
          </a>
        </td>
      </tr>
    </ng-template>
    <ng-template #expandedrow let-composition>
      <tr *ngIf="composition.message">
        <td colspan="8">
          <span class="error-message"
            >*{{ composition.message }} ({{ draft.owner }})</span
          >
        </td>
      </tr>
      <tr>
        <td [attr.colspan]="pendingReview || problems ? 8 : 7">
          <div class="p-3">
            <p-table [value]="composition.products" dataKey="productVariantId">
              <ng-template #header>
                <tr>
                  <th>CEAN</th>
                  <th>Produto</th>
                  <th>Marca</th>
                  <th>BrandEquity</th>
                  <th>Categoria</th>
                  <th>Preço de custo</th>
                  <th>Preço de mercado</th>
                  <th>Peso</th>
                  <th *ngIf="isSummaryCombo()">Envio</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template #body let-product>
                <tr>
                  <td>{{ product.internalEAN }}</td>
                  <td>{{ product.productVariantName }}</td>
                  <td>{{ product.brandName }}</td>
                  <td>{{ product.brandEquity }}</td>
                  <td>{{ product.category }}</td>
                  <td>{{ product.costPrice | currency : 'BRL' }}</td>
                  <td>{{ product.salePrice | currency : 'BRL' }}</td>
                  <td>{{ product.weight }}g</td>
                  <td *ngIf="isSummaryCombo()">
                    {{
                      subscriptionNameToSendComposition(product.subscriptionId)
                    }}
                  </td>
                  <td>
                    <a
                      type="button"
                      class="admin-link"
                      (click)="showFilters(product)"
                      >Ver filtros</a
                    >
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="buttons">
    <p-button
      (onClick)="approveDraft()"
      label="Aprovar draft"
      styleClass="p-button-primary"
      *ngIf="approveAll"
    />
    <p-button
      (onClick)="finishDraft()"
      label="Concluir draft"
      styleClass="p-button-primary"
      *ngIf="
        !draft?.draftStatus &&
        draft?.approved &&
        (draft?.owner === user?.username ||
          draft?.responsible === user?.username)
      "
    />
  </div>
</div>
<p-confirmDialog />
