import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockPageComponent } from './block-page.component';

@NgModule({
  declarations: [BlockPageComponent],
  imports: [CommonModule],
  exports: [BlockPageComponent]
})
export class BlockPageModule {}
