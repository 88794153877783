import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Credentials {
  username?: string;
  password?: string;
}

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  async login(credentials: Credentials): Promise<any> {
    return await lastValueFrom(
      this.http.post<any>(
        (environment.apiUrl ? environment.apiUrl : 'http://localhost:5000') +
          '/login',
        credentials
      )
    );
  }
}
