<div class="syncronization">
  <h2>Sincronização de dados</h2>
  <ng-container *ngIf="infos().length && (!sync || sync.status === 0)">
    <p-table styleClass="p-datatable-sm" [value]="infos()" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th class="text-right">Quantidade atual</th>
          <th class="text-right">Quantidade sincronizada</th>
          <th class="text-right">Diferença</th>
          <th class="text-center">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-info>
        <tr [class.danger]="unsyncedDiff(info)">
          <td>{{ info.name }}</td>
          <td class="text-right">
            <ng-container *ngIf="info.unsynced !== undefined">
              {{ info.unsynced | number:'1.0-0' }}
              <p-button
                (onClick)="refreshInfo(info)"
                [rounded]="true"
                [text]="true"
                icon="pi pi-refresh"
                pTooltip="Atualizar"
                tooltipPosition="bottom"
              ></p-button>
            </ng-container>
            <i
              class="pi pi-spinner pi-spin"
              *ngIf="info.unsynced === undefined"
            ></i>
          </td>
          <td class="text-right">
            <ng-container *ngIf="info.synced !== undefined">
              {{ info.synced | number:'1.0-0'}}
              <p-button
                (onClick)="refreshInfo(info, true)"
                [rounded]="true"
                [text]="true"
                icon="pi pi-refresh"
                pTooltip="Atualizar"
                tooltipPosition="bottom"
              ></p-button>
            </ng-container>
            <i
              class="pi pi-spinner pi-spin"
              *ngIf="info.synced === undefined"
            ></i>
          </td>
          <td class="text-right">
            <ng-container *ngIf="unsyncedDiff(info) !== undefined">
              {{ unsyncedDiff(info)| number:'1.0-0' }}
            </ng-container>
          </td>
          <td class="text-center">
            <p-button
              *ngIf="info.id !== 1"
              (onClick)="callAction($event, info)"
              [rounded]="true"
              [text]="true"
              icon="pi pi-cloud-upload"
              pTooltip="Sincronizar"
              tooltipPosition="bottom"
            ></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <br />
  <div *ngIf="sync">
    <p *ngIf="sync.status">
      <b>Syncronização em andamento</b>
      <p-button
        type="button"
        [rounded]="true"
        [text]="true"
        size="small"
        styleClass="p-button-primary"
        icon="pi pi-refresh"
        (onClick)="checkSyncInProgress()"
        pTooltip="Atualizar logs"
      ></p-button>
    </p>
    <p *ngIf="sync.status === 0"><b>Última sincronização</b></p>
    <p>Início: {{ sync.dateCreated | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
    <p *ngIf="sync.dateFinished">
      Fim: {{ sync.dateFinished | date : 'dd/MM/yyyy HH:mm:ss' }}
    </p>
    <p>Tempo: {{ timeDiffLabel }}</p>
    <p *ngIf="sync.syncronizationType === 0">Tipo: Assinantes e Perfis</p>
    <p *ngIf="sync.syncronizationType === 1">Tipo: Histórico de produtos</p>
    <p *ngIf="sync.syncronizationType === 2">Tipo: Perfis de beleza</p>
    <p *ngIf="sync.syncronizationType === 3">Tipo: Produtos disponíveis</p>
    <br />
    <textarea
      *ngIf="sync.status || showLogs"
      rows="15"
      cols="100"
      pInputTextarea
      [(ngModel)]="log"
      [disabled]="true"
    ></textarea>
    <a
      type="button"
      (click)="showLogs = true"
      *ngIf="!showLogs && sync.status === 0"
      class="admin-link"
      >Ver logs</a
    >
  </div>
  <p-confirmPopup></p-confirmPopup>
</div>
