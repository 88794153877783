import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DraftDefaultProductsComponent } from './draft-default-products/draft-default-products.component';
import { DraftFormComponent } from './draft-form.component';
import { DraftSegmentationComponent } from './draft-segmentation/draft-segmentation.component';

const routes: Routes = [
  {
    path: 'id/:id',
    component: DraftFormComponent
  },
  {
    path: 'new',
    component: DraftFormComponent
  },
  {
    path: 'id/:id/segmentation',
    component: DraftSegmentationComponent
  },
  {
    path: 'id/:id/default-products',
    component: DraftDefaultProductsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DraftFormRoutingModule {}
