import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { ApiModule, Configuration } from './allocation-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderModule } from './components/loader/loader.module';
import { HttpClientInterceptor } from './services/auth/http-client-interceptor';
import { TokenStorageService } from './services/auth/token-storage.service';
registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ProgressSpinnerModule,
    LoaderModule,
    SidebarModule,
    ToastModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          accessToken: TokenStorageService.getToken,
          basePath: environment.apiUrl
        })
    )
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    ConfirmationService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (router: Router) => {
        return new HttpClientInterceptor(router);
      },
      multi: true,
      deps: [Router]
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
