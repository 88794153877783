export enum Role {
  None = { label: 'Nenhum', enumValue: 'NONE', value: 0, order: -1 } as any,
  TI = { label: 'TI', enumValue: 'ROLE_TI', value: 1, order: 3 } as any,
  Customer = {
    label: 'Glambox User',
    enumValue: 'ROLE_CUSTOMER',
    value: 2,
    order: 5
  } as any,
  User = {
    label: 'Allocation User',
    enumValue: 'ROLE_ALLOC_USER',
    value: 3,
    order: 2
  } as any,
  Full_Administrator = {
    label: 'Full Administrator',
    enumValue: 'ROLE_ADMIN',
    value: 4,
    order: 0
  } as any,
  Admin = {
    label: 'Allocation Administrator',
    enumValue: 'ROLE_ALLOC_ADMIN',
    value: 6,
    order: 1
  } as any,
  SAC = { label: 'SAC', enumValue: 'ROLE_SAC', value: 7, order: 4 } as any
}

export function getAllRoles(): Array<{
  label: string;
  value: number;
  enumValue: string;
  order: number;
}> {
  const objetos: Array<{
    label: string;
    value: number;
    enumValue: string;
    order: number;
  }> = [];
  for (const [p, propertyValue] of Object.entries(Role)) {
    if (typeof propertyValue === 'object') objetos.push(propertyValue);
  }
  return objetos;
}

export function getRole(
  enumValue: string
):
  | { label: string; value: number; enumValue: string; order: number }
  | undefined {
  return getAllRoles().find((d) => d.enumValue === enumValue);
}
