/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FilterTypeDTO } from './filterTypeDTO';

export interface FilterDTO {
  name?: string;
  filterTypes?: Array<FilterTypeDTO>;
}
