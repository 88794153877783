<div
  class="product-card"
  *ngIf="product && product.stock !== undefined"
  [class.product-card--dragging]="dragging"
  [class.product-card--available]="!compositionProduct && showFilters"
  [class.product-card--disabled]="
    product.subscribers === 0 ||
    (!compositionProduct && product.stock <= 0) ||
    (!showFilters &&
      (!product.stock ||
        (product.subscribers && product.stock < product.subscribers)))
  "
>
  <div class="product-card__info">
    <div class="product-card__info__details__top">
      <div class="product-card__info__details__top--left">
        <div
          class="product-card__info__details__top__name"
          [pTooltip]="
            product.productVariantName ? product.productVariantName : ''
          "
          tooltipPosition="right"
        >
          <strong *ngIf="!compositionProduct">
            {{
              product.productVariantName | titlecase | slice : 0 : 30
            }}</strong
          >
          <strong *ngIf="compositionProduct && isResponsible">
            {{
              product.productVariantName | titlecase | slice : 0 : 45
            }}</strong
          >
          <strong *ngIf="compositionProduct && !isResponsible">
            {{ product.productVariantName | titlecase }}</strong
          >
          <ng-container
            *ngIf="
              isResponsible &&
              ((!compositionProduct &&
                product.productVariantName &&
                product.productVariantName.length > 30) ||
                (compositionProduct &&
                  product.productVariantName &&
                  product.productVariantName.length > 45))
            "
            >...</ng-container
          >
        </div>
        <div>
          <ng-container> Marca: {{ product.brandName }} </ng-container>
        </div>
      </div>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-arrow-circle-right"
        class="p-button-rounded p-button-text"
        pTooltip="Incluir produto"
        tooltipPosition="right"
        *ngIf="product.subscribers && isResponsible && showFilters"
        (click)="clickAction.emit()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-filter-fill"
        class="p-button-rounded p-button-text"
        pTooltip="Ver filtros"
        tooltipPosition="right"
        *ngIf="compositionProduct"
        (click)="clickFilters.emit()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times-circle"
        class="p-button-rounded p-button-text p-button-danger"
        pTooltip="Remover produto"
        tooltipPosition="right"
        *ngIf="compositionProduct && isResponsible"
        (click)="clickAction.emit()"
      ></button>
    </div>
    <div class="product-card__info__details">
      <div class="product-card__info__details__left">
        <div>
          <div *ngIf="!compositionProduct">
            <span
              *ngIf="product.stock && product.stock > 0"
              [ngStyle]="{
                color:
                  !totalSubscribers ||
                  (totalSubscribers &&
                    product.stock &&
                    totalSubscribers < product.stock)
                    ? dragging
                      ? 'white'
                      : '#4b5563'
                    : 'red'
              }"
            >
              Estoque: {{ product.stock | number : '1.0-0' }}
            </span>
            <span
              style="color: red; font-weight: bold"
              *ngIf="!product.stock || product.stock < 0"
            >
              Esgotado
            </span>
          </div>
          <div *ngIf="compositionProduct">
            Estoque restante: {{ stock | number : '1.0-0' }}
          </div>
          <div>BrandEquity: {{ equityLabel(product.brandEquity || 0) }}</div>
          <div>CEAN: {{ product.internalEAN }}</div>
          <div>
            Peso: {{ product.weight }}g
            <ng-container *ngIf="product.expiration"
              >| Validade: {{ product.expiration }}
            </ng-container>
          </div>
          <div *ngIf="product.category">Categoria: {{ product.category }}</div>
          <div *ngIf="!product.category">Sem categoria</div>
          <div *ngIf="product.subcategory">
            Subcategoria: {{ product.subcategory }}
          </div>
          <div *ngIf="!product.subcategory">Sem subcategoria</div>
        </div>
      </div>

      <div
        *ngIf="isCombo && (compositionProduct || changeSubscription)"
        class="product-card__info__details__left__switch"
      >
        <label for="subscriptionId">box</label>
        <p-inputSwitch
          (onChange)="setSubscriptionId()"
          [trueValue]="5"
          [falseValue]="1"
          [(ngModel)]="product.subscriptionId"
          inputId="subscriptionId"
        ></p-inputSwitch>
        <label for="subscriptionId">bag</label>
      </div>
    </div>
    <div class="product-card__filters" *ngIf="showFilters">
      <div *ngIf="product.totalFilters">
        <a type="button" (click)="clickFilters.emit()">
          {{ product.totalFilters }} Filtro<span
            *ngIf="product.totalFilters > 1"
            >s</span
          >
        </a>
      </div>
      <div *ngIf="product.totalFilters === 0 && !compositionProduct">
        <a type="button" (click)="clickFilters.emit()"> Sem filtros </a>
      </div>
      <div
        [ngStyle]="{
          color:
            !totalSubscribers ||
            (totalSubscribers &&
              product.stock &&
              totalSubscribers <= product.stock)
              ? '#4b5563'
              : 'red'
        }"
        *ngIf="totalSubscribers && product.stock && product.stock > 0"
      >
        <strong>
          {{
            totalSubscribers! > product.stock!
              ? product.stock
              : totalSubscribers
          }}</strong
        >
        <span *ngIf="totalSubscribers > 1"> <strong> Assinaturas</strong></span>
        <span *ngIf="totalSubscribers === 1"><strong> Assinatura</strong></span>
      </div>
      <div *ngIf="totalSubscribers === 0">0 assinaturas</div>
      <div
        *ngIf="
          !totalSubscribers && totalSubscribers !== 0 && !compositionProduct
        "
      >
        <i class="pi pi-spin pi-spinner"></i>
      </div>
    </div>
    <div class="product-card__divider"></div>
    <div class="product-card__price">
      <div>
        Valor custo
        <ng-container *ngIf="compositionProduct">:</ng-container>
        <br *ngIf="!compositionProduct" />
        {{ product.costPrice | currency : 'BRL' }}
      </div>
      <div>
        Valor mercado
        <ng-container *ngIf="compositionProduct">:</ng-container>
        <br *ngIf="!compositionProduct" />
        {{ product.salePrice | currency : 'BRL' }}
      </div>
    </div>
  </div>
  <div class="product-card loading-item" *ngIf="!product">
    <div class="product-card__info">
      <div>
        <img src="assets/no_img_avaliable.jpg" />
      </div>
      <div class="product-card__info__details">
        <p-skeleton width="60%"></p-skeleton>
        <p-skeleton width="60%"></p-skeleton>
        <p-skeleton width="60%"></p-skeleton>
        <p-skeleton width="60%"></p-skeleton>
        <p-skeleton width="60%"></p-skeleton>
        <p-skeleton width="60%"></p-skeleton>
      </div>
    </div>
    <div class="product-card__filters">
      <p-skeleton width="40%"></p-skeleton>
      <p-skeleton width="40%"></p-skeleton>
    </div>
    <div class="product-card__price">
      <p-skeleton width="40%"></p-skeleton>
      <p-skeleton width="40%"></p-skeleton>
    </div>
  </div>
</div>
