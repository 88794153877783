import * as i2 from '@angular/cdk/drag-drop';
import { transferArrayItem, moveItemInArray, DragDropModule } from '@angular/cdk/drag-drop';
import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, numberAttribute, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ViewChild, ContentChild, ContentChildren, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { uuid, find, scrollInView, findIndexInList, findSingle, setAttribute, isEmpty } from '@primeuix/utils';
import { FilterService, PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { ButtonDirective } from 'primeng/button';
import { AngleDoubleDownIcon, AngleDoubleLeftIcon, AngleDoubleRightIcon, AngleDoubleUpIcon, AngleDownIcon, AngleLeftIcon, AngleRightIcon, AngleUpIcon } from 'primeng/icons';
import { Listbox } from 'primeng/listbox';
import { Ripple } from 'primeng/ripple';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-picklist {
    display: flex;
    gap: ${dt('picklist.gap')};
}

.p-picklist-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${dt('picklist.controls.gap')};
}

.p-picklist-list-container {
    flex: 1 1 50%;
}

.p-picklist .p-listbox {
    height: 100%;
}
`;
const classes = {
  root: 'p-picklist p-component',
  sourceControls: 'p-picklist-controls p-picklist-source-controls',
  sourceListContainer: 'p-picklist-list-container p-picklist-source-list-container',
  transferControls: 'p-picklist-controls p-picklist-transfer-controls',
  targetListContainer: 'p-picklist-list-container p-picklist-target-list-container',
  targetControls: 'p-picklist-controls p-picklist-target-controls'
};
class PickListStyle extends BaseStyle {
  name = 'picklist';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵPickListStyle_BaseFactory;
    return function PickListStyle_Factory(__ngFactoryType__) {
      return (ɵPickListStyle_BaseFactory || (ɵPickListStyle_BaseFactory = i0.ɵɵgetInheritedFactory(PickListStyle)))(__ngFactoryType__ || PickListStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: PickListStyle,
    factory: PickListStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PickListStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * PickList is used to reorder items between different lists.
 *
 * [Live Demo](https://www.primeng.org/picklist)
 *
 * @module pickliststyle
 *
 */
var PickListClasses;
(function (PickListClasses) {
  /**
   * Class name of the root element
   */
  PickListClasses["root"] = "p-picklist";
  /**
   * Class name of the source controls element
   */
  PickListClasses["sourceControls"] = "p-picklist-source-controls";
  /**
   * Class name of the source list container element
   */
  PickListClasses["sourceListContainer"] = "p-picklist-source-list-container";
  /**
   * Class name of the transfer controls element
   */
  PickListClasses["transferControls"] = "p-picklist-transfer-controls";
  /**
   * Class name of the target list container element
   */
  PickListClasses["targetListContainer"] = "p-picklist-target-list-container";
  /**
   * Class name of the target controls element
   */
  PickListClasses["targetControls"] = "p-picklist-target-controls";
})(PickListClasses || (PickListClasses = {}));
const _c0 = ["item"];
const _c1 = ["sourceHeader"];
const _c2 = ["targetHeader"];
const _c3 = ["sourceFilter"];
const _c4 = ["targetFilter"];
const _c5 = ["emptymessagesource"];
const _c6 = ["emptyfiltermessagesource"];
const _c7 = ["emptymessagetarget"];
const _c8 = ["emptyfiltermessagetarget"];
const _c9 = ["moveupicon"];
const _c10 = ["movetopicon"];
const _c11 = ["movedownicon"];
const _c12 = ["movebottomicon"];
const _c13 = ["movetotargeticon"];
const _c14 = ["movealltotargeticon"];
const _c15 = ["movetosourceicon"];
const _c16 = ["movealltosourceicon"];
const _c17 = ["targetfiltericon"];
const _c18 = ["sourcefiltericon"];
const _c19 = ["sourcelist"];
const _c20 = ["targetlist"];
const _c21 = () => ({
  "p-picklist p-component": true
});
const _c22 = a0 => ({
  $implicit: a0
});
const _c23 = a0 => ({
  options: a0
});
const _c24 = (a0, a1, a2) => ({
  $implicit: a0,
  index: a1,
  selected: a2
});
function PickList_div_1_AngleUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "moveupicon");
  }
}
function PickList_div_1_3_ng_template_0_Template(rf, ctx) {}
function PickList_div_1_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_1_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_1_AngleDoubleUpIcon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movetopicon");
  }
}
function PickList_div_1_6_ng_template_0_Template(rf, ctx) {}
function PickList_div_1_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_1_6_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_1_AngleDownIcon_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movedownicon");
  }
}
function PickList_div_1_9_ng_template_0_Template(rf, ctx) {}
function PickList_div_1_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_1_9_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_1_AngleDoubleDownIcon_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movebottomicon");
  }
}
function PickList_div_1_12_ng_template_0_Template(rf, ctx) {}
function PickList_div_1_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_1_12_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15)(1, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      const sourcelist_r4 = i0.ɵɵreference(4);
      return i0.ɵɵresetView(ctx_r2.moveUp(sourcelist_r4, ctx_r2.source, ctx_r2.selectedItemsSource, ctx_r2.onSourceReorder, ctx_r2.SOURCE_LIST));
    });
    i0.ɵɵtemplate(2, PickList_div_1_AngleUpIcon_2_Template, 1, 1, "AngleUpIcon", 8)(3, PickList_div_1_3_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_1_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      const sourcelist_r4 = i0.ɵɵreference(4);
      return i0.ɵɵresetView(ctx_r2.moveTop(sourcelist_r4, ctx_r2.source, ctx_r2.selectedItemsSource, ctx_r2.onSourceReorder, ctx_r2.SOURCE_LIST));
    });
    i0.ɵɵtemplate(5, PickList_div_1_AngleDoubleUpIcon_5_Template, 1, 1, "AngleDoubleUpIcon", 8)(6, PickList_div_1_6_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_1_Template_button_click_7_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      const sourcelist_r4 = i0.ɵɵreference(4);
      return i0.ɵɵresetView(ctx_r2.moveDown(sourcelist_r4, ctx_r2.source, ctx_r2.selectedItemsSource, ctx_r2.onSourceReorder, ctx_r2.SOURCE_LIST));
    });
    i0.ɵɵtemplate(8, PickList_div_1_AngleDownIcon_8_Template, 1, 1, "AngleDownIcon", 8)(9, PickList_div_1_9_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_1_Template_button_click_10_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      const sourcelist_r4 = i0.ɵɵreference(4);
      return i0.ɵɵresetView(ctx_r2.moveBottom(sourcelist_r4, ctx_r2.source, ctx_r2.selectedItemsSource, ctx_r2.onSourceReorder, ctx_r2.SOURCE_LIST));
    });
    i0.ɵɵtemplate(11, PickList_div_1_AngleDoubleDownIcon_11_Template, 1, 1, "AngleDoubleDownIcon", 8)(12, PickList_div_1_12_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-pc-section", "sourceControls")("data-pc-group-section", "controls");
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.sourceMoveDisabled())("buttonProps", ctx_r2.getButtonProps("moveup"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveUpAriaLabel)("data-pc-section", "sourceMoveUpButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveUpIconTemplate && !ctx_r2._moveUpIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveUpIconTemplate || ctx_r2._moveUpIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.sourceMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movetop"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveTopAriaLabel)("data-pc-section", "sourceMoveTopButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveTopIconTemplate && !ctx_r2._moveTopIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveTopIconTemplate || ctx_r2._moveTopIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.sourceMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movedown"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveDownAriaLabel)("data-pc-section", "sourceMoveDownButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveDownIconTemplate && !ctx_r2._moveDownIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveDownIconTemplate || ctx_r2._moveDownIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.sourceMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movebottom"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveBottomAriaLabel)("data-pc-section", "sourceMoveBottomButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveBottomIconTemplate || ctx_r2._moveBottomIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveBottomIconTemplate || ctx_r2._moveBottomIconTemplate);
  }
}
function PickList_ng_container_5_ng_template_1_0_ng_template_0_Template(rf, ctx) {}
function PickList_ng_container_5_ng_template_1_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_5_ng_template_1_0_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_5_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_5_ng_template_1_0_Template, 1, 0, null, 16);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.sourceHeaderTemplate || ctx_r2._sourceHeaderTemplate);
  }
}
function PickList_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_5_ng_template_1_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
}
function PickList_ng_container_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_6_ng_container_1_Template, 1, 0, "ng-container", 12);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.sourceFilterTemplate || ctx_r2._sourceFilterTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c23, ctx_r2.sourceFilterOptions));
  }
}
function PickList_ng_container_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_7_ng_container_1_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.sourceFilterIconTemplate || ctx_r2._sourceFilterIconTemplate);
  }
}
function PickList_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.sourceHeader);
  }
}
function PickList_ng_container_9_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_9_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_9_ng_template_1_ng_container_0_Template, 1, 0, "ng-container", 12);
  }
  if (rf & 2) {
    const item_r5 = ctx.$implicit;
    const index_r6 = ctx.index;
    const selected_r7 = ctx.selected;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.itemTemplate || ctx_r2._itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c24, item_r5, index_r6, selected_r7));
  }
}
function PickList_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_9_ng_template_1_Template, 1, 6, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
}
function PickList_ng_container_10_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_10_ng_container_1_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.emptyFilterMessageSourceTemplate || ctx_r2._emptyFilterMessageSourceTemplate || ctx_r2.emptyMessageSourceTemplate || ctx_r2._emptyMessageSourceTemplate);
  }
}
function PickList_ng_container_13_AngleRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleRightIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movetotargeticon");
  }
}
function PickList_ng_container_13_AngleDownIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movetotargeticon");
  }
}
function PickList_ng_container_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_13_AngleRightIcon_1_Template, 1, 1, "AngleRightIcon", 8)(2, PickList_ng_container_13_AngleDownIcon_2_Template, 1, 1, "AngleDownIcon", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.viewChanged);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.viewChanged);
  }
}
function PickList_14_ng_template_0_Template(rf, ctx) {}
function PickList_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_14_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_16_AngleDoubleRightIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleRightIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movealltotargeticon");
  }
}
function PickList_ng_container_16_AngleDoubleDownIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movealltotargeticon");
  }
}
function PickList_ng_container_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_16_AngleDoubleRightIcon_1_Template, 1, 1, "AngleDoubleRightIcon", 8)(2, PickList_ng_container_16_AngleDoubleDownIcon_2_Template, 1, 1, "AngleDoubleDownIcon", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.viewChanged);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.viewChanged);
  }
}
function PickList_17_ng_template_0_Template(rf, ctx) {}
function PickList_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_17_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_19_AngleLeftIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleLeftIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movedownsourceticon");
  }
}
function PickList_ng_container_19_AngleUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movedownsourceticon");
  }
}
function PickList_ng_container_19_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_19_AngleLeftIcon_1_Template, 1, 1, "AngleLeftIcon", 8)(2, PickList_ng_container_19_AngleUpIcon_2_Template, 1, 1, "AngleUpIcon", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.viewChanged);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.viewChanged);
  }
}
function PickList_20_ng_template_0_Template(rf, ctx) {}
function PickList_20_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_20_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_22_AngleDoubleLeftIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleLeftIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movealltosourceticon");
  }
}
function PickList_ng_container_22_AngleDoubleUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movealltosourceticon");
  }
}
function PickList_ng_container_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_22_AngleDoubleLeftIcon_1_Template, 1, 1, "AngleDoubleLeftIcon", 8)(2, PickList_ng_container_22_AngleDoubleUpIcon_2_Template, 1, 1, "AngleDoubleUpIcon", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.viewChanged);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.viewChanged);
  }
}
function PickList_23_ng_template_0_Template(rf, ctx) {}
function PickList_23_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_23_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_27_ng_template_1_0_ng_template_0_Template(rf, ctx) {}
function PickList_ng_container_27_ng_template_1_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_27_ng_template_1_0_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_ng_container_27_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_27_ng_template_1_0_Template, 1, 0, null, 16);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.targetHeaderTemplate || ctx_r2._targetHeaderTemplate);
  }
}
function PickList_ng_container_27_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_27_ng_template_1_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
}
function PickList_ng_container_28_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_28_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_28_ng_container_1_Template, 1, 0, "ng-container", 12);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.targetFilterTemplate || ctx_r2._targetFilterTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c23, ctx_r2.targetFilterOptions));
  }
}
function PickList_ng_container_29_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_29_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_29_ng_container_1_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.targetFilterIconTemplate || ctx_r2._targetFilterIconTemplate);
  }
}
function PickList_div_30_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.targetHeader);
  }
}
function PickList_ng_container_31_ng_template_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_31_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_ng_container_31_ng_template_1_ng_container_0_Template, 1, 0, "ng-container", 12);
  }
  if (rf & 2) {
    const item_r8 = ctx.$implicit;
    const index_r9 = ctx.index;
    const selected_r10 = ctx.selected;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.itemTemplate || ctx_r2._itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c24, item_r8, index_r9, selected_r10));
  }
}
function PickList_ng_container_31_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_31_ng_template_1_Template, 1, 6, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
}
function PickList_ng_container_32_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PickList_ng_container_32_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PickList_ng_container_32_ng_container_1_Template, 1, 0, "ng-container", 16);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.emptyFilterMessageTargetTemplate || ctx_r2._emptyFilterMessageTargetTemplate || ctx_r2.emptyMessageTargetTemplate || ctx_r2._emptyMessageTargetTemplate);
  }
}
function PickList_div_33_AngleUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "moveupicon");
  }
}
function PickList_div_33_3_ng_template_0_Template(rf, ctx) {}
function PickList_div_33_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_33_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_33_AngleDoubleUpIcon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleUpIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movetopicon");
  }
}
function PickList_div_33_6_ng_template_0_Template(rf, ctx) {}
function PickList_div_33_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_33_6_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_33_AngleDownIcon_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movedownicon");
  }
}
function PickList_div_33_9_ng_template_0_Template(rf, ctx) {}
function PickList_div_33_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_33_9_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_33_AngleDoubleDownIcon_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "AngleDoubleDownIcon");
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "movebottomicon");
  }
}
function PickList_div_33_12_ng_template_0_Template(rf, ctx) {}
function PickList_div_33_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PickList_div_33_12_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function PickList_div_33_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 18)(1, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_33_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r2 = i0.ɵɵnextContext();
      const targetlist_r12 = i0.ɵɵreference(26);
      return i0.ɵɵresetView(ctx_r2.moveUp(targetlist_r12, ctx_r2.target, ctx_r2.selectedItemsTarget, ctx_r2.onTargetReorder, ctx_r2.TARGET_LIST));
    });
    i0.ɵɵtemplate(2, PickList_div_33_AngleUpIcon_2_Template, 1, 1, "AngleUpIcon", 8)(3, PickList_div_33_3_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_33_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r2 = i0.ɵɵnextContext();
      const targetlist_r12 = i0.ɵɵreference(26);
      return i0.ɵɵresetView(ctx_r2.moveTop(targetlist_r12, ctx_r2.target, ctx_r2.selectedItemsTarget, ctx_r2.onTargetReorder, ctx_r2.TARGET_LIST));
    });
    i0.ɵɵtemplate(5, PickList_div_33_AngleDoubleUpIcon_5_Template, 1, 1, "AngleDoubleUpIcon", 8)(6, PickList_div_33_6_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_33_Template_button_click_7_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r2 = i0.ɵɵnextContext();
      const targetlist_r12 = i0.ɵɵreference(26);
      return i0.ɵɵresetView(ctx_r2.moveDown(targetlist_r12, ctx_r2.target, ctx_r2.selectedItemsTarget, ctx_r2.onTargetReorder, ctx_r2.TARGET_LIST));
    });
    i0.ɵɵtemplate(8, PickList_div_33_AngleDownIcon_8_Template, 1, 1, "AngleDownIcon", 8)(9, PickList_div_33_9_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "button", 11);
    i0.ɵɵlistener("click", function PickList_div_33_Template_button_click_10_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r2 = i0.ɵɵnextContext();
      const targetlist_r12 = i0.ɵɵreference(26);
      return i0.ɵɵresetView(ctx_r2.moveBottom(targetlist_r12, ctx_r2.target, ctx_r2.selectedItemsTarget, ctx_r2.onTargetReorder, ctx_r2.TARGET_LIST));
    });
    i0.ɵɵtemplate(11, PickList_div_33_AngleDoubleDownIcon_11_Template, 1, 1, "AngleDoubleDownIcon", 8)(12, PickList_div_33_12_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-pc-section", "targetControls")("data-pc-group-section", "controls");
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.targetMoveDisabled())("buttonProps", ctx_r2.getButtonProps("moveup"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveUpAriaLabel)("data-pc-section", "targetMoveUpButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveUpIconTemplate && !ctx_r2._moveUpIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveUpIconTemplate || ctx_r2._moveUpIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.targetMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movetop"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveTopAriaLabel)("data-pc-section", "targetMoveTopButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveTopIconTemplate && !ctx_r2._moveTopIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveTopIconTemplate || ctx_r2.moveTopIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.targetMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movedown"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveDownAriaLabel)("data-pc-section", "targetMoveDownButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveDownIconTemplate && !ctx_r2._moveDownIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveDownIconTemplate || ctx_r2._moveDownIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.targetMoveDisabled())("buttonProps", ctx_r2.getButtonProps("movebottom"));
    i0.ɵɵattribute("aria-label", ctx_r2.moveBottomAriaLabel)("data-pc-section", "targetMoveBottomButton");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.moveBottomIconTemplate && !ctx_r2._moveBottomIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.moveBottomIconTemplate || ctx_r2._moveBottomIconTemplate);
  }
}
/**
 * PickList is used to reorder items between different lists.
 * @group Components
 */
class PickList extends BaseComponent {
  /**
   * An array of objects for the source list.
   * @group Props
   */
  source;
  /**
   * An array of objects for the target list.
   * @group Props
   */
  target;
  /**
   * Text for the source list caption
   * @group Props
   */
  sourceHeader;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = 0;
  /**
   * Defines a string that labels the move to right button for accessibility.
   * @group Props
   */
  rightButtonAriaLabel;
  /**
   * Defines a string that labels the move to left button for accessibility.
   * @group Props
   */
  leftButtonAriaLabel;
  /**
   * Defines a string that labels the move to all right button for accessibility.
   * @group Props
   */
  allRightButtonAriaLabel;
  /**
   * Defines a string that labels the move to all left button for accessibility.
   * @group Props
   */
  allLeftButtonAriaLabel;
  /**
   * Defines a string that labels the move to up button for accessibility.
   * @group Props
   */
  upButtonAriaLabel;
  /**
   * Defines a string that labels the move to down button for accessibility.
   * @group Props
   */
  downButtonAriaLabel;
  /**
   * Defines a string that labels the move to top button for accessibility.
   * @group Props
   */
  topButtonAriaLabel;
  /**
   * Defines a string that labels the move to bottom button for accessibility.
   * @group Props
   */
  bottomButtonAriaLabel;
  /**
   * Text for the target list caption
   * @group Props
   */
  targetHeader;
  /**
   * When enabled orderlist adjusts its controls based on screen size.
   * @group Props
   */
  responsive;
  /**
   * When specified displays an input field to filter the items on keyup and decides which field to search (Accepts multiple fields with a comma).
   * @group Props
   */
  filterBy;
  /**
   * Locale to use in filtering. The default locale is the host environment's current locale.
   * @group Props
   */
  filterLocale;
  /**
   * Function to optimize the dom operations by delegating to ngForTrackBy, default algorithm checks for object identity. Use sourceTrackBy or targetTrackBy in case different algorithms are needed per list.
   * @group Props
   */
  trackBy = (index, item) => item;
  /**
   * Function to optimize the dom operations by delegating to ngForTrackBy in source list, default algorithm checks for object identity.
   * @group Props
   */
  sourceTrackBy;
  /**
   * Function to optimize the dom operations by delegating to ngForTrackBy in target list, default algorithm checks for object identity.
   * @group Props
   */
  targetTrackBy;
  /**
   * Whether to show filter input for source list when filterBy is enabled.
   * @group Props
   */
  showSourceFilter = true;
  /**
   * Whether to show filter input for target list when filterBy is enabled.
   * @group Props
   */
  showTargetFilter = true;
  /**
   * Defines how multiple items can be selected, when true metaKey needs to be pressed to select or unselect an item and when set to false selection of each item can be toggled individually. On touch enabled devices, metaKeySelection is turned off automatically.
   * @group Props
   */
  metaKeySelection = false;
  /**
   * Whether to enable dragdrop based reordering.
   * @group Props
   */
  dragdrop = false;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the source list element.
   * @group Props
   */
  sourceStyle;
  /**
   * Inline style of the target list element.
   * @group Props
   */
  targetStyle;
  /**
   * Whether to show buttons of source list.
   * @group Props
   */
  showSourceControls = true;
  /**
   * Whether to show buttons of target list.
   * @group Props
   */
  showTargetControls = true;
  /**
   * Placeholder text on source filter input.
   * @group Props
   */
  sourceFilterPlaceholder;
  /**
   * Placeholder text on target filter input.
   * @group Props
   */
  targetFilterPlaceholder;
  /**
   * When present, it specifies that the component should be disabled.
   * @group Props
   */
  disabled = false;
  /**
   * Defines a string that labels the filter input of source list.
   * @group Props
   */
  ariaSourceFilterLabel;
  /**
   * Defines a string that labels the filter input of target list.
   * @group Props
   */
  ariaTargetFilterLabel;
  /**
   * Defines how the items are filtered.
   * @group Props
   */
  filterMatchMode = 'contains';
  /**
   * Whether to displays rows with alternating colors.
   * @group Props
   */
  stripedRows;
  /**
   * Keeps selection on the transfer list.
   * @group Props
   */
  keepSelection = false;
  /**
   * Height of the viewport, a scrollbar is defined if height of list exceeds this value.
   * @group Props
   */
  scrollHeight = '14rem';
  /**
   * Whether to focus on the first visible or selected element.
   * @group Props
   */
  autoOptionFocus = true;
  /**
   * Used to pass all properties of the ButtonProps to the Button component.
   * @group Props
   */
  buttonProps = {
    severity: 'secondary'
  };
  /**
   * Used to pass all properties of the ButtonProps to the move up button inside the component.
   * @group Props
   */
  moveUpButtonProps;
  /**
   * 	Used to pass all properties of the ButtonProps to the move top button inside the component.
   * @group Props
   */
  moveTopButtonProps;
  /**
   * 	Used to pass all properties of the ButtonProps to the move down button inside the component.
   * @group Props
   */
  moveDownButtonProps;
  /**
   * 	Used to pass all properties of the ButtonProps to the move bottom button inside the component.
   * @group Props
   */
  moveBottomButtonProps;
  /**
   * 	Used to pass all properties of the ButtonProps to the move to target button inside the component.
   * @group Props
   */
  moveToTargetProps;
  /**
   * 	Used to pass all properties of the ButtonProps to the move all to target button inside the component.
   * @group Props
   */
  moveAllToTargetProps;
  /**
   *  Used to pass all properties of the ButtonProps to the move to source button inside the component.
   * @group Props
   */
  moveToSourceProps;
  /**
   *  Used to pass all properties of the ButtonProps to the move all to source button inside the component.
   * @group Props
   */
  moveAllToSourceProps;
  /**
   * Indicates the width of the screen at which the component should change its behavior.
   * @group Props
   */
  get breakpoint() {
    return this._breakpoint;
  }
  set breakpoint(value) {
    if (value !== this._breakpoint) {
      this._breakpoint = value;
      if (isPlatformBrowser(this.platformId)) {
        this.destroyMedia();
        this.initMedia();
      }
    }
  }
  /**
   * Callback to invoke when items are moved from target to source.
   * @param {PickListMoveToSourceEvent} event - Custom move to source event.
   * @group Emits
   */
  onMoveToSource = new EventEmitter();
  /**
   * Callback to invoke when all items are moved from target to source.
   * @param {PickListMoveAllToSourceEvent} event - Custom move all to source event.
   * @group Emits
   */
  onMoveAllToSource = new EventEmitter();
  /**
   * Callback to invoke when all items are moved from source to target.
   * @param {PickListMoveAllToTargetEvent} event - Custom move all to target event.
   * @group Emits
   */
  onMoveAllToTarget = new EventEmitter();
  /**
   * Callback to invoke when items are moved from source to target.
   * @param {PickListMoveToTargetEvent} event - Custom move to target event.
   * @group Emits
   */
  onMoveToTarget = new EventEmitter();
  /**
   * Callback to invoke when items are reordered within source list.
   * @param {PickListSourceReorderEvent} event - Custom source reorder event.
   * @group Emits
   */
  onSourceReorder = new EventEmitter();
  /**
   * Callback to invoke when items are reordered within target list.
   * @param {PickListTargetReorderEvent} event - Custom target reorder event.
   * @group Emits
   */
  onTargetReorder = new EventEmitter();
  /**
   * Callback to invoke when items are selected within source list.
   * @param {PickListSourceSelectEvent} event - Custom source select event.
   * @group Emits
   */
  onSourceSelect = new EventEmitter();
  /**
   * Callback to invoke when items are selected within target list.
   * @param {PickListTargetSelectEvent} event - Custom target select event.
   * @group Emits
   */
  onTargetSelect = new EventEmitter();
  /**
   * Callback to invoke when the source list is filtered
   * @param {PickListSourceFilterEvent} event - Custom source filter event.
   * @group Emits
   */
  onSourceFilter = new EventEmitter();
  /**
   * Callback to invoke when the target list is filtered
   * @param {PickListTargetFilterEvent} event - Custom target filter event.
   * @group Emits
   */
  onTargetFilter = new EventEmitter();
  /**
   * Callback to invoke when the list is focused
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the list is blurred
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  listViewSourceChild;
  listViewTargetChild;
  sourceFilterViewChild;
  targetFilterViewChild;
  getButtonProps(direction) {
    switch (direction) {
      case 'moveup':
        return {
          ...this.buttonProps,
          ...this.moveUpButtonProps
        };
      case 'movetop':
        return {
          ...this.buttonProps,
          ...this.moveTopButtonProps
        };
      case 'movedown':
        return {
          ...this.buttonProps,
          ...this.moveDownButtonProps
        };
      case 'movebottom':
        return {
          ...this.buttonProps,
          ...this.moveBottomButtonProps
        };
      case 'movetotarget':
        return {
          ...this.buttonProps,
          ...this.moveToTargetProps
        };
      case 'movealltotarget':
        return {
          ...this.buttonProps,
          ...this.moveAllToTargetProps
        };
      case 'movetosource':
        return {
          ...this.buttonProps,
          ...this.moveToSourceProps
        };
      case 'movealltosource':
        return {
          ...this.buttonProps,
          ...this.moveAllToSourceProps
        };
      default:
        return this.buttonProps;
    }
  }
  get moveUpAriaLabel() {
    return this.upButtonAriaLabel ? this.upButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveUp : undefined;
  }
  get moveTopAriaLabel() {
    return this.topButtonAriaLabel ? this.topButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveTop : undefined;
  }
  get moveDownAriaLabel() {
    return this.downButtonAriaLabel ? this.downButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveDown : undefined;
  }
  get moveBottomAriaLabel() {
    return this.bottomButtonAriaLabel ? this.bottomButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveDown : undefined;
  }
  get moveToTargetAriaLabel() {
    return this.rightButtonAriaLabel ? this.rightButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveToTarget : undefined;
  }
  get moveAllToTargetAriaLabel() {
    return this.allRightButtonAriaLabel ? this.allRightButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveAllToTarget : undefined;
  }
  get moveToSourceAriaLabel() {
    return this.leftButtonAriaLabel ? this.leftButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveToSource : undefined;
  }
  get moveAllToSourceAriaLabel() {
    return this.allLeftButtonAriaLabel ? this.allLeftButtonAriaLabel : this.config.translation.aria ? this.config.translation.aria.moveAllToSource : undefined;
  }
  get idSource() {
    return this.id + '_source';
  }
  get idTarget() {
    return this.id + '_target';
  }
  get focusedOptionId() {
    return this.focusedOptionIndex !== -1 ? this.focusedOptionIndex : null;
  }
  _breakpoint = '960px';
  visibleOptionsSource;
  visibleOptionsTarget;
  selectedItemsSource = [];
  selectedItemsTarget = [];
  reorderedListElement;
  movedUp;
  movedDown;
  itemTouched;
  styleElement;
  id = uuid('pn_id_');
  filterValueSource;
  filterValueTarget;
  fromListType;
  sourceFilterOptions;
  targetFilterOptions;
  SOURCE_LIST = -1;
  TARGET_LIST = 1;
  window;
  media;
  viewChanged;
  focusedOptionIndex = -1;
  focusedOption;
  focused = {
    sourceList: false,
    targetList: false
  };
  _componentStyle = inject(PickListStyle);
  mediaChangeListener;
  filterService = inject(FilterService);
  ngOnInit() {
    super.ngOnInit();
    if (this.responsive) {
      this.createStyle();
      this.initMedia();
    }
    if (this.filterBy) {
      this.sourceFilterOptions = {
        filter: value => this.filterSource(value),
        reset: () => this.resetSourceFilter()
      };
      this.targetFilterOptions = {
        filter: value => this.filterTarget(value),
        reset: () => this.resetTargetFilter()
      };
    }
  }
  /**
   * Custom item template.
   * @group Templates
   */
  itemTemplate;
  /**
   * Custom source header template.
   * @group Templates
   */
  sourceHeaderTemplate;
  /**
   * Custom target header template.
   * @group Templates
   */
  targetHeaderTemplate;
  /**
   * Custom source filter template.
   * @group Templates
   */
  sourceFilterTemplate;
  /**
   * Custom target filter template.
   * @group Templates
   */
  targetFilterTemplate;
  /**
   * Custom empty message when source is empty template.
   * @group Templates
   */
  emptyMessageSourceTemplate;
  /**
   * Custom empty filter message when source is empty template.
   * @group Templates
   */
  emptyFilterMessageSourceTemplate;
  /**
   * Custom empty message when target is empty template.
   * @group Templates
   */
  emptyMessageTargetTemplate;
  /**
   * Custom empty filter message when target is empty template.
   * @group Templates
   */
  emptyFilterMessageTargetTemplate;
  /**
   * Custom move up icon template.
   * @group Templates
   */
  moveUpIconTemplate;
  /**
   * Custom move top icon template.
   * @group Templates
   */
  moveTopIconTemplate;
  /**
   * Custom move down icon template.
   * @group Templates
   */
  moveDownIconTemplate;
  /**
   * Custom move bottom icon template.
   * @group Templates
   */
  moveBottomIconTemplate;
  /**
   * Custom move to target icon template.
   * @group Templates
   */
  moveToTargetIconTemplate;
  /**
   * Custom move all to target icon template.
   * @group Templates
   */
  moveAllToTargetIconTemplate;
  /**
   * Custom move to source icon template.
   * @group Templates
   */
  moveToSourceIconTemplate;
  /**
   * Custom move all to source icon template.
   * @group Templates
   */
  moveAllToSourceIconTemplate;
  /**
   * Custom target filter icon template.
   * @group Templates
   */
  targetFilterIconTemplate;
  /**
   * Custom source filter icon template.
   * @group Templates
   */
  sourceFilterIconTemplate;
  templates;
  _itemTemplate;
  _sourceHeaderTemplate;
  _targetHeaderTemplate;
  _sourceFilterTemplate;
  _targetFilterTemplate;
  _emptyMessageSourceTemplate;
  _emptyFilterMessageSourceTemplate;
  _emptyMessageTargetTemplate;
  _emptyFilterMessageTargetTemplate;
  _moveUpIconTemplate;
  _moveTopIconTemplate;
  _moveDownIconTemplate;
  _moveBottomIconTemplate;
  _moveToTargetIconTemplate;
  _moveAllToTargetIconTemplate;
  _moveToSourceIconTemplate;
  _moveAllToSourceIconTemplate;
  _targetFilterIconTemplate;
  _sourceFilterIconTemplate;
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this._itemTemplate = item.template;
          break;
        case 'option':
          this._itemTemplate = item.template;
          break;
        case 'sourceHeader':
          this._sourceHeaderTemplate = item.template;
          break;
        case 'targetHeader':
          this._targetHeaderTemplate = item.template;
          break;
        case 'sourceFilter':
          this._sourceFilterTemplate = item.template;
          break;
        case 'targetFilter':
          this._targetFilterTemplate = item.template;
          break;
        case 'emptymessagesource':
          this._emptyMessageSourceTemplate = item.template;
          break;
        case 'emptyfiltermessagesource':
          this._emptyFilterMessageSourceTemplate = item.template;
          break;
        case 'emptymessagetarget':
          this._emptyMessageTargetTemplate = item.template;
          break;
        case 'emptyfiltermessagetarget':
          this._emptyFilterMessageTargetTemplate = item.template;
          break;
        case 'moveupicon':
          this._moveUpIconTemplate = item.template;
          break;
        case 'movetopicon':
          this._moveTopIconTemplate = item.template;
          break;
        case 'movedownicon':
          this._moveDownIconTemplate = item.template;
          break;
        case 'movebottomicon':
          this._moveBottomIconTemplate = item.template;
          break;
        case 'movetotargeticon':
          this._moveToTargetIconTemplate = item.template;
          break;
        case 'movealltotargeticon':
          this._moveAllToTargetIconTemplate = item.template;
          break;
        case 'movetosourceicon':
          this._moveToSourceIconTemplate = item.template;
          break;
        case 'movealltosourceicon':
          this._moveAllToSourceIconTemplate = item.template;
          break;
        case 'targetfiltericon':
          this._targetFilterIconTemplate = item.template;
          break;
        case 'sourcefiltericon':
          this._sourceFilterIconTemplate = item.template;
          break;
        default:
          this._itemTemplate = item.template;
          break;
      }
    });
  }
  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = find(this.reorderedListElement, 'li.p-highlight');
      let listItem;
      if (this.movedUp) listItem = listItems[0];else listItem = listItems[listItems.length - 1];
      scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }
  onItemClick(event, item, selectedItems, listType, callback, itemId) {
    if (this.disabled) {
      return;
    }
    let index = this.findIndexInList(item, selectedItems);
    if (itemId) this.focusedOptionIndex = itemId;
    let selected = index != -1;
    let metaSelection = this.itemTouched ? false : this.metaKeySelection;
    if (metaSelection) {
      let metaKey = event.metaKey || event.ctrlKey || event.shiftKey;
      if (selected && metaKey) {
        selectedItems = selectedItems.filter((_, i) => i !== index);
      } else {
        if (!metaKey) {
          selectedItems = [];
        }
        selectedItems.push(item);
      }
    } else {
      if (selected) {
        selectedItems = selectedItems.filter((_, i) => i !== index); // Creating a new array without the selected item
      } else {
        selectedItems.push(item);
      }
    }
    this.setSelectionList(listType, selectedItems);
    callback.emit({
      originalEvent: event,
      items: selectedItems
    });
    this.itemTouched = false;
  }
  onOptionMouseDown(index, listType) {
    this.focused[listType === this.SOURCE_LIST ? 'sourceList' : 'targetList'] = true;
    this.focusedOptionIndex = index;
  }
  onSourceItemDblClick() {
    if (this.disabled) {
      return;
    }
    this.moveRight();
    this.viewChildMarkForCheck();
  }
  onTargetItemDblClick() {
    if (this.disabled) {
      return;
    }
    this.moveLeft();
    this.viewChildMarkForCheck();
  }
  onFilter(event, listType) {
    let query = event.target.value;
    if (listType === this.SOURCE_LIST) this.filterSource(query);else if (listType === this.TARGET_LIST) this.filterTarget(query);
  }
  filterSource(value = '') {
    this.filterValueSource = value.trim().toLocaleLowerCase(this.filterLocale);
    this.filter(this.source, this.SOURCE_LIST);
  }
  filterTarget(value = '') {
    this.filterValueTarget = value.trim().toLocaleLowerCase(this.filterLocale);
    this.filter(this.target, this.TARGET_LIST);
  }
  filter(data, listType) {
    let searchFields = this.filterBy.split(',');
    if (listType === this.SOURCE_LIST) {
      this.visibleOptionsSource = this.filterService.filter(data, searchFields, this.filterValueSource, this.filterMatchMode, this.filterLocale);
      this.onSourceFilter.emit({
        query: this.filterValueSource,
        value: this.visibleOptionsSource
      });
    } else if (listType === this.TARGET_LIST) {
      this.visibleOptionsTarget = this.filterService.filter(data, searchFields, this.filterValueTarget, this.filterMatchMode, this.filterLocale);
      this.onTargetFilter.emit({
        query: this.filterValueTarget,
        value: this.visibleOptionsTarget
      });
    }
  }
  isItemVisible(item, listType) {
    if (listType == this.SOURCE_LIST) return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);else return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
  }
  isEmpty(listType) {
    if (listType == this.SOURCE_LIST) return this.filterValueSource ? !this.visibleOptionsSource || this.visibleOptionsSource.length === 0 : !this.source || this.source.length === 0;else return this.filterValueTarget ? !this.visibleOptionsTarget || this.visibleOptionsTarget.length === 0 : !this.target || this.target.length === 0;
  }
  isVisibleInList(data, item, filterValue) {
    if (filterValue && filterValue.trim().length) {
      for (let i = 0; i < data.length; i++) {
        if (item == data[i]) {
          return true;
        }
      }
    } else {
      return true;
    }
  }
  onItemTouchEnd() {
    if (this.disabled) {
      return;
    }
    this.itemTouched = true;
  }
  sortByIndexInList(items, list) {
    return items.sort((item1, item2) => findIndexInList(item1, list) - findIndexInList(item2, list));
  }
  viewChildMarkForCheck() {
    this.listViewSourceChild.cd.markForCheck();
    this.listViewTargetChild.cd.markForCheck();
  }
  moveUp(listElement, list, selectedItems, callback, listType) {
    if (selectedItems && selectedItems.length) {
      selectedItems = this.sortByIndexInList(selectedItems, list);
      for (let i = 0; i < selectedItems.length; i++) {
        let selectedItem = selectedItems[i];
        let selectedItemIndex = findIndexInList(selectedItem, list);
        if (selectedItemIndex != 0) {
          let movedItem = list[selectedItemIndex];
          let temp = list[selectedItemIndex - 1];
          list[selectedItemIndex - 1] = movedItem;
          list[selectedItemIndex] = temp;
        } else {
          break;
        }
      }
      if (this.dragdrop && (this.filterValueSource && listType === this.SOURCE_LIST || this.filterValueTarget && listType === this.TARGET_LIST)) this.filter(list, listType);
      this.movedUp = true;
      this.reorderedListElement = listElement;
      callback.emit({
        items: selectedItems
      });
      this.viewChildMarkForCheck();
    }
  }
  moveTop(listElement, list, selectedItems, callback, listType) {
    if (selectedItems && selectedItems.length) {
      selectedItems = this.sortByIndexInList(selectedItems, list);
      for (let i = 0; i < selectedItems.length; i++) {
        let selectedItem = selectedItems[i];
        let selectedItemIndex = findIndexInList(selectedItem, list);
        if (selectedItemIndex != 0) {
          let movedItem = list.splice(selectedItemIndex, 1)[0];
          list.unshift(movedItem);
        } else {
          break;
        }
      }
      if (this.dragdrop && (this.filterValueSource && listType === this.SOURCE_LIST || this.filterValueTarget && listType === this.TARGET_LIST)) this.filter(list, listType);
      listElement.scrollTop = 0;
      callback.emit({
        items: selectedItems
      });
      this.viewChildMarkForCheck();
    }
  }
  moveDown(listElement, list, selectedItems, callback, listType) {
    if (selectedItems && selectedItems.length) {
      selectedItems = this.sortByIndexInList(selectedItems, list);
      for (let i = selectedItems.length - 1; i >= 0; i--) {
        let selectedItem = selectedItems[i];
        let selectedItemIndex = findIndexInList(selectedItem, list);
        if (selectedItemIndex != list.length - 1) {
          let movedItem = list[selectedItemIndex];
          let temp = list[selectedItemIndex + 1];
          list[selectedItemIndex + 1] = movedItem;
          list[selectedItemIndex] = temp;
        } else {
          break;
        }
      }
      if (this.dragdrop && (this.filterValueSource && listType === this.SOURCE_LIST || this.filterValueTarget && listType === this.TARGET_LIST)) this.filter(list, listType);
      this.movedDown = true;
      this.reorderedListElement = listElement;
      callback.emit({
        items: selectedItems
      });
      this.viewChildMarkForCheck();
    }
  }
  moveBottom(listElement, list, selectedItems, callback, listType) {
    if (selectedItems && selectedItems.length) {
      selectedItems = this.sortByIndexInList(selectedItems, list);
      for (let i = selectedItems.length - 1; i >= 0; i--) {
        let selectedItem = selectedItems[i];
        let selectedItemIndex = findIndexInList(selectedItem, list);
        if (selectedItemIndex != list.length - 1) {
          let movedItem = list.splice(selectedItemIndex, 1)[0];
          list.push(movedItem);
        } else {
          break;
        }
      }
      if (this.dragdrop && (this.filterValueSource && listType === this.SOURCE_LIST || this.filterValueTarget && listType === this.TARGET_LIST)) this.filter(list, listType);
      listElement.scrollTop = listElement.scrollHeight;
      callback.emit({
        items: selectedItems
      });
      this.viewChildMarkForCheck();
    }
  }
  moveRight() {
    if (this.selectedItemsSource && this.selectedItemsSource.length) {
      let itemsToMove = [...this.selectedItemsSource];
      for (let i = 0; i < itemsToMove.length; i++) {
        let selectedItem = itemsToMove[i];
        if (findIndexInList(selectedItem, this.target) == -1) {
          this.target?.push(this.source?.splice(findIndexInList(selectedItem, this.source), 1)[0]);
          if (this.visibleOptionsSource?.includes(selectedItem)) {
            this.visibleOptionsSource.splice(findIndexInList(selectedItem, this.visibleOptionsSource), 1);
          }
        }
      }
      this.onMoveToTarget.emit({
        items: itemsToMove
      });
      if (this.keepSelection) {
        this.selectedItemsTarget = [...this.selectedItemsTarget, ...itemsToMove];
      }
      itemsToMove = [];
      this.selectedItemsSource = [];
      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST);
      }
      this.viewChildMarkForCheck();
    }
  }
  moveAllRight() {
    if (this.source) {
      let movedItems = [];
      for (let i = 0; i < this.source.length; i++) {
        if (this.isItemVisible(this.source[i], this.SOURCE_LIST)) {
          let removedItem = this.source.splice(i, 1)[0];
          this.target?.push(removedItem);
          movedItems.push(removedItem);
          i--;
        }
      }
      this.onMoveAllToTarget.emit({
        items: movedItems
      });
      if (this.keepSelection) {
        this.selectedItemsTarget = [...this.selectedItemsTarget, ...this.selectedItemsSource];
      }
      this.selectedItemsSource = [];
      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST);
      }
      this.visibleOptionsSource = [];
      this.viewChildMarkForCheck();
    }
  }
  moveLeft() {
    if (this.selectedItemsTarget && this.selectedItemsTarget.length) {
      let itemsToMove = [...this.selectedItemsTarget];
      for (let i = 0; i < itemsToMove.length; i++) {
        let selectedItem = itemsToMove[i];
        if (findIndexInList(selectedItem, this.source) == -1) {
          this.source?.push(this.target?.splice(findIndexInList(selectedItem, this.target), 1)[0]);
          if (this.visibleOptionsTarget?.includes(selectedItem)) {
            this.visibleOptionsTarget.splice(findIndexInList(selectedItem, this.visibleOptionsTarget), 1)[0];
          }
        }
      }
      this.onMoveToSource.emit({
        items: itemsToMove
      });
      if (this.keepSelection) {
        this.selectedItemsSource = [...this.selectedItemsSource, itemsToMove];
      }
      itemsToMove = [];
      this.selectedItemsTarget = [];
      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST);
      }
      this.viewChildMarkForCheck();
    }
  }
  moveAllLeft() {
    if (this.target) {
      let movedItems = [];
      for (let i = 0; i < this.target.length; i++) {
        if (this.isItemVisible(this.target[i], this.TARGET_LIST)) {
          let removedItem = this.target.splice(i, 1)[0];
          this.source?.push(removedItem);
          movedItems.push(removedItem);
          i--;
        }
      }
      this.onMoveAllToSource.emit({
        items: movedItems
      });
      if (this.keepSelection) {
        this.selectedItemsSource = [...this.selectedItemsSource, ...this.selectedItemsTarget];
      }
      this.selectedItemsTarget = [];
      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST);
      }
      this.visibleOptionsTarget = [];
      this.viewChildMarkForCheck();
    }
  }
  isSelected(item, selectedItems) {
    return this.findIndexInList(item, selectedItems) != -1;
  }
  findIndexInList(item, selectedItems) {
    return findIndexInList(item, selectedItems);
  }
  onDrop(event, listType) {
    let isTransfer = event.previousContainer !== event.container;
    let dropIndexes = this.getDropIndexes(event.previousIndex, event.currentIndex, listType, isTransfer, event.item.data);
    if (listType === this.SOURCE_LIST) {
      if (isTransfer) {
        transferArrayItem(event.previousContainer.data, event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex);
        let selectedItemIndex = findIndexInList(event.item.data, this.selectedItemsTarget);
        if (selectedItemIndex != -1) {
          this.selectedItemsTarget.splice(selectedItemIndex, 1);
          if (this.keepSelection) {
            this.selectedItemsTarget.push(event.item.data);
          }
        }
        if (this.visibleOptionsTarget) this.visibleOptionsTarget.splice(event.previousIndex, 1);
        this.onMoveToSource.emit({
          items: [event.item.data]
        });
      } else {
        moveItemInArray(event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex);
        this.onSourceReorder.emit({
          items: [event.item.data]
        });
      }
      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST);
      }
    } else {
      if (isTransfer) {
        transferArrayItem(event.previousContainer.data, event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex);
        let selectedItemIndex = findIndexInList(event.item.data, this.selectedItemsSource);
        if (selectedItemIndex != -1) {
          this.selectedItemsSource.splice(selectedItemIndex, 1);
          if (this.keepSelection) {
            this.selectedItemsTarget.push(event.item.data);
          }
        }
        if (this.visibleOptionsSource) this.visibleOptionsSource.splice(event.previousIndex, 1);
        this.onMoveToTarget.emit({
          items: [event.item.data]
        });
      } else {
        moveItemInArray(event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex);
        this.onTargetReorder.emit({
          items: [event.item.data]
        });
      }
      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST);
      }
    }
  }
  onListFocus(event, listType) {
    this.onFocus.emit(event);
  }
  onListBlur(event, listType) {
    this.onBlur.emit(event);
  }
  getListElement(listType) {
    return listType === this.SOURCE_LIST ? this.listViewSourceChild?.el.nativeElement : this.listViewTargetChild?.el.nativeElement;
  }
  getListItems(listType) {
    let listElemet = this.getListElement(listType);
    return find(listElemet, 'li.p-picklist-item');
  }
  getLatestSelectedVisibleOptionIndex(visibleList, selectedItems) {
    const latestSelectedItem = [...selectedItems].reverse().find(item => visibleList.includes(item));
    return latestSelectedItem !== undefined ? visibleList.indexOf(latestSelectedItem) : -1;
  }
  getVisibleList(listType) {
    if (listType === this.SOURCE_LIST) {
      return this.visibleOptionsSource && this.visibleOptionsSource.length > 0 ? this.visibleOptionsSource : this.source && this.source.length > 0 ? this.source : null;
    }
    return this.visibleOptionsTarget && this.visibleOptionsTarget.length > 0 ? this.visibleOptionsTarget : this.target && this.target.length > 0 ? this.target : null;
  }
  setSelectionList(listType, selectedItems) {
    if (listType === this.SOURCE_LIST) {
      this.selectedItemsSource = selectedItems;
    } else {
      this.selectedItemsTarget = selectedItems;
    }
  }
  findNextOptionIndex(index, listType) {
    const items = this.getListItems(listType);
    const matchedOptionIndex = [...items].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex + 1 : 0;
  }
  findPrevOptionIndex(index, listType) {
    const items = this.getListItems(listType);
    const matchedOptionIndex = [...items].findIndex(link => link.id === index);
    return matchedOptionIndex > -1 ? matchedOptionIndex - 1 : 0;
  }
  onItemKeyDown(event, selectedItems, callback, listType) {
    switch (event.code) {
      case 'ArrowDown':
        this.onArrowDownKey(event, selectedItems, callback, listType);
        break;
      case 'ArrowUp':
        this.onArrowUpKey(event, selectedItems, callback, listType);
        break;
      case 'Home':
        this.onHomeKey(event, selectedItems, callback, listType);
        break;
      case 'End':
        this.onEndKey(event, selectedItems, callback, listType);
        break;
      case 'Enter':
        this.onEnterKey(event, selectedItems, callback, listType);
        break;
      case 'Space':
        this.onSpaceKey(event, selectedItems, callback, listType);
        break;
      case 'KeyA':
        if (event.ctrlKey) {
          this.setSelectionList(listType, this.getVisibleList(listType));
          callback.emit({
            items: selectedItems
          });
          event.preventDefault();
        }
      default:
        break;
    }
  }
  getFocusedOption(index, listType) {
    if (index === -1) return null;
    if (listType === this.SOURCE_LIST) {
      return this.visibleOptionsSource && this.visibleOptionsSource.length ? this.visibleOptionsSource[index] : this.source && this.source.length ? this.source[index] : null;
    }
    return this.visibleOptionsTarget && this.visibleOptionsTarget.length ? this.visibleOptionsTarget[index] : this.target && this.target.length ? this.target[index] : null;
  }
  changeFocusedOptionIndex(index, listType) {
    const items = this.getListItems(listType);
    if (items?.length > 0) {
      let order = index >= items.length ? items.length - 1 : index < 0 ? 0 : index;
      this.focusedOptionIndex = items[order].getAttribute('id');
      this.focusedOption = this.getFocusedOption(order, listType);
      this.scrollInView(items[order].getAttribute('id'), listType);
    }
  }
  scrollInView(id, listType) {
    const element = findSingle(this.getListElement(listType), `li[id="${id}"]`);
    if (element) {
      element.scrollIntoView && element.scrollIntoView({
        block: 'nearest',
        inline: 'start'
      });
    }
  }
  onArrowDownKey(event, selectedItems, callback, listType) {
    const optionIndex = this.findNextOptionIndex(this.focusedOptionIndex, listType);
    this.changeFocusedOptionIndex(optionIndex, listType);
    if (event.shiftKey) {
      this.onEnterKey(event, selectedItems, callback, listType);
    }
    event.preventDefault();
  }
  onArrowUpKey(event, selectedItems, callback, listType) {
    const optionIndex = this.findPrevOptionIndex(this.focusedOptionIndex, listType);
    this.changeFocusedOptionIndex(optionIndex, listType);
    if (event.shiftKey) {
      this.onEnterKey(event, selectedItems, callback, listType);
    }
    event.preventDefault();
  }
  onEnterKey(event, selectedItems, callback, listType) {
    this.onItemClick(event, this.focusedOption, selectedItems, listType, callback);
    event.preventDefault();
  }
  onSpaceKey(event, selectedItems, callback, listType) {
    event.preventDefault();
    if (event.shiftKey && selectedItems && selectedItems.length > 0) {
      let visibleList = this.getVisibleList(listType);
      let lastSelectedIndex = this.getLatestSelectedVisibleOptionIndex(visibleList, selectedItems);
      if (lastSelectedIndex !== -1) {
        let focusedIndex = findIndexInList(this.focusedOption, visibleList);
        selectedItems = [...visibleList.slice(Math.min(lastSelectedIndex, focusedIndex), Math.max(lastSelectedIndex, focusedIndex) + 1)];
        this.setSelectionList(listType, selectedItems);
        callback.emit({
          items: selectedItems
        });
        return;
      }
    }
    this.onEnterKey(event, selectedItems, callback, listType);
  }
  onHomeKey(event, selectedItems, callback, listType) {
    if (event.ctrlKey && event.shiftKey) {
      let visibleList = this.getVisibleList(listType);
      let focusedIndex = findIndexInList(this.focusedOption, visibleList);
      selectedItems = [...visibleList.slice(0, focusedIndex + 1)];
      this.setSelectionList(listType, selectedItems);
      callback.emit({
        items: selectedItems
      });
    } else {
      this.changeFocusedOptionIndex(0, listType);
    }
    event.preventDefault();
  }
  onEndKey(event, selectedItems, callback, listType) {
    let visibleList = this.getVisibleList(listType);
    let lastIndex = visibleList && visibleList.length > 0 ? visibleList.length - 1 : null;
    if (lastIndex === null) return;
    if (event.ctrlKey && event.shiftKey) {
      let focusedIndex = findIndexInList(this.focusedOption, visibleList);
      selectedItems = [...visibleList.slice(focusedIndex, lastIndex)];
      this.setSelectionList(listType, selectedItems);
      callback.emit({
        items: selectedItems
      });
    } else {
      this.changeFocusedOptionIndex(lastIndex, listType);
    }
    event.preventDefault();
  }
  getDropIndexes(fromIndex, toIndex, droppedList, isTransfer, data) {
    let previousIndex, currentIndex;
    if (droppedList === this.SOURCE_LIST) {
      previousIndex = isTransfer ? this.filterValueTarget ? findIndexInList(data, this.target) : fromIndex : this.filterValueSource ? findIndexInList(data, this.source) : fromIndex;
      currentIndex = this.filterValueSource ? this.findFilteredCurrentIndex(this.visibleOptionsSource, toIndex, this.source) : toIndex;
    } else {
      previousIndex = isTransfer ? this.filterValueSource ? findIndexInList(data, this.source) : fromIndex : this.filterValueTarget ? findIndexInList(data, this.target) : fromIndex;
      currentIndex = this.filterValueTarget ? this.findFilteredCurrentIndex(this.visibleOptionsTarget, toIndex, this.target) : toIndex;
    }
    return {
      previousIndex,
      currentIndex
    };
  }
  findFilteredCurrentIndex(visibleOptions, index, options) {
    if (visibleOptions.length === index) {
      let toIndex = findIndexInList(visibleOptions[index - 1], options);
      return toIndex + 1;
    } else {
      return findIndexInList(visibleOptions[index], options);
    }
  }
  resetSourceFilter() {
    this.visibleOptionsSource = null;
    this.filterValueSource = null;
    this.sourceFilterViewChild && (this.sourceFilterViewChild.nativeElement.value = '');
  }
  resetTargetFilter() {
    this.visibleOptionsTarget = null;
    this.filterValueTarget = null;
    this.targetFilterViewChild && (this.targetFilterViewChild.nativeElement.value = '');
  }
  resetFilter() {
    this.resetSourceFilter();
    this.resetTargetFilter();
  }
  initMedia() {
    if (isPlatformBrowser(this.platformId)) {
      this.media = this.document.defaultView.matchMedia(`(max-width: ${this.breakpoint})`);
      this.viewChanged = this.media.matches;
      this.bindMediaChangeListener();
    }
  }
  destroyMedia() {
    this.unbindMediaChangeListener();
  }
  bindMediaChangeListener() {
    if (this.media && !this.mediaChangeListener) {
      this.mediaChangeListener = this.renderer.listen(this.media, 'change', event => {
        this.viewChanged = event.matches;
        this.cd.markForCheck();
      });
    }
  }
  unbindMediaChangeListener() {
    if (this.mediaChangeListener) {
      this.mediaChangeListener();
      this.mediaChangeListener = null;
    }
  }
  createStyle() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.styleElement) {
        this.renderer.setAttribute(this.el.nativeElement.children[0], this.id, '');
        this.styleElement = this.renderer.createElement('style');
        this.renderer.setAttribute(this.styleElement, 'type', 'text/css');
        this.renderer.appendChild(this.document.head, this.styleElement);
        let innerHTML = `
                @media screen and (max-width: ${this.breakpoint}) {
                    .p-picklist[${this.id}] {
                        flex-direction: column;
                    }

                    .p-picklist[${this.id}] .p-picklist-controls {
                        flex-direction: row;
                    }
                }`;
        this.renderer.setProperty(this.styleElement, 'innerHTML', innerHTML);
        setAttribute(this.styleElement, 'nonce', this.config?.csp()?.nonce);
      }
    }
  }
  sourceMoveDisabled() {
    if (this.disabled || !this.selectedItemsSource.length) {
      return true;
    }
  }
  targetMoveDisabled() {
    if (this.disabled || !this.selectedItemsTarget.length) {
      return true;
    }
  }
  moveRightDisabled() {
    return this.disabled || isEmpty(this.selectedItemsSource);
  }
  moveLeftDisabled() {
    return this.disabled || isEmpty(this.selectedItemsTarget);
  }
  moveAllRightDisabled() {
    return this.disabled || isEmpty(this.source);
  }
  moveAllLeftDisabled() {
    return this.disabled || isEmpty(this.target);
  }
  destroyStyle() {
    if (this.styleElement) {
      this.renderer.removeChild(this.document.head, this.styleElement);
      this.styleElement = null;
      ``;
    }
  }
  ngOnDestroy() {
    this.destroyStyle();
    this.destroyMedia();
    super.ngOnDestroy();
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵPickList_BaseFactory;
    return function PickList_Factory(__ngFactoryType__) {
      return (ɵPickList_BaseFactory || (ɵPickList_BaseFactory = i0.ɵɵgetInheritedFactory(PickList)))(__ngFactoryType__ || PickList);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: PickList,
    selectors: [["p-pickList"], ["p-picklist"], ["p-pick-list"]],
    contentQueries: function PickList_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, _c0, 4);
        i0.ɵɵcontentQuery(dirIndex, _c1, 4);
        i0.ɵɵcontentQuery(dirIndex, _c2, 4);
        i0.ɵɵcontentQuery(dirIndex, _c3, 4);
        i0.ɵɵcontentQuery(dirIndex, _c4, 4);
        i0.ɵɵcontentQuery(dirIndex, _c5, 4);
        i0.ɵɵcontentQuery(dirIndex, _c6, 4);
        i0.ɵɵcontentQuery(dirIndex, _c7, 4);
        i0.ɵɵcontentQuery(dirIndex, _c8, 4);
        i0.ɵɵcontentQuery(dirIndex, _c9, 4);
        i0.ɵɵcontentQuery(dirIndex, _c10, 4);
        i0.ɵɵcontentQuery(dirIndex, _c11, 4);
        i0.ɵɵcontentQuery(dirIndex, _c12, 4);
        i0.ɵɵcontentQuery(dirIndex, _c13, 4);
        i0.ɵɵcontentQuery(dirIndex, _c14, 4);
        i0.ɵɵcontentQuery(dirIndex, _c15, 4);
        i0.ɵɵcontentQuery(dirIndex, _c16, 4);
        i0.ɵɵcontentQuery(dirIndex, _c17, 4);
        i0.ɵɵcontentQuery(dirIndex, _c18, 4);
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sourceHeaderTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.targetHeaderTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sourceFilterTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.targetFilterTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyMessageSourceTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyFilterMessageSourceTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyMessageTargetTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyFilterMessageTargetTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveUpIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveTopIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveDownIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveBottomIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveToTargetIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveAllToTargetIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveToSourceIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.moveAllToSourceIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.targetFilterIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sourceFilterIconTemplate = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function PickList_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c19, 5);
        i0.ɵɵviewQuery(_c20, 5);
        i0.ɵɵviewQuery(_c3, 5);
        i0.ɵɵviewQuery(_c4, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewSourceChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listViewTargetChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sourceFilterViewChild = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.targetFilterViewChild = _t.first);
      }
    },
    inputs: {
      source: "source",
      target: "target",
      sourceHeader: "sourceHeader",
      tabindex: [2, "tabindex", "tabindex", numberAttribute],
      rightButtonAriaLabel: "rightButtonAriaLabel",
      leftButtonAriaLabel: "leftButtonAriaLabel",
      allRightButtonAriaLabel: "allRightButtonAriaLabel",
      allLeftButtonAriaLabel: "allLeftButtonAriaLabel",
      upButtonAriaLabel: "upButtonAriaLabel",
      downButtonAriaLabel: "downButtonAriaLabel",
      topButtonAriaLabel: "topButtonAriaLabel",
      bottomButtonAriaLabel: "bottomButtonAriaLabel",
      targetHeader: "targetHeader",
      responsive: [2, "responsive", "responsive", booleanAttribute],
      filterBy: "filterBy",
      filterLocale: "filterLocale",
      trackBy: "trackBy",
      sourceTrackBy: "sourceTrackBy",
      targetTrackBy: "targetTrackBy",
      showSourceFilter: [2, "showSourceFilter", "showSourceFilter", booleanAttribute],
      showTargetFilter: [2, "showTargetFilter", "showTargetFilter", booleanAttribute],
      metaKeySelection: [2, "metaKeySelection", "metaKeySelection", booleanAttribute],
      dragdrop: [2, "dragdrop", "dragdrop", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      sourceStyle: "sourceStyle",
      targetStyle: "targetStyle",
      showSourceControls: [2, "showSourceControls", "showSourceControls", booleanAttribute],
      showTargetControls: [2, "showTargetControls", "showTargetControls", booleanAttribute],
      sourceFilterPlaceholder: "sourceFilterPlaceholder",
      targetFilterPlaceholder: "targetFilterPlaceholder",
      disabled: [2, "disabled", "disabled", booleanAttribute],
      ariaSourceFilterLabel: "ariaSourceFilterLabel",
      ariaTargetFilterLabel: "ariaTargetFilterLabel",
      filterMatchMode: "filterMatchMode",
      stripedRows: [2, "stripedRows", "stripedRows", booleanAttribute],
      keepSelection: [2, "keepSelection", "keepSelection", booleanAttribute],
      scrollHeight: "scrollHeight",
      autoOptionFocus: [2, "autoOptionFocus", "autoOptionFocus", booleanAttribute],
      buttonProps: "buttonProps",
      moveUpButtonProps: "moveUpButtonProps",
      moveTopButtonProps: "moveTopButtonProps",
      moveDownButtonProps: "moveDownButtonProps",
      moveBottomButtonProps: "moveBottomButtonProps",
      moveToTargetProps: "moveToTargetProps",
      moveAllToTargetProps: "moveAllToTargetProps",
      moveToSourceProps: "moveToSourceProps",
      moveAllToSourceProps: "moveAllToSourceProps",
      breakpoint: "breakpoint"
    },
    outputs: {
      onMoveToSource: "onMoveToSource",
      onMoveAllToSource: "onMoveAllToSource",
      onMoveAllToTarget: "onMoveAllToTarget",
      onMoveToTarget: "onMoveToTarget",
      onSourceReorder: "onSourceReorder",
      onTargetReorder: "onTargetReorder",
      onSourceSelect: "onSourceSelect",
      onTargetSelect: "onTargetSelect",
      onSourceFilter: "onSourceFilter",
      onTargetFilter: "onTargetFilter",
      onFocus: "onFocus",
      onBlur: "onBlur"
    },
    features: [i0.ɵɵProvidersFeature([PickListStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature],
    decls: 34,
    vars: 93,
    consts: [["sourcelist", ""], ["targetlist", ""], ["header", ""], ["item", ""], ["cdkDropListGroup", "", 3, "ngStyle", "ngClass"], ["class", "p-picklist-controls p-picklist-source-controls", 4, "ngIf"], [1, "p-picklist-list-container", "p-picklist-source-list-container"], ["optionLabel", "name", 3, "ngModelChange", "onFocus", "onBlur", "keydown", "onDblClick", "multiple", "options", "ngModel", "id", "ngStyle", "striped", "tabindex", "disabled", "metaKeySelection", "scrollHeight", "autoOptionFocus", "filter", "filterBy", "filterLocale", "filterPlaceHolder"], [4, "ngIf"], ["class", "p-picklist-title", 4, "ngIf"], [1, "p-picklist-controls", "p-picklist-transfer-controls"], ["type", "button", "pButton", "", "pRipple", "", "severity", "secondary", 1, "p-button-icon-only", 3, "click", "disabled", "buttonProps"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "p-picklist-list-container", "p-picklist-target-list-container"], ["class", "p-picklist-controls p-picklist-target-controls", 4, "ngIf"], [1, "p-picklist-controls", "p-picklist-source-controls"], [4, "ngTemplateOutlet"], [1, "p-picklist-title"], [1, "p-picklist-controls", "p-picklist-target-controls"]],
    template: function PickList_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 4);
        i0.ɵɵtemplate(1, PickList_div_1_Template, 13, 26, "div", 5);
        i0.ɵɵelementStart(2, "div", 6)(3, "p-listbox", 7, 0);
        i0.ɵɵtwoWayListener("ngModelChange", function PickList_Template_p_listbox_ngModelChange_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          i0.ɵɵtwoWayBindingSet(ctx.selectedItemsSource, $event) || (ctx.selectedItemsSource = $event);
          return i0.ɵɵresetView($event);
        });
        i0.ɵɵlistener("onFocus", function PickList_Template_p_listbox_onFocus_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListFocus($event, ctx.SOURCE_LIST));
        })("onBlur", function PickList_Template_p_listbox_onBlur_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListBlur($event, ctx.SOURCE_LIST));
        })("keydown", function PickList_Template_p_listbox_keydown_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onItemKeyDown($event, ctx.selectedItemsSource, ctx.onSourceSelect, ctx.SOURCE_LIST));
        })("onDblClick", function PickList_Template_p_listbox_onDblClick_3_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onSourceItemDblClick());
        });
        i0.ɵɵtemplate(5, PickList_ng_container_5_Template, 3, 0, "ng-container", 8)(6, PickList_ng_container_6_Template, 2, 4, "ng-container", 8)(7, PickList_ng_container_7_Template, 2, 1, "ng-container", 8)(8, PickList_div_8_Template, 2, 1, "div", 9)(9, PickList_ng_container_9_Template, 3, 0, "ng-container", 8)(10, PickList_ng_container_10_Template, 2, 1, "ng-container", 8);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(11, "div", 10)(12, "button", 11);
        i0.ɵɵlistener("click", function PickList_Template_button_click_12_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.moveRight());
        });
        i0.ɵɵtemplate(13, PickList_ng_container_13_Template, 3, 2, "ng-container", 8)(14, PickList_14_Template, 1, 0, null, 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "button", 11);
        i0.ɵɵlistener("click", function PickList_Template_button_click_15_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.moveAllRight());
        });
        i0.ɵɵtemplate(16, PickList_ng_container_16_Template, 3, 2, "ng-container", 8)(17, PickList_17_Template, 1, 0, null, 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "button", 11);
        i0.ɵɵlistener("click", function PickList_Template_button_click_18_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.moveLeft());
        });
        i0.ɵɵtemplate(19, PickList_ng_container_19_Template, 3, 2, "ng-container", 8)(20, PickList_20_Template, 1, 0, null, 12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "button", 11);
        i0.ɵɵlistener("click", function PickList_Template_button_click_21_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.moveAllLeft());
        });
        i0.ɵɵtemplate(22, PickList_ng_container_22_Template, 3, 2, "ng-container", 8)(23, PickList_23_Template, 1, 0, null, 12);
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(24, "div", 13)(25, "p-listbox", 7, 1);
        i0.ɵɵtwoWayListener("ngModelChange", function PickList_Template_p_listbox_ngModelChange_25_listener($event) {
          i0.ɵɵrestoreView(_r1);
          i0.ɵɵtwoWayBindingSet(ctx.selectedItemsTarget, $event) || (ctx.selectedItemsTarget = $event);
          return i0.ɵɵresetView($event);
        });
        i0.ɵɵlistener("onFocus", function PickList_Template_p_listbox_onFocus_25_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListFocus($event, ctx.TARGET_LIST));
        })("onBlur", function PickList_Template_p_listbox_onBlur_25_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onListBlur($event, ctx.TARGET_LIST));
        })("keydown", function PickList_Template_p_listbox_keydown_25_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onItemKeyDown($event, ctx.selectedItemsTarget, ctx.onTargetSelect, ctx.TARGET_LIST));
        })("onDblClick", function PickList_Template_p_listbox_onDblClick_25_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onTargetItemDblClick());
        });
        i0.ɵɵtemplate(27, PickList_ng_container_27_Template, 3, 0, "ng-container", 8)(28, PickList_ng_container_28_Template, 2, 4, "ng-container", 8)(29, PickList_ng_container_29_Template, 2, 1, "ng-container", 8)(30, PickList_div_30_Template, 2, 1, "div", 9)(31, PickList_ng_container_31_Template, 3, 0, "ng-container", 8)(32, PickList_ng_container_32_Template, 2, 1, "ng-container", 8);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(33, PickList_div_33_Template, 13, 26, "div", 14);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", i0.ɵɵpureFunction0(84, _c21));
        i0.ɵɵattribute("data-pc-name", "picklist")("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showSourceControls);
        i0.ɵɵadvance();
        i0.ɵɵattribute("data-pc-section", "sourceWrapper")("data-pc-group-section", "listWrapper");
        i0.ɵɵadvance();
        i0.ɵɵproperty("multiple", true)("options", ctx.source);
        i0.ɵɵtwoWayProperty("ngModel", ctx.selectedItemsSource);
        i0.ɵɵproperty("id", ctx.idSource + "_list")("ngStyle", ctx.sourceStyle)("striped", ctx.stripedRows)("tabindex", ctx.tabindex)("disabled", ctx.disabled)("metaKeySelection", ctx.metaKeySelection)("scrollHeight", ctx.scrollHeight)("autoOptionFocus", ctx.autoOptionFocus)("filter", ctx.filterBy)("filterBy", ctx.filterBy)("filterLocale", ctx.filterLocale)("filterPlaceHolder", ctx.sourceFilterPlaceholder);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.sourceHeaderTemplate || ctx._sourceHeaderTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.sourceFilterTemplate || ctx._sourceFilterTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.sourceFilterIconTemplate || ctx._sourceFilterIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.sourceHeaderTemplate && !ctx._sourceHeaderTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.itemTemplate || ctx._itemTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.emptyFilterMessageSourceTemplate || ctx._emptyFilterMessageSourceTemplate || ctx.emptyMessageSourceTemplate || ctx._emptyMessageSourceTemplate);
        i0.ɵɵadvance();
        i0.ɵɵattribute("data-pc-section", "buttons")("data-pc-group-section", "controls");
        i0.ɵɵadvance();
        i0.ɵɵproperty("disabled", ctx.moveRightDisabled())("buttonProps", ctx.getButtonProps("movetotarget"));
        i0.ɵɵattribute("aria-label", ctx.moveToTargetAriaLabel)("data-pc-section", "moveToTargetButton");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.moveToTargetIconTemplate && !ctx._moveToTargetIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.moveToTargetIconTemplate || ctx._moveToTargetIconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(85, _c22, ctx.viewChanged));
        i0.ɵɵadvance();
        i0.ɵɵproperty("disabled", ctx.moveAllRightDisabled())("buttonProps", ctx.getButtonProps("movealltotarget"));
        i0.ɵɵattribute("aria-label", ctx.moveAllToTargetAriaLabel)("data-pc-section", "moveAllToTargetButton");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.moveAllToTargetIconTemplate && !ctx._moveAllToTargetIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.moveAllToTargetIconTemplate || ctx._moveAllToTargetIconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(87, _c22, ctx.viewChanged));
        i0.ɵɵadvance();
        i0.ɵɵproperty("disabled", ctx.moveLeftDisabled())("buttonProps", ctx.getButtonProps("movetosource"));
        i0.ɵɵattribute("aria-label", ctx.moveToSourceAriaLabel)("data-pc-section", "moveToSourceButton");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.moveToSourceIconTemplate && !ctx._moveToSourceIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.moveToSourceIconTemplate || ctx._moveToSourceIconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(89, _c22, ctx.viewChanged));
        i0.ɵɵadvance();
        i0.ɵɵproperty("disabled", ctx.moveAllLeftDisabled())("buttonProps", ctx.getButtonProps("movealltosource"));
        i0.ɵɵattribute("aria-label", ctx.moveAllToSourceAriaLabel)("data-pc-section", "moveAllToSourceButton");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.moveAllToSourceIconTemplate && !ctx._moveAllToSourceIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.moveAllToSourceIconTemplate || ctx._moveAllToSourceIconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(91, _c22, ctx.viewChanged));
        i0.ɵɵadvance();
        i0.ɵɵattribute("data-pc-section", "targetWrapper")("data-pc-group-section", "listwrapper");
        i0.ɵɵadvance();
        i0.ɵɵproperty("multiple", true)("options", ctx.target);
        i0.ɵɵtwoWayProperty("ngModel", ctx.selectedItemsTarget);
        i0.ɵɵproperty("id", ctx.idTarget + "_list")("ngStyle", ctx.targetStyle)("striped", ctx.stripedRows)("tabindex", ctx.tabindex)("disabled", ctx.disabled)("metaKeySelection", ctx.metaKeySelection)("scrollHeight", ctx.scrollHeight)("autoOptionFocus", ctx.autoOptionFocus)("filter", ctx.filterBy)("filterBy", ctx.filterBy)("filterLocale", ctx.filterLocale)("filterPlaceHolder", ctx.targetFilterPlaceholder);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.targetHeaderTemplate || ctx._targetHeaderTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.targetFilterTemplate || ctx._targetFilterTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.targetFilterIconTemplate || ctx._targetFilterIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.targetHeaderTemplate && !ctx._targetHeaderTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.itemTemplate || ctx._itemTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.emptyFilterMessageTargetTemplate || ctx._emptyFilterMessageTargetTemplate || ctx.emptyMessageTargetTemplate || ctx._emptyMessageTargetTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showTargetControls);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, ButtonDirective, Ripple, DragDropModule, i2.CdkDropListGroup, AngleDoubleDownIcon, AngleDoubleLeftIcon, AngleDoubleRightIcon, AngleDoubleUpIcon, AngleDownIcon, AngleLeftIcon, AngleRightIcon, AngleUpIcon, Listbox, FormsModule, i3.NgControlStatus, i3.NgModel, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PickList, [{
    type: Component,
    args: [{
      selector: 'p-pickList, p-picklist, p-pick-list',
      standalone: true,
      imports: [CommonModule, ButtonDirective, Ripple, DragDropModule, AngleDoubleDownIcon, AngleDoubleLeftIcon, AngleDoubleRightIcon, AngleDoubleUpIcon, AngleDownIcon, AngleLeftIcon, AngleRightIcon, AngleUpIcon, Listbox, FormsModule, SharedModule],
      template: `
        <div [class]="styleClass" [ngStyle]="style" [ngClass]="{ 'p-picklist p-component': true }" cdkDropListGroup [attr.data-pc-name]="'picklist'" [attr.data-pc-section]="'root'">
            <div class="p-picklist-controls p-picklist-source-controls" *ngIf="showSourceControls" [attr.data-pc-section]="'sourceControls'" [attr.data-pc-group-section]="'controls'">
                <button
                    type="button"
                    [attr.aria-label]="moveUpAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="sourceMoveDisabled()"
                    (click)="moveUp(sourcelist, source, selectedItemsSource, onSourceReorder, SOURCE_LIST)"
                    [attr.data-pc-section]="'sourceMoveUpButton'"
                    [buttonProps]="getButtonProps('moveup')"
                >
                    <AngleUpIcon *ngIf="!moveUpIconTemplate && !_moveUpIconTemplate" [attr.data-pc-section]="'moveupicon'" />
                    <ng-template *ngTemplateOutlet="moveUpIconTemplate || _moveUpIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveTopAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="sourceMoveDisabled()"
                    (click)="moveTop(sourcelist, source, selectedItemsSource, onSourceReorder, SOURCE_LIST)"
                    [attr.data-pc-section]="'sourceMoveTopButton'"
                    [buttonProps]="getButtonProps('movetop')"
                >
                    <AngleDoubleUpIcon *ngIf="!moveTopIconTemplate && !_moveTopIconTemplate" [attr.data-pc-section]="'movetopicon'" />
                    <ng-template *ngTemplateOutlet="moveTopIconTemplate || _moveTopIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveDownAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="sourceMoveDisabled()"
                    (click)="moveDown(sourcelist, source, selectedItemsSource, onSourceReorder, SOURCE_LIST)"
                    [attr.data-pc-section]="'sourceMoveDownButton'"
                    [buttonProps]="getButtonProps('movedown')"
                >
                    <AngleDownIcon *ngIf="!moveDownIconTemplate && !_moveDownIconTemplate" [attr.data-pc-section]="'movedownicon'" />
                    <ng-template *ngTemplateOutlet="moveDownIconTemplate || _moveDownIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveBottomAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="sourceMoveDisabled()"
                    (click)="moveBottom(sourcelist, source, selectedItemsSource, onSourceReorder, SOURCE_LIST)"
                    [attr.data-pc-section]="'sourceMoveBottomButton'"
                    [buttonProps]="getButtonProps('movebottom')"
                >
                    <AngleDoubleDownIcon *ngIf="!moveBottomIconTemplate || _moveBottomIconTemplate" [attr.data-pc-section]="'movebottomicon'" />
                    <ng-template *ngTemplateOutlet="moveBottomIconTemplate || _moveBottomIconTemplate"></ng-template>
                </button>
            </div>
            <div class="p-picklist-list-container p-picklist-source-list-container" [attr.data-pc-section]="'sourceWrapper'" [attr.data-pc-group-section]="'listWrapper'">
                <p-listbox
                    #sourcelist
                    [multiple]="true"
                    [options]="source"
                    [(ngModel)]="selectedItemsSource"
                    optionLabel="name"
                    [id]="idSource + '_list'"
                    [ngStyle]="sourceStyle"
                    [striped]="stripedRows"
                    [tabindex]="tabindex"
                    (onFocus)="onListFocus($event, SOURCE_LIST)"
                    (onBlur)="onListBlur($event, SOURCE_LIST)"
                    (keydown)="onItemKeyDown($event, selectedItemsSource, onSourceSelect, SOURCE_LIST)"
                    (onDblClick)="onSourceItemDblClick()"
                    [disabled]="disabled"
                    [metaKeySelection]="metaKeySelection"
                    [scrollHeight]="scrollHeight"
                    [autoOptionFocus]="autoOptionFocus"
                    [filter]="filterBy"
                    [filterBy]="filterBy"
                    [filterLocale]="filterLocale"
                    [filterPlaceHolder]="sourceFilterPlaceholder"
                >
                    <ng-container *ngIf="sourceHeaderTemplate || _sourceHeaderTemplate">
                        <ng-template #header>
                            <ng-template *ngTemplateOutlet="sourceHeaderTemplate || _sourceHeaderTemplate"></ng-template>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="sourceFilterTemplate || _sourceFilterTemplate">
                        <ng-container *ngTemplateOutlet="sourceFilterTemplate || _sourceFilterTemplate; context: { options: sourceFilterOptions }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="sourceFilterIconTemplate || _sourceFilterIconTemplate">
                        <ng-container *ngTemplateOutlet="sourceFilterIconTemplate || _sourceFilterIconTemplate"></ng-container>
                    </ng-container>
                    <div class="p-picklist-title" *ngIf="!sourceHeaderTemplate && !_sourceHeaderTemplate">{{ sourceHeader }}</div>
                    <ng-container *ngIf="itemTemplate || _itemTemplate">
                        <ng-template #item let-item let-index="index" let-selected="selected">
                            <ng-container *ngTemplateOutlet="itemTemplate || _itemTemplate; context: { $implicit: item, index: index, selected: selected }"></ng-container>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="emptyFilterMessageSourceTemplate || _emptyFilterMessageSourceTemplate || emptyMessageSourceTemplate || _emptyMessageSourceTemplate">
                        <ng-container *ngTemplateOutlet="emptyFilterMessageSourceTemplate || _emptyFilterMessageSourceTemplate || emptyMessageSourceTemplate || _emptyMessageSourceTemplate"></ng-container>
                    </ng-container>
                </p-listbox>
            </div>
            <div class="p-picklist-controls p-picklist-transfer-controls" [attr.data-pc-section]="'buttons'" [attr.data-pc-group-section]="'controls'">
                <button
                    type="button"
                    [attr.aria-label]="moveToTargetAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="moveRightDisabled()"
                    (click)="moveRight()"
                    [attr.data-pc-section]="'moveToTargetButton'"
                    [buttonProps]="getButtonProps('movetotarget')"
                >
                    <ng-container *ngIf="!moveToTargetIconTemplate && !_moveToTargetIconTemplate">
                        <AngleRightIcon *ngIf="!viewChanged" [attr.data-pc-section]="'movetotargeticon'" />
                        <AngleDownIcon *ngIf="viewChanged" [attr.data-pc-section]="'movetotargeticon'" />
                    </ng-container>
                    <ng-template *ngTemplateOutlet="moveToTargetIconTemplate || _moveToTargetIconTemplate; context: { $implicit: viewChanged }"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveAllToTargetAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="moveAllRightDisabled()"
                    (click)="moveAllRight()"
                    [attr.data-pc-section]="'moveAllToTargetButton'"
                    [buttonProps]="getButtonProps('movealltotarget')"
                >
                    <ng-container *ngIf="!moveAllToTargetIconTemplate && !_moveAllToTargetIconTemplate">
                        <AngleDoubleRightIcon *ngIf="!viewChanged" [attr.data-pc-section]="'movealltotargeticon'" />
                        <AngleDoubleDownIcon *ngIf="viewChanged" [attr.data-pc-section]="'movealltotargeticon'" />
                    </ng-container>
                    <ng-template *ngTemplateOutlet="moveAllToTargetIconTemplate || _moveAllToTargetIconTemplate; context: { $implicit: viewChanged }"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveToSourceAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="moveLeftDisabled()"
                    (click)="moveLeft()"
                    [attr.data-pc-section]="'moveToSourceButton'"
                    [buttonProps]="getButtonProps('movetosource')"
                >
                    <ng-container *ngIf="!moveToSourceIconTemplate && !_moveToSourceIconTemplate">
                        <AngleLeftIcon *ngIf="!viewChanged" [attr.data-pc-section]="'movedownsourceticon'" />
                        <AngleUpIcon *ngIf="viewChanged" [attr.data-pc-section]="'movedownsourceticon'" />
                    </ng-container>
                    <ng-template *ngTemplateOutlet="moveToSourceIconTemplate || _moveToSourceIconTemplate; context: { $implicit: viewChanged }"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveAllToSourceAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="moveAllLeftDisabled()"
                    (click)="moveAllLeft()"
                    [attr.data-pc-section]="'moveAllToSourceButton'"
                    [buttonProps]="getButtonProps('movealltosource')"
                >
                    <ng-container *ngIf="!moveAllToSourceIconTemplate && !_moveAllToSourceIconTemplate">
                        <AngleDoubleLeftIcon *ngIf="!viewChanged" [attr.data-pc-section]="'movealltosourceticon'" />
                        <AngleDoubleUpIcon *ngIf="viewChanged" [attr.data-pc-section]="'movealltosourceticon'" />
                    </ng-container>
                    <ng-template *ngTemplateOutlet="moveAllToSourceIconTemplate || _moveAllToSourceIconTemplate; context: { $implicit: viewChanged }"></ng-template>
                </button>
            </div>
            <div class="p-picklist-list-container p-picklist-target-list-container" [attr.data-pc-section]="'targetWrapper'" [attr.data-pc-group-section]="'listwrapper'">
                <p-listbox
                    #targetlist
                    [multiple]="true"
                    [options]="target"
                    [(ngModel)]="selectedItemsTarget"
                    optionLabel="name"
                    [id]="idTarget + '_list'"
                    [ngStyle]="targetStyle"
                    [striped]="stripedRows"
                    [tabindex]="tabindex"
                    (onFocus)="onListFocus($event, TARGET_LIST)"
                    (onBlur)="onListBlur($event, TARGET_LIST)"
                    (keydown)="onItemKeyDown($event, selectedItemsTarget, onTargetSelect, TARGET_LIST)"
                    (onDblClick)="onTargetItemDblClick()"
                    [disabled]="disabled"
                    [metaKeySelection]="metaKeySelection"
                    [scrollHeight]="scrollHeight"
                    [autoOptionFocus]="autoOptionFocus"
                    [filter]="filterBy"
                    [filterBy]="filterBy"
                    [filterLocale]="filterLocale"
                    [filterPlaceHolder]="targetFilterPlaceholder"
                >
                    <ng-container *ngIf="targetHeaderTemplate || _targetHeaderTemplate">
                        <ng-template #header>
                            <ng-template *ngTemplateOutlet="targetHeaderTemplate || _targetHeaderTemplate"></ng-template>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="targetFilterTemplate || _targetFilterTemplate">
                        <ng-container *ngTemplateOutlet="targetFilterTemplate || _targetFilterTemplate; context: { options: targetFilterOptions }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="targetFilterIconTemplate || _targetFilterIconTemplate">
                        <ng-container *ngTemplateOutlet="targetFilterIconTemplate || _targetFilterIconTemplate"></ng-container>
                    </ng-container>
                    <div class="p-picklist-title" *ngIf="!targetHeaderTemplate && !_targetHeaderTemplate">{{ targetHeader }}</div>
                    <ng-container *ngIf="itemTemplate || _itemTemplate">
                        <ng-template #item let-item let-index="index" let-selected="selected">
                            <ng-container *ngTemplateOutlet="itemTemplate || _itemTemplate; context: { $implicit: item, index: index, selected: selected }"></ng-container>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="emptyFilterMessageTargetTemplate || _emptyFilterMessageTargetTemplate || emptyMessageTargetTemplate || _emptyMessageTargetTemplate">
                        <ng-container *ngTemplateOutlet="emptyFilterMessageTargetTemplate || _emptyFilterMessageTargetTemplate || emptyMessageTargetTemplate || _emptyMessageTargetTemplate"></ng-container>
                    </ng-container>
                </p-listbox>
            </div>
            <div class="p-picklist-controls p-picklist-target-controls" *ngIf="showTargetControls" [attr.data-pc-section]="'targetControls'" [attr.data-pc-group-section]="'controls'">
                <button
                    type="button"
                    [attr.aria-label]="moveUpAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="targetMoveDisabled()"
                    (click)="moveUp(targetlist, target, selectedItemsTarget, onTargetReorder, TARGET_LIST)"
                    [attr.data-pc-section]="'targetMoveUpButton'"
                    [buttonProps]="getButtonProps('moveup')"
                >
                    <AngleUpIcon *ngIf="!moveUpIconTemplate && !_moveUpIconTemplate" [attr.data-pc-section]="'moveupicon'" />
                    <ng-template *ngTemplateOutlet="moveUpIconTemplate || _moveUpIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveTopAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="targetMoveDisabled()"
                    (click)="moveTop(targetlist, target, selectedItemsTarget, onTargetReorder, TARGET_LIST)"
                    [attr.data-pc-section]="'targetMoveTopButton'"
                    [buttonProps]="getButtonProps('movetop')"
                >
                    <AngleDoubleUpIcon *ngIf="!moveTopIconTemplate && !_moveTopIconTemplate" [attr.data-pc-section]="'movetopicon'" />
                    <ng-template *ngTemplateOutlet="moveTopIconTemplate || moveTopIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveDownAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="targetMoveDisabled()"
                    (click)="moveDown(targetlist, target, selectedItemsTarget, onTargetReorder, TARGET_LIST)"
                    [attr.data-pc-section]="'targetMoveDownButton'"
                    [buttonProps]="getButtonProps('movedown')"
                >
                    <AngleDownIcon *ngIf="!moveDownIconTemplate && !_moveDownIconTemplate" [attr.data-pc-section]="'movedownicon'" />
                    <ng-template *ngTemplateOutlet="moveDownIconTemplate || _moveDownIconTemplate"></ng-template>
                </button>
                <button
                    type="button"
                    [attr.aria-label]="moveBottomAriaLabel"
                    pButton
                    pRipple
                    severity="secondary"
                    class="p-button-icon-only"
                    [disabled]="targetMoveDisabled()"
                    (click)="moveBottom(targetlist, target, selectedItemsTarget, onTargetReorder, TARGET_LIST)"
                    [attr.data-pc-section]="'targetMoveBottomButton'"
                    [buttonProps]="getButtonProps('movebottom')"
                >
                    <AngleDoubleDownIcon *ngIf="!moveBottomIconTemplate && !_moveBottomIconTemplate" [attr.data-pc-section]="'movebottomicon'" />
                    <ng-template *ngTemplateOutlet="moveBottomIconTemplate || _moveBottomIconTemplate"></ng-template>
                </button>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [PickListStyle]
    }]
  }], null, {
    source: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    sourceHeader: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    rightButtonAriaLabel: [{
      type: Input
    }],
    leftButtonAriaLabel: [{
      type: Input
    }],
    allRightButtonAriaLabel: [{
      type: Input
    }],
    allLeftButtonAriaLabel: [{
      type: Input
    }],
    upButtonAriaLabel: [{
      type: Input
    }],
    downButtonAriaLabel: [{
      type: Input
    }],
    topButtonAriaLabel: [{
      type: Input
    }],
    bottomButtonAriaLabel: [{
      type: Input
    }],
    targetHeader: [{
      type: Input
    }],
    responsive: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    filterBy: [{
      type: Input
    }],
    filterLocale: [{
      type: Input
    }],
    trackBy: [{
      type: Input
    }],
    sourceTrackBy: [{
      type: Input
    }],
    targetTrackBy: [{
      type: Input
    }],
    showSourceFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showTargetFilter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    metaKeySelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    dragdrop: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    sourceStyle: [{
      type: Input
    }],
    targetStyle: [{
      type: Input
    }],
    showSourceControls: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showTargetControls: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    sourceFilterPlaceholder: [{
      type: Input
    }],
    targetFilterPlaceholder: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaSourceFilterLabel: [{
      type: Input
    }],
    ariaTargetFilterLabel: [{
      type: Input
    }],
    filterMatchMode: [{
      type: Input
    }],
    stripedRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    keepSelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    scrollHeight: [{
      type: Input
    }],
    autoOptionFocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    buttonProps: [{
      type: Input
    }],
    moveUpButtonProps: [{
      type: Input
    }],
    moveTopButtonProps: [{
      type: Input
    }],
    moveDownButtonProps: [{
      type: Input
    }],
    moveBottomButtonProps: [{
      type: Input
    }],
    moveToTargetProps: [{
      type: Input
    }],
    moveAllToTargetProps: [{
      type: Input
    }],
    moveToSourceProps: [{
      type: Input
    }],
    moveAllToSourceProps: [{
      type: Input
    }],
    breakpoint: [{
      type: Input
    }],
    onMoveToSource: [{
      type: Output
    }],
    onMoveAllToSource: [{
      type: Output
    }],
    onMoveAllToTarget: [{
      type: Output
    }],
    onMoveToTarget: [{
      type: Output
    }],
    onSourceReorder: [{
      type: Output
    }],
    onTargetReorder: [{
      type: Output
    }],
    onSourceSelect: [{
      type: Output
    }],
    onTargetSelect: [{
      type: Output
    }],
    onSourceFilter: [{
      type: Output
    }],
    onTargetFilter: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    listViewSourceChild: [{
      type: ViewChild,
      args: ['sourcelist']
    }],
    listViewTargetChild: [{
      type: ViewChild,
      args: ['targetlist']
    }],
    sourceFilterViewChild: [{
      type: ViewChild,
      args: ['sourceFilter']
    }],
    targetFilterViewChild: [{
      type: ViewChild,
      args: ['targetFilter']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['item', {
        descendants: false
      }]
    }],
    sourceHeaderTemplate: [{
      type: ContentChild,
      args: ['sourceHeader', {
        descendants: false
      }]
    }],
    targetHeaderTemplate: [{
      type: ContentChild,
      args: ['targetHeader', {
        descendants: false
      }]
    }],
    sourceFilterTemplate: [{
      type: ContentChild,
      args: ['sourceFilter', {
        descendants: false
      }]
    }],
    targetFilterTemplate: [{
      type: ContentChild,
      args: ['targetFilter', {
        descendants: false
      }]
    }],
    emptyMessageSourceTemplate: [{
      type: ContentChild,
      args: ['emptymessagesource', {
        descendants: false
      }]
    }],
    emptyFilterMessageSourceTemplate: [{
      type: ContentChild,
      args: ['emptyfiltermessagesource', {
        descendants: false
      }]
    }],
    emptyMessageTargetTemplate: [{
      type: ContentChild,
      args: ['emptymessagetarget', {
        descendants: false
      }]
    }],
    emptyFilterMessageTargetTemplate: [{
      type: ContentChild,
      args: ['emptyfiltermessagetarget', {
        descendants: false
      }]
    }],
    moveUpIconTemplate: [{
      type: ContentChild,
      args: ['moveupicon', {
        descendants: false
      }]
    }],
    moveTopIconTemplate: [{
      type: ContentChild,
      args: ['movetopicon', {
        descendants: false
      }]
    }],
    moveDownIconTemplate: [{
      type: ContentChild,
      args: ['movedownicon', {
        descendants: false
      }]
    }],
    moveBottomIconTemplate: [{
      type: ContentChild,
      args: ['movebottomicon', {
        descendants: false
      }]
    }],
    moveToTargetIconTemplate: [{
      type: ContentChild,
      args: ['movetotargeticon', {
        descendants: false
      }]
    }],
    moveAllToTargetIconTemplate: [{
      type: ContentChild,
      args: ['movealltotargeticon', {
        descendants: false
      }]
    }],
    moveToSourceIconTemplate: [{
      type: ContentChild,
      args: ['movetosourceicon', {
        descendants: false
      }]
    }],
    moveAllToSourceIconTemplate: [{
      type: ContentChild,
      args: ['movealltosourceicon', {
        descendants: false
      }]
    }],
    targetFilterIconTemplate: [{
      type: ContentChild,
      args: ['targetfiltericon', {
        descendants: false
      }]
    }],
    sourceFilterIconTemplate: [{
      type: ContentChild,
      args: ['sourcefiltericon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(PickList, {
    className: "PickList",
    filePath: "picklist.ts",
    lineNumber: 353
  });
})();
class PickListModule {
  static ɵfac = function PickListModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PickListModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: PickListModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [PickList, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PickListModule, [{
    type: NgModule,
    args: [{
      imports: [PickList, SharedModule],
      exports: [PickList, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PickListModule, {
    imports: [PickList, SharedModule],
    exports: [PickList, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PickList, PickListClasses, PickListModule, PickListStyle };
