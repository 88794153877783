export class CookieService {
  static setCookie(
    name: string,
    value: string,
    days: number,
    minutes?: number
  ) {
    let expires = '';
    if (days || minutes) {
      const date = new Date();
      date.setTime(
        date.getTime() +
          (minutes
            ? (minutes as number) * 60 * 1000
            : days * 24 * 60 * 60 * 1000)
      );
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  static getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  static eraseCookie(name: string) {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
