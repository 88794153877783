<p-tabView>
  <p-tabPanel header="Período">
    <form class="horizontal" [formGroup]="periodForm">
      <p-floatlabel class="form-control">
        <p-calendar
          inputId="dateStart"
          formControlName="dateStart"
          dateFormat="dd/mm/yy"
          appendTo="body"
        ></p-calendar>
        <label for="dateStart">Início</label>
      </p-floatlabel>
      <p-floatlabel class="form-control">
        <p-calendar
          inputId="dateEnd"
          formControlName="dateEnd"
          dateFormat="dd/mm/yy"
          appendTo="body"
        ></p-calendar>
        <label for="dateEnd">Fim</label>
      </p-floatlabel>
      <span class="form-button">
        <p-button
          label="Syncronizar"
          [disabled]="!periodForm.valid"
          size="small"
          styleClass="p-button-success"
          (onClick)="ref.close(periodForm.value)"
        ></p-button>
      </span>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Edições" *ngIf="editions && editions.length">
    <form class="horizontal" [formGroup]="editionForm">
      <p-floatlabel class="form-control">
        <p-multiSelect
          [options]="subscriptions"
          optionValue="subscriptionId"
          optionLabel="subscriptionName"
          inputId="subscriptionIds"
          formControlName="subscriptionIds"
          appendTo="body"
          fluid
        >
          <ng-template #item let-subscription>
            {{ subscription.subscriptionName | titlecase }}
          </ng-template>
          <ng-template #selectedItems let-subscription>
            {{ subscription.subscriptionName | titlecase }}
          </ng-template>
        </p-multiSelect>
        <label for="subscriptionId">Produto</label>
      </p-floatlabel>
      <p-floatlabel class="form-control">
        <p-multiSelect
          [options]="editions"
          optionValue="editionId"
          optionLabel="theme"
          inputId="editionIds"
          formControlName="editionIds"
          appendTo="body"
          fluid
        >
          <ng-template #item let-edition>
            {{ edition.theme }} -
            {{ releaseDate(edition) | date : 'MMMM/yyyy' | titlecase }}
          </ng-template>
        </p-multiSelect>
        <label for="editionIds">Edições</label>
      </p-floatlabel>
      <span class="form-button">
        <p-button
          label="Syncronizar"
          [disabled]="!editionForm.valid"
          size="small"
          styleClass="p-button-success"
          (onClick)="ref.close(editionForm.value)"
        ></p-button>
      </span>
      <small
        >* O histórico das edições será removido e sincronizado novamente</small
      >
    </form>
  </p-tabPanel>
</p-tabView>
