export enum DraftStatus {
  Open = { label: 'Em aberto', enumValue: 'Open', value: 0 } as any,
  Close = { label: 'Concluído', enumValue: 'Close', value: 1 } as any
}

export function getAllDrafts(): Array<{
  label: string;
  value: number;
  enumValue: string;
}> {
  const objetos: Array<{ label: string; value: number; enumValue: string }> =
    [];
  for (const [p, propertyValue] of Object.entries(DraftStatus)) {
    if (typeof propertyValue === 'object') objetos.push(propertyValue);
  }
  return objetos;
}

export function getDraftStatus(
  status: DraftStatus
): { label: string; value: number; enumValue: string } | undefined {
  return getAllDrafts().find((d) => d.value === (status as any).value);
}
