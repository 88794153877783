/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Draft } from '../model/draft';
import { DraftComposition } from '../model/draftComposition';
import { DraftCompositionProduct } from '../model/draftCompositionProduct';
import { DraftCompositionRequest } from '../model/draftCompositionRequest';
import { DraftDefaultProductRequest } from '../model/draftDefaultProductRequest';
import { DraftReviewRequest } from '../model/draftReviewRequest';
import { DraftSubscriptionDTO } from '../model/draftSubscriptionDTO';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4ADraft } from '../model/responseB4ADraft';
import { ResponseB4ADraftComposition } from '../model/responseB4ADraftComposition';
import { ResponseB4ADraftCompositionProduct } from '../model/responseB4ADraftCompositionProduct';
import { ResponseB4ADraftCompositionSummary } from '../model/responseB4ADraftCompositionSummary';
import { ResponseB4ADraftSubscriberFilter } from '../model/responseB4ADraftSubscriberFilter';
import { ResponseB4AInteger } from '../model/responseB4AInteger';
import { ResponseB4AListCompositionSubscriber } from '../model/responseB4AListCompositionSubscriber';
import { ResponseB4AListCompositionSummaryDTO } from '../model/responseB4AListCompositionSummaryDTO';
import { ResponseB4AListDraftCompositionProductReserved } from '../model/responseB4AListDraftCompositionProductReserved';
import { ResponseB4AListDraftCompositionSubcategoryCheck } from '../model/responseB4AListDraftCompositionSubcategoryCheck';
import { ResponseB4AListDraftCompositionSubcategoryRepeatedSubscriber } from '../model/responseB4AListDraftCompositionSubcategoryRepeatedSubscriber';
import { ResponseB4AListDraftCompositionSubscriber } from '../model/responseB4AListDraftCompositionSubscriber';
import { ResponseB4AListDraftCompositionSummary } from '../model/responseB4AListDraftCompositionSummary';
import { ResponseB4AListDraftDefaultProductView } from '../model/responseB4AListDraftDefaultProductView';
import { ResponseB4AListDraftSubscriberBadgeDTO } from '../model/responseB4AListDraftSubscriberBadgeDTO';
import { ResponseB4AListDraftSubscriberStateCities } from '../model/responseB4AListDraftSubscriberStateCities';
import { ResponseB4AListDraftSubscriberView } from '../model/responseB4AListDraftSubscriberView';
import { ResponseB4AListDuplicatedSubscribers } from '../model/responseB4AListDuplicatedSubscribers';
import { ResponseB4AListLateSubscriber } from '../model/responseB4AListLateSubscriber';
import { ResponseB4APageCompositionSubscriber } from '../model/responseB4APageCompositionSubscriber';
import { ResponseB4APageDraftView } from '../model/responseB4APageDraftView';
import { ResponseB4ASegmentationRequest } from '../model/responseB4ASegmentationRequest';
import { ResponseB4AString } from '../model/responseB4AString';
import { SegmentationRequest } from '../model/segmentationRequest';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class DraftControllerService {
  protected basePath = 'http://localhost:5000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addProductToComposition(
    body?: DraftCompositionProduct,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftCompositionSummary>;
  public addProductToComposition(
    body?: DraftCompositionProduct,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftCompositionSummary>>;
  public addProductToComposition(
    body?: DraftCompositionProduct,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftCompositionSummary>>;
  public addProductToComposition(
    body?: DraftCompositionProduct,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraftCompositionSummary>(
      'post',
      `${this.basePath}/draft/composition/product`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public approveDraft(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public approveDraft(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public approveDraft(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public approveDraft(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling approveDraft.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/draft/approve/${encodeURIComponent(String(draftId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public reopenDraftComposition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftComposition>;
  public reopenDraftComposition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftComposition>>;
  public reopenDraftComposition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftComposition>>;
  public reopenDraftComposition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling reopenDraftComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftComposition>(
      'put',
      `${this.basePath}/draft/composition/reopen/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resolveDraftComposition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftComposition>;
  public resolveDraftComposition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftComposition>>;
  public resolveDraftComposition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftComposition>>;
  public resolveDraftComposition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling resolveDraftComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftComposition>(
      'put',
      `${this.basePath}/draft/composition/resolve/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public approveDraftComposition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public approveDraftComposition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public approveDraftComposition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public approveDraftComposition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling approveDraftComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/draft/composition/approve/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkSubcategoriesLastMonth(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftCompositionSubcategoryCheck>;
  public checkSubcategoriesLastMonth(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftCompositionSubcategoryCheck>>;
  public checkSubcategoriesLastMonth(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftCompositionSubcategoryCheck>>;
  public checkSubcategoriesLastMonth(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling checkSubcategoriesLastMonth.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftCompositionSubcategoryCheck>(
      'get',
      `${this.basePath}/draft/subcategory/check/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public convertPartition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftComposition>;
  public convertPartition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftComposition>>;
  public convertPartition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftComposition>>;
  public convertPartition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling convertPartition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftComposition>(
      'put',
      `${this.basePath}/draft/partition/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDraftComposition(
    body?: DraftCompositionRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftComposition>;
  public createDraftComposition(
    body?: DraftCompositionRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftComposition>>;
  public createDraftComposition(
    body?: DraftCompositionRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftComposition>>;
  public createDraftComposition(
    body?: DraftCompositionRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraftComposition>(
      'post',
      `${this.basePath}/draft/composition`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPartition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public createPartition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public createPartition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public createPartition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling createPartition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/draft/partition/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDraft(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteDraft(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteDraft(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteDraft(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling deleteDraft.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/draft/${encodeURIComponent(String(draftId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDraftComposition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteDraftComposition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteDraftComposition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteDraftComposition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling deleteDraftComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/draft/composition/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDraftDefaultProducts(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftDefaultProductView>;
  public findDraftDefaultProducts(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftDefaultProductView>>;
  public findDraftDefaultProducts(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftDefaultProductView>>;
  public findDraftDefaultProducts(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling findDraftDefaultProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftDefaultProductView>(
      'get',
      `${this.basePath}/draft/default/products/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDraftDuplicatedSubscribers(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDuplicatedSubscribers>;
  public findDraftDuplicatedSubscribers(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDuplicatedSubscribers>>;
  public findDraftDuplicatedSubscribers(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDuplicatedSubscribers>>;
  public findDraftDuplicatedSubscribers(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling findDraftDuplicatedSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDuplicatedSubscribers>(
      'get',
      `${this.basePath}/draft/duplicated/subscribers/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findDraftSubscribers(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftSubscriberView>;
  public findDraftSubscribers(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftSubscriberView>>;
  public findDraftSubscribers(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftSubscriberView>>;
  public findDraftSubscribers(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling findDraftSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftSubscriberView>(
      'get',
      `${this.basePath}/draft/subscribers/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findLateSubscribers(
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListLateSubscriber>;
  public findLateSubscribers(
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListLateSubscriber>>;
  public findLateSubscribers(
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListLateSubscriber>>;
  public findLateSubscribers(
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findLateSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListLateSubscriber>(
      'get',
      `${this.basePath}/draft/subscribers/late/${encodeURIComponent(
        String(editionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findStates(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftSubscriberStateCities>;
  public findStates(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftSubscriberStateCities>>;
  public findStates(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftSubscriberStateCities>>;
  public findStates(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling findStates.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftSubscriberStateCities>(
      'get',
      `${this.basePath}/draft/subscribers/location/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberBadges(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftSubscriberBadgeDTO>;
  public findSubscriberBadges(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftSubscriberBadgeDTO>>;
  public findSubscriberBadges(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftSubscriberBadgeDTO>>;
  public findSubscriberBadges(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling findSubscriberBadges.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftSubscriberBadgeDTO>(
      'get',
      `${this.basePath}/draft/subscribers/badge/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public finishDraft(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public finishDraft(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public finishDraft(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public finishDraft(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling finishDraft.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/draft/${encodeURIComponent(String(draftId))}/finish`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public get(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraft>;
  public get(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraft>>;
  public get(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraft>>;
  public get(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling get.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraft>(
      'get',
      `${this.basePath}/draft/id/${encodeURIComponent(String(draftId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllocatedSubscribersTable(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCompositionSubscriber>;
  public getAllocatedSubscribersTable(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCompositionSubscriber>>;
  public getAllocatedSubscribersTable(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCompositionSubscriber>>;
  public getAllocatedSubscribersTable(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCompositionSubscriber>(
      'put',
      `${this.basePath}/draft/composition/allocated`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompositionProducts(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftCompositionProductReserved>;
  public getCompositionProducts(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftCompositionProductReserved>>;
  public getCompositionProducts(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftCompositionProductReserved>>;
  public getCompositionProducts(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling getCompositionProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftCompositionProductReserved>(
      'get',
      `${this.basePath}/draft/composition/${encodeURIComponent(
        String(draftCompositionId)
      )}/product`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompositionSubscribers(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftCompositionSubscriber>;
  public getCompositionSubscribers(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftCompositionSubscriber>>;
  public getCompositionSubscribers(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftCompositionSubscriber>>;
  public getCompositionSubscribers(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling getCompositionSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftCompositionSubscriber>(
      'get',
      `${this.basePath}/draft/composition/${encodeURIComponent(
        String(draftCompositionId)
      )}/subscribers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompositionSubscribersPage(
    body?: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCompositionSubscriber>;
  public getCompositionSubscribersPage(
    body?: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCompositionSubscriber>>;
  public getCompositionSubscribersPage(
    body?: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCompositionSubscriber>>;
  public getCompositionSubscribersPage(
    body?: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCompositionSubscriber>(
      'put',
      `${this.basePath}/draft/composition/subscriber/view`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompositionSubscribersView(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionSubscriber>;
  public getCompositionSubscribersView(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionSubscriber>>;
  public getCompositionSubscribersView(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionSubscriber>>;
  public getCompositionSubscribersView(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling getCompositionSubscribersView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionSubscriber>(
      'get',
      `${this.basePath}/draft/composition/${encodeURIComponent(
        String(draftCompositionId)
      )}/subscriber/view`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDraftComposition(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftCompositionSummary>;
  public getDraftComposition(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftCompositionSummary>>;
  public getDraftComposition(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftCompositionSummary>>;
  public getDraftComposition(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling getDraftComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftCompositionSummary>(
      'get',
      `${this.basePath}/draft/composition/info/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDraftCompositions(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftCompositionSummary>;
  public getDraftCompositions(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftCompositionSummary>>;
  public getDraftCompositions(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftCompositionSummary>>;
  public getDraftCompositions(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling getDraftCompositions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftCompositionSummary>(
      'get',
      `${this.basePath}/draft/composition/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDraftSubscriberFilters(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ASegmentationRequest>;
  public getDraftSubscriberFilters(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ASegmentationRequest>>;
  public getDraftSubscriberFilters(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ASegmentationRequest>>;
  public getDraftSubscriberFilters(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling getDraftSubscriberFilters.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ASegmentationRequest>(
      'get',
      `${this.basePath}/draft/subscriber/filter/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDraftSummary(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCompositionSummaryDTO>;
  public getDraftSummary(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCompositionSummaryDTO>>;
  public getDraftSummary(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCompositionSummaryDTO>>;
  public getDraftSummary(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling getDraftSummary.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCompositionSummaryDTO>(
      'get',
      `${this.basePath}/draft/summary/${encodeURIComponent(String(draftId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSubcategoryRepeatedSubscribersLastMonth(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftCompositionSubcategoryRepeatedSubscriber>;
  public getSubcategoryRepeatedSubscribersLastMonth(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListDraftCompositionSubcategoryRepeatedSubscriber>
  >;
  public getSubcategoryRepeatedSubscribersLastMonth(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<ResponseB4AListDraftCompositionSubcategoryRepeatedSubscriber>
  >;
  public getSubcategoryRepeatedSubscribersLastMonth(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling getSubcategoryRepeatedSubscribersLastMonth.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListDraftCompositionSubcategoryRepeatedSubscriber>(
      'get',
      `${this.basePath}/draft/subcategory/repeated/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public list(
    body?: PageableRequest,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageDraftView>;
  public list(
    body?: PageableRequest,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageDraftView>>;
  public list(
    body?: PageableRequest,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageDraftView>>;
  public list(
    body?: PageableRequest,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageDraftView>(
      'put',
      `${this.basePath}/draft/list`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recalculateSubscribersAndStocks(
    draftCompositionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftCompositionSummary>;
  public recalculateSubscribersAndStocks(
    draftCompositionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftCompositionSummary>>;
  public recalculateSubscribersAndStocks(
    draftCompositionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftCompositionSummary>>;
  public recalculateSubscribersAndStocks(
    draftCompositionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling recalculateSubscribersAndStocks.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftCompositionSummary>(
      'put',
      `${this.basePath}/draft/composition/recalculate/${encodeURIComponent(
        String(draftCompositionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rejectDraftComposition(
    body?: DraftReviewRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public rejectDraftComposition(
    body?: DraftReviewRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public rejectDraftComposition(
    body?: DraftReviewRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public rejectDraftComposition(
    body?: DraftReviewRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AString>(
      'put',
      `${this.basePath}/draft/composition/reject`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftCompositionId
   * @param productVariantId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeProductFromComposition(
    draftCompositionId: number,
    productVariantId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftCompositionSummary>;
  public removeProductFromComposition(
    draftCompositionId: number,
    productVariantId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftCompositionSummary>>;
  public removeProductFromComposition(
    draftCompositionId: number,
    productVariantId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftCompositionSummary>>;
  public removeProductFromComposition(
    draftCompositionId: number,
    productVariantId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftCompositionId === null || draftCompositionId === undefined) {
      throw new Error(
        'Required parameter draftCompositionId was null or undefined when calling removeProductFromComposition.'
      );
    }

    if (productVariantId === null || productVariantId === undefined) {
      throw new Error(
        'Required parameter productVariantId was null or undefined when calling removeProductFromComposition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ADraftCompositionSummary>(
      'delete',
      `${this.basePath}/draft/composition/product/${encodeURIComponent(
        String(draftCompositionId)
      )}/${encodeURIComponent(String(productVariantId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public save(
    body?: Draft,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraft>;
  public save(
    body?: Draft,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraft>>;
  public save(
    body?: Draft,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraft>>;
  public save(
    body?: Draft,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraft>(
      'post',
      `${this.basePath}/draft`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveDraftDefaultProducts(
    draftId: number,
    body?: Array<DraftDefaultProductRequest>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListDraftDefaultProductView>;
  public saveDraftDefaultProducts(
    draftId: number,
    body?: Array<DraftDefaultProductRequest>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListDraftDefaultProductView>>;
  public saveDraftDefaultProducts(
    draftId: number,
    body?: Array<DraftDefaultProductRequest>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListDraftDefaultProductView>>;
  public saveDraftDefaultProducts(
    draftId: number,
    body?: Array<DraftDefaultProductRequest>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling saveDraftDefaultProducts.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListDraftDefaultProductView>(
      'put',
      `${this.basePath}/draft/default/products/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveDraftSubscriberFilter(
    body?: SegmentationRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftSubscriberFilter>;
  public saveDraftSubscriberFilter(
    body?: SegmentationRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftSubscriberFilter>>;
  public saveDraftSubscriberFilter(
    body?: SegmentationRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftSubscriberFilter>>;
  public saveDraftSubscriberFilter(
    body?: SegmentationRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraftSubscriberFilter>(
      'post',
      `${this.basePath}/draft/subscriber/filter`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setSubscriptionIdOnProduct(
    body?: DraftSubscriptionDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftCompositionProduct>;
  public setSubscriptionIdOnProduct(
    body?: DraftSubscriptionDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftCompositionProduct>>;
  public setSubscriptionIdOnProduct(
    body?: DraftSubscriptionDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftCompositionProduct>>;
  public setSubscriptionIdOnProduct(
    body?: DraftSubscriptionDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraftCompositionProduct>(
      'put',
      `${this.basePath}/draft/subscription`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unsyncronizedSubscribers(
    draftId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AInteger>;
  public unsyncronizedSubscribers(
    draftId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AInteger>>;
  public unsyncronizedSubscribers(
    draftId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AInteger>>;
  public unsyncronizedSubscribers(
    draftId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling unsyncronizedSubscribers.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AInteger>(
      'get',
      `${this.basePath}/draft/unsyncronized/${encodeURIComponent(
        String(draftId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param draftId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public update(
    draftId: number,
    body?: Draft,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraft>;
  public update(
    draftId: number,
    body?: Draft,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraft>>;
  public update(
    draftId: number,
    body?: Draft,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraft>>;
  public update(
    draftId: number,
    body?: Draft,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (draftId === null || draftId === undefined) {
      throw new Error(
        'Required parameter draftId was null or undefined when calling update.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraft>(
      'put',
      `${this.basePath}/draft/${encodeURIComponent(String(draftId))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDraftComposition(
    body?: DraftComposition,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ADraftComposition>;
  public updateDraftComposition(
    body?: DraftComposition,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ADraftComposition>>;
  public updateDraftComposition(
    body?: DraftComposition,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ADraftComposition>>;
  public updateDraftComposition(
    body?: DraftComposition,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ADraftComposition>(
      'put',
      `${this.basePath}/draft/composition`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
