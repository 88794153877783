import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import { SyncronizationControllerService } from 'src/app/allocation-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

@Component({
  selector: 'app-block-page',
  templateUrl: './block-page.component.html',
  styleUrls: ['./block-page.component.scss'],
  standalone: false
})
export class BlockPageComponent implements OnInit, OnDestroy {
  private config = inject(DynamicDialogConfig);
  private syncService = inject(SyncronizationControllerService);

  statusId = 0;
  statusList: Array<number> = [0, 1, 2];
  lastRun = new Date();
  diff = signal(0);
  interval: NodeJS.Timeout;
  ngOnInit(): void {
    this.statusId = Number(this.config.data.statusId || 0);

    BroadcastUtil.get('sync').subscribe((statusId: number) => {
      this.statusId = statusId;
    });
    this.interval = setInterval(() => {
      this.diff.set((new Date().getTime() - this.lastRun.getTime()) / 1000);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      delete this.interval;
    }
  }

  async checkSync() {
    try {
      LoaderService.showLoader();
      this.lastRun = new Date();
      this.diff.set(0);
      const sync = await lastValueFrom(
        this.syncService
          .findLastSyncronization()
          .pipe(map((data) => data.result))
      );
      if (!sync.status) {
        if (Notification.permission !== 'granted')
          alert(
            'A sincronização foi concluída. Por favor habilite as notificações do navegador.'
          );
        else
          alert(
            'A sincronização foi concluída. Tente desabilitar e habilitar novamente as notificações para resolver o problema.'
          );
      } else if (Notification.permission !== 'granted') {
        alert(
          'A sincronização ainda não foi concluída. Por favor habilite as notificações do navegador.'
        );
      } else {
        alert('A sincronização ainda não foi concluída. Aguarde!');
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}
