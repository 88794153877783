<div>
  <p-table
    styleClass="p-datatable-sm"
    *ngIf="allowedUsers"
    [value]="allowedUsers"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} do total de {totalRecords} usuários"
    [rowsPerPageOptions]="[10, 25, 50]"
  >
    <ng-template pTemplate="caption">
      <div class="title">
        Usuários
        <button
          pButton
          type="buttom"
          class="p-button-secondary p-button-rounded"
          label="Adicionar Usuário"
          (click)="addNewUser()"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Id</th>
        <th>Nome</th>
        <th>E-mail</th>
        <th>Permissão</th>
        <th>Ações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-allowedUser>
      <tr>
        <td>{{ allowedUser.userId }}</td>
        <td>
          {{ allowedUser.displayName | titlecase }}
        </td>
        <td>{{ allowedUser.username }}</td>
        <td>
          <p-dropdown
            appendTo="body"
            [options]="permissions"
            [autoDisplayFirst]="false"
            (onChange)="updatePermission($event, allowedUser)"
            [(ngModel)]="allowedUser.role"
            [disabled]="userId === allowedUser.userId"
            *ngIf="
              allowedUser.role === 'ROLE_ALLOC_ADMIN' ||
              allowedUser.role === 'ROLE_ALLOC_USER'
            "
          >
          </p-dropdown>
          <p-dropdown
            appendTo="body"
            [options]="allPermissions"
            [autoDisplayFirst]="false"
            [(ngModel)]="allowedUser.role"
            [disabled]="true"
            *ngIf="
              allowedUser.role !== 'ROLE_ALLOC_ADMIN' &&
              allowedUser.role !== 'ROLE_ALLOC_USER'
            "
          >
          </p-dropdown>
        </td>
        <td>
          <button
            *ngIf="
              allowedUser.role === 'ROLE_ALLOC_ADMIN' ||
              allowedUser.role === 'ROLE_ALLOC_USER'
            "
            pButton
            pRipple
            pTooltip="Editar usuário"
            tooltipPosition="bottom"
            type="button"
            icon="pi pi-pencil"
            class="button p-button-rounded p-button-text"
            (click)="editUser(allowedUser)"
          ></button>
          <button
            *ngIf="
              allowedUser.role === 'ROLE_ALLOC_ADMIN' ||
              allowedUser.role === 'ROLE_ALLOC_USER'
            "
            pButton
            pRipple
            pTooltip="Alterar senha"
            tooltipPosition="bottom"
            type="button"
            icon="pi pi-key"
            class="button p-button-rounded p-button-text"
            (click)="changePassword(allowedUser)"
          ></button>
          <button
            *ngIf="
              userId !== allowedUser.userId &&
              (allowedUser.role === 'ROLE_ALLOC_ADMIN' ||
                allowedUser.role === 'ROLE_ALLOC_USER')
            "
            pButton
            pTooltip="Excluir usuário"
            tooltipPosition="bottom"
            pRipple
            type="button"
            icon="pi pi-trash"
            class="button p-button-rounded p-button-text"
            (click)="excludeUser($event, allowedUser)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmPopup></p-confirmPopup>
