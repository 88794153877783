<div class="product-filters-modal">
  <form
    (submit)="applyFilters()"
    [formGroup]="filtersGroup"
    *ngIf="brands && categories"
  >
    <span>
      <label for="categories">Categorias</label>
      <p-multiSelect
        name="categories"
        id="categories"
        [options]="categories"
        appendTo="body"
        formControlName="categories"
        [showToggleAll]="true"
        defaultLabel="Nenhuma selecionada"
        [maxSelectedLabels]="0"
        [filter]="true"
        filterBy="displayName,externalId,parent"
        selectedItemsLabel="{0} itens"
        optionValue="categoryId"
      >
        <ng-template let-category #item>
          <span>{{ categoryName(category) }}</span>
        </ng-template>
      </p-multiSelect>
    </span>
    <span>
      <label for="categories">Marcas</label>
      <p-multiSelect
        name="brands"
        appendTo="body"
        id="brands"
        [options]="brands"
        formControlName="brands"
        [showToggleAll]="true"
        [maxSelectedLabels]="0"
        [filter]="true"
        defaultLabel="Nenhuma selecionada"
        filterBy="brandName"
        selectedItemsLabel="{0} itens"
        optionValue="brandId"
      >
        <ng-template let-brand #item>
          <span>
            {{ brand.brandName }} - BrandEquity:{{ brand.brandEquity }}
          </span>
        </ng-template>
      </p-multiSelect>
    </span>
    <span>
      <label for="brandEquity">BrandEquity</label>
      <p-multiSelect
        name="brandEquities"
        appendTo="body"
        id="brandEquities"
        [options]="brandEquities"
        formControlName="brandEquities"
        [showToggleAll]="true"
        [maxSelectedLabels]="0"
        [filter]="true"
        defaultLabel="Nenhuma selecionada"
        filterBy="label"
        selectedItemsLabel="{0} itens"
        optionValue="value"
      >
        <ng-template let-brandEquity #item>
          <span> {{ brandEquity.value }}. {{ brandEquity.label }} </span>
        </ng-template>
      </p-multiSelect>
    </span>
    <div class="product-filters-modal__horizontal">
      <span>
        <label for="minSubscribers">Mín. Assinantes</label>
        <p-inputNumber
          formControlName="minSubscribers"
          id="minSubscribers"
          name="minSubscribers"
        >
        </p-inputNumber>
      </span>
      <span>
        <label for="maxSubscribers">Máx. assinantes</label>
        <p-inputNumber
          formControlName="maxSubscribers"
          id="maxSubscribers"
          name="maxSubscribers"
        >
        </p-inputNumber>
      </span>
    </div>
    <div class="product-filters-modal__horizontal">
      <span>
        <label for="minStock">Mín. Estoque</label>
        <p-inputNumber formControlName="minStock" id="minStock" name="minStock">
        </p-inputNumber>
      </span>
      <span>
        <label for="maxStock">Máx. Estoque</label>
        <p-inputNumber formControlName="maxStock" id="maxStock" name="maxStock">
        </p-inputNumber>
      </span>
    </div>
    <div class="product-filters-modal__buttons">
      <p-button
        label="Limpar filtros"
        styleClass="p-button-danger"
        type="button"
        [disabled]="
          !filtersGroup.value.categories?.length &&
          !filtersGroup.value.brands?.length &&
          !filtersGroup.value.minStock &&
          !filtersGroup.value.maxStock &&
          !filtersGroup.value.minSubscribers &&
          !filtersGroup.value.maxSubscribers
        "
        (onClick)="resetFilters()"
      ></p-button>
      <p-button
        [label]="buttonLabel"
        styleClass="p-button-primary"
        type="submit"
        [disabled]="!productsInFilters.length"
      ></p-button>
    </div>
  </form>
</div>
