/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DraftSubscriberFilter {
  draftId?: number;
  editionId?: number;
  personTypes?: string;
  isNew?: number;
  isBirthday?: number;
  subscriberslimit?: number;
  partnerType?: number;
  recurrenceMonth?: number;
  recurrence?: number;
  badgeIsNew?: number;
  badgeIds?: string;
  consecutiveEditions?: number;
  referenceBoxIds?: number;
  hasGift?: number;
  paymentDay?: string;
  isNewCycle?: number;
}
