import {
  Component,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  DraftComposition,
  DraftCompositionSubcategoryCheck,
  DraftCompositionSubcategoryRepeatedSubscriber,
  DraftControllerService
} from 'src/app/allocation-api';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';
import { environment } from 'src/environments/environment';
import {
  ActionButtonClickEvent,
  TableActionButton,
  TableColumn
} from '../table/table.component';

@Component({
    selector: 'app-repeated-subcategories-modal',
    templateUrl: './repeated-subcategories-modal.component.html',
    styleUrl: './repeated-subcategories-modal.component.scss',
    standalone: false
})
export class RepeatedSubcategoriesModalComponent {
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  ref: DynamicDialogRef = inject(DynamicDialogRef);
  private draftService: DraftControllerService = inject(DraftControllerService);

  repeated: Array<DraftCompositionSubcategoryCheck> = this.config.data.repeated;
  draftComposition: DraftComposition = this.config.data.draftComposition;
  cols = [
    new TableColumn(
      'Categoria',
      'subcategory',
      true,
      'text',
      environment.adminUrl + '/products/categories/',
      'subcategoryId',
      true,
      'contains'
    ),
    new TableColumn(
      'Assinantes',
      'count',
      true,
      'number',
      undefined,
      undefined,
      true,
      'gte'
    ),
    new TableColumn('Ação', '', false, 'button')
  ];
  tableActionButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'showSubscribers',
      PrimeIcons.EYE,
      () => true,
      '',
      'Ver assinantes',
      'bottom',
      true,
      true,
      'primary',
      'small'
    )
  ];
  subscriberCols = [
    new TableColumn(
      'GlamId',
      'boxId',
      true,
      'number',
      environment.adminUrl + '/users/subscribers/',
      'subscriberId',
      true,
      'contains'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      environment.adminUrl + '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'SKU Admin',
      'productVariantId',
      true,
      'text',
      environment.adminUrl + '/products/catalog/product-variant/',
      'productVariantId'
    ),
    new TableColumn(
      'Edição',
      'edition',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn(
      'Vínculo',
      'productType',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    )
  ];
  subscribers: Array<DraftCompositionSubcategoryRepeatedSubscriber> | undefined;
  selected: WritableSignal<DraftCompositionSubcategoryCheck | undefined> =
    signal(undefined);
  selectedSubscribers = computed(() => {
    return this.selected()
      ? this.subscribers?.filter(
          (s) => s.subcategoryId === this.selected()?.subcategoryId
        ) || []
      : undefined;
  });

  async showSubscribers($event: ActionButtonClickEvent): Promise<void> {
    if (!this.subscribers) {
      await this.findSubscribers();
    }
    this.selected.set($event.item);
  }

  async findSubscribers(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.subscribers = await lastValueFrom(
        this.draftService
          .getSubcategoryRepeatedSubscribersLastMonth(
            this.draftComposition.draftCompositionId as number
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
    LoaderService.showLoader(false);
  }
}
