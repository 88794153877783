export * from './adminController.service';
export * from './draftCompositionFilterController.service';
export * from './draftController.service';
export * from './editionController.service';
export * from './notificationsController.service';
export * from './openController.service';
export * from './personController.service';
export * from './productController.service';
export * from './subscriptionController.service';
export * from './syncronizationController.service';
export * from './userController.service';
import { AdminControllerService } from './adminController.service';
import { DraftCompositionFilterControllerService } from './draftCompositionFilterController.service';
import { DraftControllerService } from './draftController.service';
import { EditionControllerService } from './editionController.service';
import { NotificationsControllerService } from './notificationsController.service';
import { OpenControllerService } from './openController.service';
import { PersonControllerService } from './personController.service';
import { ProductControllerService } from './productController.service';
import { SubscriptionControllerService } from './subscriptionController.service';
import { SyncronizationControllerService } from './syncronizationController.service';
import { UserControllerService } from './userController.service';
export const APIS = [
  AdminControllerService,
  DraftCompositionFilterControllerService,
  DraftControllerService,
  EditionControllerService,
  NotificationsControllerService,
  OpenControllerService,
  PersonControllerService,
  ProductControllerService,
  SubscriptionControllerService,
  SyncronizationControllerService,
  UserControllerService
];
