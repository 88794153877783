import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserRoleViewResponse } from 'src/app/allocation-api';

@Component({
  selector: 'app-password-update-modal',
  templateUrl: './password-update-modal.component.html',
  styleUrls: ['./password-update-modal.component.scss']
})
export class PasswordUpdateModalComponent {
  user: (UserRoleViewResponse & { role: string }) | undefined;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.user = this.config.data?.user;
  }
}
