import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import {
  Draft,
  DraftCompositionProductReserved,
  DraftCompositionSummary,
  DraftControllerService,
  DraftDefaultProductView,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { getEquityLabel } from 'src/app/models/enums/BrandEquity';
import { CompositionSubscribersModalComponent } from '../../draft-summary/composition-subscribers-modal/composition-subscribers-modal.component';

@Component({
  selector: 'app-draft-compositions',
  templateUrl: './draft-compositions.component.html',
  styleUrls: ['./draft-compositions.component.scss'],
  standalone: false
})
export class DraftCompositionsComponent {
  @Input()
  draftCompositionProducts: Array<DraftCompositionProductReserved> = [];

  @Input()
  draftComposition?: DraftCompositionSummary;

  @Input()
  draftCompositions: Array<DraftCompositionSummary> | undefined;

  @Input()
  draft: Draft | undefined;

  @Input()
  user: UserRoleViewResponse | undefined;

  @Input()
  defaultProducts: Array<DraftDefaultProductView> | undefined;

  @Output()
  addNew = new EventEmitter<void>();

  @Output()
  showComposition = new EventEmitter<DraftCompositionSummary>();

  @Output()
  viewPartition = new EventEmitter<DraftCompositionSummary>();

  @Output()
  convertToComposition = new EventEmitter<DraftCompositionSummary>();

  @Output()
  deleteComposition = new EventEmitter<DraftCompositionSummary>();

  @Output()
  showCompositionSubscribers = new EventEmitter<DraftCompositionSummary>();

  @Output()
  submitPage = new EventEmitter<void>();

  @Output()
  changeName = new EventEmitter<DraftCompositionSummary>();

  constructor(
    private draftService: DraftControllerService,
    private dialog: DialogService
  ) {}

  isSelected(index: number): boolean {
    return (
      this.draftComposition !== undefined &&
      this.draftCompositions?.indexOf(this.draftComposition) === index
    );
  }

  isPartition(composition: DraftCompositionSummary) {
    return (
      composition.draftCompositionStatus &&
      composition.compositionSubscribers &&
      !composition.compositionProducts
    );
  }

  brandEquities(
    composition: DraftCompositionSummary
  ): Array<{ quantity: number; brandEquity: number }> | null {
    if (composition.brandEquity?.length && composition.brandEquity !== '0') {
      const equities = composition.brandEquity
        .split(',')
        .map((e) => ({ quantity: 1, brandEquity: Number(e) }));
      const grouped: Array<{ quantity: number; brandEquity: number }> = [];
      equities.forEach((e) => {
        const idx = grouped.findIndex((eq) => eq.brandEquity === e.brandEquity);
        if (idx >= 0) {
          grouped[idx].quantity++;
        } else {
          grouped.push(e);
        }
      });
      return grouped;
    }
    return null;
  }

  equityLabel(equity: number): string {
    return equity + ' ' + getEquityLabel(equity);
  }

  async showDraftSubscribers(): Promise<void> {
    this.dialog.open(CompositionSubscribersModalComponent, {
      data: {
        draftCompositionId: this.draftComposition?.draftCompositionId
      },
      modal: true,
      header: 'Assinantes do draft ' + this.draft?.name,
      width: '80vw'
    });
  }

  get subscribersToAlocate(): number {
    return (
      (this.draftComposition?.draftTotalSubscribers || 0) -
      this.totalSignersInCompositions
    );
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }

  get totalSignersInCompositions(): number {
    if (this.draftCompositions) {
      return this.draftCompositions
        .filter((c) => c.compositionProducts)
        .reduce((sum, c) => (sum += c.compositionSubscribers || 0), 0);
    } else return 0;
  }

  get totalSignersInPartitions(): number {
    if (this.draftCompositions) {
      return this.draftCompositions
        .filter((c) => !c.compositionProducts)
        .reduce((sum, c) => (sum += c.compositionSubscribers || 0), 0);
    } else return 0;
  }

  get totalSubscribers(): number {
    if (this.draftCompositions) {
      return this.draftCompositions[0]?.draftTotalSubscribers || 0;
    } else return 0;
  }

  get currentCompositionInvalid(): boolean {
    return (
      this.draftComposition?.draftCompositionName?.trim().length === 0 ||
      (this.draftCompositionProducts.length > 0 &&
        this.draftCompositionProducts.every((cp) =>
          this.defaultProducts?.some(
            (dp) => dp.productVariantId === cp.productVariantId
          )
        ))
    );
  }

  get problems() {
    return this.draftCompositions?.filter((c) => c.message)?.length || 0;
  }
}
