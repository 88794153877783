import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeDTO,
  BeautyProfileAttribute,
  DraftCompositionProductReserved,
  DraftCompositionSummary,
  DraftControllerService,
  DraftProductFilter,
  DraftSubscriberStateCities,
  FilterDTO,
  FilterTypeDTO,
  Group,
  Option,
  PersonControllerService,
  ProductCategory,
  ProductControllerService,
  ProductVariantBrand
} from 'src/app/allocation-api';
import { TableColumn } from 'src/app/components/table/table.component';
import { getEquityLabel } from 'src/app/models/enums/BrandEquity';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';
import { CompositionSubscribersModalComponent } from '../../draft-summary/composition-subscribers-modal/composition-subscribers-modal.component';
import { DraftCompositionProductFilterDTO } from '../draft-composition-filters-modal/draft-composition-filters-modal.component';

@Component({
  selector: 'app-composition-summary-modal',
  templateUrl: './composition-summary-modal.component.html',
  styleUrls: ['./composition-summary-modal.component.scss']
})
export class CompositionSummaryModalComponent implements OnInit {
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  public ref: DynamicDialogRef = inject(DynamicDialogRef);
  private draftService: DraftControllerService = inject(DraftControllerService);
  private productService: ProductControllerService = inject(
    ProductControllerService
  );
  private personService: PersonControllerService = inject(
    PersonControllerService
  );
  private dialog: DialogService = inject(DialogService);

  products: Array<ProductFilterDTO> = [];
  compositionSummary?: WritableSignal<DraftCompositionSummary> = signal(
    this.config.data.draftComposition
  );
  filters: WritableSignal<Array<FilterDTO> | undefined> = signal(undefined);
  beautyProfile: WritableSignal<Array<Group> | undefined> = signal(undefined);
  badges?: Array<BadgeDTO> = [];
  filterTypes: Array<FilterTypeDTO> | undefined;
  categories: Array<ProductCategory> | undefined;
  categoriesList: Array<ProductCategory> | undefined;
  states: Array<DraftSubscriberStateCities> | undefined;
  allProducts: Array<ProductVariantBrand> | undefined;
  productVariants: WritableSignal<Array<ProductVariantBrand> | undefined> =
    signal([]);
  allBadges: Array<BadgeDTO> | undefined;
  beautyProfileQuestions = computed(() => {
    return this.beautyProfile()?.reduce(
      (questions: Array<BeautyProfileAttribute>, group) => {
        questions = questions
          .concat(group.attributes || [])
          .concat(
            group.attributeGroups?.reduce(
              (atts: Array<BeautyProfileAttribute>, g) =>
                (atts = atts.concat(g.attributes || [])),
              []
            ) || []
          );
        return questions;
      },
      []
    );
  });
  beautyProfileOptions = computed(() => {
    return this.beautyProfileQuestions()?.reduce(
      (options: Array<Option>, question) =>
        options.concat(question.options || []),
      []
    );
  });
  allFilterTypes = computed(() =>
    this.filters()?.reduce(
      (types: { [filter: string]: Array<FilterTypeDTO> }, filter) => {
        if (filter.name && !types[filter.name])
          types[filter.name] = filter.filterTypes || [];
        else if (filter.name && types[filter.name])
          types[filter.name] = types[filter.name].concat(
            filter.filterTypes || []
          );
        return types;
      },
      {}
    )
  );
  productCols = [
    new TableColumn('CEAN', 'internalEAN', false, 'text'),
    new TableColumn('Produto', 'productVariantName', false, 'text'),
    new TableColumn('Marca', 'brandName', false, 'text'),
    new TableColumn('BrandEquity', 'brandEquity', false, 'number'),
    new TableColumn('Categoria', 'category', false, 'text'),
    new TableColumn('Preço de custo', 'costPrice', false, 'currency'),
    new TableColumn('Preço de mercado', 'salePrice', false, 'currency'),
    new TableColumn('Peso (g)', 'weight', false, 'formattedNumber')
  ];
  cols = [
    new TableColumn('Filtro', 'filter', false, 'text'),
    new TableColumn('Condição', 'condition', false, 'text'),
    new TableColumn('Atributo', 'question', false, 'text'),
    new TableColumn('Valor', 'value', false, 'number'),
    new TableColumn('Período', 'period', false, 'text')
  ];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.isSummaryCombo)
      this.productCols.push(
        new TableColumn('Montagem', 'subscriptionName', false, 'text')
      );
    await Promise.all([
      this.findBadgesList(),
      this.getBeautyProfile(),
      this.getCategories(),
      this.getFiltersGrouped(),
      this.findCompositionProducts()
    ]);

    LoaderService.showLoader(false);
  }

  async findBadgesList(): Promise<void> {
    try {
      this.allBadges = await lastValueFrom(
        this.personService.getBadgesList().pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findCompositionProducts(): Promise<void> {
    try {
      this.products = await lastValueFrom(
        this.draftService
          .getCompositionProducts(this.compositionSummary().draftCompositionId)
          .pipe(
            map((data) =>
              data.result.map((p) => ({
                ...p,
                subscriptionName: this.subscriptionNameToSendComposition(
                  p.subscriptionId
                )
              }))
            )
          )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getCategories(): Promise<void> {
    try {
      this.categories = await lastValueFrom(
        this.productService.getCategories().pipe(map((data) => data.result))
      );
      const noParent = this.categories?.filter((c) => !c.parentId);
      noParent.forEach((p) => {
        p.children = this.categories?.filter(
          (c) => c.parentId && c.parentId === p.categoryId
        );
        p.children?.sort(
          (c1, c2) => (c1.order as number) - (c2.order as number)
        );
      });
      this.categoriesList = noParent;
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getBeautyProfile(): Promise<void> {
    try {
      this.beautyProfile.set(
        await lastValueFrom(
          this.personService.getBeautyProfile().pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async getFiltersGrouped(): Promise<void> {
    try {
      this.filters.set(
        await lastValueFrom(
          this.productService
            .getFiltersGrouped()
            .pipe(map((data) => data.result))
        )
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findProductsInFilters(ids: Array<number>): Promise<void> {
    try {
      const result = await lastValueFrom(
        this.productService.findAll(ids).pipe(map((data) => data.result))
      );
      this.productVariants.update((variants) => variants.concat(result));
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  // async ngOnInit(): Promise<void> {
  //   this.allProducts = this.config.data.allProducts;
  //   this.products = this.config.data.products;
  //   this.compositionSummary = this.config.data.draftComposition;
  //   this.filters = this.config.data.filters;
  //   this.filterTypes = this.config.data.filterTypes;
  //   this.badges = this.config.data.badges;
  //   this.states = this.config.data.states;
  //   this.beautyProfileGroup = this.config.data.beautyProfileGroup;
  //   this.categories = this.config.data.categories;
  //   this.beautyProfileGroup?.forEach((g) => {
  //     if (g.attributes?.length) {
  //       this.beautyProfileQuestions = this.beautyProfileQuestions.concat(
  //         g.attributes
  //       );
  //       g.attributes.forEach((a) => {
  //         if (a.options?.length) {
  //           this.beautyProfileOptions = this.beautyProfileOptions.concat(
  //             a.options
  //           );
  //         }
  //       });
  //     }
  //   });
  // }

  equityLabel(equity: number): string {
    return equity + ' ' + getEquityLabel(equity);
  }

  brandEquities(
    composition: DraftCompositionSummary
  ): Array<{ quantity: number; brandEquity: number }> | null {
    if (composition.brandEquity?.length && composition.brandEquity !== '0') {
      const equities = composition.brandEquity
        .split(',')
        .map((e) => ({ quantity: 1, brandEquity: Number(e) }));
      const grouped: Array<{ quantity: number; brandEquity: number }> = [];
      equities.forEach((e) => {
        const idx = grouped.findIndex((eq) => eq.brandEquity === e.brandEquity);
        if (idx >= 0) {
          grouped[idx].quantity++;
        } else {
          grouped.push(e);
        }
      });
      return grouped;
    }
    return null;
  }

  async toggleFilters(product: ProductFilterDTO): Promise<void> {
    if (!product.filters?.length) {
      LoaderService.showLoader();
      product.draftProductfilters =
        (await lastValueFrom(
          this.productService
            .getProductFilters(
              product.productVariantId as number,
              this.compositionSummary()?.draftId as number
            )
            .pipe(map((data) => data.result))
        )) || [];
      await this.findProductsInFilters(
        product.draftProductfilters
          .filter((p) => [12, 13].includes(p.filterId))
          .reduce(
            (values, p) =>
              values.concat(
                p.value
                  .split(',')
                  .filter((v) => v !== product.productVariantId.toString())
                  .map((v) => Number(v))
              ),
            []
          )
          .concat([product.productVariantId])
      );
      product.filters = (
        product.draftProductfilters.some((f) => f.filterId === 0)
          ? []
          : [
              {
                filter: 'Variante',
                condition: 'Não recebeu',
                question: '-',
                period: '-',
                value: this.findValue(13, product.productVariantId.toString()),
                filterId: 13,
                originalValue: product.productVariantId?.toString()
              } as DraftCompositionProductFilterDTO
            ]
      ).concat(
        product.draftProductfilters
          ?.filter(
            (f) =>
              f.filterId !== 13 ||
              f.value !== product.productVariantId?.toString()
          )
          .map((f) => ({
            ...f,
            value: f.value
              .split(',')
              .filter((v) => v !== product.productVariantId.toString())
              .toString()
          }))
          ?.filter((p) => p.filterId > 0)
          .reduce((filters: Array<DraftCompositionProductFilterDTO>, p) => {
            filters = filters.concat(
              p.value.split(',').map((v) => ({
                filter: this.findFilter(p.filterId).name,
                condition: this.allFilterTypes()[
                  this.findFilter(p.filterId).name
                ].find((ft) => ft.filterId === p.filterId).name,
                value: this.findValue(p.filterId, v),
                valueRange: p.valueRange,
                question: [1, 2, 17].includes(p?.filterId)
                  ? this.beautyProfileQuestions()?.find((q) =>
                      q.options.some((o) => o.id === Number(v))
                    ).name
                  : '-',
                period: this.valueRangeLabel(p.valueRange),
                filterId: p.filterId,
                originalValue: v
              }))
            );
            return filters;
          }, [])
      );
      LoaderService.showLoader(false);
    }
  }

  async showCompositionSubscribers(): Promise<void> {
    this.dialog.open(CompositionSubscribersModalComponent, {
      data: {
        draftCompositionId: this.compositionSummary()?.draftCompositionId
      },
      header:
        'Assinantes da composição ' +
        this.compositionSummary()?.draftCompositionName,
      width: '80vw'
    });
  }

  isSummaryCombo(): boolean {
    return this.compositionSummary()?.editionId?.toString()[0] === '7';
  }

  subscriptionNameToSendComposition(subscriptionId: number): string {
    switch (subscriptionId) {
      case 1:
        return 'Glambox';
      case 5:
        return 'Glambag';
      default:
        return 'Não definido';
    }
  }

  findFilter(filterId: number): FilterDTO {
    return this.filters()?.find((f) =>
      f.filterTypes.some((ft) => ft.filterId === filterId)
    );
  }

  findValue(filterId: number, value: string): any {
    switch (filterId) {
      // Perfil de beleza
      case 1:
      case 2:
      case 17:
        return this.beautyProfileOptions()?.find((o) => o.id === Number(value))
          ?.name;
      // Variante
      case 0:
      case 12:
      case 13:
        return this.productVariants()?.find(
          (pv) => pv.productVariantId === Number(value)
        )?.productVariantName;
      // Joia
      case 14:
        return this.allBadges?.find((b) => b.badgeId === Number(value))?.title;
      // Categoria
      case 15:
      case 16:
        return this.categories?.find((c) => c.categoryId === Number(value))
          ?.displayName;
      default:
        return value;
    }
  }

  valueRangeLabel(valueRange): string {
    if (valueRange === null || valueRange === undefined) return '-';
    if (valueRange > 1) return `Últimos ${valueRange} meses`;
    if (valueRange === 1) return `Último mês`;
    if (valueRange <= 0) return `Desde o início`;
  }

  rowBackground = (product: DraftCompositionProductReserved) => {
    if (this.isSummaryCombo)
      switch (product.subscriptionId) {
        case 5:
          return '#8540f54c';
        default:
          return '#fe357b4c';
      }
    return 'auto';
  };
}

export interface ProductFilterDTO extends DraftCompositionProductReserved {
  draftProductfilters?: Array<DraftProductFilter>;
  filters?: Array<DraftCompositionProductFilterDTO>;
}
