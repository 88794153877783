<components-loader *ngIf="loader"></components-loader>
<div class="container">
  <p-sidebar
    [visible]="true"
    [style]="{ background: '#1c1a1b' }"
    styleClass="sidebar"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
    [dismissible]="false"
    [modal]="false"
    transitionOptions="0ms"
  >
    <div class="logo">
      <img src="assets/Logo_B4A_White.png" />
    </div>
    <ul class="menu">
      <li *ngFor="let menu of menuOptions">
        <a type="button" [routerLink]="menu.path">{{ menu.title }}</a>
      </li>
      <li *ngIf="userLogged">
        <a type="button" (click)="logout()">Logout</a>
      </li>
    </ul>
  </p-sidebar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<p-toast></p-toast>
