import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { LoaderService } from '../loader.service';
import { TokenStorageService } from './token-storage.service';

export class HttpClientInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((err: HttpErrorResponse) => this.handleAuthError(err)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (err.status === 401 || err.status === 403) {
      if (!err.url.includes('/login')) {
        TokenStorageService.signOut();
        window.location.reload();
        LoaderService.showLoader(false);
        throw { error: { message: 'Usuário sem permissão de acesso' } };
      } else {
        throw { error: { message: 'Email ou senha inválidos.' } };
      }
    }
    throw err;
  }
}
