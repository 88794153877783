import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PasswordUpdateModule } from 'src/app/components/password-update/password-update.module';
import { UserFormModule } from 'src/app/components/user-form/user-form.module';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    UserFormModule,
    PasswordUpdateModule
  ]
})
export class SettingsModule {}
