import { Injectable } from '@angular/core';
import SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import Stomp from 'stompjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private stompClient;
  private topic = '/statusProcessor';
  private onMessage: Function = this.logMessage;

  private logMessage(message: any) {
    console.log('[Websocket new message]:', message);
  }

  public connect(onMessage?: Function) {
    console.log('Starting a WebSocket connection');
    this.onMessage = onMessage || this.logMessage;
    const ws = new SockJS(environment.websocketUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.connect(
      {},
      (frame) => {
        this.stompClient.subscribe(this.topic, (event: { body: string }) => {
          this.onMessage(JSON.parse(event.body));
        });
      },
      this.onError.bind(this)
    );
  }

  private onError(error) {
    console.log('Error while connect: ' + error);
    setTimeout(() => {
      console.log('Trying to connect again...');
      this.connect(this.onError);
    }, 3000);
  }
}
