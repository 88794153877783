<p-confirmPopup />
<div>
  <div
    *ngIf="isBirthday()"
    style="display: flex; align-items: center; justify-content: space-evenly"
  >
    <img
      src="https://i.pinimg.com/originals/1d/52/5d/1d525d9bf737ce5cae9ced3e2677a5e6.gif"
      height="150px"
      width="auto"
    /><img
      src="https://lh3.googleusercontent.com/a-/ALV-UjXnlEQWFL3lxdDhTei8BAYO9kHCcMADSoCeEjr7fC8BzbtN-w=s80-p-k-rw-no"
      height="85px"
      width="auto"
      style="border-radius: 50%; border: 3px solid silver"
    /><img
      src="https://cliply.co/wp-content/uploads/2021/09/CLIPLY_372109170_FREE_FIREWORKS_400.gif"
      height="150px"
      width="auto"
    />
  </div>
  <h2>Drafts</h2>
  <p-table
    styleClass="p-datatable-sm"
    *ngIf="user"
    [value]="drafts()"
    [paginator]="true"
    [rows]="pageRequest?.pageSize || 7"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} do total de {totalRecords} Drafts"
    [rowsPerPageOptions]="[7, 10, 25, 50]"
    [rowHover]="true"
    [lazy]="true"
    [totalRecords]="pageDraftView?.totalElements || 0"
    (onLazyLoad)="pageDrafts($event)"
    sortField="datecreated"
    [sortOrder]="1"
  >
    <ng-template pTemplate="caption">
      <div class="table-title">
        <span class="p-float-label">
          <p-dropdown
            [autoDisplayFirst]="false"
            [options]="[
              { label: 'Em aberto', value: 'Open' },
              { label: 'Concluídos', value: 'Close' },
              { label: 'Antigos', value: 'Legacy' }
            ]"
            [(ngModel)]="status"
            (onChange)="changeStatus()"
            inputId="status"
          >
          </p-dropdown>
          <label for="status">Status</label>
        </span>

        <button
          pButton
          type="buttom"
          class="p-button-secondary p-button-rounded"
          label="Criar Draft"
          routerLink="form/new"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="subscriptionname" class="text-center">
          Assinatura
          <p-sortIcon field="subscriptionname"></p-sortIcon>
        </th>
        <th pSortableColumn="editionid" class="text-center">
          Edição
          <p-sortIcon field="editionid"></p-sortIcon>
        </th>
        <th pSortableColumn="datecreated" class="text-center">
          Data de criação
          <p-sortIcon field="datecreated"></p-sortIcon>
        </th>
        <th pSortableColumn="username">
          Responsável
          <p-sortIcon field="username"></p-sortIcon>
        </th>
        <th pSortableColumn="subscribers" class="text-center">
          Assinantes
          <p-sortIcon field="subscribers"></p-sortIcon>
        </th>
        <th pSortableColumn="compositions" class="text-center">
          Composições
          <p-sortIcon field="compositions"></p-sortIcon>
        </th>
        <th class="text-center">Ações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-draft>
      <tr
        [class.pending-review]="
          open &&
          pendingReview(draft) &&
          (user?.username === draft.owner || user?.username === draft.username)
        "
        [class.problem]="
          open &&
          draft.problems &&
          (user?.username === draft.owner || user?.username === draft.username)
        "
        [class.approved]="
          open &&
          draft.compositions &&
          !draft.review &&
          !draft.problems &&
          draft.approved &&
          (user?.username === draft.owner || user?.username === draft.username)
        "
      >
        <td>{{ draft.name }}</td>
        <td class="text-center">{{ draft.subscriptionName }}</td>
        <td class="text-center">
          {{ editionDate(draft.editionId) | titlecase }}
        </td>
        <td class="text-center">
          {{ draft.dateCreated | date : 'dd/MM/yyyy HH:mm' }}
        </td>
        <td>
          <span *ngIf="draft.username !== draft.owner"
            >{{ draft.owner }} -></span
          >
          {{ draft.username }}
        </td>
        <td class="text-center">
          <ng-container *ngIf="draft.subscribers">
            {{ draft.subscribers }}
          </ng-container>
          <ng-container *ngIf="!draft.subscribers"> Não definido </ng-container>
        </td>
        <td class="text-center">
          {{ draft.compositions || 0 }}
        </td>
        <td class="text-center">
          <ng-container *ngIf="open">
            <a
              type="button"
              [routerLink]="'form/id/' + draft.draftId"
              *ngIf="
                user?.username !== draft.username &&
                user?.username !== draft.owner &&
                !pendingReview(draft) &&
                !draft.problems
              "
              pButton
              class="p-button-rounded p-button-text p-button-sm"
              icon="pi pi-eye"
              pTooltip="Ver draft"
              tooltipPosition="bottom"
            >
            </a>
            <a
              type="button"
              [routerLink]="'summary/id/' + draft.draftId"
              *ngIf="
                (draft.problems && user?.username !== draft.username) ||
                ((pendingReview(draft) || draft.problems) &&
                  user?.username !== draft.username &&
                  user?.username !== draft.owner)
              "
              pButton
              class="p-button-rounded p-button-text p-button-sm"
              icon="pi pi-eye"
              pTooltip="Ver draft"
              tooltipPosition="bottom"
            >
            </a>
            <a
              pButton
              type="button"
              icon="pi pi-file-edit"
              class="p-button-primary p-button-rounded p-button-text"
              [routerLink]="'form/id/' + draft.draftId"
              *ngIf="
                !pendingReview(draft) &&
                !draft.approved &&
                !draft.problems &&
                (user.username === draft.username ||
                  user.username === draft.owner)
              "
              pTooltip="Editar draft"
              tooltipPosition="bottom"
            ></a>
            <a
              pButton
              type="button"
              icon="pi pi-file-edit"
              class="p-button-primary p-button-rounded p-button-text"
              [routerLink]="'summary/id/' + draft.draftId"
              *ngIf="
                (draft.problems || pendingReview(draft)) &&
                user.username === draft.username
              "
              pTooltip="Editar draft"
              tooltipPosition="bottom"
            ></a>
            <a
              pButton
              type="button"
              icon="pi pi-list-check"
              class="p-button-primary p-button-rounded p-button-text"
              [routerLink]="'summary/id/' + draft.draftId"
              *ngIf="pendingReview(draft) && user?.username === draft.owner"
              pTooltip="Revisar composições"
              tooltipPosition="bottom"
            ></a>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-trash"
              class="p-button-danger p-button-rounded p-button-text"
              (click)="confirm($event, draft.draftId)"
              *ngIf="user.username === draft.username && !draft.approved"
              pTooltip="Excluir draft"
              tooltipPosition="bottom"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-verified"
              class="p-button-rounded p-button-text p-button-warning"
              (click)="finish(draft)"
              *ngIf="
                draft?.approved &&
                (user?.username === draft?.username ||
                  user?.username === draft?.owner)
              "
              pTooltip="Concluir draft"
              tooltipPosition="bottom"
            ></button>
          </ng-container>
          <ng-container *ngIf="!open">
            <a
              target="_blank"
              type="button"
              [routerLink]="'summary/id/' + draft.draftId"
              pButton
              class="p-button-rounded p-button-text p-button-sm"
              icon="pi pi-eye"
              pTooltip="Ver draft"
              tooltipPosition="bottom"
            >
            </a>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
