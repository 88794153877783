<form class="horizontal" (submit)="submit()" [formGroup]="userForm">
  <ng-container *ngIf="!dirty">
    <span class="p-float-label form-control">
      <input
        type="text"
        pInputText
        formControlName="displayName"
        name="displayName"
      />
      <label for="displayName">Nome de exibição</label>
    </span>
    <span class="p-float-label form-control">
      <input
        type="text"
        pInputText
        formControlName="username"
        name="username"
      />
      <label for="username">E-mail</label>
    </span>
  </ng-container>
  <ng-container *ngIf="!user">
    <span class="p-float-label form-control">
      <input
        type="password"
        pInputText
        formControlName="password"
        name="password"
      />
      <label for="password">Senha</label>
    </span>
    <span class="p-float-label form-control">
      <input
        type="password"
        pInputText
        formControlName="passwordConfirmation"
        name="passwordConfirmation"
      />
      <label for="passwordConfirmation">Confirmação de senha</label>
    </span>
  </ng-container>
  <ng-container *ngIf="user?.userId && modal">
    <span class="p-float-label form-control">
      <p-dropdown
        [options]="permissions"
        appendTo="body"
        [autoDisplayFirst]="false"
        formControlName="role"
        (onChange)="changeRole($event)"
        inputId="role"
      >
      </p-dropdown>
      <label for="role">Permissão</label>
    </span>
    <small *ngIf="dirty">
      O usuário foi criado com a permissão <b>Usuário</b>, mas você pode
      alterá-la se desejar.
    </small>
  </ng-container>

  <div class="buttons">
    <p-button
      label="Voltar"
      type="button"
      (onClick)="closeModal.emit(dirty)"
      styleClass="p-button-danger"
      *ngIf="!dirty && modal"
    ></p-button>
    <p-button
      [disabled]="!userForm.valid"
      label="Salvar"
      type="submit"
      styleClass="p-button-primary"
      *ngIf="!dirty"
    ></p-button>
    <p-button
      type="button"
      styleClass="p-button-primary"
      label="Está ok!"
      *ngIf="dirty && modal"
      (onClick)="closeModal.emit(dirty)"
    >
    </p-button>
  </div>
</form>
