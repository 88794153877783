/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DraftDefaultProductView {
  draftId?: number;
  productVariantId?: number;
  productVariantName?: string;
  brandName?: string;
  defaultImageURL?: string;
  externalId?: number;
  brandEquity?: number;
  category?: string;
  subcategory?: string;
  costPrice?: number;
  salePrice?: number;
  weight?: number;
  internalEAN?: string;
  subscriptionId?: number;
  stock?: number;
  cean?: string;
}
