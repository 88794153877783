import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import {
  PasswordUpdateRequest,
  UserControllerService,
  UserRoleViewResponse,
  UserView
} from 'src/app/allocation-api';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent implements OnChanges {
  @Input()
  user: UserRoleViewResponse | undefined | UserView;

  @Input()
  modal = false;

  @Output()
  closeModal = new EventEmitter<boolean>();

  passwordForm = new FormGroup({
    oldPassword: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    newPassword: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ]),
    newPasswordConfirmation: new FormControl<string | null>(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20)
    ])
  });

  constructor(private userService: UserControllerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.modal) this.passwordForm.controls['oldPassword'].disable();
    else this.passwordForm.controls['oldPassword'].enable();
  }

  async changePassword(): Promise<void> {
    if (this.passwordForm.valid) {
      LoaderService.showLoader();
      try {
        await lastValueFrom(
          this.userService.updateUserPassword(
            this.user?.userId as number,
            this.passwordForm.value as PasswordUpdateRequest
          )
        );
        ToastUtil.addToast({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Senha atualizada com sucesso'
        });
        this.passwordForm.reset();
        this.closeModal.emit(true);
      } catch (error) {
        ToastUtil.showErrorToast(error);
      }
      LoaderService.showLoader(false);
    }
  }
}
