import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServicesModule } from 'src/app/services/services.module';
import { DraftRoutingModule } from './draft-routing.module';
import { DraftComponent } from './draft.component';

// PrimeNG
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DraftComponent],
  imports: [
    CommonModule,
    DraftRoutingModule,
    ServicesModule,
    TableModule,
    ButtonModule,
    ConfirmPopupModule,
    InputSwitchModule,
    FormsModule,
    DropdownModule,
    TooltipModule
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class DraftModule {}
