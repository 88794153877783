/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';

@Component({
    selector: 'components-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent {}
