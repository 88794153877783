import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import {
  Draft,
  DraftCompositionProductReserved,
  DraftCompositionSummary,
  DraftControllerService,
  ProductVariantAvailable,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { DraftSubscriptionDTO } from 'src/app/allocation-api/model/draftSubscriptionDTO';
import { getEquityLabel } from 'src/app/models/enums/BrandEquity';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input()
  product:
    | (ProductVariantAvailable & {
        subscriptionId: number;
        subscribers?: number;
        totalFilters?: number;
      })
    | undefined;

  @Input()
  compositionProduct: DraftCompositionProductReserved | undefined;

  @Input()
  draftComposition: DraftCompositionSummary | undefined;

  @Input()
  user?: UserRoleViewResponse;

  @Input()
  draft?: Draft;

  @Output()
  clickFilters = new EventEmitter<void>();

  @Input()
  dragging = false;

  @Output()
  clickAction = new EventEmitter<void>();

  @Input()
  showFilters = true;

  @Input()
  changeSubscription = false;

  @Output()
  subscriptionChange = new EventEmitter<number>();

  constructor(private draftService: DraftControllerService) {}

  ngOnInit(): void {
    this.syncData();
    BroadcastUtil.get('detectChanges').subscribe(() => {
      this.syncData();
    });
  }

  syncData(): void {
    if (this.compositionProduct) {
      this.product = {
        ...(this.compositionProduct as ProductVariantAvailable & {
          subscriptionId: number;
        })
      };
    }
  }

  equityLabel(equity: number): string {
    return equity + ' ' + getEquityLabel(equity);
  }

  async setSubscriptionId(): Promise<void> {
    if (this.changeSubscription && !this.compositionProduct) {
      this.subscriptionChange.emit(this.product?.subscriptionId);
      return;
    }
    LoaderService.showLoader();
    try {
      const draftSubscriptionDTO: DraftSubscriptionDTO = {
        draftCompositionId: this.compositionProduct
          ?.draftCompositionId as number,
        productVariantId: this.product?.productVariantId as number,
        subscriptionId: this.product?.subscriptionId as number
      };

      const product = await lastValueFrom(
        this.draftService
          .setSubscriptionIdOnProduct(draftSubscriptionDTO)
          .pipe(map((data) => data.result))
      );
      if (product?.subscriptionId && this.compositionProduct && this.product) {
        this.compositionProduct.subscriptionId = product.subscriptionId;
        this.product.subscriptionId = product.subscriptionId;
      }
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }

    LoaderService.showLoader(false);
  }

  get totalSubscribers(): number | undefined {
    if (
      this.product?.subscribers !== undefined &&
      this.product.subscribers !== null
    ) {
      if (
        this.draftComposition?.subscribersLimit &&
        this.product.subscribers > this.draftComposition.subscribersLimit
      ) {
        return this.draftComposition.subscribersLimit;
      }
      return this.product.subscribers;
    }
    return undefined;
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }

  get isCombo(): boolean {
    return this.draft?.editionId?.toString()[0] === '7';
  }

  get stock(): number {
    let stock =
      (this.product?.stock || 0) - (this.compositionProduct?.reserved || 0);
    if (stock < 0) {
      return 0;
    }
    return stock;
  }
}
