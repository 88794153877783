<form (submit)="changeName()" [formGroup]="formGroup">
  <span class="p-float-label form-control">
    <input
      id="draftCompositionName"
      pInputText
      formControlName="draftCompositionName"
      type="text"
    />
    <label for="draftCompositionName">Nome</label>
  </span>
  <span class="buttons">
    <p-button
      type="button"
      label="Cancelar"
      styleClass="p-button-danger"
      (onClick)="ref.close()"
    ></p-button>
    <p-button
      type="submit"
      label="Salvar"
      [disabled]="!formGroup.valid"
    ></p-button>
  </span>
</form>
