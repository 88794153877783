import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AppTableModule } from '../table/app-table.module';
import { RepeatedSubcategoriesModalComponent } from './repeated-subcategories-modal.component';

@NgModule({
  declarations: [RepeatedSubcategoriesModalComponent],
  imports: [CommonModule, AppTableModule, ButtonModule],
  exports: [RepeatedSubcategoriesModalComponent]
})
export class RepeatedSubcategoriesModalModule {}
