<p-tabView>
  <p-tabPanel header="Período">
    <form class="horizontal" [formGroup]="periodForm">
      <span class="form-control p-float-label">
        <p-calendar
          inputId="dateStart"
          formControlName="dateStart"
          dateFormat="dd/mm/yy"
          appendTo="body"
        ></p-calendar>
        <label for="dateStart">Início</label>
      </span>
      <span class="form-control p-float-label">
        <p-calendar
          inputId="dateEnd"
          formControlName="dateEnd"
          dateFormat="dd/mm/yy"
          appendTo="body"
        ></p-calendar>
        <label for="dateEnd">Fim</label>
      </span>
      <span class="form-button">
        <p-button
          label="Syncronizar"
          [disabled]="!periodForm.valid"
          size="small"
          styleClass="p-button-success"
          (onClick)="ref.close(periodForm.value)"
        ></p-button>
      </span>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Edições" *ngIf="editions && editions.length">
    <form class="horizontal" [formGroup]="editionForm">
      <span class="p-float-label form-control">
        <p-dropdown
          [options]="subscriptions"
          optionLabel="subscriptionName"
          optionValue="subscriptionId"
          formControlName="subscriptionId"
          [autoDisplayFirst]="false"
          id="subscriptionId"
          (onChange)="findEditions()"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-subscription>
            {{ subscription.subscriptionName | titlecase }}
          </ng-template>
          <ng-template pTemplate="selectedItem" let-subscription>
            {{ subscription.subscriptionName | titlecase }}
          </ng-template>
        </p-dropdown>
        <label for="subscriptionId">Produto</label>
      </span>
      <span class="form-control p-float-label">
        <p-multiSelect
          [options]="editions"
          optionValue="editionId"
          optionLabel="theme"
          inputId="editionIds"
          formControlName="editionIds"
          appendTo="body"
        >
          <ng-template pTemplate="item" let-edition>
            {{ edition.theme }} -
            {{ releaseDate(edition) | date : 'MMMM/yyyy' | titlecase }}
          </ng-template>
        </p-multiSelect>
        <label for="editionIds">Edições</label>
      </span>
      <span class="form-button">
        <p-button
          label="Syncronizar"
          [disabled]="!editionForm.valid"
          size="small"
          styleClass="p-button-success"
          (onClick)="ref.close(editionForm.value)"
        ></p-button>
      </span>
    </form>
  </p-tabPanel>
</p-tabView>
