<div class="title">Segmentação do Draft: {{ draft?.name }}</div>
<div class="segmentation">
  <div
    class="segmentation__products"
    pDroppable="compositionProducts"
    (onDrop)="isResponsible && removeProductFromComposition(draggedProduct)"
    *ngIf="user?.username === draft?.responsible && products"
  >
    <div class="segmentation__products__title">
      <span><strong>Produtos para alocação</strong></span
      ><span>&nbsp;({{ products.length | number : '1.0-0' }} produtos)</span>
    </div>
    <div class="filters">
      <p-inputgroup>
        <input
          type="text"
          [(ngModel)]="filterInput"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Buscar por Nome ou CEAN"
          (keyup)="search()"
          pInputText
        />
        <p-inputgroup-addon>
          <p-button
            icon="pi pi-times"
            severity="secondary"
            (onClick)="clearSearch()"
            [disabled]="!filterInput?.trim()"
          />
        </p-inputgroup-addon>
      </p-inputgroup>
      <p-button
        [text]="true"
        (onClick)="filterProducts()"
        pTooltip="Filtrar produtos"
        tooltipPosition="bottom"
        [icon]="filtersCount > 0 ? 'pi pi-filter-fill' : 'pi pi-filter'"
      >
      </p-button>
      <i>
        <span *ngIf="filtersCount" class="badge">{{ filtersCount }}</span>
      </i>
    </div>
    <p-virtualScroller
      #vs
      [items]="products"
      scrollHeight="70vh"
      [itemSize]="250"
      [lazy]="true"
      (onLazyLoad)="pageProducts($event)"
      styleClass="segmentation"
      *ngIf="products?.length"
    >
      <ng-template let-product #item let-i="index">
        <app-product-card
          [user]="user"
          [draft]="draft"
          [dragging]="product === draggedProduct"
          [product]="product"
          pDraggable="products"
          (onDragStart)="dragStart(product)"
          (onDragEnd)="dragEnd()"
          (clickFilters)="changeFilters(product)"
          (clickAction)="addProduct(product)"
          class="product-cards"
          [draftComposition]="draftComposition"
        ></app-product-card>
      </ng-template>
      <ng-template let-product #loader>
        <app-product-card [product]="product"></app-product-card>
      </ng-template>
    </p-virtualScroller>
    <p class="no-products" *ngIf="products.length === 0">
      Não encontramos nenhum produto <strong>em estoque</strong> com o filtro
      informado
    </p>
  </div>
  <div
    class="segmentation__composition"
    *ngIf="draftComposition"
    pDroppable="products"
    (onDrop)="addProduct(draggedProduct)"
  >
    <div class="segmentation__subtitle">
      <strong>Composição atual</strong>
      <span>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-server"
          class="p-button-rounded p-button-text"
          pTooltip="Criar partição"
          tooltipPosition="bottom"
          *ngIf="
            !draftComposition.message &&
            isResponsible &&
            totalSignersInCompositions < totalSubscribers
          "
          (click)="createPartition()"
        ></button>
      </span>
    </div>
    <ng-container *ngIf="draftComposition.message">
      <span class="problem">
        <p>* {{ draftComposition.message }} ({{ draft?.owner }})</p>
        <p-button
          icon="pi pi-check-square"
          pTooltip="Marcar como resolvido"
          tooltipPosition="bottom"
          severity="success"
          [text]="true"
          [rounded]="true"
          size="small"
          (click)="resolveProblems(draftComposition)"
        ></p-button>
      </span>
      <br />
    </ng-container>
    <br />
    <form
      class="segmentation__composition__form horizontal"
      [formGroup]="compositionForm"
    >
      <p-floatlabel class="form-control">
        <input
          type="text"
          pInputText
          (blur)="updateComposition()"
          name="draftCompositionName"
          id="draftCompositionName"
          formControlName="draftCompositionName"
        />
        <label for="draftCompositionName">Nome</label>
      </p-floatlabel>
      <p-floatlabel class="form-control">
        <p-inputNumber
          type="text"
          formControlName="subscribersLimit"
          (onBlur)="updateComposition()"
          name="subscribersLimit"
          id="subscribersLimit"
          placeholder="0 = Sem limite"
        ></p-inputNumber>
        <label for="subscribersLimit">Limite</label>
      </p-floatlabel>
    </form>
    <div class="segmentation__composition__products">
      <div
        class="segmentation__composition__products__product"
        pDraggable="compositionProducts"
        (onDragStart)="isResponsible && dragStart(product)"
        (onDragEnd)="dragEnd()"
        *ngFor="let product of draftCompositionProducts"
      >
        <app-product-card
          [dragging]="isResponsible && product === draggedProduct"
          [compositionProduct]="product"
          (clickAction)="removeProductFromComposition(product)"
          (clickFilters)="showProductFilters(product)"
          [user]="user"
          [draft]="draft"
        ></app-product-card>
      </div>
    </div>
    <div class="segmentation__composition__divider"></div>
    <div class="segmentation__composition__footer">
      <div>Valor mercado: {{ salePrice | currency : 'BRL' }}</div>
      <div>Valor custo: {{ costPrice | currency : 'BRL' }}</div>
      <div>
        Produtos: {{ draftCompositionProducts.length }}&nbsp;&nbsp;&nbsp;Peso:
        {{ weight }}g
      </div>
      <div>
        Total de assinantes:
        <ng-container *ngIf="draftCompositionFilters?.length">
          &nbsp;
          <i
            class="pi pi-exclamation-triangle filter-warning"
            (click)="viewPartition(draftComposition)"
            pTooltip="Ver filtros de partição"
            tooltipPosition="bottom"
          ></i>
          &nbsp;
        </ng-container>
        <a
          [class.admin-link]="draftComposition.compositionSubscribers"
          type="button"
          (click)="
            draftComposition.compositionSubscribers &&
              showCompositionSubscribers(draftComposition)
          "
          pTooltip="Ver assinantes"
          tooltipPosition="bottom"
        >
          {{ draftComposition.compositionSubscribers | number : '1.0-0' }}</a
        >
        / {{ draftComposition.draftSubscribersLeft | number : '1.0-0' }}
      </div>
    </div>
  </div>

  <app-draft-compositions
    [draft]="draft"
    [draftComposition]="draftComposition"
    [draftCompositionProducts]="draftCompositionProducts"
    [draftCompositions]="draftCompositions"
    [defaultProducts]="defaultProducts"
    [user]="user"
    (addNew)="addNewComposition()"
    (convertToComposition)="convertToComposition($event)"
    (deleteComposition)="deleteCompositionDialog($event)"
    (showComposition)="showComposition($event)"
    (showCompositionSubscribers)="showCompositionSubscribers($event)"
    (submitPage)="submit()"
    (viewPartition)="viewPartition($event)"
    (changeName)="changeCompositionName($event)"
    *ngIf="draft"
  ></app-draft-compositions>
</div>
<p-confirmDialog
  icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
