import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-review-reject-modal',
  templateUrl: './review-reject-modal.component.html',
  styleUrl: './review-reject-modal.component.scss'
})
export class ReviewRejectModalComponent {
  ref = inject(DynamicDialogRef);
  composition = inject(DynamicDialogConfig).data.composition;

  review = new FormGroup({
    message: new FormControl<string>(null, [
      Validators.required,
      Validators.max(1000)
    ])
  });
}
