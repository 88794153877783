<ng-container *ngIf="(captionTemplate || changeColumns) && showTitle">
  <h3 *ngIf="!tableTitle">{{ modelName | titlecase }}</h3>
  <h3 *ngIf="tableTitle">{{ tableTitle }}</h3>
</ng-container>
<p-table
  #dt1
  styleClass="p-datatable-sm p-datatable-striped app-table"
  *ngIf="tableContent && cols && sortField"
  [value]="tableContent"
  [paginator]="paginator && tableContent.length > 0"
  [rows]="pageRequest?.pageSize || defaultRows"
  [showCurrentPageReport]="!isMobile"
  currentPageReportTemplate="Mostrando de {first} a {last} do total de {totalRecords} {{
    modelName | lowercase
  }}"
  paginatorDropdownAppendTo="body"
  [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100, 1000]"
  [rowHover]="tableContent.length > 0"
  [lazy]="lazy"
  [totalRecords]="pageContent?.totalElements || 0"
  (onLazyLoad)="pageTable !== undefined && pageTable($event)"
  [sortField]="sortField || ''"
  [sortOrder]="sortOrder || 0"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [columns]="colsToDisplay"
  [autoLayout]="true"
  responsiveLayout="stack"
  [pageLinks]="isMobile ? 3 : 5"
  [showFirstLastIcon]="!isMobile"
  selectionMode="multiple"
  [(selection)]="selectedItems"
  sortMode="single"
  [customSort]="true"
  (sortFunction)="tableSort($event)"
  [dataKey]="dataKey"
  [rowSelectable]="rowSelectable"
  (onRowExpand)="rowExpand.emit({$event, service})"
  (onPage)="onPage($event)"
  [filters]="filters()"
>
  <ng-template pTemplate="caption" *ngIf="showHeader">
    <ng-container *ngIf="!captionTemplate && !changeColumns && showTitle">
      <h3 *ngIf="!tableTitle">{{ modelName | titlecase }}</h3>
      <h3 *ngIf="tableTitle">{{ tableTitle }}</h3>
    </ng-container>
    <form
      class="header"
      [formGroup]="columnsForm"
      *ngIf="changeColumns && !captionTemplate"
    >
      <span class="p-float-label">
        <p-multiSelect
          emptyMessage="Nenhuma"
          id="columns"
          [options]="changebleColumns"
          formControlName="columns"
          (onPanelHide)="
            updateTotalCols($event, multiselect) && reloadColumns()
          "
          class="input"
          optionLabel="header"
          [maxSelectedLabels]="0"
          (onChange)="updateTotalCols($event, multiselect)"
          [selectedItemsLabel]="columnsLabel"
          emptyMessage=""
          appendTo="body"
          #multiselect
        >
          <ng-template></ng-template>
        </p-multiSelect>
        <label for="columns">Colunas para exibição</label>
      </span>
    </form>
    <ng-container
      [ngTemplateOutlet]="captionTemplate"
      *ngIf="captionTemplate"
    ></ng-container>
    <div class="buttons">
      <p-button
        type="button"
        pRipple
        (onClick)="emitSelectionList()"
        [label]="selectionActionLabel"
        *ngIf="selectedItems?.length"
        [pTooltip]="selectionActionTooltip"
        tooltipPosition="bottom"
        [styleClass]="selectionActionClass"
        [icon]="selectionActionIcon"
      >
      </p-button>
      <p-button
        type="button"
        pRipple
        (onClick)="opxls.toggle($event)"
        icon="pi pi-file-excel"
        [disabled]="tableContent.length === 0"
        styleClass="mr-2"
        severity="success"
        pTooltip="Exportar XLSX"
        tooltipPosition="bottom"
        *ngIf="exportExcel"
      >
      </p-button>
      <p-button
        type="button"
        pRipple
        icon="pi pi-file-pdf"
        (onClick)="oppdf.toggle($event)"
        styleClass="mr-2"
        severity="danger"
        [disabled]="tableContent.length === 0"
        pTooltip="Exportar PDF"
        tooltipPosition="bottom"
        *ngIf="exportPdf"
      ></p-button>
      <p-button
        type="button"
        pRipple
        [icon]="addButtonIcon"
        [label]="addLabel"
        [routerLink]="addButtonRouterLink"
        styleClass="mr-2"
        severity="primary"
        *ngIf="addButton && !addButtonAction"
      ></p-button>
      <p-button
        type="button"
        pRipple
        [icon]="addButtonIcon"
        [label]="addLabel"
        (onClick)="addButtonClick.emit()"
        styleClass="mr-2"
        severity="primary"
        *ngIf="addButton && addButtonAction"
      ></p-button>
      <p-button
        type="button"
        pRipple
        icon="pi pi-refresh"
        (onClick)="refresh()"
        styleClass="p-button-help mr-2"
        *ngIf="refreshButton"
      ></p-button>
      <p-overlayPanel #oppdf styleClass="table-overlay-panel">
        <ng-template pTemplate>
          <p-button
            type="button"
            icon="pi pi-file-pdf"
            label="Tabela"
            (onClick)="exportTable(true, 'pdf')"
            pRipple
            styleClass="mr-2"
            severity="danger"
            pTooltip="Exportar todas as páginas como PDF"
            tooltipPosition="left"
          ></p-button>
          <p-button
            type="Página"
            icon="pi pi-file-pdf"
            pRipple
            label="Página"
            (onClick)="exportTable(false, 'pdf')"
            styleClass="mr-2"
            severity="danger"
            pTooltip="Exportar página atual como PDF"
            tooltipPosition="left"
          ></p-button>
        </ng-template>
      </p-overlayPanel>
      <p-overlayPanel #opxls styleClass="table-overlay-panel">
        <ng-template pTemplate>
          <p-button
            type="button"
            pRipple
            label="Tabela"
            icon="pi pi-file-excel"
            (onClick)="exportTable(true, 'xls')"
            styleClass="mr-2"
            severity="success"
            pTooltip="Exportar todas as páginas para Excel"
            tooltipPosition="left"
          ></p-button>
          <p-button
            type="Página"
            pRipple
            icon="pi pi-file-excel"
            label="Página"
            (onClick)="exportTable(false, 'xls')"
            styleClass="mr-2"
            severity="success"
            pTooltip="Exportar página atual para Excel"
            tooltipPosition="left"
          ></p-button>
        </ng-template>
      </p-overlayPanel>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <ng-container
      [ngTemplateOutlet]="preHeaderTemplate"
      *ngIf="preHeaderTemplate"
    ></ng-container>
    <tr>
      <th *ngIf="dataKey && expandField && expansionCols"></th>
      <th
        *ngFor="let col of columns"
        pReorderableColumn
        pResizableColumn
        [pSortableColumn]="col.field"
        [style]="col.field === 'checkbox' ? 'width: 4rem' : ''"
        [ngStyle]="col.ngStyle"
      >
        <ng-container *ngIf="col.type !== 'checkbox'">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </ng-container>
        <ng-container *ngIf="col.type === 'checkbox' && selectAll">
          <p-tableHeaderCheckbox
            id="header-checkbox"
            style="height: 0; display: block"
          ></p-tableHeaderCheckbox>
          <p-checkbox
            id="visible-checkbox"
            [binary]="true"
            [(ngModel)]="checkboxAll"
            (onChange)="toggleAllAction($event)"
          ></p-checkbox>
        </ng-container>
      </th>
    </tr>
    <tr *ngIf="searchForm && showFilters">
      <th *ngIf="dataKey && expandField && expansionCols"></th>
      <th *ngFor="let col of columns; let i">
        <div *ngIf="col.filter" [formGroup]="formGroup(col.field)">
          <p-columnFilter
            [type]="col.type"
            [showMatchModes]="false"
            [showButtons]="false"
            [showMenu]="false"
            *ngIf="lazy"
          >
            <ng-template pTemplate="filter">
              <ng-container
                *ngIf="!dropdownFilters || !dropdownFilters[col.field]?.length"
              >
                <input
                  type="text"
                  pInputText
                  *ngIf="col.type === 'text' || col.type === 'link'"
                  [formControl]="valueControl(col.field)"
                  (keypress)="filterChanged($event, $event, col.field)"
                />
                <p-inputNumber
                  inputId="filter"
                  [formControl]="valueControl(col.field)"
                  [format]="false"
                  (keypress)="filterChanged($event, $event, col.field)"
                  *ngIf="
                    col.type === 'currency' ||
                    col.type === 'number' ||
                    col.type === 'formattedInteger' ||
                    col.type === 'formattedNumber'
                  "
                ></p-inputNumber>
                <p-calendar
                  inputId="filter"
                  [formControl]="valueControl(col.field)"
                  (onSelect)="
                    (col.condition !== 'between' &&
                      filterChanged({ keyCode: 13 }, $event, col.field)) ||
                      (col.condition === 'between' &&
                        rangeValid(valueControl(col.field)) &&
                        filterChanged({ keyCode: 13 }, $event, col.field))
                  "
                  *ngIf="col.type === 'date'"
                  appendTo="body"
                  dateFormat="dd/mm/yy"
                  [selectionMode]="
                    col.condition === 'between' ? 'range' : 'single'
                  "
                  [showClear]="valueControl(col.field).value"
                  (onClear)="filterChanged({ keyCode: 13 }, $event, col.field)"
                ></p-calendar>
              </ng-container>
              <p-dropdown
                *ngIf="
                  dropdownFilters &&
                  dropdownFilters[col.field]?.length &&
                  !multiSelectFilter
                "
                [options]="dropdownFilters[col.field]"
                [formControl]="valueControl(col.field)"
                id="field"
                appendTo="body"
                (onChange)="filterChanged({ keyCode: 13 }, $event, col.field)"
                [filter]="true"
                filterBy="label"
                optionLabel="label"
                optionValue="value"
                [group]="isGroup(col.field)"
              >
              </p-dropdown>
              <p-multiSelect
                *ngIf="
                  dropdownFilters &&
                  dropdownFilters[col.field]?.length &&
                  multiSelectFilter
                "
                emptyMessage="Nenhuma"
                [id]="'filter_' + col.field"
                [options]="dropdownFilters[col.field]"
                [formControl]="valueControl(col.field)"
                (onPanelHide)="
                  updateMultiSelectFilterCols($event, col.field) &&
                    filterChanged({ keyCode: 13 }, $event, col.field)
                "
                class="input"
                [maxSelectedLabels]="0"
                [selectedItemsLabel]="multiSelectLabel[col.field]"
                (onChange)="updateMultiSelectFilterCols($event, col.field)"
                emptyMessage=""
                appendTo="body"
                optionLabel="label"
                optionValue="value"
                [group]="isGroup(col.field)"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
          <p-columnFilter
            [type]="col.type"
            [showMatchModes]="false"
            [showButtons]="false"
            [showMenu]="false"
            [field]="col.field"
            [matchMode]="
              dropdownFilters &&
              dropdownFilters[col.field] &&
              dropdownFilters[col.field].length &&
              multiSelectFilter
                ? 'in'
                : col.condition
            "
            *ngIf="!lazy"
          >
            <ng-template pTemplate="filter" let-filter="filterCallback">
              <ng-container
                *ngIf="!dropdownFilters || !dropdownFilters[col.field]?.length"
              >
                <input
                  type="text"
                  pInputText
                  *ngIf="col.type === 'text' || col.type === 'link'"
                  [formControl]="valueControl(col.field)"
                  (keydown.enter)="
                    emitChange($event, col.field) &&
                      filter(valueControl(col.field).value)
                  "
                  [id]="'filter_' + col.field"
                />
                <p-inputNumber
                  [inputId]="'filter_' + col.field"
                  [id]="'filter_' + col.field"
                  [formControl]="valueControl(col.field)"
                  [format]="false"
                  (keydown.enter)="
                    emitChange($event, col.field) &&
                      filter(valueControl(col.field).value)
                  "
                  *ngIf="
                    col.type === 'number' ||
                    col.type === 'curreny' ||
                    col.type === 'formattedInteger' ||
                    col.type === 'formattedNumber'
                  "
                ></p-inputNumber>
                <p-calendar
                  [inputId]="'filter_' + col.field"
                  [id]="'filter_' + col.field"
                  [formControl]="valueControl(col.field)"
                  (onSelect)="
                    (col.condition !== 'between' &&
                      emitChange($event, col.field) &&
                      filter(dateValue(valueControl(col.field)))) ||
                      (col.condition === 'between' &&
                        rangeValid(valueControl(col.field)) &&
                        emitChange($event, col.field) &&
                        filter(dateValue(valueControl(col.field))))
                  "
                  (onClear)="
                    emitChange($event, col.field) &&
                      filter(dateValue(valueControl(col.field)))
                  "
                  [showClear]="valueControl(col.field).value"
                  appendTo="body"
                  dateFormat="dd/mm/yy"
                  *ngIf="col.type === 'date'"
                  [selectionMode]="
                    col.condition === 'between' ? 'range' : 'single'
                  "
                ></p-calendar>
              </ng-container>
              <p-dropdown
                *ngIf="
                  dropdownFilters &&
                  dropdownFilters[col.field]?.length &&
                  !multiSelectFilter
                "
                [options]="dropdownFilters[col.field]"
                [formControl]="valueControl(col.field)"
                [id]="'filter_' + col.field"
                appendTo="body"
                (onChange)="
                  emitChange($event, col.field) && filter($event.value)
                "
                [filter]="true"
                [autoDisplayFirst]="false"
                filterBy="label"
                optionLabel="label"
                optionValue="value"
                [group]="isGroup(col.field)"
              >
              </p-dropdown>
              <p-multiSelect
                *ngIf="
                  dropdownFilters &&
                  dropdownFilters[col.field]?.length &&
                  multiSelectFilter
                "
                emptyMessage="Nenhuma"
                [options]="dropdownFilters[col.field]"
                [formControl]="valueControl(col.field)"
                class="input"
                [maxSelectedLabels]="0"
                [id]="'filter_' + col.field"
                emptyMessage=""
                [selectedItemsLabel]="multiSelectLabel[col.field]"
                (onChange)="
                  updateMultiSelectFilterCols($event, col.field) &&
                    $event.value.length &&
                    emitChange($event, col.field) &&
                    filter($event.value)
                "
                (onSelectAllChange)="
                  emitChange($event, col.field) &&
                    multiSelectAllChange($event, col.field)
                "
                appendTo="body"
                optionLabel="label"
                optionValue="value"
                [group]="isGroup(col.field)"
              >
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowItem
    let-columns="columns"
    let-expanded="expanded"
  >
    <tr
      [ngStyle]="{
        background:
          rowBackground && rowBackground(rowItem)
            ? rowBackground(rowItem)
            : 'auto',
        color: rowColor && rowColor(rowItem) ? rowColor(rowItem) : 'auto'
      }"
    >
      <td *ngIf="dataKey && expandField && expansionCols">
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="rowItem"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td
        *ngFor="let col of columns"
        [class.text-center]="!col.showMobile"
        [ngClass]="col.styleClass"
        [class.actions]="col.type === 'button'"
      >
        <span class="p-column-title" *ngIf="col.showMobile">{{
          col.header
        }}</span>
        <ng-container *ngIf="col.type === 'image'">
          <img [src]="rowItem[col.field]" />
        </ng-container>
        <ng-container
          *ngIf="
            col.type === 'text' ||
            col.type === 'number' ||
            col.type === 'formattedInteger' ||
            col.type === 'formattedNumber'
          "
        >
          <ng-container
            *ngIf="
              col.routerLink === undefined &&
              col.type !== 'formattedInteger' &&
              col.type !== 'formattedNumber'
            "
          >
            {{ rowItem[col.field] }}
          </ng-container>
          <ng-container
            *ngIf="
              col.routerLink === undefined && col.type === 'formattedInteger'
            "
          >
            {{ rowItem[col.field] | number : '1.0-0' }}
          </ng-container>
          <ng-container
            *ngIf="
              col.routerLink === undefined && col.type === 'formattedNumber'
            "
          >
            {{ rowItem[col.field] | number : '1.0-2' }}
          </ng-container>
          <ng-container *ngIf="col.routerLink !== undefined">
            <ng-container
              *ngIf="
                col.linkActive(rowItem) &&
                (!col.routerLinkFieldName ||
                  rowItem[col.routerLinkFieldName]) &&
                (!actionFields || !actionFields.includes(col.field))
              "
            >
              <a
                type="button"
                [routerLink]="col.routerLink + rowItem[col.routerLinkFieldName]"
                [target]="linkTarget"
                class="data-link"
                *ngIf="!externalLink(col.routerLink)"
              >
                <ng-container
                  *ngIf="col.type === 'text' || col.type === 'number'"
                >
                  {{ rowItem[col.field] }}
                </ng-container>
                <ng-container *ngIf="col.type === 'formattedNumber'">
                  {{ rowItem[col.field] | number : '1.0-2' }}
                </ng-container>
                <ng-container *ngIf="col.type === 'formattedInteger'">
                  {{ rowItem[col.field] | number : '1.0-0' }}
                </ng-container>
              </a>
              <a
                [href]="
                  col.routerLink + (rowItem[col.routerLinkFieldName] || '')
                "
                [target]="linkTarget"
                class="data-link"
                *ngIf="externalLink(col.routerLink)"
              >
                <ng-container
                  *ngIf="col.type === 'text' || col.type === 'number'"
                >
                  {{ rowItem[col.field] }}
                </ng-container>
                <ng-container *ngIf="col.type === 'formattedNumber'">
                  {{ rowItem[col.field] | number : '1.0-2' }}
                </ng-container>
                <ng-container *ngIf="col.type === 'formattedInteger'">
                  {{ rowItem[col.field] | number : '1.0-0' }}
                </ng-container>
              </a>
            </ng-container>
            <a
              type="button"
              class="data-link"
              (click)="linkAction.emit({ value: rowItem, field: col.field })"
              *ngIf="
                col.linkActive(rowItem) &&
                linkTarget === '_action' &&
                actionFields &&
                actionFields.includes(col.field)
              "
            >
              <ng-container
                *ngIf="col.type === 'text' || col.type === 'number'"
              >
                {{ rowItem[col.field] }}
              </ng-container>
              <ng-container *ngIf="col.type === 'formattedNumber'">
                {{ rowItem[col.field] | number : '1.2-2' }}
              </ng-container>
              <ng-container *ngIf="col.type === 'formattedInteger'">
                {{ rowItem[col.field] | number : '1.0-0' }}
              </ng-container>
            </a>
            <ng-container
              *ngIf="
                !col.linkActive(rowItem) ||
                (col.routerLinkFieldName && !rowItem[col.routerLinkFieldName])
              "
            >
              {{ rowItem[col.field] }}
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="col.type === 'currency'">
          {{ rowItem[col.field] | currency : 'BRL' }}
        </ng-container>
        <ng-container *ngIf="col.type === 'percentage'">
          {{ rowItem[col.field] * 100 | number : '1.2-2' }}%
        </ng-container>
        <span
          *ngIf="col.type === 'date' && rowItem[col.field]"
          tooltipPosition="bottom"
          [pTooltip]="
            timeDiffLabel(rowItem[col.field]).includes('/')
              ? ''
              : timeLabel(rowItem[col.field]) || ''
          "
        >
          {{ timeDiffLabel(rowItem[col.field]) }}
        </span>
        <span *ngIf="col.type === 'inputSwitch'">
          <label [for]="'inputSwitch_' + col.field" class="input-switch">
            {{ col.switchFalseLabel }}
            <p-inputSwitch
              [(ngModel)]="rowItem[col.field]"
              [trueValue]="trueValue(rowItem[col.field])"
              [falseValue]="falseValue(rowItem[col.field])"
              (onChange)="booleanAction.emit(rowItem)"
              [id]="'inputSwitch_' + col.field"
            ></p-inputSwitch>
            {{ col.switchTrueLabel }}
          </label>
        </span>
        <span *ngIf="col.type === 'boolean'">
          {{ rowItem[col.field] ? 'Sim' : 'Não' }}
        </span>
        <span *ngIf="col.type === 'checkbox'">
          <p-tableCheckbox
            [pTooltip]="
              rowSelectable === undefined || rowSelectable({ data: rowItem })
                ? ''
                : col.selectDisabledTooltip
            "
            tooltipPosition="bottom"
            [value]="rowItem"
            [disabled]="
              rowSelectable !== undefined && !rowSelectable({ data: rowItem })
            "
          ></p-tableCheckbox>
        </span>
        <ng-container *ngIf="col.type === 'button' && actionButtons">
          <ng-container *ngFor="let button of actionButtons">
            <p-button
              *ngIf="button.display(rowItem)"
              [label]="button.label"
              [icon]="button.icon"
              [styleClass]="button.styleClass"
              [pTooltip]="button.tooltip"
              [tooltipPosition]="button.tooltipPosition"
              [rounded]="button.rounded"
              [text]="button.text"
              [severity]="button.severity"
              [size]="button.size"
              (onClick)="
                actionButtonClick.emit({
                  item: rowItem,
                  $event: $event,
                  action: button.action
                })
              "
            >
            </p-button>
          </ng-container>
        </ng-container>
        <span
          class="status"
          [class.status--active]="col.statusActiveFunction(rowItem)"
          *ngIf="col.type === 'status'"
        >
          {{ rowItem[col.field] }}
        </span>
        <ng-container *ngIf="col.type === 'inputNumber'">
          <p-inputNumber
            [(ngModel)]="rowItem[col.field]"
            (onBlur)="rowItemChanged.emit(rowItem)"
          ></p-inputNumber>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" *ngIf="showFooter">
    <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr style="height: 200px">
      <td
        [attr.colspan]="
          cols.length + (dataKey && expandField && expansionCols ? 1 : 0)
        "
        class="text-center"
      >
        {{ emptyMessage }}
      </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="rowexpansion"
    let-subRowItem
    *ngIf="expandField && expansionCols && dataKey"
  >
    <tr>
      <td [attr.colspan]="cols.length + 1">
        <app-table
          [lazy]="false"
          [elements]="subRowItem[expandField]"
          [cols]="expansionCols"
          [addButton]="false"
          [exportExcel]="false"
          [exportPdf]="false"
          [rowColor]="rowColor"
          [rowBackground]="rowBackground"
          [paginator]="expandPaginator"
          *ngIf="subRowItem[expandField]"
          [changeColumns]="changeColumns"
          [showTitle]="false"
          [modelName]="expansionModelName"
        ></app-table>
      </td>
    </tr>
  </ng-template>
</p-table>
