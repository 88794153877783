import { BroadcastUtil } from './broadcast.util';

export class ToastUtil {
  public static addToast(message: {
    severity: 'success' | 'error' | 'info' | 'warning';
    detail: string;
    summary?: string;
  }): void {
    if (!message.summary)
      message.summary =
        message.severity[0].toUpperCase() + message.severity.substring(1);
    BroadcastUtil.get('toast').emit(message);
  }

  public static showErrorToast(
    error: any,
    errorGenericMessage = 'Não foi possível realizar esta ação.'
  ) {
    console.error(error);
    ToastUtil.addToast({
      summary: 'Erro',
      severity: 'error',
      detail:
        error?.error?.message || error?.message || ` ${errorGenericMessage}`
    });
  }
}
