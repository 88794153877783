<form (submit)="ref.close(review.value)" [formGroup]="review">
  <p-floatlabel>
    <input
      type="text"
      name="message"
      id="message"
      formControlName="message"
      placeholder="Informe o problema na composição"
      pInputText
    />
    <label for="message">Motivo</label>
  </p-floatlabel>
  <span class="buttons">
    <p-button
      type="button"
      (onClick)="ref.close()"
      label="Voltar"
      styleClass="p-button-primary"
    />
    <p-button
      type="submit"
      label="Enviar"
      styleClass="p-button-danger"
      [disabled]="!review.valid"
    />
  </span>
</form>
