import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  signal
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { lastValueFrom, map } from 'rxjs';
import {
  DraftControllerService,
  DraftView,
  PageDraftView,
  PageableRequest,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import {
  DraftStatus,
  getAllDrafts,
  getDraftStatus
} from 'src/app/models/enums';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss'],
  providers: [DatePipe],
  standalone: false
})
export class DraftComponent implements OnInit {
  @ViewChild(Table)
  table: Table | undefined;

  drafts: WritableSignal<Array<DraftView>> = signal([]);
  user: UserRoleViewResponse | undefined;
  status?: string;
  pageRequest: PageableRequest | undefined;
  pageDraftView: PageDraftView | undefined;
  isBirthday = computed(
    () => this.datePipe.transform(new Date(), 'yyyy-MM-dd') === '2024-05-17'
  );
  constructor(
    private draftService: DraftControllerService,
    private confirmationService: ConfirmationService,
    private userService: UserControllerService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (params) => {
      if (params['status']) {
        this.status = params['status'];
      } else {
        this.status = getDraftStatus(DraftStatus.Open)?.enumValue as string;
      }
    });
    try {
      LoaderService.showLoader();
      await this.getUserInfo();
      LoaderService.showLoader(false);
    } catch (error: any) {
      alert(error.error.message);
    }
  }

  async getUserInfo(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      console.error(error);
      alert('Problemas ao busca usuário.');
    }
  }

  async pageDrafts($event?: TableLazyLoadEvent): Promise<void> {
    LoaderService.showLoader();
    if ($event && this.pageRequest) {
      this.pageRequest.page =
        (($event.first || 0) + ($event.rows || 0)) / this.pageRequest.pageSize -
        1;
      this.pageRequest.sortBy = $event.sortField as string;
      this.pageRequest.sortDirection = $event.sortOrder === 1 ? 'desc' : 'asc';
      this.pageRequest.pageSize = $event.rows || 7;
    } else if (!this.pageRequest) {
      this.pageRequest = {
        page: 0,
        pageSize: 7,
        sortBy: 'dateCreated',
        sortDirection: 'desc'
      };
      if (this.table) {
        this.table.sortField = 'dateCreated';
        this.table.sortOrder = 1;
      }
    }
    try {
      this.pageDraftView = await lastValueFrom(
        this.draftService
          .list(this.pageRequest, this.status)
          .pipe(map((data) => data.result))
      );
      this.drafts.set(this.pageDraftView?.content || []);
    } catch (error) {
      this.drafts.set([]);
      ToastUtil.showErrorToast(error);
    }
    LoaderService.showLoader(false);
  }

  confirm(event: Event, draftId: number) {
    this.confirmationService.confirm({
      target: event.target as HTMLButtonElement,
      message: 'Excluir draft?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: async () => {
        try {
          LoaderService.showLoader();
          const response = await lastValueFrom(
            this.draftService
              .deleteDraft(draftId)
              .pipe(map((data) => data.result))
          );
          ToastUtil.addToast({
            severity: 'info',
            summary: 'Confirmed',
            detail: response as string
          });
          await this.pageDrafts();
          LoaderService.showLoader(false);
        } catch (error: any) {
          alert(error?.error?.message);
        }
      }
    });
  }

  editionDate(editionId: number): string | null {
    const data =
      ((editionId / 100) % 10000).toFixed(0) +
      '-' +
      (editionId % 100).toString().padStart(2, '0') +
      '-01 12:00:00';
    return this.datePipe.transform(
      new Date(data),
      'MMMM/yyyy',
      undefined,
      'pt-BR'
    );
  }

  async changeStatus(): Promise<void> {
    this.router.navigate(['draft'], { queryParams: { status: this.status } });
    await this.pageDrafts();
  }

  pendingReview(draft: DraftView) {
    return draft.review && !draft.problems;
  }

  async finish(draft: DraftView) {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.draftService
          .finishDraft(draft.draftId)
          .pipe(map((data) => data.result))
      );
      await this.pageDrafts();
      ToastUtil.addToast({
        severity: 'success',
        summary: 'Sucess',
        detail: 'Draft finalizado com sucesso.'
      });
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  get open(): boolean {
    return this.status === getDraftStatus(DraftStatus.Open)?.enumValue;
  }

  get statusOptions(): Array<{
    label: string;
    value: number;
    enumValue: string;
  }> {
    return getAllDrafts();
  }
}
