import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as xlsx from 'xlsx';
import { TableColumn } from '../components/table/table.component';

export class FileUtil {
  static exportPdf(
    content: Array<any>,
    tableName: string,
    cols?: Array<TableColumn> | undefined,
    orientation: 'portrait' | 'landscape' | 'l' | 'p' = 'l'
  ): void {
    const doc = new jsPDF({ orientation });
    autoTable(doc, {
      columns: cols?.map((c) => ({ dataKey: c.field, header: c.header })),
      body: content
    });
    doc.save(`${tableName}-${new Date().getTime()}.pdf`);
  }

  static exportExcel(
    content: Array<unknown>,
    tableName: string,
    cols?: Array<string>,
    extraContent?: Array<{
      tableName: string;
      content: Array<unknown>;
      cols?: Array<string>;
    }>
  ) {
    const worksheet = this.addContentToSheet(content, cols);
    const workbook = {
      Sheets: {} as { [name: string]: xlsx.WorkSheet },
      SheetNames: [tableName.slice(0, 31)]
    };
    workbook.Sheets[tableName.slice(0, 31)] = worksheet;
    if (extraContent?.length) {
      extraContent.forEach((e) => {
        workbook.Sheets[e.tableName.slice(0, 31)] = this.addContentToSheet(
          e.content,
          e.cols
        );
        workbook.SheetNames.push(e.tableName.slice(0, 31));
      });
    }
    const excelBuffer: xlsx.WorkSheet = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    this.saveAsExcelFile(excelBuffer as BlobPart, tableName);
  }

  static saveAsExcelFile(buffer: BlobPart, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(
      data,
      fileName + '-' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  private static addContentToSheet(
    content: Array<unknown>,
    cols?: Array<string>
  ): xlsx.WorkSheet {
    const worksheet = xlsx.utils.book_new();
    if (cols?.length) {
      xlsx.utils.sheet_add_aoa(worksheet, [cols]);
      xlsx.utils.sheet_add_json(worksheet, content, {
        origin: 'A2',
        skipHeader: true
      });
    } else {
      xlsx.utils.sheet_add_json(worksheet, content);
    }
    return worksheet;
  }

  static exportCSV(
    content: Array<unknown>,
    tableName: string,
    cols?: Array<string>
  ): void {
    let csvContent = 'data:text/csv;charset=utf-8,';
    if (!cols) cols = Object.keys(content[0] || {});
    csvContent += cols.join(',') + '\r\n';
    content.forEach((element) => {
      csvContent +=
        cols?.map((c) => (element as any)[c] || '').join(',') + '\r\n';
    });
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvContent).replaceAll('#', '%23'));
    link.setAttribute(
      'download',
      tableName + '_' + new Date().getTime() + '.csv'
    );
    link.style.height = '0px';
    document.body.appendChild(link);
    link.click();
  }

  static exportXML(
    content: Array<unknown>,
    tableName: string,
    cols?: Array<string>
  ): void {
    if (!cols) cols = Object.keys(content[0] || {});
    let xml = '<?xml version="1.0" encoding="utf-8" standalone="yes"?>\n';
    const date = new Date();
    xml += `<CustomQuery name="${tableName}" queryDate="${date.toISOString()}">\n`;
    xml += content.reduce((result, el) => {
      return (
        result +
        `  <Entity>\n${cols?.reduce(
          (r, c) => (r += `    <${c}>${(el as any)[c]}</${c}>\n`),
          ''
        )}  </Entity>\n`
      );
    }, '');
    xml += '</CustomQuery>';
    const filename = `${tableName}_${new Date().toISOString()}.xml`;
    const pom = document.createElement('a');
    const bb = new Blob([xml], { type: 'text/plain' });

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);

    pom.dataset['downloadurl'] = ['text/plain', pom.download, pom.href].join(
      ':'
    );
    pom.draggable = true;
    pom.classList.add('dragout');
    pom.click();
  }

  static b64toBlob(
    b64Data: string,
    contentType: string,
    sliceSize = 512
  ): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  static async getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
}
