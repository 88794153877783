import { Component, OnInit } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { UserControllerService, UserView } from 'src/app/allocation-api';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  user: UserView | undefined;
  constructor(private userService: UserControllerService) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    await this.findUser();
    LoaderService.showLoader(false);
  }

  async findUser(): Promise<void> {
    try {
      this.user = (await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      )) as any;
    } catch (error) {
      ToastUtil.showErrorToast(error, 'Erro ao buscar usuário');
    }
  }
}
