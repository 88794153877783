<div class="dialog-composition-modal" *ngIf="products.length">
  <div *ngIf="products.length" class="header">
    <div>
      <strong> Nome da composição: </strong>
      {{ compositionSummary()?.draftCompositionName }}
    </div>
    <div>
      <strong>Produtos:</strong> {{ compositionSummary()?.compositionProducts }}
    </div>
    <div>
      <strong>Valor mercado:</strong>
      {{ compositionSummary()?.salePrice | currency : 'BRL' }}
    </div>
    <div><strong>Peso:</strong> {{ compositionSummary()?.weight }}g</div>
    <div>
      <strong>Valor custo:</strong>
      {{ compositionSummary()?.costPrice | currency : 'BRL' }}
    </div>
    <div>
      <strong>Assinantes: </strong>
      <a
        type="button"
        class="admin-link"
        (click)="showCompositionSubscribers()"
      >
        {{ compositionSummary()?.compositionSubscribers }}
      </a>
    </div>
  </div>
  <app-table
    [lazy]="false"
    [cols]="productCols"
    [elements]="products"
    [showHeader]="false"
    [expansionCols]="cols"
    expandField="filters"
    dataKey="productVariantId"
    (rowExpand)="toggleFilters($event.$event.data)"
    parentNameField="productVariantName"
    [expandPaginator]="false"
    modelName="produtos"
    [rowBackground]="rowBackground"
  ></app-table>
</div>
