import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { BlockPageModule } from 'src/app/components/block-page/block-page.module';
import { ProductCardModule } from 'src/app/components/product-card/product-card.module';
import { ProductFiltersModalModule } from 'src/app/components/product-filters-modal/product-filters-modal.module';
import { ProductSelectModalModule } from 'src/app/components/product-select-modal/product-select-modal.module';
import { RepeatedSubcategoriesModalModule } from 'src/app/components/repeated-subcategories-modal/repeated-subcategories-modal.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { CompositionNameModalComponent } from './composition-name-modal/composition-name-modal.component';
import { CompositionSummaryModalComponent } from './composition-summary-modal.component/composition-summary-modal.component';
import { DraftCompositionFiltersModalComponent } from './draft-composition-filters-modal/draft-composition-filters-modal.component';
import { DraftCompositionsComponent } from './draft-compositions/draft-compositions.component';
import { DraftDefaultProductsComponent } from './draft-default-products/draft-default-products.component';
import { DraftFormRoutingModule } from './draft-form-routing.module';
import { DraftFormComponent } from './draft-form.component';
import { DraftSegmentationComponent } from './draft-segmentation/draft-segmentation.component';
import { DraftSubscriberFilterFormComponent } from './draft-subscriber-filter-form.component/draft-subscriber-filter-form.component';
import { LateSubscribersModalComponent } from './late-subscribers-modal/late-subscribers-modal.component';
@NgModule({
  declarations: [
    DraftFormComponent,
    DraftSubscriberFilterFormComponent,
    DraftSegmentationComponent,
    CompositionSummaryModalComponent,
    DraftCompositionsComponent,
    CompositionNameModalComponent,
    LateSubscribersModalComponent,
    DraftDefaultProductsComponent,
    DraftCompositionFiltersModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProductFiltersModalModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule,
    ConfirmPopupModule,
    ButtonModule,
    InputMaskModule,
    ProductCardModule,
    VirtualScrollerModule,
    DraftFormRoutingModule,
    DragDropModule,
    ConfirmDialogModule,
    ChipsModule,
    InputTextareaModule,
    CheckboxModule,
    TableModule,
    TooltipModule,
    AutoCompleteModule,
    BlockPageModule,
    DialogModule,
    ToastModule,
    PickListModule,
    RepeatedSubcategoriesModalModule,
    AppTableModule,
    ProductSelectModalModule
  ]
})
export class DraftFormModule {}
