const TOKEN_KEY = 'auth-token';
const USER_ID = 'auth-user';
const ROLE_KEY = 'auth-role';

export class TokenStorageService {
  static signOut(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  static saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  static getToken(): string {
    const token = localStorage.getItem(TOKEN_KEY);
    return !!token ? token.replace('Bearer ', '') : '';
  }

  static saveUser(userId: string): void {
    window.localStorage.removeItem(USER_ID);
    window.localStorage.setItem(USER_ID, userId);
  }

  static saveRoles(roles: string): void {
    window.localStorage.removeItem(ROLE_KEY);
    window.localStorage.setItem(ROLE_KEY, roles);
  }

  static getUserId(): number {
    return Number(localStorage.getItem(USER_ID));
  }

  static getUserRoles(): string[] | null {
    const roles = localStorage.getItem(ROLE_KEY);
    if (roles) {
      return roles.split(',');
    }
    return null;
  }

  static saveResult(result: {
    Authorization: string;
    userId: string;
    roles: string;
  }): void {
    this.saveUser(result.userId);
    this.saveToken(result.Authorization);
    this.saveRoles(result.roles);
  }

  static get userLogged(): boolean {
    return !!this.getUserId();
  }
}
