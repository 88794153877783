import { Component, computed, inject, signal } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Draft, DraftControllerService } from 'src/app/allocation-api';
import { TableColumn } from 'src/app/components/table/table.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-composition-subscribers-modal',
  templateUrl: './composition-subscribers-modal.component.html',
  styleUrls: ['./composition-subscribers-modal.component.scss']
})
export class CompositionSubscribersModalComponent {
  public draftService = inject(DraftControllerService);
  private config = inject(DynamicDialogConfig);
  draftCompositionId = signal(this.config.data.draftCompositionId);
  draft = signal(this.config.data.draft as Draft);
  cols = [
    new TableColumn(
      'Nome',
      'firstName',
      true,
      'text',
      `${environment.adminUrl}users/person/`,
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'Sobrenome',
      'lastName',
      true,
      'text',
      `${environment.adminUrl}users/person/`,
      'personId',
      true,
      'contains'
    ),
    new TableColumn(
      'GlamID',
      'boxId',
      true,
      'text',
      `${environment.adminUrl}users/subscribers/`,
      'subscriberId'
    )
  ];

  fixedFilters = computed(() =>
    this.draft()?.draftStatus
      ? [
          {
            condition: 'equals',
            value: this.draftCompositionId()?.toString(),
            field: 'compositionId',
            fieldType: 'number'
          }
        ]
      : [
          {
            condition: 'equals',
            value: this.draftCompositionId()?.toString(),
            field: 'draftCompositionId',
            fieldType: 'number'
          }
        ]
  );
  pageFunctionName = computed(() =>
    this.draft()?.draftStatus
      ? 'getAllocatedSubscribersTable'
      : 'getCompositionSubscribersPage'
  );

  get subscriberLink(): string {
    return environment.adminUrl + 'users/subscribers/';
  }

  get personLink(): string {
    return environment.adminUrl + 'users/person/';
  }
}
