import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  computed
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  FilterMetadata,
  FilterService,
  PrimeIcons,
  SortEvent
} from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import {
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectSelectAllChangeEvent
} from 'primeng/multiselect';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  Table,
  TableHeaderCheckbox,
  TableLazyLoadEvent,
  TablePageEvent,
  TableRowExpandEvent
} from 'primeng/table';
import { Observable, lastValueFrom, map } from 'rxjs';
import {
  Pageable,
  PageableFilter,
  PageableRequest,
  Sort
} from 'src/app/allocation-api';

import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe, FilterService]
})
export class TableComponent implements OnInit, OnChanges {
  @ViewChild(Table)
  table: Table | undefined;

  @ViewChild('oppdf')
  panelPDF: OverlayPanel | undefined;

  @ViewChild('opxls')
  panelXLSX: OverlayPanel | undefined;

  @ViewChild(TableHeaderCheckbox)
  headerCheckbox: TableHeaderCheckbox | undefined;

  @ViewChildren(MultiSelect)
  multiSelects: Array<MultiSelect> | undefined;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  @Input() lazy = true;
  @Input() sortField: string | undefined;
  @Input() sortOrder: number | undefined;
  @Input() defaultRows = 5;
  @Input() cols: Array<TableColumn> | undefined;
  @Input() changeColumns = false;
  @Input() expansionCols: Array<TableColumn> | undefined;
  @Input() modelName = 'table';
  @Input() dataKey: string | undefined;
  @Input() expandField: string | undefined;
  @Input() expansionModelName: string | undefined = 'registros';
  @Input() emptyMessage: string | undefined;
  @Input() findAll: () => Promise<PageContent<any>> = this.findAllElements;
  @Input() findPage:
    | ((
        request: PageableRequest,
        service?: unknown
      ) => Promise<PageContent<any>>)
    | undefined;
  @Input() service: unknown;
  @Input() pageFunctionName: string | undefined;
  @Input() elements: Array<any> | undefined;
  @Input() linkTarget: '_blank' | '_self' | '_parent' | '_top' | '_action' =
    '_blank';
  @Input() actionFields: Array<string> | undefined;
  @Input() exportExcel = true;
  @Input() exportPdf = true;
  @Input() addButton = true;
  @Input() addLabel = 'Novo';
  @Input() addButtonIcon = PrimeIcons.PLUS;
  @Input() addButtonRouterLink = 'new';
  @Input() addButtonAction = false;
  @Input() refreshButton = false;
  @Input() tableTitle: string | undefined;
  @Input() dropdownFilters:
    | {
        [field: string]: Array<DropdownFilter>;
      }
    | undefined;
  @Input() multiSelectFilter = false;
  @Input() fixedFilters: Array<PageableFilter> = [];
  @Input() showFooter = false;
  @Input() footerTemplate: TemplateRef<any> | null = null;
  @Input() captionTemplate: TemplateRef<any> | null = null;
  @Input() preHeaderTemplate: TemplateRef<any> | null = null;
  @Input() selectionActionLabel = 'Ativar';
  @Input() selectionActionTooltip = 'Ativa os items da lista';
  @Input() selectionActionClass = 'p-button-secondary';
  @Input() selectionActionIcon: string = PrimeIcons.VERIFIED;
  @Input() selectAll = true;
  @Input() showHeader = true;
  @Input() showTitle = true;
  @Input() actionButtons: Array<TableActionButton> | undefined;
  @Input() paginator = true;
  @Input() colsExportMode: 'all' | 'visible' = 'all';
  @Input() rowSelectable: ((value: { data: object }) => boolean) | undefined;
  @Input() defaultFilterValues: { [field: string]: any | Array<any> } = {};
  @Input() rowBackground: ((item: any) => string | undefined) | undefined;
  @Input() rowColor: ((item: any) => string | undefined) | undefined;
  @Input() expandPaginator = true;
  @Input() customPdfExport:
    | ((elements: Array<unknown>, cols: Array<TableColumn>) => void)
    | undefined;
  @Input() falseValueFunction: ((value: any) => any) | undefined;
  @Input() trueValueFunction: ((value: any) => any) | undefined;
  @Output() booleanAction = new EventEmitter<any>();
  @Output() emitSelection = new EventEmitter<Array<any>>();
  @Output() addButtonClick = new EventEmitter<Array<any>>();
  @Output() actionButtonClick = new EventEmitter<ActionButtonClickEvent>();
  @Output() rowItemChanged = new EventEmitter<any>();
  @Output() rowExpand = new EventEmitter<{
    $event: TableRowExpandEvent;
    service: unknown;
  }>();
  @Output() linkAction = new EventEmitter<{ field: string; value: any }>();
  @Output() columnsChanged = new EventEmitter<Array<TableColumn>>();
  @Output() refreshTable = new EventEmitter<void>();
  @Output() tableLoaded = new EventEmitter<void>();
  @Output() changedFilter = new EventEmitter<FilterChangedEvent>();

  filters = computed(() => {
    const obj: { [c: string]: FilterMetadata } = {};
    this.cols
      ?.filter((c) => c.filter)
      .forEach(
        (c) =>
          (obj[c.field] = {
            matchMode: c.condition,
            value: this.defaultFilterValues[c.field] || null
          } as FilterMetadata)
      );
    return obj;
  });

  filterFields: Array<{
    label: string;
    value: string;
    condition: string;
    fieldType: string;
  }> = [];
  searchForm: FormGroup | undefined;
  pageContent: PageContent<any> | undefined;
  pageRequest: PageableRequest | undefined;
  page: TablePageEvent | undefined;
  isMobile?: boolean;
  selectedItems: Array<any> = [];
  toggleAll = false;
  tableContent: Array<any> | undefined;
  columnsForm = new FormGroup({
    columns: new FormControl<Array<TableColumn>>([])
  });
  colsToDisplay: Array<TableColumn> | undefined;
  changebleColumns: Array<TableColumn> | undefined;
  totalCols = 0;
  columnsLabel = 'Todas';
  checkboxAll = false;
  totalColsFilters: { [field: string]: number } = {};
  multiSelectLabel: { [field: string]: string } = {};

  constructor(
    private datePipe: DatePipe,
    @Inject(FormBuilder) private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private filterService: FilterService
  ) {
    this.checkScreenSize();
  }

  async ngOnInit(): Promise<void> {
    this.filterService.register(
      'in',
      (value: any, filter: Array<string>): boolean => {
        if (filter === undefined || filter === null || !filter.length) {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return filter.some(
          (f) =>
            FormUtil.semAcento(f).toUpperCase() ===
            FormUtil.semAcento(value).toUpperCase()
        );
      }
    );
    if (this.rowSelectable !== undefined)
      this.rowSelectable = this.rowSelectable.bind(this);
    if (!this.emptyMessage)
      this.emptyMessage = `Sem ${this.modelName} com os filtros informados.`;
    this.colsToDisplay = [...(this.cols || [])];
    this.changebleColumns = this.cols?.filter((c) => !c.alwaysVisible);
    this.columnsForm.controls['columns'].setValue(this.changebleColumns || []);
    this.totalCols = this.colsToDisplay?.length;
    this.filterFields =
      this.cols
        ?.filter((c) => c.filter)
        .map((c) => ({
          label: c.header,
          value: c.field,
          condition: c.condition,
          fieldType: c.type
        })) || [];
    this.searchForm = this.fb?.group({
      filters: this.fb?.array<
        FormGroup<{
          field: FormControl<string | null>;
          value: FormControl<string | null>;
          contition: FormControl<string | null>;
          fieldType: FormControl<string | null>;
        }>
      >([])
    });
    this.filterFields.forEach((f) => {
      (this.searchForm?.controls['filters'] as FormArray).push(
        new FormGroup({
          field: new FormControl(f.value as string),
          value: new FormControl(
            this.defaultFilterValues &&
            this.defaultFilterValues[f.value] !== undefined
              ? this.defaultFilterValues[f.value]
              : ''
          ),
          condition: new FormControl(f.condition),
          fieldType: new FormControl(f.fieldType)
        })
      );
    });
    if (this.multiSelectFilter && this.dropdownFilters) {
      Object.keys(this.dropdownFilters).forEach((key) => {
        if (
          this.defaultFilterValues &&
          this.dropdownFilters &&
          this.defaultFilterValues[key]?.length !==
            this.dropdownFilters[key].length
        ) {
          this.multiSelectLabel[key] =
            this.defaultFilterValues[key]?.length + ' opções';
          this.totalColsFilters[key] = this.defaultFilterValues[key]?.length;
        } else {
          this.multiSelectLabel[key] = 'Todas';
          this.totalColsFilters[key] = 0;
        }
      });
    }
    if (!this.sortField)
      this.sortField = this.cols?.find(
        (c) => c.field && c.field.trim().length
      )?.field;
    if (!this.sortOrder) this.sortOrder = -1;
    if (!this.lazy && this.elements) {
      this.createTableFromElements();
      this.tableLoaded.emit();
    } else if (!this.lazy && !this.elements) {
      AppDialogService.showErrorDialog(
        {
          message:
            'Please provide the array of elements when lazy is set to FALSE'
        },
        true
      );
      this.tableLoaded.emit();
    } else if (this.lazy && !this.findPage && !this.pageFunctionName) {
      AppDialogService.showErrorDialog(
        {
          message:
            'Please provide [findPage] function or [pageFunctionName] when lazy is set to TRUE'
        },
        true
      );
      this.tableLoaded.emit();
    } else if (this.lazy && !this.service) {
      AppDialogService.showErrorDialog(
        {
          message: 'Please provide [service] when lazy is set to TRUE'
        },
        true
      );
      this.tableLoaded.emit();
    } else if (this.lazy) {
      this.tableContent = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fixedFilters']) {
      this.filterGlobal();
    } else if (
      changes['cols'] &&
      changes['cols'].currentValue &&
      changes['cols'].previousValue &&
      (changes['cols'].currentValue.length !==
        changes['cols'].previousValue?.length ||
        (changes['cols'].currentValue as Array<TableColumn>).some((c) =>
          (changes['cols'].previousValue as Array<TableColumn>).every(
            (pc) => pc.field !== c.field
          )
        ))
    ) {
      delete this.tableContent;
      this.pageTable();
    } else if (changes['elements']?.currentValue) {
      this.createTableFromElements();
    }
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
    // if (this.cdRef) this.cdRef.detectChanges();
  }

  filterGlobal(condition = 'contains'): void {
    if (this.searchForm?.valid) {
      this.table?.filterGlobal(this.searchForm.value || {}, condition);
    }
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }

  timeLabel(dateStart: string): string | null {
    return this.datePipe.transform(dateStart, 'dd/MM/yyyy HH:mm:ss');
  }

  async exportTable(all = false, type: 'xls' | 'pdf' = 'xls'): Promise<void> {
    LoaderService.showLoader();
    let dataExport = this.tableContent || [];
    if (all && this.findAll !== undefined && this.lazy) {
      const totalPage = await this.findAll();
      dataExport = totalPage?.content as Array<any>;
    } else if (all && !this.lazy) {
      dataExport = this.table?.filteredValue || this.tableContent || [];
    } else if (!all && !this.lazy && this.page) {
      dataExport =
        (this.table?.filteredValue || this.tableContent)?.slice(
          this.page?.first || 0,
          this.page?.first + this.page?.rows
        ) || [];
    }
    if (dataExport?.length > 60000) {
      AppDialogService.showErrorDialog({
        message:
          'Não é possível exportar mais de 60 mil linhas.<br>Por favor filtre a lista e exporte em lotes menores.'
      });
      if (type === 'pdf') {
        this.panelPDF?.toggle(false, this.panelPDF.target);
      } else {
        this.panelXLSX?.toggle(false, this.panelXLSX.target);
      }
      LoaderService.showLoader(false);
      return;
    }
    if (type === 'pdf' && !this.customPdfExport) {
      FileUtil.exportPdf(dataExport, this.modelName, this.colsToDisplay);
    } else if (this.customPdfExport) {
      this.customPdfExport(dataExport, this.expansionCols || []);
    } else {
      let cols;
      if (this.colsExportMode === 'visible' && this.colsToDisplay) {
        cols = this.colsToDisplay?.map((c) => c.header);
        dataExport = dataExport.map((d) => {
          const data = {} as any;
          this.colsToDisplay?.forEach(
            (col) => (data[col.header] = d[col.field])
          );
          return data;
        });
      } else if (dataExport?.length) {
        cols = Object.keys(dataExport[0]);
      }

      if (this.dataKey && this.expandField && this.expansionCols) {
        FileUtil.exportExcel(dataExport, this.modelName, cols, [
          {
            tableName: this.expandField,
            content: dataExport.reduce((list: Array<unknown>, item) => {
              list = list.concat(
                this.expandField ? item[this.expandField] || [] : []
              );
              return list;
            }, [])
          }
        ]);
      } else {
        FileUtil.exportExcel(dataExport, this.modelName, cols);
      }
    }
    LoaderService.showLoader(false);
  }

  formGroup(field: string): FormGroup {
    return (this.searchForm?.controls['filters'] as FormArray).controls.find(
      (c) => c.value.field === field
    ) as FormGroup;
  }

  valueControl(field: string): FormControl {
    return this.formGroup(field).get('value') as FormControl;
  }

  emitChange($event: any, field: string) {
    this.changedFilter.emit({ $event, field });
    return true;
  }

  async filterChanged(
    $event: {
      keyCode?: number;
      which?: number;
    },
    origalEvent?: any,
    field?: string
  ): Promise<void> {
    this.changedFilter.emit({
      $event: origalEvent,
      field,
      value: this.valueControl(field || '')?.value
    });
    const keyCode = $event.keyCode || $event.which;
    if (keyCode === 13) {
      if (this.pageRequest) {
        this.pageRequest.filters = [
          ...(this.searchForm?.value.filters || []),
          ...this.fixedFilters
        ];
        this.pageRequest.page = 0;
      }
      await this.pageTable();
    }
  }

  async pageTable($event?: TableLazyLoadEvent): Promise<void> {
    LoaderService.showLoader();
    if ($event)
      this.pageRequest = {
        page:
          (($event.first || 0) + ($event.rows || 0)) / ($event.rows || 5) - 1,
        sortBy: $event.sortField
          ? ($event.sortField as string)
          : this.sortField,
        sortDirection: $event.sortOrder === 1 ? 'desc' : 'asc',
        filters: [
          ...(this.searchForm?.value.filters || []),
          ...this.fixedFilters
        ],
        pageSize: $event.rows || 5
      };
    await this.findElements();
    this.tableLoaded.emit();
    LoaderService.showLoader(false);
  }

  async findElements(): Promise<void> {
    if (this.findPage) {
      try {
        this.content = await this.findPage(
          this.pageRequest as PageableRequest,
          this.service as Component
        );
      } catch (error: any) {
        delete this.pageContent;
        AppDialogService.showErrorDialog(error);
      }
    } else if (
      this.lazy &&
      this.pageFunctionName &&
      (!this.service ||
        !this.pageFunctionName ||
        !(this.service as any)[this.pageFunctionName])
    ) {
      AppDialogService.showErrorDialog({
        message:
          'Pagination function ' +
          this.pageFunctionName +
          ' not found on the service provided'
      });
    } else if (this.lazy && this.pageFunctionName) {
      try {
        const func = (this.service as any)[this.pageFunctionName](
          this.pageRequest
        ) as Observable<{
          result: PageContent<any>;
        }>;
        if (func instanceof Promise) {
          this.content = await ((this.service as any)[this.pageFunctionName](
            this.pageRequest
          ) as Promise<PageContent<any>>);
        } else {
          this.content = await lastValueFrom(
            func.pipe(map((data) => data.result))
          );
        }
      } catch (error) {
        delete this.pageContent;
        AppDialogService.showErrorDialog(error);
      }
    } else if (!this.lazy) {
      this.filterGlobal();
    } else {
      AppDialogService.showErrorDialog({
        message: 'Method findPage not implemented'
      });
    }
  }

  findAllElements(): Promise<PageContent<any>> {
    return this.findPage
      ? this.findPage(
          {
            page: 0,
            pageSize: this.pageContent?.totalElements as number,
            filters: this.pageRequest?.filters,
            sortBy: this.pageRequest?.sortBy,
            sortDirection: this.pageRequest?.sortDirection
          },
          this.service as Component
        )
      : this.pageFunctionName
      ? (this.service as any)[this.pageFunctionName] instanceof Observable
        ? lastValueFrom(
            (
              (this.service as any)[this.pageFunctionName]({
                page: 0,
                pageSize: this.pageContent?.totalElements as number,
                filters: this.pageRequest?.filters,
                sortBy: this.pageRequest?.sortBy,
                sortDirection: this.pageRequest?.sortDirection
              }) as Observable<{ result: PageContent<any> }>
            ).pipe(map((data) => data.result))
          )
        : ((this.service as any)[this.pageFunctionName]({
            page: 0,
            pageSize: this.pageContent?.totalElements as number,
            filters: this.pageRequest?.filters,
            sortBy: this.pageRequest?.sortBy,
            sortDirection: this.pageRequest?.sortDirection
          }) as Promise<PageContent<any>>)
      : Promise.resolve({});
  }

  emitSelectionList(): void {
    this.emitSelection.emit(this.selectedItems);
  }

  toggleAllAction($event: CheckboxChangeEvent): void {
    if (this.tableContent && this.tableContent.length > 100) {
      LoaderService.showLoader();
      setTimeout(() => {
        this.cdRef.detach();
        this.checkAll($event);
        setTimeout(() => {
          LoaderService.showLoader(false);
          this.cdRef.reattach();
        }, 500);
      }, 3000);
    } else {
      this.checkAll($event);
    }
  }

  checkAll($event: CheckboxChangeEvent): void {
    if (this.headerCheckbox) {
      this.headerCheckbox.onClick($event as any);
      this.headerCheckbox.updateCheckedState();
    }
  }

  detectChanges(): void {
    this.table?.cd.detectChanges();
  }

  dateValue(control: FormControl): Array<Date | null> {
    if (control.value && control.value instanceof Date) {
      const date = control.value as Date;
      let nextDay = new Date(date.getTime());
      nextDay = new Date(nextDay.setDate(nextDay.getDate() + 1));
      return [date, nextDay];
    } else if (control.value && control.value instanceof Array) {
      return control.value;
    }
    return [null, null];
  }

  trueValue(value: any): boolean | number {
    if (this.trueValueFunction) return this.trueValueFunction(value);
    if (typeof value === 'boolean') {
      return true;
    }
    return 1;
  }

  falseValue(value: any): boolean | number {
    if (this.falseValueFunction) return this.falseValueFunction(value);
    if (typeof value === 'boolean') {
      return false;
    }
    return 0;
  }

  rangeValid(control: AbstractControl): boolean {
    return (control.value as []).filter((v) => v).length > 1;
  }

  tableSort($event: SortEvent): void {
    $event.data?.sort((data1, data2) => {
      const value1 = data1[$event.field as string];
      const value2 = data2[$event.field as string];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return -1 * ($event.order || 1) * result;
    });
  }

  reloadColumns(): void {
    if (
      this.columnsForm.value.columns?.length &&
      (this.columnsForm.value.columns.length !==
        this.colsToDisplay?.filter((c) => !c.alwaysVisible).length ||
        this.columnsForm.value.columns.some((c) =>
          this.colsToDisplay?.every((cd) => cd.field !== c.field)
        ))
    ) {
      LoaderService.showLoader();
      this.cdRef.detach();
      this.colsToDisplay = this.cols?.filter(
        (c) =>
          this.columnsForm?.value?.columns?.some(
            (cc) => cc.field === c.field
          ) || c.alwaysVisible
      );
      if (this.colsToDisplay?.length !== this.cols?.length)
        this.colsExportMode = 'visible';
      else this.colsExportMode = 'all';
      setTimeout(() => {
        this.cdRef.reattach();
        this.cdRef.detectChanges();
        this.columnsChanged.emit(this.colsToDisplay);
        LoaderService.showLoader(false);
      }, 1000);
    }
  }

  updateTotalCols(
    $event: MultiSelectChangeEvent | void,
    multiselect: MultiSelect
  ): boolean {
    this.totalCols = this.columnsForm.value.columns?.length || 0;
    if (!this.totalCols && ($event as MultiSelectChangeEvent).itemValue) {
      this.columnsForm.controls['columns'].setValue([
        ($event as MultiSelectChangeEvent).itemValue
      ]);
    } else if (!this.totalCols) {
      this.columnsForm.controls['columns'].setValue(
        this.changebleColumns || []
      );
    } else if (!($event as MultiSelectChangeEvent).itemValue) {
      this.columnsLabel = 'Todas';
      if ($event) multiselect.hide();
    } else {
      this.columnsLabel =
        this.totalCols === this.changebleColumns?.length
          ? 'Todas'
          : `${this.totalCols} opções`;
    }
    this.cdRef.detectChanges();
    return true;
  }

  updateMultiSelectFilterCols(
    $event: MultiSelectChangeEvent | void,
    field: string
  ): boolean {
    this.totalColsFilters[field] = this.valueControl(field).value.length || 0;
    if (
      !this.totalColsFilters[field] &&
      ($event as MultiSelectChangeEvent).itemValue
    ) {
      this.valueControl(field).setValue([
        ($event as MultiSelectChangeEvent).itemValue.value
      ]);
    } else if (!this.totalColsFilters[field]) {
      this.valueControl(field).setValue(this.defaultFilterValues[field] || []);
    } else if (!($event as MultiSelectChangeEvent)?.itemValue) {
      this.multiSelectLabel[field] = 'Todos';
      if ($event)
        this.multiSelects?.find((m) => m.id === 'filter_' + field)?.hide();
    } else {
      this.multiSelectLabel[field] =
        this.totalColsFilters[field] ===
        (this.defaultFilterValues[field]?.length || 0)
          ? 'Todos'
          : `${this.totalColsFilters[field]} filtros`;
    }
    this.cdRef.detectChanges();
    return true;
  }

  multiSelectAllChange(
    $event: MultiSelectSelectAllChangeEvent,
    field: string
  ): void {
    if ($event.checked) {
      this.valueControl(field).setValue(
        this.dropdownFilters
          ? this.dropdownFilters[field].map((f) => f.value)
          : ''
      );
      this.multiSelectLabel[field] = 'Todos';
    } else {
      this.valueControl(field).setValue([]);
      this.multiSelectLabel[field] = 'Nenhum';
    }
    this.cdRef.detectChanges();
  }

  async refresh(clearSelection = false): Promise<void> {
    if (clearSelection) {
      this.selectedItems = [];
      this.checkboxAll = false;
    }
    if (this.lazy) await this.findElements();
    else this.refreshTable.emit();
  }

  externalLink(link: string) {
    return link.includes('http://') || link.includes('https://');
  }

  onPage($event: TablePageEvent): void {
    this.page = $event;
  }

  applyDefaultFilters(): void {
    Object.keys(this.table?.filters || {}).forEach((field) => {
      if (
        this.defaultFilterValues[field] &&
        this.table?.filters[field] instanceof Array
      ) {
        this.table.filters[field] = (
          this.table.filters[field] as Array<FilterMetadata>
        ).map((f) => ({
          ...f,
          value: this.defaultFilterValues[field]
        }));
      } else if (
        this.defaultFilterValues[field] &&
        this.table?.filters[field] instanceof Object
      ) {
        (this.table?.filters[field] as FilterMetadata).value =
          this.defaultFilterValues[field];
      }
    });
    // this.table._filter();
    this.tableLoaded.emit();
  }

  createTableFromElements(): void {
    this.content = {
      content: this.elements,
      numberOfElements: this.pageRequest?.pageSize,
      totalElements: this.elements?.length || 0
    };
    this.page = {
      first: 0,
      rows: this.defaultRows
    };
  }

  isGroup(field: string): boolean {
    return (
      this.dropdownFilters !== undefined &&
      this.dropdownFilters[field] !== undefined &&
      this.dropdownFilters[field][0] !== undefined &&
      this.dropdownFilters[field][0].items?.length !== undefined &&
      (this.dropdownFilters[field][0].items as Array<any>).length > 0
    );
  }

  get singleFilterFormGroup(): FormGroup {
    return (this.searchForm?.controls['filters'] as FormArray).at(
      0
    ) as FormGroup;
  }

  set content(pageContent: PageContent<any>) {
    this.pageContent = pageContent;
    this.tableContent =
      this.pageContent?.content?.map((item) => {
        this.cols
          ?.filter((c) => c.type === 'date')
          .forEach((c) => {
            if (item[c.field]) item[c.field] = new Date(item[c.field]);
          });
        return item;
      }) || [];
  }

  get canClear(): boolean {
    return (
      (this.searchForm?.value?.field !== undefined &&
        this.searchForm?.value?.field !== 'productId' &&
        this.searchForm.value?.value?.length !== undefined &&
        this.searchForm.value?.value?.length > 0) ||
      (this.searchForm?.value?.field !== undefined &&
        this.searchForm?.value?.field === 'productId' &&
        this.searchForm.value?.value !== null &&
        Number(this.searchForm.value.value) > 0)
    );
  }

  get showFilters(): boolean {
    return this.filterFields.length > 0 || this.fixedFilters?.length > 0;
  }
}

export class TableColumn {
  header: string;
  field: string;
  filter: boolean;
  type:
    | 'text'
    | 'number'
    | 'date'
    | 'link'
    | 'button'
    | 'boolean'
    | 'inputSwitch'
    | 'checkbox'
    | 'currency'
    | 'image'
    | 'status'
    | 'formattedInteger'
    | 'formattedNumber'
    | 'percentage'
    | 'inputNumber' = 'text';
  routerLink?: string;
  routerLinkFieldName?: string;
  linkActive?: (value: object) => boolean;
  showMobile: boolean;
  condition:
    | 'equals'
    | 'notEquals'
    | 'contains'
    | 'startsWith'
    | 'gt'
    | 'lt'
    | 'gte'
    | 'between'
    | 'in'
    | 'notIn'
    | 'lte' = 'equals';
  styleClass: string | undefined;
  statusActiveFunction?: (value: object) => boolean;
  canSelect?: (value: object) => boolean;
  selectDisabledTooltip?: string;
  switchTrueLabel = 'Sim';
  switchFalseLabel = 'Não';
  alwaysVisible = false;
  ngStyle: any;

  constructor(
    header: string,
    field: string,
    filter = true,
    type:
      | 'text'
      | 'number'
      | 'date'
      | 'link'
      | 'button'
      | 'boolean'
      | 'inputSwitch'
      | 'checkbox'
      | 'currency'
      | 'image'
      | 'status'
      | 'formattedNumber'
      | 'formattedInteger'
      | 'percentage'
      | 'inputNumber' = 'text',
    routerLink?: string,
    routerLinkFieldName?: string,
    showMobile = true,
    condition:
      | 'equals'
      | 'notEquals'
      | 'contains'
      | 'startsWith'
      | 'gt'
      | 'lt'
      | 'gte'
      | 'between'
      | 'in'
      | 'notIn'
      | 'lte' = 'equals',
    styleClass?: string,
    statusActiveFunction?: (value: object) => boolean,
    canSelect?: (value: object) => boolean,
    selectDisabledTooltip?: string,
    linkActive: (value: object) => boolean = () => true,
    switchTrueLabel?: string,
    switchFalseLabel?: string,
    alwaysVisible = false,
    ngStyle: any = {}
  ) {
    this.header = header;
    this.field = field;
    this.filter = filter;
    this.type = type;
    this.routerLink = routerLink;
    this.routerLinkFieldName = routerLinkFieldName;
    this.showMobile = showMobile;
    this.condition = condition;
    this.styleClass = styleClass;
    this.statusActiveFunction = statusActiveFunction;
    this.canSelect = canSelect;
    this.selectDisabledTooltip = selectDisabledTooltip;
    this.linkActive = linkActive;
    if (switchTrueLabel) this.switchTrueLabel = switchTrueLabel;
    if (switchFalseLabel) this.switchFalseLabel = switchFalseLabel;
    this.alwaysVisible =
      alwaysVisible || this.type === 'button' || this.type === 'checkbox';
    this.ngStyle = ngStyle;
  }
}

export interface PageContent<T> {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<T>;
  number?: number;
  sort?: Sort;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  pageable?: Pageable;
  empty?: boolean;
}
export class TableActionButton {
  label: string;
  action: string;
  icon: string;
  styleClass: string;
  display: (item: any) => boolean;
  tooltip: string;
  tooltipPosition = 'bottom';
  rounded: boolean;
  text: boolean;
  severity: string;
  size: 'small' | 'large' | undefined;

  constructor(
    label: string,
    action: string,
    // action: (item: any, $event: Event) => void,
    icon: string,
    display: (item: any) => boolean,
    styleClass: string,
    tooltip = '',
    tooltipPosition = 'bottom',
    rounded = false,
    text = false,
    severity = 'primary',
    size: 'small' | 'large' | undefined
  ) {
    this.label = label;
    this.action = action;
    this.icon = icon;
    this.styleClass = styleClass;
    if (display !== undefined && display !== null) this.display = display;
    else this.display = () => true;
    this.tooltip = tooltip;
    this.tooltipPosition = tooltipPosition;
    this.rounded = rounded;
    this.text = text;
    this.severity = severity;
    this.size = size;
  }
}

export interface DropdownFilter {
  label: string;
  value?: any;
  items?: Array<{ label: string; value: string }>;
}

export interface FilterChangedEvent {
  $event?: any;
  field?: string;
  value?: string | boolean | number | Array<string> | Array<number>;
}

export interface ActionButtonClickEvent {
  item: any;
  $event: Event;
  action: string;
}
