import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProductFiltersModalComponent } from './product-filters-modal.component';

@NgModule({
  declarations: [ProductFiltersModalComponent],
  imports: [
    CommonModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    InputNumberModule
  ],
  exports: [ProductFiltersModalComponent]
})
export class ProductFiltersModalModule {}
