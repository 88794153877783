// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:5000',
  apiUrl: 'https://allocation-api-stage.b4a.com.br',
  adminUrl: 'https://admin-stage.b4a.com.br/',
  firebase: {
    apiKey: 'AIzaSyCH-wx6DRc1-N1Dy_KpGjWirwlY8MUKI78',
    authDomain: 'b4a-prj-allocation-stg.firebaseapp.com',
    projectId: 'b4a-prj-allocation-stg',
    storageBucket: 'b4a-prj-allocation-stg.appspot.com',
    messagingSenderId: '89674853213',
    appId: '1:89674853213:web:776ed9c10411c958de16b4',
    vapidKey:
      'BEBotAJuwAVyYOdL5rMNY7WpSD9WhjkZQlih7lE4DeRnVEqeVv89ZpOHKnBPwDnz-lnKiB-sgq-Np8AihhDhB_0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
