import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DraftCompositionSummary } from 'src/app/allocation-api';

@Component({
  selector: 'app-composition-name-modal',
  templateUrl: './composition-name-modal.component.html',
  styleUrls: ['./composition-name-modal.component.scss']
})
export class CompositionNameModalComponent {
  composition: DraftCompositionSummary;
  formGroup: FormGroup;

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.composition = this.config.data.composition;
    this.formGroup = new FormGroup({
      draftCompositionName: new FormControl<string>(
        this.composition.draftCompositionName as string,
        [Validators.required, Validators.minLength(1)]
      ),
      draftCompositionId: new FormControl<number>(
        this.composition.draftCompositionId as number
      )
    });
  }

  async changeName(): Promise<void> {
    if (this.formGroup.valid) {
      this.ref.close(this.formGroup.value);
    }
  }
}
