import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DraftModule } from './pages/draft/draft.module';
import { LoginModule } from './pages/login/login.module';
import { SettingsModule } from './pages/settings/settings.module';
import { SyncronizationModule } from './pages/syncronization/syncronization.module';
import { UsersModule } from './pages/users/users.module';
import { AuthGuard } from './services/auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'draft',
    pathMatch: 'full'
  },
  {
    path: 'draft',
    canActivate: [AuthGuard],
    loadChildren: () => DraftModule
  },
  {
    path: 'syncronization',
    canActivate: [AuthGuard],
    loadChildren: () => SyncronizationModule
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => UsersModule
  },
  {
    path: 'login',
    loadChildren: () => LoginModule
  },
  {
    path: 'settings',
    loadChildren: () => SettingsModule
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
