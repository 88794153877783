import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PickList } from 'primeng/picklist';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import {
  Draft,
  DraftCompositionSummary,
  DraftControllerService,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { EditionControllerService } from 'src/app/allocation-api/api/editionController.service';
import { Edition } from 'src/app/allocation-api/model/edition';
import { Subscription } from 'src/app/allocation-api/model/subscription';
import { DraftStatus, getDraftStatus } from 'src/app/models/enums';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastUtil } from 'src/app/utils/toast.util';
@Component({
  selector: 'app-draft-form',
  templateUrl: './draft-form.component.html',
  styleUrls: ['./draft-form.component.scss'],
  standalone: false
})
export class DraftFormComponent implements OnInit {
  @ViewChild(PickList)
  picklist!: PickList;

  draft: Draft | undefined;
  subscriptions: Array<Subscription> | undefined;
  draftForm: FormGroup | undefined;
  editions: Array<Edition> | undefined;
  user: UserRoleViewResponse | undefined;
  subscriptionSegmentationReady = false;
  compositions: Array<DraftCompositionSummary> | undefined;
  users: Array<UserRoleViewResponse> | undefined;
  constructor(
    private draftService: DraftControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private editionService: EditionControllerService,
    private userService: UserControllerService,
    private cdRef: ChangeDetectorRef
  ) {}

  get isNew(): boolean {
    return !this.draft?.draftId;
  }

  ngOnInit(): void {
    LoaderService.showLoader();
    this.route.params.subscribe(async (params: Params) => {
      try {
        const promises = [
          lastValueFrom(
            this.userService.getInfo().pipe(map((data) => data.result))
          ),
          lastValueFrom(
            this.userService
              .findUsersAvaialble()
              .pipe(map((data) => data.result))
          )
        ];
        const [header, users] = await Promise.all(promises);
        this.subscriptions = [
          {
            subscriptionId: 1,
            subscriptionName: 'Glambox'
          },
          {
            subscriptionId: 5,
            subscriptionName: 'Glambag'
          },
          {
            subscriptionId: 7,
            subscriptionName: 'Glamcombo'
          }
        ];
        this.user = header as UserRoleViewResponse;
        this.users = (users as Array<UserRoleViewResponse>).filter(
          (u) =>
            u.roles.includes('ROLE_ADMIN') ||
            u.roles.includes('ROLE_ALLOC_ADMIN')
        );
        if (params['id']) {
          this.draft = await lastValueFrom(
            this.draftService
              .get(Number(params['id']))
              .pipe(map((data) => data.result))
          );
          this.compositions = await lastValueFrom(
            this.draftService
              .getDraftCompositions(Number(params['id']))
              .pipe(map((data) => data.result))
          );
        } else {
          this.draft = {
            draftStatus: getDraftStatus(DraftStatus.Open)?.value || 0,
            responsible: this.user.username
          };
        }
      } catch (error: any) {
        ToastUtil.showErrorToast(error);
        delete this.draft;
        delete this.subscriptions;
      }
      this.draftForm = new FormGroup({
        draftId: new FormControl<number | undefined>(this.draft?.draftId),
        name: new FormControl<string | undefined>(this.draft?.name, [
          Validators.required
        ]),
        editionId: new FormControl<number | undefined>(this.draft?.editionId, [
          Validators.required
        ]),
        owner: new FormControl<string | undefined>(
          this.isAdmin ? this.user.username : this.draft?.owner,
          [Validators.required]
        ),
        draftStatus: new FormControl<number | undefined>(
          this.draft?.draftStatus,
          [Validators.required]
        ),
        subscriptionId: new FormControl<number | undefined>(
          this.draft?.draftId ? Number(this.draft.editionId?.toString()[0]) : 1,
          [Validators.required]
        ),
        description: new FormControl<string | undefined>({
          value: this.draft?.description,
          disabled:
            this.draft?.draftId !== undefined &&
            this.draft?.owner !== this.user?.username
        })
      });
      if (
        this.draft?.draftId &&
        (!this.isResponsible ||
          this.compositions?.some((a) => a.compositionSubscribers))
      ) {
        this.draftForm.disable();
        if (!this.isOwner || this.isResponsible) {
          this.draftForm.controls['name'].enable();
          this.draftForm.controls['owner'].enable();
          this.draftForm.controls['description'].enable();
        }
      }
      try {
        this.editions = await lastValueFrom(
          this.editionService
            .getLastSubscriptions(this.rawValue?.subscriptionId || 1)
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        ToastUtil.showErrorToast(error);
        delete this.editions;
      }
      if (!this.draft?.draftId) LoaderService.showLoader(false);
    });
  }

  async submit(): Promise<void> {
    if (this.draftForm?.valid) {
      this.draftForm.markAllAsTouched();
      if (this.draftForm.touched && this.draftForm.dirty) {
        let draft: Draft;
        draft = this.rawValue as Draft;
        LoaderService.showLoader();
        try {
          if (!draft.draftId) {
            draft =
              (await firstValueFrom(
                this.draftService.save(draft).pipe(map((data) => data.result))
              )) || draft;
            // this.draftForm.get('draftId')?.setValue(this.draft?.draftId);
            this.router.navigate(['draft/form/id/' + draft?.draftId]);
          } else {
            if (this.draftForm.get('editionId')?.dirty) {
              delete this.draft;
              this.draftForm.get('editionId')?.markAsPristine();
            }
            this.draft = await firstValueFrom(
              this.draftService
                .update(draft.draftId, draft)
                .pipe(map((data) => data.result))
            );

            // Permitir trocar assinatura e edição se não houverem composições
            if (
              this.isOwner &&
              this.isResponsible &&
              !this.compositions?.some((c) => c.compositionSubscribers)
            ) {
              this.draftForm.controls['editionId'].enable();
              this.draftForm.controls['subscriptionId'].enable();
            } else if (
              this.isOwner &&
              !this.isResponsible &&
              (this.compositions?.length ||
                this.compositions?.some((c) => c.compositionSubscribers))
            ) {
              this.draftForm.disable();
              this.draftForm.controls['name'].enable();
              this.draftForm.controls['responsible'].enable();
              this.draftForm.controls['description'].enable();
            }
          }
        } catch (error) {
          ToastUtil.showErrorToast(error);
        }
        LoaderService.showLoader(false);
      }
    } else if (this.draftForm) {
      Object.keys(this.draftForm.controls).forEach((key) => {
        this.draftForm?.get(key)?.markAsDirty();
        this.draftForm?.get(key)?.markAsTouched();
      });
    }
  }

  releaseDate(edition: Edition): Date {
    if (edition.publicRelease) {
      return new Date(edition.publicRelease);
    }
    const month = edition.editionId?.toString().substring(5);
    const year = edition.editionId?.toString().substring(1, 4);
    return new Date(`${year}-${month?.padStart(2, '0')}-02`);
  }

  async findEditions(): Promise<void> {
    if (this.draftForm?.value?.subscriptionId) {
      try {
        LoaderService.showLoader();
        this.draftForm.get('editionId')?.reset();
        this.editions = await lastValueFrom(
          this.editionService
            .getLastSubscriptions(this.rawValue?.subscriptionId || 1)
            .pipe(map((data) => data.result))
        );
        if (this.draft?.draftId) {
          await this.submit();
        }
        this.cdRef.detectChanges();
        LoaderService.showLoader(false);
      } catch (error) {
        delete this.editions;
      }
    }
  }

  get rawValue(): (Draft & { subscriptionId: number }) | undefined {
    return {
      ...this.draftForm?.getRawValue(),
      responsible: this.user.username
    };
  }

  get isOwner(): boolean {
    return this.draft?.owner === this.user?.username;
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }

  get isAdmin() {
    return (
      TokenStorageService.getUserRoles()?.includes('ROLE_ALLOC_ADMIN') ||
      TokenStorageService.getUserRoles().includes('ROLE_ADMIN')
    );
  }
}
