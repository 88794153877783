import { Component, HostListener, inject, OnDestroy } from '@angular/core';
import {
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe
} from '@angular/fire/messaging';
import { lastValueFrom } from 'rxjs';
import { NotificationsControllerService } from 'src/app/allocation-api';
import { FirebaseTopic } from 'src/app/models/enums/FirebaseTopic';
import { CookieService } from 'src/app/services/cookie.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-listener',
  standalone: true,
  template: ''
})
export abstract class ChangeListenerComponent implements OnDestroy {
  private notificationsService = inject(NotificationsControllerService);
  protected messaging = inject(Messaging);

  unsubscribeMessages: Unsubscribe | undefined;
  lastMessages: { [key: string]: string } = {};
  topics: Array<FirebaseTopic>;
  @HostListener('window:focus', ['$event'])
  async onFocus(): Promise<void> {
    await this.onPageFocus();
  }

  constructor(topics: Array<FirebaseTopic>) {
    this.topics = topics;
    const token = CookieService.getCookie('FCMToken');
    if (token) {
      BroadcastUtil.get('browserMessage').subscribe((payload: MessagePayload) =>
        this.onMessageReceived(payload)
      );
      this.topics.forEach((topic) => this.subscribeToTopic(token, topic));
      this.unsubscribeMessages = onMessage(this.messaging, (payload) => {
        if (
          payload.data &&
          payload.data['topic'] &&
          this.lastMessages[payload.data['topic']] &&
          this.lastMessages[payload.data['topic']] === payload.messageId
        ) {
          console.log('duplicated message:', payload.messageId);
        } else if (
          this.topics.includes(
            payload.data['topic'].replace('-dev', '') as FirebaseTopic
          )
        ) {
          if (payload.data && payload.data['topic']) {
            this.lastMessages[payload.data['topic']] = payload.messageId;
            payload.data['topic'] = payload.data['topic'].replace('-dev', '');
          }
          this.onMessageReceived(payload);
        }
      });
    }
  }

  subscribeToTopic(token: string, topic: string): void {
    topic += !environment.production ? '-dev' : '';
    lastValueFrom(this.notificationsService.subscribeToTopic(topic, token))
      .then((response) => {
        console.log(response.result);
      })
      .catch((error) => console.error(error));
  }

  ngOnDestroy(): void {
    if (this.unsubscribeMessages) {
      this.unsubscribeMessages();
    }
    this.unsubscribeTopics();
  }

  private async unsubscribeTopics() {
    try {
      await Promise.all(
        this.topics.map((topic) =>
          this.notificationsService.unsubscribeFromTopic(
            topic,
            CookieService.getCookie('FCMToken')
          )
        )
      );
      console.log('Unsubscribed from topic(s): ', this.topics.toString());
    } catch (error) {
      console.error(error);
    }
  }

  abstract onMessageReceived(payload: MessagePayload): void;
  abstract onPageFocus(): void;
}
