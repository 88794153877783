<form class="horizontal" (submit)="submit()">
  <p-listbox
    [options]="products"
    [formControl]="product"
    [filter]="true"
    optionLabel="productVariantName"
    styleClass="fixed-height"
    filterPlaceHolder="Pesquise pelo Nome, Marca ou SKU"
    (onFilter)="filter($event)"
    [style]="{ height: '50vh' }"
  >
    <ng-template pTemplate="empty">
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading">
        {{
          searchValid
            ? 'Nenhum item encontrado'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptyFilter" let-product>
      <ng-container *ngIf="loading">
        <i class="pi pi-spinner pi-spin"></i>
      </ng-container>
      <ng-container *ngIf="!loading && !products?.length">
        {{
          searchValid
            ? 'Nenhum item encontrado'
            : 'Digite ao menos 3 caracteres'
        }}
      </ng-container>
      <ng-container *ngIf="!loading && products?.length">
        {{ product.productVariantName }}
        <!-- <ng-container *ngIf="product.internalEAN"
          >(SKU: {{ product.internalEAN }})</ng-container
        >
        {{ product.brandName }} -->
      </ng-container>
    </ng-template>
  </p-listbox>
  <div class="buttons">
    <p-button
      type="button"
      label="Salvar"
      (onClick)="submit()"
      severity="primary"
      [disabled]="!product.valid"
    ></p-button>
  </div>
</form>
