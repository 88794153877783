/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SegmentationRequest {
  draftId?: number;
  editionId?: number;
  personTypes?: number[];
  isNew?: number;
  isBirthday?: number;
  limit?: number;
  partnerType?: number;
  recurrenceMonth?: number;
  recurrence?: number;
  badgeIsNew?: number;
  badgeIds?: Array<number>;
  consecutiveEditions?: number;
  referenceBoxIds?: Array<string>;
  hasGift?: number;
  paymentDays?: Array<number>;
  isNewCycle?: number;
}
