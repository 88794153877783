import { DatePipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map, Subscription } from 'rxjs';
import {
  BadgeDTO,
  CompositionSubscriber,
  CompositionSummaryDTO,
  Draft,
  DraftCompositionProductReserved,
  DraftControllerService,
  DraftSubscriberView,
  InfluencerStatus,
  PersonControllerService,
  SegmentationRequest,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { DraftUpdate, Message, SegmentationUpdate } from 'src/app/models';
import { Topic } from 'src/app/models/enums';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { ToastUtil } from 'src/app/utils/toast.util';
import { DraftCompositionFiltersModalComponent } from '../draft-form/draft-composition-filters-modal/draft-composition-filters-modal.component';
import { CompositionSubscribersModalComponent } from './composition-subscribers-modal/composition-subscribers-modal.component';
import { ReviewRejectModalComponent } from './review-reject-modal/review-reject-modal.component';

@Component({
  selector: 'app-draft-summary',
  templateUrl: './draft-summary.component.html',
  styleUrls: ['./draft-summary.component.scss'],
  providers: [DatePipe, DialogService],
  standalone: false
})
export class DraftSummaryComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private draftService = inject(DraftControllerService);
  private datePipe = inject(DatePipe);
  private personService = inject(PersonControllerService);
  private dialogService = inject(DialogService);
  private userService = inject(UserControllerService);
  private confirmationService = inject(ConfirmationService);

  draft: Draft | undefined;
  draftFilters: SegmentationRequest | undefined;
  compositions: Array<Composition> | undefined;
  parnertTypes: Array<InfluencerStatus> | undefined;
  badges: Array<BadgeDTO> | undefined;
  waitingChange: DraftCompositionProductReserved | undefined;
  user: UserRoleViewResponse;
  subscriptions: Subscription[] = [];
  ngOnInit(): void {
    this.subscriptions.push(
      BroadcastUtil.get(Topic.ALLOCATION_DRAFT_CHANGE.toString()).subscribe(
        (message: Message<DraftUpdate>) => this.onMessageReceived(message.body)
      )
    );
    this.subscriptions.push(
      BroadcastUtil.get(
        Topic.ALLOCATION_SEGMENTATION_CHANGE.toString()
      ).subscribe((message: Message<DraftUpdate>) =>
        this.onMessageReceived(message.body)
      )
    );
    this.route.params.subscribe(async (params) => {
      if (params['draftId']) {
        LoaderService.showLoader();
        const id = Number(params['draftId']);
        const compositions = await lastValueFrom(
          this.draftService
            .getDraftCompositions(id)
            .pipe(map((data) => data.result))
        );
        if (compositions.some((c) => !c.draftCompositionStatus))
          this.router.navigate(['/draft/form/id/' + id + '/segmentation']);
        await Promise.all([
          this.getUserInfo(),
          this.findDraft(id),
          this.findCompositions(id),
          this.findFilters(id),
          this.findPartnerTypes(),
          this.findBadges()
        ]);
        LoaderService.showLoader(false);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  async findDraft(id: number): Promise<void> {
    this.draft = await lastValueFrom(
      this.draftService.get(id).pipe(map((data) => data.result))
    );
  }

  async findCompositions(id: number): Promise<void> {
    this.compositions = await lastValueFrom(
      this.draftService.getDraftSummary(id).pipe(map((data) => data.result))
    );
    if (!this.compositions?.length && this.responsible)
      this.confirmationService.confirm({
        acceptLabel: 'Reiniciar segmentação',
        rejectLabel: 'Excluir draft',
        acceptButtonStyleClass: 'p-button-primary',
        rejectButtonStyleClass: 'p-button-danger',
        message: 'Não existem composições no draft. O que desejar fazer?',
        header: 'Draft vazio',
        accept: () =>
          this.router.navigate(['/draft/form/id/' + this.draft.draftId]),
        reject: async () => await this.deleteDraft()
      });
  }

  async findFilters(id: number): Promise<void> {
    this.draftFilters = await lastValueFrom(
      this.draftService
        .getDraftSubscriberFilters(id)
        .pipe(map((data) => data.result))
    );
  }

  async getUserInfo(): Promise<void> {
    try {
      this.user = await lastValueFrom(
        this.userService.getInfo().pipe(map((data) => data.result))
      );
    } catch (error) {
      console.error(error);
      alert('Problemas ao busca usuário.');
    }
  }

  async findPartnerTypes(): Promise<void> {
    try {
      this.parnertTypes = await lastValueFrom(
        this.personService.getPartnerTypes().pipe(map((data) => data.result))
      );
    } catch (error) {
      console.error(error);
      this.parnertTypes = [];
    }
  }

  async findBadges(): Promise<void> {
    try {
      this.badges = await lastValueFrom(
        this.personService.getBadgesList().pipe(map((data) => data.result))
      );
    } catch (error) {
      console.error(error);
      this.badges = [];
    }
  }

  async toggleComposition(composition: Composition): Promise<void> {
    if (!composition.products) {
      LoaderService.showLoader();
      composition.products = await lastValueFrom(
        this.draftService
          .getCompositionProducts(composition.draftCompositionId as number)
          .pipe(map((data) => data.result))
      );
      LoaderService.showLoader(false);
    }
  }

  async showCompositionSubscribers(composition: Composition): Promise<void> {
    this.dialogService.open(CompositionSubscribersModalComponent, {
      data: {
        draftCompositionId: composition.compositionId,
        draft: this.draft
      },
      modal: true,
      header: 'Assinantes da composição ' + composition.draftCompositionName,
      width: '80vw'
    });
  }

  async showFilters(product: DraftCompositionProductReserved): Promise<void> {
    this.dialogService.open(DraftCompositionFiltersModalComponent, {
      data: {
        reserved: product,
        draftComposition: {
          ...(this.compositions?.find(
            (c) => c.draftCompositionId === product.draftCompositionId
          ) || {}),
          draftId: this.draft.draftId
        }
      },
      modal: true,
      width: '70%',
      closable: true,
      header: product?.productVariantName,
      maximizable: true
    });
  }

  get subscriptionName(): string {
    const subscriptionId = Number(this.draft?.editionId?.toString()[0]) || 1;
    switch (subscriptionId) {
      case 5:
        return 'Glambag';
      case 6:
        return 'Glampass';
      case 7:
        return 'Glamcombo';
      default:
        return 'Glambox';
    }
  }

  get editionDate(): string | null {
    const editionId = this.draft?.editionId as number;
    const data =
      ((editionId / 100) % 10000).toFixed(0) +
      '-' +
      (editionId % 100).toString().padStart(2, '0') +
      '-01 12:00:00';
    return this.datePipe.transform(
      new Date(data),
      'MMMM/yyyy',
      undefined,
      'pt-BR'
    );
  }

  get totalSubscribers(): number {
    return (
      this.compositions?.reduce(
        (sum, c) => (sum += c.subscribers as number),
        0
      ) || 0
    );
  }

  get personType(): string {
    let types = '';
    if (this.draftFilters?.personTypes?.includes(1)) {
      types += 'Glamgirl';
    }
    if (this.draftFilters?.personTypes?.includes(2)) {
      types += (types.length ? ', ' : '') + 'Glampartner';
    }
    return types;
  }

  get parnerType(): string | undefined {
    return this.draftFilters?.partnerType &&
      this.draftFilters.personTypes?.includes(2)
      ? this.parnertTypes?.find(
          (t) => t.influencerStatusTypeId === this.draftFilters?.partnerType
        )?.influencerStatus
      : 'Todas';
  }

  get timeFilter(): string {
    switch (this.draftFilters?.isNew) {
      case 0:
        return 'Antigas';
      case 1:
        return 'Novas';
      default:
        return 'Todas';
    }
  }

  get recurrenceMonth(): string {
    if (this.draftFilters?.recurrenceMonth) {
      const year = (
        (this.draftFilters?.recurrenceMonth as number) / 100
      ).toFixed(0);
      const month = (this.draftFilters?.recurrenceMonth as number) % 100;
      const date = new Date(year + '-' + month + '-01 12:00:00');
      return (
        this.datePipe.transform(date, 'MMMM/yyyy', undefined, 'pt-BR') ||
        'Todos'
      );
    }
    return 'Todos';
  }

  get recurrence(): string {
    switch (this.draftFilters?.recurrence) {
      case 0:
        return 'Não';
      case 1:
        return 'Sim';
      default:
        return 'Todos';
    }
  }

  get birthday(): string {
    switch (this.draftFilters?.isBirthday) {
      case 0:
        return 'Não';
      case 1:
        return 'Sim';
      default:
        return 'Todos';
    }
  }

  get badgeIsNew(): string {
    switch (this.draftFilters?.badgeIsNew) {
      case 0:
        return 'Não';
      case 1:
        return 'Sim';
      default:
        return 'Todos';
    }
  }

  get badge(): string | undefined {
    return this.draftFilters?.badgeIds?.length
      ? this.draftFilters?.badgeIds
          .map((id) => this.badges?.find((b) => b.badgeId === id)?.title)
          .toString()
      : 'Todos';
  }

  get gift(): string {
    switch (this.draftFilters?.hasGift) {
      case 0:
        return 'Não';
      case 1:
        return 'Sim';
      default:
        return 'Todos';
    }
  }

  get paymentDay(): string {
    return this.draftFilters?.paymentDays?.length
      ? this.draftFilters?.paymentDays.toString()
      : 'Todos';
  }

  get subscribersLimit(): string {
    return this.draftFilters?.limit
      ? this.draftFilters?.limit?.toString()
      : 'Não';
  }

  get owner() {
    return this.user?.username === this.draft?.owner;
  }

  get responsible() {
    return this.user?.username === this.draft?.responsible;
  }

  get pendingReview() {
    return this.owner && !this.draft?.draftStatus && !this.draft.approved;
  }

  get problems() {
    return this.compositions?.filter((c) => c.message)?.length || 0;
  }

  get approveAll() {
    return (
      this.pendingReview &&
      this.compositions?.length &&
      this.compositions.every((c) => !c.approved && !c.message)
    );
  }

  subscriptionNameToSendComposition(subscriptionId: number): string {
    switch (subscriptionId) {
      case 1:
        return 'Glambox';
      case 5:
        return 'Glambag';
      default:
        return 'Não definido';
    }
  }

  isSummaryCombo(): boolean {
    return this.draft?.editionId?.toString()[0] === '7';
  }

  async approveDraft() {
    this.confirmationService.confirm({
      acceptLabel: 'Aprovar',
      rejectLabel: 'Voltar',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      header: 'Aprovar draft',
      message: 'Deseja aprovar o draft e todas as composições?',
      accept: async () => {
        try {
          LoaderService.showLoader();
          const detail = await lastValueFrom(
            this.draftService
              .approveDraft(this.draft.draftId)
              .pipe(map((data) => data.result))
          );
          ToastUtil.addToast({
            severity: 'success',
            detail,
            summary: 'Aprovado'
          });
          this.askFinishDraft();
        } catch (error) {
          ToastUtil.showErrorToast(error);
        } finally {
          LoaderService.showLoader(false);
        }
      }
    });
  }

  async approveComposition(composition: Composition) {
    try {
      LoaderService.showLoader();
      const detail = await lastValueFrom(
        this.draftService
          .approveDraftComposition(composition.draftCompositionId)
          .pipe(map((data) => data.result))
      );
      ToastUtil.addToast({
        severity: 'success',
        detail,
        summary: 'Aprovado'
      });
      composition.approved = true;
      delete composition.message;
      if (this.compositions.every((c) => c.approved)) {
        this.askFinishDraft();
      } else if (this.compositions.every((c) => c.approved || c.message)) {
        ToastUtil.addToast({
          severity: 'info',
          detail: 'Revisão finalizada.',
          summary: 'Sucesso'
        });
        this.router.navigate(['/draft']);
      }
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  rejectComposition(composition: Composition) {
    this.dialogService
      .open(ReviewRejectModalComponent, {
        data: { composition },
        header: 'Reprovar composição ' + composition.draftCompositionName,
        modal: true,
        width: '600px'
      })
      .onClose.subscribe(async (review?: { message: string }) => {
        if (review) {
          try {
            LoaderService.showLoader();
            const detail = await lastValueFrom(
              this.draftService
                .rejectDraftComposition({
                  ...review,
                  draftCompositionId: composition.draftCompositionId
                })
                .pipe(map((data) => data.result))
            );
            ToastUtil.addToast({
              detail,
              severity: 'success',
              summary: 'Reprovado'
            });
            composition.message = review.message;
            composition.approved = false;
            if (this.compositions.every((c) => c.approved || c.message)) {
              ToastUtil.addToast({
                severity: 'info',
                detail: 'Revisão finalizada.',
                summary: 'Sucesso'
              });
              this.router.navigate(['/draft']);
            }
          } catch (error) {
            ToastUtil.showErrorToast(error);
          } finally {
            LoaderService.showLoader(false);
          }
        }
      });
  }

  askFinishDraft() {
    this.confirmationService.confirm({
      acceptLabel: 'Gerar composições',
      rejectLabel: 'Apenas aprovar',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      message: 'Deseja concluir o draft e gerar as composições?',
      header: 'Draft aprovado',
      accept: async () => {
        await this.finishDraft();
      },
      reject: () => {
        this.router.navigate(['/draft']);
      }
    });
  }

  async finishDraft() {
    try {
      LoaderService.showLoader();
      const result = await lastValueFrom(
        this.draftService
          .finishDraft(this.draft?.draftId)
          .pipe(map((data) => data.result))
      );
      if (result) {
        ToastUtil.addToast({
          detail: result,
          summary: 'Sucesso',
          severity: 'success'
        });
      }
      window.location.reload();
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async editComposition(composition: Composition) {
    try {
      LoaderService.showLoader();
      const open = await lastValueFrom(
        this.draftService
          .reopenDraftComposition(composition.draftCompositionId)
          .pipe(map((data) => data.result))
      );
      this.router.navigate([
        '/draft/form/id/' + this.draft.draftId + '/segmentation'
      ]);
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async deleteComposition(composition: Composition) {
    try {
      LoaderService.showLoader();
      const detail = await lastValueFrom(
        this.draftService
          .deleteDraftComposition(composition.draftCompositionId)
          .pipe(map((data) => data.result))
      );
      ToastUtil.addToast({
        detail,
        severity: 'success',
        summary: 'Sucesso'
      });
      await this.findCompositions(this.draft.draftId);
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async deleteDraft() {
    try {
      LoaderService.showLoader();
      const detail = await lastValueFrom(
        this.draftService
          .deleteDraft(this.draft.draftId)
          .pipe(map((data) => data.result))
      );
      ToastUtil.addToast({
        detail,
        severity: 'success',
        summary: 'Sucesso'
      });
      this.router.navigate(['/draft']);
    } catch (error) {
      ToastUtil.showErrorToast(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  onMessageReceived(payload: DraftUpdate | SegmentationUpdate): void {
    if (payload['draftId'] === this.draft.draftId && !this.responsible) {
      ToastUtil.addToast({
        severity: 'warning',
        summary: 'Atenção',
        detail: 'O draft foi alterado, a página será atualizada.'
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }
}

export interface Composition extends CompositionSummaryDTO {
  subscribersList?: Array<CompositionSubscriber | DraftSubscriberView>;
}
