<div class="draft-form" *ngIf="draftForm && subscriptions && users">
  <div class="title" *ngIf="!isNew && draftForm?.enabled">
    Editar draft: {{ draft?.draftId }}
  </div>
  <div class="title" *ngIf="!isNew && draftForm?.disabled">
    Segmentar draft: {{ draft?.draftId }}
  </div>
  <div class="title" *ngIf="isNew">Criar draft</div>

  <form
    class="horizontal"
    (submit)="submit()"
    [formGroup]="draftForm"
    *ngIf="draftForm"
  >
    <span class="p-float-label form-control">
      <input
        id="name"
        type="text"
        pInputText
        formControlName="name"
        (blur)="draft?.draftId && submit()"
      />
      <label for="name">Nome do draft</label>
    </span>
    <span class="p-float-label form-control">
      <p-dropdown
        [options]="subscriptions"
        optionLabel="subscriptionName"
        optionValue="subscriptionId"
        formControlName="subscriptionId"
        [autoDisplayFirst]="false"
        id="subscriptionId"
        (onChange)="findEditions()"
      >
        <ng-template pTemplate="item" let-subscription>
          {{ subscription.subscriptionName | titlecase }}
        </ng-template>
        <ng-template pTemplate="selectedItem" let-subscription>
          {{ subscription?.subscriptionName | titlecase }}
        </ng-template>
      </p-dropdown>
      <label for="subscriptionId">Produto</label>
    </span>
    <span class="p-float-label form-control" *ngIf="editions">
      <p-dropdown
        [options]="editions"
        optionLabel="theme"
        optionValue="editionId"
        formControlName="editionId"
        [autoDisplayFirst]="false"
        (onChange)="draft?.draftId && submit()"
        id="editionId"
      >
        <ng-template pTemplate="item" let-edition>
          {{ edition.theme }} -
          {{ releaseDate(edition) | date : 'MMMM/yyyy' | titlecase }}
        </ng-template>
      </p-dropdown>
      <label for="editionId">Edição desejada</label>
    </span>
    <span
      class="p-float-label form-control"
      *ngIf="!isAdmin && (!draft?.draftId || user?.username !== draft?.owner)"
    >
      <p-dropdown
        [options]="users"
        optionLabel="username"
        optionValue="username"
        formControlName="owner"
        (onChange)="draft?.draftId && submit()"
        id="owner"
        [filter]="true"
        filterBy="username"
      >
        <ng-template pTemplate="item" let-user>
          {{ user.username }}
          <ng-container *ngIf="user.username === user?.username">
            &nbsp;
            <i class="pi pi-user"></i>
          </ng-container>
        </ng-template>
      </p-dropdown>
      <label for="owner">Responsável</label>
    </span>
    <span
      class="p-float-label form-control form-control--textarea"
      [class.form-control--textarea--full]="draft?.draftId"
    >
      <textarea
        pInputTextarea
        formControlName="description"
        id="description"
        (blur)="draft?.draftId && submit()"
      ></textarea>
      <label for="description">Descrição</label>
    </span>
    <span class="form-button">
      <p-button
        type="submit"
        [label]="isNew ? 'Salvar' : 'Continuar'"
        [disabled]="!draftForm.valid"
        *ngIf="!draft?.draftId"
      ></p-button>
    </span>
  </form>
  <div class="composition-limit" *ngIf="draft?.draftId && rawValue?.editionId">
    <app-draft-subscriber-filter-form
      [draft]="draft"
      [editionId]="draft?.editionId"
      [compositions]="compositions"
      [user]="user"
    >
    </app-draft-subscriber-filter-form>
  </div>
</div>
