import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  BrandSummary,
  ProductCategory,
  ProductVariantAvailable
} from 'src/app/allocation-api';
import { getAllBrandEquities } from 'src/app/models/enums/BrandEquity';

@Component({
  selector: 'app-product-filters-modal',
  templateUrl: './product-filters-modal.component.html',
  styleUrls: ['./product-filters-modal.component.scss']
})
export class ProductFiltersModalComponent {
  categories: Array<ProductCategory & { parent?: string }> = [
    { displayName: 'Sem categoria', externalId: 0, categoryId: null as any }
  ];
  brands: Array<BrandSummary>;
  filtersGroup = new FormGroup({
    categories: new FormControl<Array<number>>([]),
    brands: new FormControl<Array<number>>([]),
    brandEquities: new FormControl<Array<number>>([]),
    minStock: new FormControl<number>(0, Validators.min(0)),
    maxStock: new FormControl<number | null>(null),
    minSubscribers: new FormControl<number>(0, Validators.min(0)),
    maxSubscribers: new FormControl<number | null>(null, Validators.min(0))
  });
  products: Array<ProductVariantAvailable>;
  brandEquities: Array<{ label: string; value: number }>;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.brandEquities = getAllBrandEquities();
    const categories = this.categories.concat(this.config.data.categories);
    this.brands = this.config.data.brands;
    this.brands.sort((b1, b2) =>
      b1.brandName?.toLocaleLowerCase() === 'sem marca'
        ? -1
        : b2.brandName?.toLocaleLowerCase() === 'sem marca'
        ? 1
        : 0
    );
    this.products = this.config.data.products;
    this.categories = [];
    categories
      .filter((c) => !c.parentId)
      .forEach((c) => {
        this.categories.push(c);
        this.categories = this.categories.concat(
          categories
            .filter((cc) => cc.parentId === c.categoryId)
            .map((cc) => ({ ...cc, parent: c.displayName }))
        );
      });
    if (
      this.config.data.brandsSelected ||
      this.config.data.categoriesSelected ||
      this.config.data.minStock ||
      this.config.data.maxStock
    ) {
      this.filtersGroup.reset({
        brands: this.config.data.brandsSelected,
        categories: this.config.data.categoriesSelected,
        brandEquities: this.config.data.brandEquities,
        maxStock: this.config.data.maxStock,
        minStock: this.config.data.minStock || 0,
        minSubscribers: this.config.data.minSubscribers || 0,
        maxSubscribers: this.config.data.maxSubscribers
      });
    }
  }

  applyFilters(): void {
    this.ref.close({
      ...this.filtersGroup.value,
      products: this.productsInFilters
    });
  }

  resetFilters(close = false): void {
    this.filtersGroup.reset({
      brands: [],
      categories: [],
      brandEquities: [],
      maxStock: null,
      minStock: 0,
      minSubscribers: 0
    });
    if (close) {
      this.applyFilters();
    }
  }

  categoryName(category: ProductCategory): string {
    return category.parentId
      ? this.categories.find((c) => c.categoryId === category.parentId)
          ?.externalId +
          '.' +
          category.externalId +
          ' ' +
          category.displayName
      : category.externalId + ' ' + category.displayName;
  }

  get productsInFilters(): Array<ProductVariantAvailable> {
    let products: Array<ProductVariantAvailable> = [];
    if (this.products?.length) {
      products = this.products.filter(
        (p) =>
          (p.stock as number) >= (this.filtersGroup.value.minStock as number)
      );
      if (this.filtersGroup.value.maxStock) {
        products = this.products.filter(
          (p) =>
            (p.stock as number) <= (this.filtersGroup.value.maxStock as number)
        );
      }
      if (this.filtersGroup.value.brands?.length) {
        products = products?.filter((p) =>
          this.filtersGroup.value.brands?.includes(p.brandId as number)
        );
      }
      if (this.filtersGroup.value.categories?.length) {
        products = products?.filter(
          (p) =>
            this.filtersGroup.value.categories?.includes(
              p.categoryId as number
            ) ||
            this.filtersGroup.value.categories?.includes(
              p.subcategoryId as number
            )
        );
      }
      if (this.filtersGroup.value.brandEquities?.length) {
        products = products?.filter((p) =>
          this.filtersGroup.value.brandEquities?.includes(
            p.brandEquity as number
          )
        );
      }
    }
    return products;
  }

  get buttonLabel(): string {
    return `Aplicar filtros (${this.productsInFilters?.length || 0} produtos)`;
  }
}
