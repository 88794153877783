import { BroadcastUtil } from '../utils/broadcast.util';
import { TokenStorageService } from './auth/token-storage.service';

export class AppDialogService {
  static showInfoDialog(
    options:
      | DialogOptions
      | { message: string; header: string; visible?: boolean },
    goBack = false
  ): void {
    options.visible = true;
    BroadcastUtil.get('dialog').emit({ options, goBack });
  }

  static showErrorDialog(
    error: any,
    goBack = false,
    genericMessage = 'Erro ao realizar esta operação, entre em contato com o suporte'
  ): void {
    if (TokenStorageService.userLogged && error.status === 403) {
      return;
    }
    console.error(error);
    this.showInfoDialog(
      {
        message: error?.error?.message || error?.message || genericMessage,
        header: 'Erro',
        visible: true
      },
      goBack
    );
  }
}

export interface DialogOptions {
  header?: string;
  draggable?: boolean;
  keepInViewport?: boolean;
  resizable?: boolean;
  contentStyle?: object;
  visible: boolean;
  modal?: boolean;
  position?:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topleft'
    | 'topright'
    | 'bottomleft'
    | 'bottomright';
  blockScroll?: boolean;
  closeOnEscape?: boolean;
  dismissableMask?: boolean;
  rtl?: boolean;
  closable?: boolean;
  appendTo?: object;
  style?: object;
  styleClass?: string;
  maskStyleClass?: string;
  contentStyleClass?: string;
  showHeader?: boolean;
  baseZIndex?: number;
  autoZIndex?: boolean;
  minX?: number;
  minY?: number;
  focusOnShow?: boolean;
  focusTrap?: boolean;
  maximizable?: boolean;
  breakpoints?: object;
  transitionOptions?: string;
  closeIcon?: string;
  closeAriaLabel?: string;
  closeTabindex?: string;
  minimizeIcon?: string;
  maximizeIcon?: string;
  message: string;
}

export interface TypedError {
  error?: {
    message: string;
  };
  message?: string;
  status?: number;
}
