import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';

export class FormUtil {
  static validarCPF(cpf: string): Observable<boolean> {
    let soma;
    let resto;
    soma = 0;

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '00000000000') {
      return of(false);
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return of(false);
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return of(false);
    }
    return of(true);
  }

  static cpfValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validarCPF(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static cardExpirationValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validateExpiration(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  static validateExpiration(expiration: string): Observable<boolean> {
    const exp = [expiration.substring(0, 2), expiration.substring(2, 4)];
    if (Number(exp[0]) > 12) {
      return of(false);
    }
    if (Number(exp[1]) + 2000 < new Date().getFullYear()) {
      return of(false);
    }
    const date = new Date(Number(exp[1]) + 2000 + '-' + exp[0] + '-01');
    if (date.getTime() < new Date().getTime()) {
      return of(false);
    }
    return of(true);
  }

  static getCardFlagId(flagName: string): number {
    switch (flagName.toLowerCase()) {
      case 'visa':
        return 1;
      case 'master':
      case 'mastercard':
      case 'master card':
        return 2;
      case 'amex':
        return 3;
      case 'diners':
        return 4;
      case 'hipercard':
        return 5;
      case 'elo':
        return 6;
      case 'discover':
        return 7;
      case 'aura':
        return 8;
      case 'jcb':
        return 9;
      default:
        return 0;
    }
  }

  static getFlagById(id: number): string {
    switch (id) {
      case 1:
        return 'visa';
      case 2:
        return 'master';
      case 3:
        return 'amex';
      case 4:
        return 'diners';
      case 5:
        return 'hipercard';
      case 6:
        return 'elo';
      case 7:
        return 'discover';
      case 8:
        return 'aura';
      case 9:
        return 'jcb';
      default:
        return 'credit-card';
    }
  }

  static prediction(editionId: number): Date {
    let date = new Date();
    date = new Date(
      date.setMonth(
        Number(
          editionId
            .toString()
            .substring(
              editionId.toString().length - 2,
              editionId.toString().length
            )
        )
      )
    );
    date = new Date(
      date.setFullYear(Number(editionId.toString().substring(1, 5)))
    );
    date = new Date(date.setDate(15));
    return date;
  }

  static getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static date(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');
      const dayDate = +day;
      const monthDate = +month;
      const yearDate = +year;

      if (dayDate > 31) {
        return { invalidDate: 'Data inválida' };
      }

      if (monthDate > 12) {
        return { invalidDate: 'Data inválida' };
      }

      if (yearDate < 1000) {
        return { invalidDate: 'Data inválida' };
      }

      return null;
    };
  }

  static beforeToday(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');

      if (
        new Date(Number(year), Number(month), Number(day)).getTime() >
        new Date().getTime()
      ) {
        return { invalidDate: 'Maior que a data atual' };
      }
      return null;
    };
  }

  static afterToday(): ValidatorFn {
    return (formControl: AbstractControl) => {
      if (!formControl.value) {
        return null;
      }

      const [day, month, year]: string = formControl.value.split('/');

      if (
        new Date(Number(year), Number(month), Number(day)).getTime() <
        new Date().getTime()
      ) {
        return { invalidDate: 'Menor que a data atual' };
      }
      return null;
    };
  }

  static birthdayYear(): ValidatorFn {
    const validator = (formControl: FormControl) => {
      if (!formControl.value) {
        return null;
      }

      const { 2: year }: string = formControl.value.split('/');
      const birthdayYear = +year;

      const currentYear = new Date().getFullYear();
      const numberYearsSubtract = 300;
      const minYearsOld = 18;
      const maxYearsOld = 120;
      const minValidYear = currentYear - numberYearsSubtract;

      if (currentYear - birthdayYear < minYearsOld) {
        return { invalidDate: 'Menor de 18 anos' };
      }

      if (currentYear - birthdayYear > maxYearsOld) {
        return { invalidDate: 'Idade inválida' };
      }

      if (birthdayYear < minValidYear) {
        return { invalidDate: 'Ano inválido' };
      }

      return null;
    };

    return validator as ValidatorFn;
  }

  static monthDiff(d1: Date, d2: Date) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  static onlyNumbers(text: string): number {
    return Number(text.replace(/\D/g, ''));
  }

  static stringAsDate(text: string): Date {
    const split = text.split('/');
    return new Date(split[2] + '-' + split[1] + '-' + split[0]);
  }

  static dateStringAsDate(text: string): Date {
    return new Date(text.split('T')[0] + ' 12:00:00');
  }

  static timeDiffLabel(dateStart: string, datePipe: DatePipe): string {
    const today = new Date();
    const startDate = this.utcDate(dateStart);
    const diffMs = Math.abs(today.getTime() - startDate.getTime()); // milliseconds between now & startDate
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if (diffDays > 0) {
      if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
        return datePipe.transform(startDate, 'dd/MM/yyyy') || '';
      }
      return datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss') || '';
    } else if (diffMins > 0 && diffHrs < 1) {
      return `Há ${diffMins} minuto(s)`;
    } else if (diffHrs >= 1 && diffHrs < 2) {
      return `Há 1 hora`;
    } else if (diffHrs >= 2 && diffHrs < 13) {
      return `Há ${diffHrs} horas`;
    } else if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
      return datePipe.transform(startDate, 'dd/MM/yyyy') || '';
    } else if (diffMins <= 0 && diffMs > 0) {
      return 'Agora';
    } else {
      return datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss') || '';
    }
  }

  static couponDiscountValue(
    value: number,
    couponType: number,
    currencyPipe: CurrencyPipe
  ): string {
    return couponType === 0
      ? `${value * 100} %`
      : (currencyPipe.transform(value, 'BRL') as string);
  }

  static isDateBefore(date: Date, dateMax: Date): boolean {
    return date.getTime() <= dateMax.getTime();
  }

  public static semAcento(string: string): string {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public static getSlug(string: string): string {
    return this.semAcento(string).replace(' ', '_').toLowerCase();
  }

  public static cpfFormatted(cpf: number): string {
    return cpf
      .toString()
      .padStart(11, '0')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  public static editionDate(editionId: number): string {
    return `${((editionId % 1000000) % 100).toString().padStart(2, '0')}/${(
      (editionId % 1000000) /
      100
    ).toFixed(0)}`;
  }

  public static editionImage(editionId: number): string {
    return (
      'https://s3.amazonaws.com/Glambox.Content.MediaObject/Edition/' +
      (editionId / 1000000 >= 1 ? editionId : '1' + editionId) +
      '_boxEdition.png'
    );
  }

  public static getColor(
    subscriptionId: number | null | undefined,
    installments: number | undefined,
    subscriptionTypeId: number | undefined
  ): string | undefined {
    return subscriptionId !== undefined && subscriptionId !== null
      ? this.colors[subscriptionId].find(
          (c) =>
            c.installments === installments &&
            (c.subscriptionTypeId === undefined ||
              c.subscriptionTypeId === subscriptionTypeId)
        )?.color
      : this.colors[0][this.colors[0].length - 1].color;
  }

  public static get colors(): {
    [key: number]: Array<{
      installments?: number;
      subscriptionTypeId?: number;
      color: string;
    }>;
  } {
    return {
      1: [
        { color: '#FE357B', installments: 12 },
        { color: '#c09cea', installments: 1, subscriptionTypeId: 70 },
        { color: '#ffc0de', installments: 1, subscriptionTypeId: 1 },
        { color: '#FE86B0', installments: 6 },
        { color: '#4d4d4d' }
      ],
      0: [
        { color: '#FE357B', installments: 12 },
        { color: '#c09cea', installments: 1, subscriptionTypeId: 70 },
        { color: '#ffc0de', installments: 1, subscriptionTypeId: 1 },
        { color: '#FE86B0', installments: 6 },
        { color: '#4d4d4d' }
      ],
      5: [
        { color: '#8540F5', installments: 12 },
        { color: '#dbbcff', installments: 1 },
        { color: '#BB95F8', installments: 6 },
        { color: '#4d4d4d' }
      ],
      6: [
        { color: '#FF7100', installments: 12 },
        { color: '#ffc89d', installments: 1 },
        { color: '#FFA965', installments: 6 },
        { color: '#4d4d4d' }
      ],
      7: [
        { color: '#008733', installments: 12 },
        { color: '#bafdd2', installments: 1 },
        { color: '#ACD8BD', installments: 6 },
        { color: '#4d4d4d' }
      ]
    };
  }

  public static get productReferencesOnRewardTypes(): Array<number> {
    return [-6, -5, -4, -3, -1, 51];
  }

  public static get personReferencesOnRewardTypes(): Array<number> {
    return [2, 22, 23, 24, 38, 39, 40, 41, 43, 42];
  }

  public static get subscriberReferencesOnRewardTypes(): Array<number> {
    return [10, 12, 14];
  }

  public static subscriberStatusColor(subscriberStatus: number): string {
    switch (subscriberStatus) {
      case 1:
        return '#3f68aa';
      case 2:
        return '#015104';
      case 3:
        return '#87380a';
      case 4:
        return '#6ebdbf';
      case 5:
      case 6:
        return '#657af2';
      default:
        return '#ef4444';
    }
  }

  static validateImageUrl(url: string): Observable<boolean> {
    return of(url.match(/\.(jpeg|jpg|gif|png|webp)/) != null);
  }

  static imageUrlValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.validateImageUrl(control.value).pipe(
        map((res) => {
          // if res is true, username exists, return true
          return !res ? { invalid: true } : null;
          // NB: Return null if there is no error
        })
      );
    };
  }

  public static utcDate(dateWithTimezone: Date | string): Date {
    let date: Date;
    if (dateWithTimezone instanceof Date) {
      date = new Date(dateWithTimezone.getTime());
    } else {
      date = new Date(dateWithTimezone);
    }
    const dateAux = new Date(date.getTime());
    date.setMilliseconds(dateAux.getUTCMilliseconds());
    date.setSeconds(dateAux.getUTCSeconds());
    date.setMinutes(dateAux.getUTCMinutes());
    date.setHours(dateAux.getUTCHours());
    date.setDate(dateAux.getUTCDate());
    date.setMonth(dateAux.getUTCMonth());
    date.setFullYear(dateAux.getUTCFullYear());
    return date;
  }
}
