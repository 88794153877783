<form (submit)="changePassword()" [formGroup]="passwordForm">
  <span class="p-float-label form-control" *ngIf="!modal">
    <input
      type="password"
      pInputText
      formControlName="oldPassword"
      name="oldPassword"
    />
    <label for="oldPassword">Senha atual</label>
  </span>
  <span class="p-float-label form-control">
    <input
      type="password"
      pInputText
      formControlName="newPassword"
      name="password"
    />
    <label for="password">Nova senha</label>
  </span>
  <span class="p-float-label form-control">
    <input
      type="password"
      pInputText
      formControlName="newPasswordConfirmation"
      name="passwordConfirmation"
    />
    <label for="passwordConfirmation">Confirmação de senha</label>
  </span>
  <p-button
    label="Alterar"
    type="submit"
    [disabled]="!passwordForm.valid"
    styleClass="p-button-danger"
  >
  </p-button>
</form>
