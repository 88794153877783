import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Scroller, ScrollerLazyLoadEvent } from 'primeng/scroller';
import { lastValueFrom, map, Subscription } from 'rxjs';
import {
  BrandSummary,
  Draft,
  DraftCompositionFilterControllerService,
  DraftCompositionProductReserved,
  DraftCompositionSummary,
  DraftControllerService,
  DraftDefaultProductView,
  DraftProductFilter,
  DuplicatedSubscribers,
  Filter,
  FilterDTO,
  FilterType,
  Group,
  PersonControllerService,
  ProductCategory,
  ProductControllerService,
  ProductVariantAvailable,
  ProductVariantAvailableDraft,
  ProductVariantBrand,
  UserControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { BlockPageComponent } from 'src/app/components/block-page/block-page.component';
import { ProductFiltersModalComponent } from 'src/app/components/product-filters-modal/product-filters-modal.component';
import { RepeatedSubcategoriesModalComponent } from 'src/app/components/repeated-subcategories-modal/repeated-subcategories-modal.component';
import {
  DraftUpdate,
  Message,
  SegmentationUpdate,
  StockUpdate,
  SyncronizationUpdate
} from 'src/app/models';
import { DraftStatus, getDraftStatus, Topic } from 'src/app/models/enums';
import { getEquityLabel } from 'src/app/models/enums/BrandEquity';
import { SegmentationAction } from 'src/app/models/enums/SegmentationAction';
import { CookieService } from 'src/app/services/cookie.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { FormatUtil } from 'src/app/utils/format.util';
import { ToastUtil } from 'src/app/utils/toast.util';
import { CompositionSubscribersModalComponent } from '../../draft-summary/composition-subscribers-modal/composition-subscribers-modal.component';
import { CompositionNameModalComponent } from '../composition-name-modal/composition-name-modal.component';
import { CompositionSummaryModalComponent } from '../composition-summary-modal.component/composition-summary-modal.component';
import { DraftCompositionFiltersModalComponent } from '../draft-composition-filters-modal/draft-composition-filters-modal.component';

@Component({
  selector: 'app-draft-segmentation',
  templateUrl: './draft-segmentation.component.html',
  styleUrls: ['./draft-segmentation.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService],
  standalone: false
})
export class DraftSegmentationComponent implements OnInit, OnDestroy {
  products:
    | Array<
        ProductVariantAvailable & {
          subscribers?: number;
          totalFilters?: number;
        }
      >
    | undefined;
  productsList:
    | Array<
        ProductVariantAvailable & {
          subscribers?: number;
          totalFilters?: number;
        }
      >
    | undefined;
  draft: Draft | undefined;
  draftId: number | undefined;
  draftComposition?: DraftCompositionSummary;
  draggedProduct?: ProductVariantAvailable | DraftCompositionProductReserved;
  draftCompositions: Array<DraftCompositionSummary> | undefined;
  draftCompositionProducts: Array<DraftCompositionProductReserved> = [];
  draftCompositionFilters: Array<DraftProductFilter> | undefined;
  user: UserRoleViewResponse | undefined;
  totalPages = 1;
  page = 0;
  pageSize = 5;
  searching = false;
  filterInput = '';
  filtersGrouped: Array<FilterDTO> | undefined;
  filters: Array<Filter> | undefined;
  filterTypes: Array<FilterType> | undefined;
  categories: Array<ProductCategory> | undefined;
  last = 0;
  beautyProfile: Array<Group> | undefined;
  compositionForm = new FormGroup({
    draftCompositionName: new FormControl<string | null>(
      null,
      Validators.required
    ),
    subscribersLimit: new FormControl<number>(0, Validators.required)
  });
  brands: Array<BrandSummary> | undefined;
  brandsSelected: Array<number> = [];
  categoriesSelected: Array<number> = [];
  brandEquitiesSelected: Array<number> = [];
  productsListFiltered: Array<
    ProductVariantAvailable & { subscribers?: number }
  > = [];
  maxStock?: number;
  minStock = 0;
  maxSubscribers?: number;
  minSubscribers = 0;
  emptyBrandId?: number;
  allProducts: Array<ProductVariantBrand> | undefined;
  waitingChange:
    | ProductVariantAvailable
    | DraftCompositionProductReserved
    | DraftCompositionSummary
    | undefined;
  @ViewChild('vs')
  vs: Scroller | undefined;
  blockModal: DynamicDialogRef | undefined;
  unsync: number | undefined;
  defaultProducts: Array<DraftDefaultProductView> | undefined;
  subscriptions: Subscription[] = [];

  constructor(
    private productService: ProductControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private draftService: DraftControllerService,
    private dialog: DialogService,
    private confirmationService: ConfirmationService,
    private personService: PersonControllerService,
    private userService: UserControllerService,
    private cdRef: ChangeDetectorRef,
    private filterService: DraftCompositionFilterControllerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(
      BroadcastUtil.get(Topic.ALLOCATION_DRAFT_CHANGE.toString()).subscribe(
        (message: Message<DraftUpdate>) => this.notifyDraftChange(message.body)
      )
    );
    this.subscriptions.push(
      BroadcastUtil.get(
        Topic.ALLOCATION_SEGMENTATION_CHANGE.toString()
      ).subscribe(
        async (message: Message<SegmentationUpdate>) =>
          await this.notifySegmentationChange(message.body)
      )
    );
    this.subscriptions.push(
      BroadcastUtil.get(Topic.ALLOCATION_STOCK_CHANGE.toString()).subscribe(
        async (message: Message<StockUpdate>) =>
          await this.notifyStockChange(message.body)
      )
    );
    this.subscriptions.push(
      BroadcastUtil.get(
        Topic.ALLOCATION_SYNCRONIZATION_CHANGE.toString()
      ).subscribe((message: Message<SyncronizationUpdate>) =>
        this.notifySyncronizationChange(message.body)
      )
    );
    this.route.params.subscribe(async (params: Params) => {
      this.draftId = Number(params['id']);
      if (this.draftId) await this.init(this.draftId as number);
      else
        ToastUtil.addToast({
          detail: 'Informe o id do draft',
          summary: 'Erro',
          severity: 'error'
        });
    });
  }

  ngOnDestroy() {
    this.subscriptions?.forEach((s) => s.unsubscribe());
  }

  async init(draftId: number): Promise<void> {
    LoaderService.showLoader();
    await this.checkUnsyncronizedSubscribers(draftId);
    if (!this.unsync) {
      const [
        draft,
        filtersGrouped,
        beautyProfile,
        user,
        filters,
        filterTypes,
        categories,
        brands,
        duplicated,
        defaultProducts
      ]: any[] = await Promise.all([
        lastValueFrom(
          this.draftService.get(draftId).pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.productService
            .getFiltersGrouped()
            .pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.personService.getBeautyProfile().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.userService.getInfo().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.productService.getFilters().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.productService.getFilterTypes().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.productService.getCategories().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.productService.getBrands().pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.draftService
            .findDraftDuplicatedSubscribers(draftId)
            .pipe(map((data) => data.result))
        ),
        lastValueFrom(
          this.draftService
            .findDraftDefaultProducts(draftId)
            .pipe(map((data) => data.result))
        ),
        this.findDraftCompositions(draftId)
      ]);
      if (
        duplicated &&
        (duplicated as Array<DuplicatedSubscribers>).length > 0 &&
        !this.draftCompositions?.some((c) => c.compositionSubscribers)
      ) {
        this.confirmationService.confirm({
          acceptVisible: false,
          rejectLabel: 'Voltar',
          rejectIcon: ' ',
          rejectButtonStyleClass: 'p-button-danger',
          message:
            'Existem assinantes que já estão sendo alocadas em outro draft. Será necessário voltar.',
          reject: () => {
            this.router.navigate(['draft/form/id/' + this.draft?.draftId]);
          },
          dismissableMask: false,
          closeOnEscape: false,
          header: 'Assinantes duplicadas'
        });
      }
      this.draft = draft as Draft;
      this.filtersGrouped = filtersGrouped as Array<FilterDTO>;
      this.filters = filters as Array<Filter>;
      this.filterTypes = filterTypes as Array<FilterType>;
      this.beautyProfile = beautyProfile as Array<Group>;
      this.user = user as UserRoleViewResponse;
      this.categories = categories as Array<ProductCategory>;
      this.brands = brands as Array<BrandSummary>;
      this.emptyBrandId = this.brands.find(
        (b) => b.brandName?.toLocaleLowerCase() === 'sem marca'
      )?.brandId;
      this.defaultProducts = defaultProducts as Array<DraftDefaultProductView>;
      if (this.draft.draftStatus === getDraftStatus(DraftStatus.Close)?.value) {
        this.draftClosedWarning(
          'O draft já está finalizado. O que deseja fazer?'
        );
        return;
      }
      this.compositionForm.reset(this.draftComposition);
      if (this.draftComposition?.draftCompositionId) {
        await Promise.all([
          this.findDraftCompositionProducts(),
          this.findProductsList(),
          this.findPartitionFilters()
        ]);
      }
      LoaderService.showLoader(false);
      if (this.isResponsible) {
        await this.pageProducts({ last: 3, first: 0 });
      } else {
        this.compositionForm.disable();
      }
      this.scroollToBottom();
      if (!this.totalSubscribers && this.isResponsible) {
        this.confirmationService.confirm({
          rejectVisible: false,
          acceptLabel: 'Sim',
          message:
            'O draft está sem assinantes.<br>Por favor alterar os filtros.',
          accept: () => {
            this.router.navigate(['draft/form/id/' + this.draft?.draftId]);
          }
        });
      }
    } else {
      LoaderService.showLoader(false);
    }
  }

  async findDraftCompositions(draftId: number): Promise<void> {
    try {
      await this.findCompositions(draftId);
      // Com problema por ultimo
      if (this.draftCompositions?.some((c) => c.message)) {
        this.draftCompositions = this.draftCompositions
          .filter((c) => !c.message)
          .concat(this.draftCompositions.filter((c) => c.message));
      }
      if (
        (this.isOwner || this.isResponsible) &&
        !this.draftCompositions?.length
      ) {
        this.draftComposition = await this.createComposition(draftId);
        await this.findCompositions(draftId);
        // Aguardando revisão
      } else if (
        this.isResponsible &&
        this.draftCompositions?.every(
          (c) => c.draftCompositionStatus && !c.approved && !c.message
        )
      ) {
        this.confirmationService.confirm({
          acceptLabel: 'Manter em revisão',
          rejectLabel: 'Alterar draft',
          acceptButtonStyleClass: 'p-button-primary',
          rejectButtonStyleClass: 'p-button-danger',
          message:
            'Seu draft está aguardando revisão. Deseja voltar para o modo Edição?',
          header: 'Draft aguardando revisão',
          accept: () => this.router.navigate(['/draft']),
          reject: async () => {
            try {
              LoaderService.showLoader();
              this.draftComposition = await this.createComposition(draftId);
              await this.findCompositions(draftId);
              this.updateCurrentComposition(
                this.draftCompositions?.find(
                  (c) => c.draftCompositionStatus === 0
                )
              );
            } catch (error) {
              ToastUtil.showErrorToast(error);
            } finally {
              LoaderService.showLoader(false);
            }
          }
        });
        return;
      } else if (
        this.isOwner &&
        this.draftCompositions?.every(
          (c) => c.draftCompositionStatus && !c.approved && !c.message
        )
      ) {
        this.router.navigate(['/draft/summary/id/' + this.draft.draftId]);
      }
      this.updateCurrentComposition(
        this.draftCompositions?.find((c) => c.draftCompositionStatus === 0)
      );
    } catch (error) {
      console.log(error);
      this.draftCompositions = [];
    }
  }

  async checkUnsyncronizedSubscribers(draftId: number): Promise<void> {
    let cookie: any = CookieService.getCookie('sync');
    if (cookie) {
      cookie = JSON.parse(cookie);
      if (Number(cookie.statusId) > 0) {
        this.blockPage(cookie);
        this.unsync = 1;
      }
    }
  }

  dragStart(
    product: ProductVariantAvailable | DraftCompositionProductReserved
  ) {
    if (
      (product as DraftCompositionProductReserved).draftCompositionId ||
      ((product as ProductVariantAvailable & { subscribers?: number })
        .subscribers !== 0 &&
        ((product as ProductVariantAvailable).stock || 0) > 0)
    ) {
      this.draggedProduct = product;
    }
  }

  dragEnd() {
    delete this.draggedProduct;
  }

  reset() {
    if (this.vs) this.vs.scrollToIndex(0, 'smooth');
  }

  async addProductToComposition(
    product?: ProductVariantAvailable
  ): Promise<void> {
    if (
      product &&
      this.draftCompositions?.length &&
      this.draftComposition?.draftCompositionId &&
      this.compositionForm.valid
    ) {
      LoaderService.showLoader();
      try {
        const composition = await lastValueFrom(
          this.draftService
            .addProductToComposition({
              draftCompositionId: this.draftComposition?.draftCompositionId,
              draftCompositionProductId: product.productVariantId
            })
            .pipe(map((data) => data.result))
        );
        this.updateCurrentComposition(composition);
      } catch (error) {
        console.error(error);
      }
      await Promise.all([
        this.findDraftCompositionProducts(),
        this.findProductsList()
      ]);
      LoaderService.showLoader(false);
      this.clearSearch(true).then(() => {});
    } else if (!this.compositionForm.valid) {
      this.touchForm();
      this.showCompositionNameWarning();
    }
  }

  showCompositionNameWarning(): void {
    this.confirmationService.confirm({
      message: 'Por favor informe o nome da composição',
      rejectLabel: 'Ok',
      acceptVisible: false,
      header: 'Atenção',
      rejectButtonStyleClass: 'p-button-danger',
      reject: () => {
        this.focusDraftCompositionName();
      }
    });
  }

  async findProductsList(): Promise<void> {
    try {
      if (this.draftComposition?.draftCompositionId && this.isResponsible) {
        this.products = await lastValueFrom(
          this.productService
            .getAvailableList(this.draftComposition?.draftCompositionId)
            .pipe(map((data) => data.result))
        );
        this.products?.forEach((p) => {
          if (!p.brandId && this.emptyBrandId) {
            p.brandId = this.emptyBrandId;
          }
        });
        this.productsList = [...(this.products || [])];
        this.setProductsFiltered();
      }
    } catch (error) {
      this.products = [];
    }
  }

  addProduct(
    p?: ProductVariantAvailable | DraftCompositionProductReserved
  ): void {
    const product = p as ProductVariantAvailable;
    if (
      product?.category &&
      this.draftCompositionProducts.some(
        (s) => s.category && s.category === product.category
      )
    ) {
      this.confirmationService.confirm({
        message: `Já existe um produto da categoria <strong>${product.category}</strong> na composição atual.<br>Deseja continuar?`,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.addProductToComposition(product);
        }
      });
    } else {
      this.addProductToComposition(product);
    }
  }

  async removeProductFromComposition(
    product?: ProductVariantAvailable | DraftCompositionProductReserved
  ): Promise<void> {
    if (
      product &&
      this.draftCompositions?.length &&
      this.draftComposition?.draftCompositionId
    ) {
      LoaderService.showLoader();
      try {
        const composition = await lastValueFrom(
          this.draftService
            .removeProductFromComposition(
              this.draftComposition?.draftCompositionId,
              product.productVariantId as number
            )
            .pipe(map((data) => data.result))
        );
        this.updateCurrentComposition(composition);
      } catch (error) {
        console.error(error);
      }
      await Promise.all([
        this.findDraftCompositionProducts(),
        this.findProductsList()
      ]);
      this.search(true).then(() => {});
      LoaderService.showLoader(false);
    }
  }

  async pageProducts(event: ScrollerLazyLoadEvent): Promise<void> {
    if (event.last && event.last > this.last) {
      const last = this.last + 5;
      const promises =
        this.products
          ?.slice(this.last, last)
          .filter((p) => !p.subscribers)
          .map((p) => this.findProduct(p.productVariantId || 0)) || [];
      this.last = last;
      await Promise.all(promises);
    }
  }

  async findProduct(productId: number): Promise<void> {
    if (this.draftComposition?.draftCompositionId) {
      let product: ProductVariantAvailableDraft;
      if (this.draftComposition.draftSubscribersLeft) {
        product = (await lastValueFrom(
          this.productService
            .getProductInfoById(
              this.draftComposition.draftCompositionId,
              productId
            )
            .pipe(map((data) => data.result))
        )) || { productVariantId: productId, subscribers: 0, totalFilters: 1 };
      } else {
        product = {
          productVariantId: productId,
          subscribers: 0,
          totalFilters: 1
        };
      }
      if (this.productsList && this.products && product?.productVariantId) {
        const idx = this.products.findIndex(
          (p) => p.productVariantId === product.productVariantId
        );
        const idxOrig = this.productsList.findIndex(
          (p) => p.productVariantId === product.productVariantId
        );
        if (idx >= 0) {
          this.products[idx].subscribers = product.subscribers;
          this.products[idx].totalFilters = product.totalFilters;
        }
        if (idxOrig >= 0) {
          this.productsList[idxOrig].subscribers = product.subscribers;
          this.productsList[idxOrig].totalFilters = product.totalFilters;
        }
        this.detectChanges();
      }
    }
  }

  updateProductStock(productVariantId: number, stock: number): boolean {
    let found = false;
    if (this.products && this.productsList) {
      const idx = this.products.findIndex(
        (p) => p.productVariantId === productVariantId
      );
      const idxOrig = this.productsList.findIndex(
        (p) => p.productVariantId === productVariantId
      );
      const idxComp = this.draftCompositionProducts.findIndex(
        (p) => p.productVariantId === productVariantId
      );
      if (idx >= 0) {
        found = true;
        this.products[idx].stock = stock;
      }
      if (idxOrig >= 0) {
        found = true;
        this.productsList[idxOrig].stock = stock;
      }
      if (idxComp >= 0) {
        found = true;
        // if ((this.draftCompositionProducts[idxComp].reserved as number) === (this.draftCompositionProducts[idxComp].stock as number) && (this.draftCompositionProducts[idxComp].stock as number) < stock) {
        //   const reserved = this.draftCompositionProducts[idxComp].reserved || 0;
        // } else
        if (
          (this.draftCompositionProducts[idxComp].reserved as number) > stock
        ) {
          this.productSoldOut(this.draftCompositionProducts[idxComp], stock);
        } else if (
          (this.draftCompositionProducts[idxComp].stock as number) < stock
        ) {
          this.productStockGrown(this.draftCompositionProducts[idxComp]);
        }
        this.draftCompositionProducts[idxComp].stock = stock;
      }
    }
    return found;
  }

  productStockGrown(product: DraftCompositionProductReserved): void {
    const reserved = Number(this.draftComposition?.compositionSubscribers);
    this.recalculateSubscribersAndStocks(false).then(() => {
      if (this.draftComposition?.compositionSubscribers !== reserved)
        this.confirmationService.confirm({
          rejectVisible: false,
          acceptLabel: 'OK',
          message: `O estoque do produto ${
            product.productVariantName
          } foi alterado e
            ${
              (this.draftComposition?.compositionSubscribers || 0) - reserved
            } assinantes foram incluídas na composição.`,
          accept: () => {}
        });
    });
  }

  productSoldOut(product: ProductVariantAvailable, stock: number): void {
    let message = `O produto ${product.productVariantName} foi reservado em outro draft e possui apenas ${stock} unidades.<br>Deseja recalcular as assinantes da composição ou remover o produto?`;
    let header = 'Produto reservado';
    if (!stock) {
      header = 'Produto esgotado';
      message = `O produto ${product.productVariantName} foi reservado e está sem estoque disponível. Será necessário removê-lo da sua composição.`;
    }
    this.confirmationService.confirm({
      message,
      acceptVisible: stock > 0,
      acceptLabel: 'Recalcular assinantes',
      rejectLabel: 'Remover produto',
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-danger',
      acceptIcon: ' ',
      rejectIcon: ' ',
      dismissableMask: false,
      header,
      reject: async () => {
        await this.removeProductFromComposition(product);
      },
      accept: async () => {
        LoaderService.showLoader();
        await this.recalculateSubscribersAndStocks();
        LoaderService.showLoader(false);
      }
    });
  }

  async recalculateSubscribersAndStocks(reload = true): Promise<void> {
    try {
      const comp = await lastValueFrom(
        this.draftService
          .recalculateSubscribersAndStocks(
            this.draftComposition?.draftCompositionId as number
          )
          .pipe(map((data) => data.result))
      );
      await this.updateCurrentComposition(comp);
      await this.findDraftCompositionProducts();
      this.clearSearch(reload).then(() => {});
    } catch (error) {
      console.error(error);
    }
  }

  notifyDraftChange(message: DraftUpdate) {
    if (this.draft?.draftId === message['draftId']) {
      if (
        this.isResponsible &&
        this.draft.responsible !== message['responsible']
      ) {
        this.confirmationService.confirm({
          message:
            'Você não é mais responsável por este Draft.<br>Entre em contato com o proprietário.',
          rejectLabel: 'Ok',
          acceptVisible: false,
          header: 'Atenção',
          rejectButtonStyleClass: 'p-button-danger',
          rejectIcon: PrimeIcons.CHECK
        });
      }
      this.draft.description = message['description'];
      this.draft.name = message['name'];
      this.draft.responsible = message['responsible'];
    }
  }

  async notifyStockChange(message: StockUpdate) {
    if (
      this.draft?.draftId &&
      message['draftId'] &&
      Number(message['draftId']) !== Number(this.draft.draftId)
    )
      await this.updateProductsStock(message);
  }

  async notifySegmentationChange(message: SegmentationUpdate) {
    if (
      this.draft?.draftId === Number(message['draftId']) &&
      message['action'] &&
      !this.isResponsible
    ) {
      message['action'] = Number(message['action']);
      switch (message['action']) {
        case SegmentationAction.Draft:
          this.draftClosedWarning(
            'O draft foi finalizado pelo responsável. O que deseja fazer?'
          );
          break;
        case SegmentationAction.Refresh:
          LoaderService.showLoader();
          window.location.reload();
          break;
        default:
          await this.updatePage(
            message['action'] === SegmentationAction.Products
          );
          break;
      }
    }
  }

  async notifySyncronizationChange(message: SyncronizationUpdate) {
    if (Number(message['statusId']) === 0) {
      await this.unblockPage();
    } else if (!this.blockModal) {
      this.blockPage(message);
    } else {
      BroadcastUtil.get('sync').emit(Number(message['statusId']));
      CookieService.setCookie('sync', JSON.stringify(message), 0, 0.5);
    }
  }

  blockPage(data: { [key: string]: any }): void {
    CookieService.setCookie('sync', JSON.stringify(data), 0, 1);
    if (!this.blockModal)
      this.blockModal = this.dialog.open(BlockPageComponent, {
        closable: false,
        closeOnEscape: false,
        showHeader: false,
        width: '500px',
        modal: true,
        styleClass: 'block-page',
        height: '300px',
        data
      });
  }

  async unblockPage(): Promise<void> {
    CookieService.eraseCookie('sync');
    if (this.blockModal) {
      this.blockModal.close();
      delete this.blockModal;
      delete this.unsync;
    }
    if (!this.draft) {
      await this.init(this.draftId as number);
    }
  }

  async updatePage(updateProducts = false): Promise<void> {
    LoaderService.showLoader();
    const id = this.draftComposition?.draftCompositionId;
    this.draftCompositions =
      (await lastValueFrom(
        this.draftService
          .getDraftCompositions(this.draft?.draftId as number)
          .pipe(map((data) => data.result))
      )) || [];
    this.draftComposition =
      this.draftCompositions[this.draftCompositions?.length - 1];
    this.compositionForm.patchValue(this.draftComposition);
    // Se nova composição ou mudou produto
    if (this.draftComposition.draftCompositionId !== id || updateProducts) {
      await Promise.all([
        this.findDraftCompositionProducts(),
        this.findPartitionFilters()
      ]);
    }
    LoaderService.showLoader(false);
  }

  async updateProductsStock(products: any): Promise<void> {
    const notFound: Array<number> = [];
    Object.keys(products).forEach((key) => {
      if (products && key !== 'topic' && key !== 'draftId' && products[key]) {
        if (!this.updateProductStock(Number(key), Number(products[key]))) {
          notFound.push(Number(key));
        }
      }
    });
    if (notFound.length) {
      await this.findProductsList();
      await this.search();
    }
    this.detectChanges();
  }

  async search(reload = false): Promise<void> {
    this.products = this.productsListFiltered?.filter(
      (p) =>
        (p.subscribers === undefined ||
          !this.minSubscribers ||
          (p.subscribers >= this.minSubscribers &&
            (!this.maxSubscribers || p.subscribers <= this.maxSubscribers))) &&
        // (p.brandName && FormatUtil.containsIgnoreAccents(p.brandName, this.filterInput)) ||
        ((p.productVariantName &&
          FormatUtil.containsIgnoreAccents(
            p.productVariantName,
            this.filterInput
          )) ||
          (p.internalEAN &&
            FormatUtil.containsIgnoreAccents(p.internalEAN, this.filterInput)))
      // (p.category && FormatUtil.containsIgnoreAccents(p.category, this.filterInput)) ||
      // (p.subcategory && FormatUtil.containsIgnoreAccents(p.subcategory, this.filterInput))
    );

    const inputText = this.filterInput.toString();
    setTimeout(() => {
      if (this.filterInput === inputText) {
        this.reset();
      }
    }, 500);
    setTimeout(async () => {
      if (this.filterInput === inputText || reload) {
        this.last = 0;
        await this.pageProducts({ last: 3, first: 0 });
      }
    }, 2000);
  }

  async clearSearch(reload = false): Promise<void> {
    if (this.filterInput.length > 0 || reload) {
      this.filterInput = '';
      await this.search(reload);
    }
  }

  async changeFilters(
    product: ProductVariantAvailable & {
      subscribers?: number;
      totalFilters?: number;
    }
  ): Promise<void> {
    if (this.draftComposition?.draftCompositionId) {
      this.dialog
        .open(DraftCompositionFiltersModalComponent, {
          data: {
            product,
            draftComposition: this.draftComposition
          },
          width: '70%',
          modal: true,
          maximizable: true,
          closable: false,
          closeOnEscape: false,
          header: product?.productVariantName
        })
        .onClose.subscribe(
          async (
            p?: ProductVariantAvailable & {
              subscribers?: number;
              totalFilters?: number;
              add?: boolean;
            }
          ) => {
            if (p) {
              LoaderService.showLoader(false);
              if (p.subscribers !== undefined) {
                product.totalFilters = p.totalFilters;
                product.subscribers = p.subscribers;
              } else {
                await this.findProduct(product.productVariantId as number);
              }
              if (p.add) await this.addProductToComposition(p);
            }
          }
        );
    }
  }

  async showComposition(composition: DraftCompositionSummary): Promise<void> {
    if (composition.draftCompositionId) {
      this.dialog.open(CompositionSummaryModalComponent, {
        showHeader: true,
        maximizable: true,
        styleClass: 'dialog-composition-modal',
        dismissableMask: true,
        modal: true,
        width: '80%',
        data: {
          draftComposition: composition
        }
      });
    }
  }

  async findPartitionFilters(
    composition?: DraftCompositionSummary
  ): Promise<void> {
    try {
      this.draftCompositionFilters = await lastValueFrom(
        this.filterService
          .findFilters(
            composition?.draftCompositionId ||
              this.draftComposition?.draftCompositionId
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
  }

  async findDraftCompositionProducts(): Promise<void> {
    if (this.draftComposition?.draftCompositionId) {
      try {
        this.draftCompositionProducts =
          (await lastValueFrom(
            this.draftService
              .getCompositionProducts(this.draftComposition?.draftCompositionId)
              .pipe(map((data) => data.result))
          )) || [];
        let product = this.draftCompositionProducts.find(
          (p) => (p.reserved || 0) > (p.stock || 0)
        );
        if (product) {
          this.productSoldOut(product, product.stock || 0);
        }
      } catch (error) {
        console.log(error);
        this.draftCompositionProducts = [];
      }
    }
  }

  async updateComposition(): Promise<void> {
    if (
      this.draftComposition?.draftCompositionId &&
      this.compositionForm.valid &&
      this.compositionForm.dirty
    ) {
      LoaderService.showLoader();
      try {
        if (
          this.draftComposition?.subscribersLimit === undefined ||
          this.draftComposition?.subscribersLimit === null
        ) {
          this.draftComposition.subscribersLimit = 0;
        }
        this.draftComposition.draftCompositionName = this.compositionForm.value
          .draftCompositionName as string;
        this.draftComposition.subscribersLimit = this.compositionForm.value
          .subscribersLimit as number;
        await lastValueFrom(
          this.draftService
            .updateDraftComposition(this.draftComposition)
            .pipe(map((data) => data.result))
        );
        await this.updateCurrentComposition();
        await this.findDraftCompositionProducts();
        this.compositionForm.markAsPristine();
        BroadcastUtil.get('detectChanges').emit();
      } catch (error) {
        console.log(error);
      }
      LoaderService.showLoader(false);
    } else if (this.compositionForm.dirty) {
      this.touchForm();
    }
  }

  touchForm(): void {
    Object.keys(this.compositionForm.controls).forEach((key) => {
      this.compositionForm.get(key)?.markAsDirty();
      this.compositionForm.get(key)?.markAsTouched();
    });
  }

  focusDraftCompositionName(): void {
    setTimeout(() => {
      document.getElementById('draftCompositionName')?.focus();
    }, 500);
  }

  async updateCurrentComposition(
    draftComposition?: DraftCompositionSummary
  ): Promise<void> {
    if (draftComposition) {
      this.draftComposition = draftComposition;
    } else if (this.draftComposition?.draftCompositionId) {
      this.draftComposition = await lastValueFrom(
        this.draftService
          .getDraftComposition(this.draftComposition.draftCompositionId)
          .pipe(map((data) => data.result))
      );
    }
    const idx = this.draftCompositions?.findIndex(
      (c) => c.draftCompositionId === this.draftComposition?.draftCompositionId
    );
    if (
      this.draftCompositions &&
      this.draftComposition &&
      idx !== undefined &&
      idx >= 0
    ) {
      this.draftCompositions[idx] = this.draftComposition;
    }
  }

  isSelected(index: number): boolean {
    return (
      this.draftComposition !== undefined &&
      this.draftCompositions?.indexOf(this.draftComposition) === index
    );
  }

  async addNewComposition(ignoreSubcategoryCheck = false): Promise<void> {
    if (
      !ignoreSubcategoryCheck &&
      (await this.checkRepeatedSubcategoriesLastMonth())
    )
      return;
    if (this.hasMinimumQuantity) {
      this.confirmationService.confirm({
        message:
          'Sua composição atual <strong>' +
          this.draftComposition?.draftCompositionName +
          '</strong> será finalizada e não será possível realizar alterações.<br>Deseja continuar?',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          await this.createNewComposition();
        }
      });
    } else if (this.draft && this.draft?.editionId) {
      this.confirmMinimumQuantityIgnore();
    }
  }

  confirmMinimumQuantityIgnore(finishDraft = false): void {
    if (this.draft && this.draft.editionId) {
      let message =
        'Recomendamos pelo menos ' +
        this.minimumProductQuantity(
          Number(this.draft.editionId.toString()[0])
        ) +
        ' produtos na ' +
        (this.draft.editionId.toString()[0] === '5' ? 'Glambag' : 'Glambox') +
        '. Deseja avançar com ' +
        this.draftCompositionProducts.length +
        ' produtos?';
      if (this.draft.editionId.toString()[0] === '7') {
        message =
          'Recomendamos pelo menos ' +
          this.minimumProductQuantity(1) +
          ' itens na Glambox e ' +
          this.minimumProductQuantity(5) +
          ' itens na Glambag. Deseja avançar com ' +
          this.glamboxProducts.length +
          ' itens na Glambox e ' +
          this.glambagProducts.length +
          ' na Glambag?';
      }
      LoaderService.showLoader();
      setTimeout(() => {
        this.confirmationService.confirm({
          message,
          header: 'Quantidade mínima',
          acceptLabel: 'Sim',
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: 'Não',
          rejectButtonStyleClass: 'p-button-primary',
          accept: async () => {
            if (finishDraft) await this.finishDraftAndCreateCompositions();
            else await this.createNewComposition();
          }
        });
        LoaderService.showLoader(false);
      }, 1000);
    }
  }

  async createNewComposition(): Promise<void> {
    LoaderService.showLoader();
    this.draftComposition = await this.createComposition(this.draft.draftId);
    if (this.draft?.draftId && this.draftComposition?.draftCompositionId) {
      await Promise.all([
        this.findDraftCompositions(this.draft.draftId),
        this.findProductsList(),
        this.findDraftCompositionProducts(),
        this.findPartitionFilters()
      ]);
      if (this.draftCompositions) {
        this.draftComposition = this.draftCompositions.find(
          (c) => c.draftCompositionStatus === 0
        );
        this.compositionForm.reset(this.draftComposition);
      }
      await this.search(true);
    }
    LoaderService.showLoader(false);
    this.scroollToBottom();
  }

  scroollToBottom(): void {
    let element = document.getElementById(
      String(this.draftComposition?.draftCompositionId)
    );
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  async deleteComposition(composition: DraftCompositionSummary): Promise<void> {
    if (this.draft?.draftId && composition.draftCompositionId) {
      LoaderService.showLoader();
      try {
        await lastValueFrom(
          this.draftService.deleteDraftComposition(
            composition.draftCompositionId
          )
        );
        await Promise.all([
          this.findDraftCompositions(this.draft.draftId),
          this.findProductsList(),
          this.findDraftCompositionProducts()
        ]);
        if (this.draftCompositions) {
          this.draftComposition =
            this.draftCompositions[this.draftCompositions?.length - 1];
        }
        LoaderService.showLoader(false);
        await this.search(true);
      } catch (error) {
        LoaderService.showLoader(false);
        console.log(error);
      }
    }
  }

  deleteCompositionDialog(composition: DraftCompositionSummary): void {
    this.confirmationService.confirm({
      message:
        'Tem certeza que deseja remover a composição ' +
        composition.draftCompositionName +
        ' e todos os produtos vinculados a ela?',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.deleteComposition(composition);
      }
    });
  }

  async submit(ignoreSubcategoryCheck = false): Promise<void> {
    if (
      !ignoreSubcategoryCheck &&
      (await this.checkRepeatedSubcategoriesLastMonth(true))
    )
      return;
    // Nova composição com somente produtos default
    if (
      this.draftCompositionProducts.length === this.defaultProducts?.length &&
      this.draftCompositionProducts.every((cp) =>
        this.defaultProducts?.some(
          (p) => p.productVariantId === cp.productVariantId
        )
      )
    ) {
      this.confirmationService.confirm({
        message: `Sua última composição contem somente os produtos padrões do draft e será descartada. Deseja continuar?`,
        acceptLabel: `Sim! Concluir com (${this.draftComposition?.compositionSubscribers}) assinantes não alocadas.`,
        rejectLabel: 'Alterar a composição',
        header: 'Gerar composições',
        rejectButtonStyleClass: 'p-button-danger',
        accept: async () => {
          LoaderService.showLoader();
          try {
            await lastValueFrom(
              this.draftService.deleteDraftComposition(
                this.draftComposition?.draftCompositionId as number
              )
            );
            await this.finishDraftAndCreateCompositions();
          } catch (error) {
            LoaderService.showLoader(false);
            ToastUtil.showErrorToast(error);
          }
        },
        reject: () => {
          if (!this.draftComposition?.draftCompositionName?.trim().length) {
            this.touchForm();
            return;
          }
        }
      });
      return;
    }
    if (this.totalSignersInCompositions < this.totalSubscribers) {
      this.confirmationService.confirm({
        message: `Existe(m) ${
          this.totalSubscribers - this.totalSignersInCompositions
        } assinante(s) não alocadas.<br>Deseja continuar?`,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        header: 'Gerar composições',
        rejectButtonStyleClass: 'p-button-danger',
        accept: async () => {
          await this.finishDraft();
        }
      });
    } else {
      this.confirmationService.confirm({
        message: `Deseja gerar as composições do draft e alocar as assinantes?<br>Está ação não pode ser desfeita!`,
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        rejectButtonStyleClass: 'p-button-danger',
        header: 'Gerar composições',
        accept: async () => {
          await this.finishDraft();
        }
      });
    }
  }

  async finishDraft(): Promise<void> {
    if (this.hasMinimumQuantity) {
      await this.finishDraftAndCreateCompositions();
    } else {
      this.confirmMinimumQuantityIgnore(true);
    }
  }

  async finishDraftAndCreateCompositions(): Promise<void> {
    if (this.draftComposition?.draftId) {
      try {
        LoaderService.showLoader();
        const result = await lastValueFrom(
          this.draftService
            .finishDraft(this.draftComposition?.draftId)
            .pipe(map((data) => data.result))
        );
        if (result) {
          ToastUtil.addToast({
            detail: result,
            summary: 'Sucesso',
            severity: 'success'
          });
        }
        LoaderService.showLoader(false);
        this.router.navigate(['draft/summary/id/' + this.draft?.draftId]);
      } catch (error) {
        LoaderService.showLoader(false);
        ToastUtil.showErrorToast(error);
      }
    }
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
    BroadcastUtil.get('detectChanges').emit();
  }

  brandEquities(
    composition: DraftCompositionSummary
  ): Array<{ quantity: number; brandEquity: number }> | null {
    if (composition.brandEquity?.length && composition.brandEquity !== '0') {
      const equities = composition.brandEquity
        .split(',')
        .map((e) => ({ quantity: 1, brandEquity: Number(e) }));
      const grouped: Array<{ quantity: number; brandEquity: number }> = [];
      equities.forEach((e) => {
        const idx = grouped.findIndex((eq) => eq.brandEquity === e.brandEquity);
        if (idx >= 0) {
          grouped[idx].quantity++;
        } else {
          grouped.push(e);
        }
      });
      return grouped;
    }
    return null;
  }

  equityLabel(equity: number): string {
    return equity + ' ' + getEquityLabel(equity);
  }

  async showProductFilters(
    product: DraftCompositionProductReserved
  ): Promise<void> {
    if (this.draftComposition?.draftCompositionId) {
      this.dialog.open(DraftCompositionFiltersModalComponent, {
        data: {
          reserved: product,
          draftComposition: this.draftComposition
        },
        modal: true,
        width: '70%',
        closable: true,
        maximizable: true,
        header: product?.productVariantName
      });
    }
  }

  draftClosedWarning(message: string): void {
    LoaderService.showLoader(false);
    this.confirmationService.confirm({
      message,
      acceptLabel: 'Ver resumo',
      acceptButtonStyleClass: 'p-button',
      acceptIcon: '',
      rejectLabel: 'Voltar ao início',
      rejectIcon: '',
      rejectButtonStyleClass: 'p-button-danger',
      header: 'Draft finalizado',
      accept: () => {
        this.router.navigate(['draft/summary/id/' + this.draft?.draftId]);
      },
      reject: () => {
        this.router.navigate(['draft']);
      }
    });
    0;
  }

  async createPartition(): Promise<void> {
    this.dialog
      .open(DraftCompositionFiltersModalComponent, {
        data: {
          draft: this.draft
        },
        closable: false,
        closeOnEscape: false,
        modal: true,
        width: '50%',
        header: 'Nova partição'
      })
      .onClose.subscribe(async (data) => {
        if (data) {
          LoaderService.showLoader();
          await Promise.all([
            this.findDraftCompositions(this.draft?.draftId as number),
            this.findProductsList()
          ]);
          await this.search(true);
          LoaderService.showLoader(false);
        }
      });
  }

  async viewPartition(composition: DraftCompositionSummary): Promise<void> {
    this.dialog
      .open(DraftCompositionFiltersModalComponent, {
        data: {
          draft: this.draft,
          draftComposition: composition
        },
        modal: true,
        width: '50%',
        header: 'Partição - ' + composition.draftCompositionName
      })
      .onClose.subscribe(async (composition: DraftCompositionSummary) => {
        if (composition) {
          LoaderService.showLoader();
          await Promise.all([
            this.findPartitionFilters(composition),
            this.findDraftCompositions(this.draft.draftId),
            this.findDraftCompositionProducts()
          ]);
          LoaderService.showLoader(false);
        }
      });
  }

  convertToComposition(composition: DraftCompositionSummary): void {
    const discard =
      this.draftCompositionProducts.length === this.defaultProducts?.length &&
      this.draftCompositionProducts.every((cp) =>
        this.defaultProducts?.some(
          (p) => p.productVariantId === cp.productVariantId
        )
      );
    let message = `Deseja converter a partição <strong>${
      composition.draftCompositionName
    }</strong> em uma composição?<br>${
      discard
        ? 'Sua última composição contem somente os produtos padrões do draft e será descartada'
        : 'Sua composição atual será finalizada.'
    }`;
    this.confirmationService.confirm({
      acceptLabel: 'Continuar',
      rejectLabel: 'Voltar',
      message,
      accept: async () => {
        LoaderService.showLoader();
        try {
          if (discard)
            await lastValueFrom(
              this.draftService.deleteDraftComposition(
                this.draftComposition?.draftCompositionId as number
              )
            );
          await lastValueFrom(
            this.draftService
              .convertPartition(composition.draftCompositionId as number)
              .pipe(map((data) => data.result))
          );
          composition.draftCompositionStatus = 0;
          this.draftComposition = composition;
          this.findDraftCompositions(this.draft?.draftId as number);
          await Promise.all([
            this.findProductsList(),
            this.findDraftCompositionProducts(),
            this.findPartitionFilters()
          ]);
          this.compositionForm.reset(this.draftComposition);
          await this.search(true);
        } catch (error) {
          ToastUtil.showErrorToast(error);
        }
        LoaderService.showLoader(false);
      }
    });
  }

  async showCompositionSubscribers(
    composition: DraftCompositionSummary
  ): Promise<void> {
    this.dialog.open(CompositionSubscribersModalComponent, {
      data: {
        draftCompositionId: composition.draftCompositionId
      },
      modal: true,
      header:
        (this.isPartition(composition)
          ? 'Assinantes da partição '
          : 'Assinantes da composição ') + composition?.draftCompositionName,
      width: '80vw'
    });
  }

  filterProducts(): void {
    this.dialog
      .open(ProductFiltersModalComponent, {
        header: 'Filtrar produtos',
        data: {
          categories: this.categories,
          brands: this.brands,
          products: this.productsList,
          maxStock: this.maxStock,
          minStock: this.minStock,
          categoriesSelected: this.categoriesSelected,
          brandsSelected: this.brandsSelected,
          brandEquities: this.brandEquitiesSelected,
          minSubscribers: this.minSubscribers,
          maxSubscribers: this.maxSubscribers
        },
        modal: true
      })
      .onClose.subscribe(
        (data: {
          brands: Array<number>;
          categories: Array<number>;
          brandEquities: Array<number>;
          minStock: number;
          maxStock?: number;
          products: Array<ProductVariantAvailable>;
          minSubscribers: number;
          maxSubscribers: number;
        }) => {
          if (data) {
            this.brandsSelected = data.brands;
            this.categoriesSelected = data.categories;
            this.minStock = data.minStock;
            this.maxStock = data.maxStock;
            this.brandEquitiesSelected = data.brandEquities;
            this.minSubscribers = data.minSubscribers;
            this.maxSubscribers = data.maxSubscribers;
            this.setProductsFiltered(data.products);
            this.search(true);
          }
        }
      );
  }

  isPartition(composition: DraftCompositionSummary) {
    return (
      composition.draftCompositionStatus &&
      composition.compositionSubscribers &&
      !composition.compositionProducts
    );
  }

  setProductsFiltered(products?: Array<ProductVariantAvailable>): void {
    if (products) {
      this.productsListFiltered = [...products];
    } else if (
      this.productsList &&
      (this.brandsSelected?.length ||
        this.categoriesSelected?.length ||
        this.minStock ||
        this.maxStock ||
        this.brandEquitiesSelected)
    ) {
      products = [];
      if (this.products?.length) {
        products = this.products.filter(
          (p) => (p.stock as number) >= (this.minStock as number)
        );
        if (this.maxStock) {
          products = this.products.filter(
            (p) => (p.stock as number) <= (this.maxStock as number)
          );
        }
        if (this.brandsSelected?.length) {
          products = products?.filter((p) =>
            this.brandsSelected?.includes(p.brandId as number)
          );
        }
        if (this.categoriesSelected?.length) {
          products = products?.filter(
            (p) =>
              this.categoriesSelected?.includes(p.categoryId as number) ||
              this.categoriesSelected?.includes(p.subcategoryId as number)
          );
        }
        if (this.brandEquitiesSelected?.length) {
          products = products?.filter((p) =>
            this.brandEquitiesSelected?.includes(p.brandEquity as number)
          );
        }
      }
      this.productsListFiltered = products;
    } else if (this.productsList) {
      this.productsListFiltered = [...this.productsList];
    } else {
      this.productsListFiltered = [];
    }
  }

  changeCompositionName(composition: DraftCompositionSummary): void {
    this.dialog
      .open(CompositionNameModalComponent, {
        header:
          'Alterar nome da composição "' +
          composition.draftCompositionName +
          '"',
        position: 'center',
        data: { composition },
        modal: true,
        width: '50%'
      })
      .onClose.subscribe(async (data) => {
        if (data) {
          LoaderService.showLoader();
          const updated = await lastValueFrom(
            this.draftService
              .updateDraftComposition(data)
              .pipe(map((data) => data.result || composition))
          );
          composition.draftCompositionName = updated.draftCompositionName;
          LoaderService.showLoader(false);
        }
      });
  }

  minimumProductQuantity(subscriptionId: number): number {
    switch (subscriptionId) {
      case 5:
        return 4;
      default:
        return 6;
    }
  }

  async checkRepeatedSubcategoriesLastMonth(
    finishDraft = false
  ): Promise<boolean> {
    LoaderService.showLoader();
    try {
      const repeated = await lastValueFrom(
        this.draftService
          .checkSubcategoriesLastMonth(
            this.draftComposition?.draftCompositionId as number
          )
          .pipe(map((data) => data.result))
      );
      LoaderService.showLoader(false);
      if (repeated?.length) {
        this.dialog
          .open(RepeatedSubcategoriesModalComponent, {
            data: {
              repeated,
              draftComposition: this.draftComposition
            },
            maximizable: true,
            modal: true,
            header:
              'Categorias repetidas - ' +
              this.draftComposition?.draftCompositionName
          })
          .onClose.subscribe(async (result: boolean) => {
            if (result && !finishDraft) await this.addNewComposition(true);
            if (result && finishDraft) await this.submit(true);
          });
        return true;
      }
      return false;
    } catch (error) {
      LoaderService.showLoader(false);
      ToastUtil.showErrorToast(error);
      return true;
    }
  }

  resolveProblems() {
    this.confirmationService.confirm({
      acceptLabel: 'Sim, avançar',
      rejectLabel: 'Voltar',
      rejectButtonStyleClass: 'p-button-danger',
      acceptButtonStyleClass: 'p-button-primary',
      header: 'Resolver problemas',
      message: 'Deseja marcar o problema como resolvido e avançar?',
      accept: async () => {
        try {
          LoaderService.showLoader();
          this.draftComposition = await lastValueFrom(
            this.draftService
              .resolveDraftComposition(this.draftComposition.draftCompositionId)
              .pipe(map((data) => data.result))
          );
          if (
            this.draftCompositions?.some(
              (c) =>
                c.message &&
                c.draftCompositionId !==
                  this.draftComposition.draftCompositionId
            )
          ) {
            ToastUtil.addToast({
              detail: 'Ainda existem composições com problema, verifique.',
              severity: 'info',
              summary: 'Problema resolvido'
            });
            this.router.navigate(['/draft/summary/id/' + this.draft.draftId]);
            return;
          } else {
            ToastUtil.addToast({
              severity: 'success',
              detail: 'Todos os problemas resolvidos',
              summary: 'Sucesso'
            });
            await this.findDraftCompositions(this.draft.draftId);
          }
        } catch (error) {
          ToastUtil.showErrorToast(error);
        } finally {
          LoaderService.showLoader(false);
        }
      }
    });
  }

  get filtersCount(): number {
    let filters = 0;
    if (this.brandsSelected?.length) {
      filters++;
    }
    if (this.categoriesSelected?.length) {
      filters++;
    }
    if (this.minStock || this.maxStock) {
      filters++;
    }
    return filters;
  }

  get totalSignersInCompositions(): number {
    if (this.draftCompositions) {
      return this.draftCompositions.reduce(
        (sum, c) => (sum += c.compositionSubscribers || 0),
        0
      );
    } else return 0;
  }

  get totalSubscribers(): number {
    if (this.draftCompositions) {
      return this.draftCompositions[0]?.draftTotalSubscribers || 0;
    } else return 0;
  }

  get salePrice(): number {
    return this.draftCompositionProducts.reduce(
      (sum, p) => (sum += p.salePrice || 0),
      0
    );
  }

  get costPrice(): number {
    return this.draftCompositionProducts.reduce(
      (sum, p) => (sum += p.costPrice || 0),
      0
    );
  }

  get allocatedSubscribers(): number {
    return (
      this.draftCompositions?.reduce(
        (sum, p) => (sum += p.compositionSubscribers || 0),
        0
      ) || 0
    );
  }

  get subscribersToAlocate(): number {
    return (
      (this.draftComposition?.draftTotalSubscribers || 0) -
      this.allocatedSubscribers
    );
  }

  get weight(): number {
    return this.draftCompositionProducts.reduce(
      (sum, p) => (sum += p.weight || 0),
      0
    );
  }

  get minStockComposition(): number {
    return (
      this.draftCompositionProducts
        ?.map((p) => p.stock as number)
        .sort((p1, p2) => p1 - p2)[0] || 0
    );
  }

  get isOwner(): boolean {
    return this.draft?.owner === this.user?.username;
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }

  get glamboxProducts(): Array<DraftCompositionProductReserved> {
    return this.draftCompositionProducts.filter((p) => p.subscriptionId === 1);
  }

  get glambagProducts(): Array<DraftCompositionProductReserved> {
    return this.draftCompositionProducts.filter((p) => p.subscriptionId === 5);
  }

  get hasMinimumQuantity(): boolean {
    switch (this.draft?.editionId?.toString()[0]) {
      case '7':
        return (
          this.glamboxProducts.length >= this.minimumProductQuantity(1) &&
          this.glambagProducts.length >= this.minimumProductQuantity(5)
        );
      default:
        return (
          this.draftCompositionProducts.length >=
          this.minimumProductQuantity(
            Number(this.draft?.editionId?.toString()[0])
          )
        );
    }
  }

  private async createComposition(draftId: number) {
    return await lastValueFrom(
      this.draftService
        .createDraftComposition({
          draftId,
          partition: false
        })
        .pipe(map((data) => data.result))
    );
  }

  private async findCompositions(draftId: number) {
    this.draftCompositions = await lastValueFrom(
      this.draftService
        .getDraftCompositions(draftId)
        .pipe(map((data) => data.result))
    );
  }
}
