/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Edition {
  editionId?: number;
  subscriptionId?: number;
  title?: string;
  theme?: string;
  hashtag?: string;
  status?: number;
  imageURL?: string;
  subscriberCount?: string;
  publicRelease?: Date;
  description?: string;
  videoURL?: string;
  articleId?: number;
  princessLetter?: string;
  coverImageUrl?: string;
  compositionCount?: number;
  avgProductCount?: number;
  avgINvoiceTotal?: number;
  avgDaleTotal?: number;
  avgCostTotal?: number;
  removedSubscriberCount?: number;
  subscriberCompositionCount?: number;
  glamclubTradeCount?: string;
  lastBoxCount?: number;
  dateCreated?: Date;
  productId?: number;
  isSuperbox?: number;
  subscriberLimit?: number;
}
