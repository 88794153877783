import { getRole } from '../models/enums/Role';

export class FormatUtil {
  public static containsIgnoreAccents(text: string, filter: string): boolean {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(
        filter
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      );
  }

  public static getRolePrincipal(roles: string): string {
    const split = roles.split(',');
    let principal: {
      label?: string;
      value?: number;
      enumValue?: string;
      order?: number;
    } = {};
    for (const role of split) {
      const value = getRole(role);
      if (
        value &&
        (!principal.value ||
          (value.order as number) < (principal.order as number))
      ) {
        principal = value;
      }
    }
    return principal.enumValue || 'NONE';
  }
}
