import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordUpdateComponent } from './password-update.component';

@NgModule({
  declarations: [PasswordUpdateComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule],
  exports: [PasswordUpdateComponent]
})
export class PasswordUpdateModule {}
