import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, map } from 'rxjs';
import {
  AdminControllerService,
  UserRoleViewResponse,
  UserView
} from 'src/app/allocation-api';
import { getRole } from 'src/app/models/enums/Role';
import { LoaderService } from 'src/app/services/loader.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { ToastUtil } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnChanges {
  @Input()
  user: (UserRoleViewResponse & { role: string }) | undefined | UserView;

  @Input()
  modal = false;

  @Output()
  closeModal = new EventEmitter<boolean>();

  userForm: FormGroup;
  dirty = false;
  permissions = [
    { label: 'Usuário', value: 'ROLE_ALLOC_USER' },
    { label: 'Admin', value: 'ROLE_ALLOC_ADMIN' }
  ];
  constructor(private adminService: AdminControllerService) {
    this.userForm = new FormGroup({
      displayName: new FormControl<string>(this.user?.displayName || '', [
        Validators.required,
        Validators.minLength(1)
      ]),
      username: new FormControl<string>(this.user?.username || '', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      ),
      passwordConfirmation: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      ),
      role: new FormControl<string | undefined>((this.user as any)?.role)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']?.currentValue) {
      this.patchValue();
    }
  }

  patchValue(): void {
    this.userForm = new FormGroup({
      displayName: new FormControl<string>(this.user?.displayName || '', [
        Validators.required,
        Validators.minLength(1)
      ]),
      username: new FormControl<string>(this.user?.username || '', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      ),
      passwordConfirmation: new FormControl<string | null>(
        { value: null, disabled: this.user?.userId !== undefined },
        [Validators.required, Validators.minLength(3), Validators.maxLength(20)]
      ),
      role: new FormControl<string | undefined>((this.user as any)?.role)
    });
  }

  async submit(): Promise<void> {
    Object.keys(this.userForm.controls).forEach((key) => {
      this.userForm.controls[key].markAsDirty();
      this.userForm.controls[key].markAsTouched();
    });

    try {
      if (this.userForm.valid && this.user?.userId) {
        LoaderService.showLoader();
        (await lastValueFrom(
          this.adminService
            .updateUser(this.user.userId, this.userForm.value)
            .pipe(map((data) => data.result))
        )) as UserRoleViewResponse;
        ToastUtil.addToast({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Usuário autalizado com sucesso'
        });
        LoaderService.showLoader(false);
        this.closeModal.emit(true);
      } else if (this.userForm.valid) {
        LoaderService.showLoader();
        const user = (await lastValueFrom(
          this.adminService
            .createUser(this.userForm.value)
            .pipe(map((data) => data.result))
        )) as UserRoleViewResponse;
        this.user = {
          ...user,
          role: FormatUtil.getRolePrincipal(user.roles as string)
        };
        ToastUtil.addToast({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Usuário cadastrado com sucesso'
        });
        this.patchValue();
        this.dirty = true;
        LoaderService.showLoader(false);
      }
    } catch (error) {
      ToastUtil.showErrorToast(error);
      LoaderService.showLoader(false);
    }
  }

  async changeRole(event: { value: string }): Promise<void> {
    LoaderService.showLoader();
    const roles = [event.value];
    if (event.value === 'ROLE_ALLOC_ADMIN') {
      roles.push('ROLE_ALLOC_USER');
    }
    try {
      const view = (await lastValueFrom(
        this.adminService
          .saveUserRoles({
            userId: this.user?.userId as number,
            roles: roles.map((r) => getRole(r)?.value as number)
          })
          .pipe(map((data) => data.result))
      )) as UserRoleViewResponse;
      this.user = {
        ...view,
        role: FormatUtil.getRolePrincipal(view.roles as string)
      };
      this.patchValue();
      ToastUtil.addToast({
        detail: `Permissão alterada para ${getRole(event.value)?.label}`,
        severity: 'success',
        summary: 'Sucesso'
      });
    } catch (error) {
      ToastUtil.showErrorToast(error);
    }
    LoaderService.showLoader(false);
  }
}
