/**
 * Allocation API
 * Gerenciador de alocações REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DraftView {
  draftId?: number;
  name?: string;
  editionId?: number;
  username?: string;
  owner?: string;
  dateCreated?: Date;
  draftStatus?: number;
  subscribers?: number;
  subscriptionName?: string;
  compositions?: number;
  review?: boolean;
  problems?: number;
}
