<app-table
  [cols]="cols"
  [elements]="repeated"
  [lazy]="false"
  [addButton]="false"
  tableTitle="Assinantes com categoria repetida recentemente"
  modelName="categorias"
  [actionButtons]="tableActionButtons"
  (actionButtonClick)="showSubscribers($event)"
  *ngIf="!selected()"
></app-table>
<app-table
  [cols]="subscriberCols"
  [elements]="selectedSubscribers()"
  [lazy]="false"
  [tableTitle]="
    'Assinantes que receberam ' + selected().subcategory + ' recentemente'
  "
  modelName="assinantes"
  [actionButtons]="tableActionButtons"
  (actionButtonClick)="showSubscribers($event)"
  addLabel="Voltar"
  addButtonIcon="pi pi-arrow-left"
  [addButtonAction]="true"
  (addButtonClick)="selected.set(undefined)"
  *ngIf="selected()"
></app-table>
<div class="buttons">
  <p-button
    label="Voltar e alterar"
    severity="primary"
    (click)="ref.close()"
  ></p-button>
  <p-button
    icon="pi pi-exclamation-triangle"
    label="Avançar mesmo assim"
    severity="danger"
    (click)="ref.close(true)"
  ></p-button>
</div>
