import { HttpClient } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { Configuration } from './configuration';

import { AdminControllerService } from './api/adminController.service';
import { DraftCompositionFilterControllerService } from './api/draftCompositionFilterController.service';
import { DraftControllerService } from './api/draftController.service';
import { EditionControllerService } from './api/editionController.service';
import { NotificationsControllerService } from './api/notificationsController.service';
import { OpenControllerService } from './api/openController.service';
import { PersonControllerService } from './api/personController.service';
import { ProductControllerService } from './api/productController.service';
import { SubscriptionControllerService } from './api/subscriptionController.service';
import { SyncronizationControllerService } from './api/syncronizationController.service';
import { UserControllerService } from './api/userController.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AdminControllerService,
    DraftCompositionFilterControllerService,
    DraftControllerService,
    EditionControllerService,
    NotificationsControllerService,
    OpenControllerService,
    PersonControllerService,
    ProductControllerService,
    SubscriptionControllerService,
    SyncronizationControllerService,
    UserControllerService
  ]
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
