import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { CompositionSubscribersModalComponent } from './composition-subscribers-modal/composition-subscribers-modal.component';
import { DraftSummaryRoutingModule } from './draft-summary-routing.module';
import { DraftSummaryComponent } from './draft-summary.component';
import { ReviewRejectModalComponent } from './review-reject-modal/review-reject-modal.component';

@NgModule({
  declarations: [
    DraftSummaryComponent,
    CompositionSubscribersModalComponent,
    ReviewRejectModalComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DraftSummaryRoutingModule,
    AppTableModule,
    TooltipModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    FloatLabelModule
  ]
})
export class DraftSummaryModule {}
