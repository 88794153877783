import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DraftFormModule } from './draft-form/draft-form.module';
import { DraftSummaryModule } from './draft-summary/draft-summary.module';
import { DraftComponent } from './draft.component';

const routes: Routes = [
  {
    path: '',
    component: DraftComponent,
    pathMatch: 'full'
  },
  {
    path: 'form',
    loadChildren: () => DraftFormModule
  },
  {
    path: 'summary',
    loadChildren: () => DraftSummaryModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DraftRoutingModule {}
