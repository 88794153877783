<table *ngIf="lateSubscribers">
  <thead>
    <tr>
      <th>Assinatura</th>
      <th>Edição</th>
      <th>Assinantes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let sub of lateSubscribers">
      <td>
        {{ subscriptionName(sub.lateEdition) }}
      </td>
      <td>
        {{ editionDate(sub.lateEdition) | titlecase }}
      </td>
      <td>{{ sub.subscribers }} assinantes</td>
    </tr>
  </tbody>
</table>
