import { CommonModule } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GlamHttpClient } from 'src/app/utils/glam-http-client';
import { AuthService } from './auth/auth.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: GlamHttpClient, deps: [HttpClient] },
    AuthService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ServicesModule {}
