/* eslint-disable @angular-eslint/no-output-native */
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MessagePayload } from '@angular/fire/messaging';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  BadgeDTO,
  Draft,
  DraftCompositionSummary,
  DraftControllerService,
  InfluencerStatus,
  LateSubscriber,
  PersonControllerService,
  SegmentationRequest,
  SubscriptionControllerService,
  SyncronizationControllerService,
  UserRoleViewResponse
} from 'src/app/allocation-api';
import { BlockPageComponent } from 'src/app/components/block-page/block-page.component';
import { ChangeListenerComponent } from 'src/app/components/change-listener/change-listener.component';
import { FirebaseTopic } from 'src/app/models/enums/FirebaseTopic';
import { SegmentationAction } from 'src/app/models/enums/SegmentationAction';
import { CookieService } from 'src/app/services/cookie.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';
import { LateSubscribersModalComponent } from '../late-subscribers-modal/late-subscribers-modal.component';

@Component({
  selector: 'app-draft-subscriber-filter-form',
  templateUrl: './draft-subscriber-filter-form.component.html',
  styleUrls: ['./draft-subscriber-filter-form.component.scss'],
  providers: [DatePipe, DialogService, ConfirmationService]
})
export class DraftSubscriberFilterFormComponent
  extends ChangeListenerComponent
  implements OnInit, OnChanges
{
  @Input()
  draft: Draft | undefined;

  @Input()
  draftOwner?: string;

  @Input()
  editionId: number | undefined;

  @Input()
  compositions: Array<DraftCompositionSummary> | undefined;

  @Input()
  user: UserRoleViewResponse | undefined;

  @Input()
  owner = true;

  @Input()
  responsible = true;

  time: Array<{ label: string; value: number }>;
  signerType: Array<{ label: string; value: number }>;
  signatureTime: Array<{ label: string; value: number }>;
  cycleOptions: Array<{ label: string; value: number }>;
  birthDay: Array<{ label: string; value: number }>;
  recurrence: Array<{ label: string; value: number }>;
  hasGift: Array<{ label: string; value: number }>;
  newBadge: Array<{ label: string; value: number }>;
  days: Array<{ label: string; value: number }>;
  partnerTypes: Array<InfluencerStatus> | undefined;
  segmentationForm: FormGroup | undefined;
  segmentationFilters: SegmentationRequest | undefined;
  badges: Array<BadgeDTO> | undefined;
  badgeType: [] | undefined;
  chipRegex: any = /\r|\n|;|,| /;
  subscribers: Array<number> | undefined;
  blockModal: DynamicDialogRef | undefined;
  unsync: number | undefined;
  dirty = false;
  lateSubscribers: Array<LateSubscriber> | undefined;
  showLate = false;
  totalBadges = 0;
  totalDays = 0;
  totalPersonTypes = 0;
  totalPartnerTypes = 0;
  finish = false;
  syncModal = false;

  constructor(
    private subscriptionService: SubscriptionControllerService,
    private personService: PersonControllerService,
    private draftService: DraftControllerService,
    private syncronizationService: SyncronizationControllerService,
    private datePipe: DatePipe,
    private router: Router,
    private dialog: DialogService,
    private confirmationService: ConfirmationService,
    private cdRef: ChangeDetectorRef
  ) {
    super([FirebaseTopic.SYNCRONIZATION]);
    this.recurrence = [
      { label: 'Habilitada', value: 1 },
      { label: 'Desabilitada', value: 0 },
      { label: 'Todas', value: -1 }
    ];
    this.signerType = [
      { label: 'Glamgirl', value: 1 },
      { label: 'Glampartner', value: 2 },
      { label: 'Friends and Family', value: 3 }
    ];
    this.signatureTime = [
      { label: 'Antigas', value: 0 },
      { label: 'Novas', value: 1 },
      { label: 'Todas', value: -1 }
    ];
    this.cycleOptions = [
      { label: 'Mantidas', value: 0 },
      { label: 'Renovação', value: 1 },
      { label: 'Todas', value: -1 }
    ];
    this.birthDay = [
      { label: 'Sim', value: 1 },
      { label: 'Não', value: 0 },
      { label: 'Todas', value: -1 }
    ];
    this.hasGift = [
      { label: 'Sim', value: 1 },
      { label: 'Não', value: 0 },
      { label: 'Todas', value: -1 }
    ];
    this.newBadge = [
      { label: 'Sim', value: 1 },
      { label: 'Não', value: 0 },
      { label: 'Todas', value: -1 }
    ];
    this.days = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
      { label: '13', value: 13 },
      { label: '14', value: 14 },
      { label: '15', value: 15 },
      { label: '16', value: 16 },
      { label: '17', value: 17 },
      { label: '18', value: 18 },
      { label: '19', value: 19 },
      { label: '20', value: 20 },
      { label: '21', value: 21 },
      { label: '22', value: 22 },
      { label: '23', value: 23 },
      { label: '24', value: 24 },
      { label: '25', value: 25 },
      { label: '26', value: 26 },
      { label: '27', value: 27 },
      { label: '28', value: 28 },
      { label: '29', value: 29 },
      { label: '30', value: 30 },
      { label: '31', value: 31 }
    ];
    this.time = [];
  }

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    if (this.isResponsible)
      await this.checkUnsyncronizedSubscribers(this.draft?.draftId as number);
    if (!this.unsync) {
      await this.init();
    }
    LoaderService.showLoader(false);
  }

  async init(): Promise<void> {
    LoaderService.showLoader();
    const [partnerTypes, badges, duplicated] = await Promise.all([
      lastValueFrom(
        this.personService.getPartnerTypes().pipe(map((data) => data.result))
      ),
      lastValueFrom(
        this.personService.getBadgesList().pipe(map((data) => data.result))
      ),
      lastValueFrom(
        this.draftService
          .findDraftDuplicatedSubscribers(this.draft?.draftId as number)
          .pipe(map((data) => data.result))
      )
    ]);
    this.partnerTypes = partnerTypes || [];
    this.badges = badges;
    if (
      duplicated?.length &&
      !this.compositions?.some((c) => c.compositionSubscribers)
    ) {
      await lastValueFrom(
        this.subscriptionService
          .segmentation({
            draftId: this.draft?.draftId as number,
            subscriberIds: []
          })
          .pipe(map((data) => data.result))
      );
    }
    let date = new Date();
    date = new Date(date.setMonth(date.getMonth() - 1));
    for (let index = 0; index <= 12; index++) {
      const month = this.datePipe.transform(date, 'MMMM', '', 'pt-BR') || '';
      this.time.push({
        label:
          month.substring(0, 1).toUpperCase() +
          month?.substring(1) +
          '/' +
          this.datePipe.transform(date, 'yyyy', '', 'pt-BR'),
        value: Number(
          this.datePipe.transform(date, 'yyyy', '', 'pt-BR') +
            '' +
            this.datePipe.transform(date, 'MM', '', 'pt-BR')
        )
      });
      date = new Date(date.setMonth(date.getMonth() + 1));
    }
    this.time.push({ label: 'Todos', value: 0 });
    if (this.draft?.draftId) {
      LoaderService.showLoader();
      try {
        this.segmentationFilters = await lastValueFrom(
          this.draftService
            .getDraftSubscriberFilters(this.draft?.draftId)
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        console.log(error);
        delete this.segmentationFilters;
      }
    }
    this.segmentationForm = new FormGroup({
      draftId: new FormControl(this.draft?.draftId, Validators.required),
      editionId: new FormControl(this.editionId, Validators.required),
      personTypes: new FormControl([1]),
      isNew: new FormControl(-1),
      isBirthday: new FormControl(-1),
      limit: new FormControl(0),
      partnerTypes: new FormControl([]),
      recurrenceMonth: new FormControl(0),
      recurrence: new FormControl(-1),
      badgeIsNew: new FormControl(-1),
      badgeIds: new FormControl(this.badges?.map((b) => b.badgeId)),
      consecutiveEditions: new FormControl(0), //ainda nao funciona
      referenceBoxIds: new FormControl([]),
      hasGift: new FormControl(-1),
      paymentDays: new FormControl(this.days.map((d) => d.value)),
      isNewCycle: new FormControl(-1)
    });
    if (this.draft?.draftId && this.segmentationFilters) {
      Object.keys(this.segmentationFilters).forEach((key: string) => {
        if (
          (this.segmentationFilters as any)[key] !== null &&
          key !== 'draftId' &&
          key !== 'editionId'
        ) {
          this.segmentationForm
            ?.get(key)
            ?.setValue((this.segmentationFilters as any)[key]);
        }
      });
    }
    this.updateTotalBadges(false);
    this.updateTotalDays(false);
    this.cdRef.detectChanges();
    this.dirty =
      this.segmentationFilters?.editionId !== undefined &&
      this.segmentationFilters.editionId !== this.editionId;
    if (
      ((this.isOwner || this.isResponsible) && this.compositions?.length) ||
      this.compositions?.some((a) => a.compositionSubscribers)
    ) {
      this.segmentationForm.disable();
      this.subscribers = await lastValueFrom(
        this.subscriptionService
          .getSegmentation(this.draft?.draftId as number)
          .pipe(
            map((data) => data.result?.map((d) => d.subscriberId as number))
          )
      );
      // } else if (this.compositions?.length && this.compositions[0].editionId === this.editionId) {
      //   this.totalCompositions = this.compositions[0].draftTotalSubscribers;
    } else
      await this.filters(
        this.compositions &&
          this.compositions.some((c) => c.compositionSubscribers)
      );
    LoaderService.showLoader(false);
  }

  async clearSync(): Promise<void> {
    delete this.unsync;
    this.syncModal = false;
    await this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['editionId']?.previousValue &&
      changes['editionId']?.currentValue &&
      changes['editionId']?.currentValue !== changes['editionId']?.previousValue
    ) {
      this.segmentationForm
        ?.get('editionId')
        ?.setValue(changes['editionId'].currentValue);
      delete this.subscribers;
    }
  }

  async submitForm(): Promise<void> {
    if (
      this.isResponsible &&
      this.segmentationForm?.enabled &&
      this.segmentationForm?.valid &&
      this.dirty
    ) {
      LoaderService.showLoader();
      this.segmentationFilters = this.segmentationForm.value;
      await lastValueFrom(
        this.subscriptionService
          .segmentation({
            draftId: this.draft?.draftId as number,
            subscriberIds: this.subscribers as Array<number>
          })
          .pipe(map((data) => data.result))
      );
      this.blockPage({
        topic: 'allocation-sync',
        status: 'PersonProductVariants',
        statusId: 2
      });
      await lastValueFrom(
        this.syncronizationService.syncronizeProductsAndHistory({})
      );
      this.finish = true;
      LoaderService.showLoader(false);
    } else {
      this.next();
    }
  }

  next(): void {
    if (
      this.compositions?.length &&
      this.compositions.some((c) => c.compositionSubscribers)
    ) {
      this.goToSegmentation();
    } else {
      this.goToDefaultProduct();
    }
  }

  goToSegmentation(): void {
    this.router.navigate([
      'draft/form/id/' + this.draft?.draftId + '/segmentation'
    ]);
  }

  goToDefaultProduct(): void {
    this.router.navigate([
      'draft/form/id/' + this.draft?.draftId + '/default-products'
    ]);
  }

  async filters(first = false): Promise<void> {
    if (
      this.segmentationForm &&
      (this.segmentationForm.dirty || !this.segmentationForm.touched)
    ) {
      LoaderService.showLoader();
      delete this.subscribers;
      this.segmentationFilters = this.segmentationForm.value;
      try {
        const [subscribers, lateSubscribers] = await Promise.all([
          lastValueFrom(
            this.subscriptionService
              .segmentationConsult(this.segmentationFilters)
              .pipe(map((data) => data.result))
          ),
          lastValueFrom(
            this.draftService
              .findLateSubscribers(this.editionId as number)
              .pipe(map((data) => data.result))
          )
        ]);
        this.subscribers = subscribers;
        this.lateSubscribers = lateSubscribers;
        this.dirty = !first;
        this.showLate = false;
        this.segmentationForm.markAsPristine();
      } catch (error) {
        alert(error);
      }
      LoaderService.showLoader(false);
    }
  }

  indexOfBadge(badge: BadgeDTO): number {
    return this.badges?.indexOf(badge) || -1;
  }

  async validateNumber(): Promise<void> {
    if (
      this.segmentationForm?.value?.limit === null ||
      this.segmentationForm?.value?.limit === undefined
    ) {
      this.segmentationForm?.get('limit')?.setValue(0);
    }
    await this.filters();
  }

  async valueChanges(): Promise<void> {
    await this.filters();
  }

  async checkUnsyncronizedSubscribers(draftId: number): Promise<void> {
    try {
      let cookie: any = CookieService.getCookie('sync');
      if (cookie) {
        cookie = JSON.parse(cookie);
        if (Number(cookie.statusId) > 0) {
          this.blockPage(cookie);
          return;
        }
      }
      this.unsync = await lastValueFrom(
        this.draftService
          .unsyncronizedSubscribers(draftId)
          .pipe(map((data) => data.result))
      );
      if (this.unsync && this.unsync < 0) {
        this.unsync = -this.unsync;
      }
      this.syncModal = this.hasSync;
    } catch (error) {
      console.log(error);
      console.log('Não foi possível verificar a sincronização');
    }
  }

  blockPage(data: { [key: string]: any }): void {
    CookieService.setCookie('sync', JSON.stringify(data), 0, 1);
    if (!this.blockModal)
      this.blockModal = this.dialog.open(BlockPageComponent, {
        closable: false,
        closeOnEscape: false,
        showHeader: false,
        width: '500px',
        styleClass: 'block-page',
        height: '300px',
        data
      });
  }

  async unblockPage(): Promise<void> {
    CookieService.eraseCookie('sync');
    if (this.blockModal) {
      this.blockModal.close();
      delete this.blockModal;
    }
    if (this.finish) this.next();
    else await this.init();
  }

  async syncronize(): Promise<void> {
    this.blockPage({
      topic: 'allocation-sync',
      status: 'SubscriberSegmentation',
      statusId: 1
    });
    delete this.unsync;
    this.syncModal = false;
    await lastValueFrom(this.syncronizationService.syncronizeSubscribers());
  }

  async onMessageReceived(payload: MessagePayload): Promise<void> {
    console.log(payload);
    if (payload.data && payload.data['topic']) {
      this.handlePayload(payload.data);
    }
  }

  async onPageFocus(): Promise<void> {
    const cookies = [
      'products',
      'draft_' + this.draft?.draftId,
      'permissions',
      'segmentation_' + this.draft?.draftId,
      'sync'
    ];
    let data: any;
    for (let cookieName of cookies) {
      let cookie = CookieService.getCookie(cookieName);
      if (cookie) {
        data = JSON.parse(cookie);
        if (Object.keys(data).length) {
          data['topic'] = data['topic'].replace('-dev', '');
          await this.handlePayload(data);
        }
        if (cookieName !== 'sync') CookieService.eraseCookie(cookieName);
      }
    }
  }

  async handlePayload(data: { [key: string]: any }): Promise<void> {
    if (this.draft) {
      switch (data['topic']) {
        case FirebaseTopic.SEGMENTATION:
          if (
            this.draft.draftId === Number(data['draftId']) &&
            data['action'] &&
            !this.isResponsible
          ) {
            data['action'] = Number(data['action']);
            switch (data['action']) {
              case SegmentationAction.Draft:
                this.draftClosedWarning(
                  'O draft foi finalizado pelo responsável. O que deseja fazer?'
                );
                break;
              case SegmentationAction.Refresh:
                LoaderService.showLoader();
                window.location.reload();
                break;
              default:
                break;
            }
          }
          break;
        case FirebaseTopic.SYNCRONIZATION:
          if (Number(data['statusId']) === 0) {
            this.unblockPage();
          } else if (Number(data['statusId']) === 3) {
            this.blockPage(data);
          } else {
            BroadcastUtil.get('sync').emit(Number(data['statusId']));
            CookieService.setCookie('sync', JSON.stringify(data), 0, 0.5);
          }
          break;
        default:
          break;
      }
    } else if (data['topic'] === FirebaseTopic.SYNCRONIZATION) {
      if (Number(data['statusId']) === 0) {
        this.unblockPage();
      } else if (!this.blockModal) {
        this.blockPage(data);
      } else {
        BroadcastUtil.get('sync').emit(Number(data['statusId']));
        CookieService.setCookie('sync', JSON.stringify(data), 0, 0.5);
      }
    }
  }

  draftClosedWarning(message: string): void {
    this.confirmationService.confirm({
      message,
      acceptLabel: 'Ver resumo',
      acceptButtonStyleClass: 'p-button',
      acceptIcon: '',
      rejectLabel: 'Voltar ao início',
      rejectIcon: '',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.router.navigate(['draft/summary/id/' + this.draft?.draftId]);
      },
      reject: () => {
        this.router.navigate(['draft']);
      }
    });
    0;
  }

  showLateSubscribers(): void {
    this.dialog.open(LateSubscribersModalComponent, {
      header: 'Assinantes aguardando alocação',
      data: {
        lateSubscribers: this.lateSubscribers
      }
    });
  }

  updateTotalBadges(detect = true): void {
    this.totalBadges = this.segmentationForm?.value.badgeIds.length;
    if (detect) this.cdRef.detectChanges();
  }

  updateTotalDays(detect = true): void {
    this.totalDays = this.segmentationForm?.value.paymentDays.length;
    if (detect) this.cdRef.detectChanges();
  }

  updateTotalPersonTypes(detect = true): void {
    this.totalPersonTypes = this.segmentationForm?.value.personTypes.length;
    if (detect) this.cdRef.detectChanges();
  }

  updateTotalPartnerTypes(detect = true): void {
    this.totalPartnerTypes = this.segmentationForm?.value.partnerTypes.length;
    if (detect) this.cdRef.detectChanges();
  }

  get paymentDaysLabel(): string {
    return this.totalDays === 31 ? 'Todos' : this.totalDays + ' itens';
  }

  get isOwner(): boolean {
    return this.draft?.owner === this.user?.username;
  }

  get isResponsible(): boolean {
    return this.draft?.responsible === this.user?.username;
  }

  get subscriptionIdName(): string {
    switch (this.editionId?.toString()[0]) {
      case '5':
        return 'Bag Ids';
      case '6':
        return 'Pass Ids';
      default:
        return 'Box Ids';
    }
  }

  get hasSync(): boolean {
    return this.unsync !== undefined && this.unsync > 0 && this.isResponsible;
  }

  get totalLateSubscribers(): number {
    return (
      this.lateSubscribers?.reduce(
        (sum, s) => (sum += s.subscribers || 0),
        0
      ) || 0
    );
  }

  get badgeIdsLabel(): string {
    return this.totalBadges === 6 ? 'Todos' : this.totalBadges + ' itens';
  }

  get personTypesLabel(): string {
    return this.totalPersonTypes === 2 ? 'Todos' : '1 iten';
  }

  get showPartnerType(): boolean {
    return (this.segmentationForm?.value?.personTypes as Array<number>)?.some(
      (v) => v === 2
    );
  }

  get compositionOpen() {
    return this.compositions?.some((c) => !c.draftCompositionStatus);
  }
}
